import { NavNode } from "../types/NavMenu";
import { Home } from "common/icons";

const navStructure: NavNode[] = [
    {
        text: "Management",
        testId: "management",
        navItemGroups: [
            {
                text: "My Group",
                icon: Home,
                testId: "mygroup",
                children: [
                    {
                        text: "Settings",
                        permission: "group:read",
                        route: "settings",
                        testId: "groupsettings",
                    },
                    {
                        text: "Users",
                        permission: "usermanagement:read",
                        route: "users",
                        testId: "users",
                    },
                ],
            },
        ],
    },
];

export const getNavStructure = () => navStructure;
