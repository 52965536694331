import { AppState } from "features";
import { posOperationBehaviorFilter } from "features/posConfiguration/selectors/posOperationFilter";

export const showPriceListsFilter = () => (state: AppState) =>
    posOperationBehaviorFilter("OrderCreate", "AcceptOverrideProductPrices")(state) ||
    posOperationBehaviorFilter("OrderCreate", "AcceptOverrideModifierPrices")(state);

export const showPriceListsItemSupport = (state: AppState) => {
    return {
        acceptOverrideProductPrices: posOperationBehaviorFilter("OrderCreate", "AcceptOverrideProductPrices")(state),
        acceptOverrideModifierPrices: posOperationBehaviorFilter("OrderCreate", "AcceptOverrideModifierPrices")(state),
    };
};
