import { createSelector } from "reselect";
import { PosCatalogueImportModifier } from "../types";
import { getModifierPosCatalogueItems } from "./getPosCatalogueItems";

// quick look up of all pos catalogue modifiers keyed by id
// `undefined` won't appear in the data but is included in the value type to force consumers to handle the possibility of broken references
export const getModifierPosCatalogueItemsById = createSelector(
    getModifierPosCatalogueItems,
    (modifiers): Record<string, PosCatalogueImportModifier | undefined> => {
        return Object.fromEntries(modifiers.map((modifier) => [modifier.id, modifier]));
    }
);
