import { Reloaded } from "common/loader";
import { ServiceCalendarData, ServiceCalendarSectionItem } from "../types/ServiceCalendarData";
import { ServiceCalendarRequestDataInput } from "../types/ServiceCalendarRequestDataInput";
import { getDateStringArray } from "../selectors/getCalendar";
import moment from "moment";

export type State = Reloaded<ServiceCalendarData>;

export enum TypeKeys {
    FETCH_BEGIN = "SERVICE_CALENDAR/FETCH_BEGIN",
    FETCH_SUCCESS = "SERVICE_CALENDAR/FETCH_SUCCESS",
    FETCH_FAILURE = "SERVICE_CALENDAR/FETCH_FAILURE",
    RESET = "SERVICE_CALENDAR/RESET",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.FETCH_BEGIN }),
    loaded: (range: ServiceCalendarRequestDataInput, data: ServiceCalendarData) => ({
        type: TypeKeys.FETCH_SUCCESS,
        data,
        range,
    }),
    failed: (error: any) => ({ type: TypeKeys.FETCH_FAILURE, error }),
    reset: () => ({ type: TypeKeys.RESET }),
};

type LoadingAction = { type: TypeKeys.FETCH_BEGIN };
type LoadedAction = { type: TypeKeys.FETCH_SUCCESS; range: ServiceCalendarRequestDataInput; data: ServiceCalendarData };
type FailedAction = { type: TypeKeys.FETCH_FAILURE; error: any };
type ResetAction = { type: TypeKeys.RESET };

export type ServiceCalendarAction = LoadingAction | LoadedAction | FailedAction | ResetAction;

const initialState: State = { status: "unloaded" };

export function reducer(state: State = initialState, action: ServiceCalendarAction): State {
    if (action.type === TypeKeys.FETCH_BEGIN) {
        return {
            ...state,
            status: "loading",
        };
    }

    if (action.type === TypeKeys.FETCH_SUCCESS) {
        const { data, range } = action;
        const existingData = state.status === "loading" ? state.data : undefined;
        const mergedCalendarData = mergeDates(existingData, fillMissingDates(range, data));

        return {
            ...state,
            status: "loaded",
            data: mergedCalendarData,
        };
    }

    if (action.type === TypeKeys.FETCH_FAILURE) {
        const { error } = action;

        return {
            ...state,
            status: "failed",
            error,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return initialState;
    }

    return state;
}

function mergeDates(existing: ServiceCalendarData | undefined, newData: ServiceCalendarData): ServiceCalendarData {
    if (!existing) {
        return newData;
    }

    const newSections = newData.sections.filter((n) => !existing.sections.find((e) => e.id === n.id)) ?? [];

    const merged = existing.sections
        .map((section) => {
            const newSectionData = newData.sections.find((x) => x.id === section.id);

            if (newSectionData) {
                const existingDates = section.dates.filter((e) => !newSectionData.dates.find((n) => n.date === e.date));
                section.dates = existingDates
                    .concat(newSectionData.dates)
                    .sort((a, b) => (moment(a.date) > moment(b.date) ? 1 : -1));
            }
            return section;
        }, [] as ServiceCalendarSectionItem[])
        .filter(Boolean);

    return {
        sections: newSections.concat(merged),
    };
}

export function fillMissingDates(
    range: ServiceCalendarRequestDataInput,
    data: ServiceCalendarData
): ServiceCalendarData {
    return {
        sections: data.sections.map((calendarSection) => {
            const dates = getDateStringArray(range.startDate, range.dayCount).map((date) => {
                if (!calendarSection) {
                    return {
                        date,
                        calendarItems: [],
                    };
                }

                const calendarItem = calendarSection.dates.find((x) => x.date === date);
                return {
                    date,
                    calendarItems: calendarItem?.calendarItems ?? [],
                };
            });

            return {
                ...calendarSection,
                dates: dates,
            };
        }),
    };
}
