import { Scope } from "./Scope";

export const GROUP_PATH = "group";

export const LOCATION_PATH = "venue";

export const TEAMS_PATH = "teams";

// path parameter for given scope
export const SCOPE_PATH_MAP = {
    [Scope.LOCATION]: LOCATION_PATH,
    [Scope.GROUP]: GROUP_PATH,
    [Scope.TEAMS]: TEAMS_PATH,
};
