import { combineReducers } from "redux";
import { State as RegionState, reducer as regionReducer } from "./region";
import { State as RegionsState, reducer as regionsReducer } from "./regions";

export interface State {
    region: {
        selectedRegion: RegionState;
        availableRegions: RegionsState;
    };
}

export const reducer = combineReducers({
    selectedRegion: regionReducer,
    availableRegions: regionsReducer,
});
