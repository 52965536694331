import { UserAccess } from "../types/UserAccess";
import { Loaded } from "common/loader";

export type State = Loaded<UserAccess[]>;

enum TypeKeys {
    LOADING = "USERS/LIST_BEGIN",
    SUCCESS = "USERS/LIST_SUCCESS",
    FAILURE = "USERS/LIST_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: UserAccess[]) => ({ type: TypeKeys.SUCCESS, data }),
    loadFailed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type UserListLoadingAction = { type: TypeKeys.LOADING };
type UserListLoadedAction = { type: TypeKeys.SUCCESS; data: UserAccess[] };
type UserListFailedAction = { type: TypeKeys.FAILURE; error: any };

type UserListAction = UserListLoadingAction | UserListLoadedAction | UserListFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: UserListAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
