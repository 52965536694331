import { Loaded } from "common/loader";
import { EditTypeKeys } from "./scaffoldEdit";
import { AnyAction } from "redux";

export interface ListTypeKeys {
    LOADING: string;
    LOADED: string;
    FAILED: string;
}

export function scaffoldList<
    TData extends { id?: TKey },
    TTypeKeys extends ListTypeKeys,
    TEditTypeKeys extends EditTypeKeys = EditTypeKeys,
    TKey = string
>(typeKeys: TTypeKeys, editTypeKeys?: TEditTypeKeys | TEditTypeKeys[], compareFn?: (a: TData, b: TData) => number) {
    type LoadingAction = { type: TTypeKeys["LOADING"] };
    type LoadedAction = { type: TTypeKeys["LOADED"]; data: TData[] };
    type LoadFailedAction = { type: TTypeKeys["FAILED"]; error: any };
    type SavedAction = { type: TEditTypeKeys["SAVE_SUCCESS"]; key: TKey; data: TData | null };

    if (editTypeKeys === undefined) {
        editTypeKeys = [];
    } else if (!Array.isArray(editTypeKeys)) {
        editTypeKeys = [editTypeKeys];
    }

    type AllActions = LoadingAction | LoadedAction | LoadFailedAction | SavedAction;

    const createAction = {
        loading: (): LoadingAction => ({ type: typeKeys.LOADING }),
        loaded: (data: TData[]): LoadedAction => ({ type: typeKeys.LOADED, data }),
        loadFailed: (error: any): LoadFailedAction => ({ type: typeKeys.FAILED, error }),
    };

    type State = Loaded<TData[]>;

    const initialState: State = {
        status: "unloaded",
    };

    function isAction<TAction extends AnyAction>(action: AnyAction, type: string): action is TAction {
        return action.type === type;
    }

    function reducer(state: State = initialState, action: AllActions): State {
        if (isAction<LoadingAction>(action, typeKeys.LOADING)) {
            return {
                status: "loading",
            };
        }

        if (isAction<LoadedAction>(action, typeKeys.LOADED)) {
            const { data } = action;

            return {
                status: "loaded",
                data,
            };
        }

        if (isAction<LoadFailedAction>(action, typeKeys.FAILED)) {
            const { error } = action;

            return {
                status: "failed",
                error,
            };
        }

        if (editTypeKeys) {
            for (const editTypeKey of editTypeKeys as TEditTypeKeys[]) {
                if (isAction<SavedAction>(action, editTypeKey.SAVE_SUCCESS)) {
                    const { key } = action;
                    const data: TData = (action as any).data;

                    if (editTypeKey === editTypeKeys[0]) {
                        if (state.status === "loaded") {
                            if (key && data === null) {
                                // Removed
                                return {
                                    ...state,
                                    data: state.data.filter((item) => item.id !== key),
                                };
                            }

                            if (key && data) {
                                // Updated

                                let newData = state.data.map((item) => (item.id === data.id ? data : item));

                                if (compareFn) {
                                    newData = newData.sort(compareFn);
                                }

                                return {
                                    ...state,
                                    data: newData,
                                };
                            }

                            if (!key && data) {
                                // Added

                                let newData = [...state.data, data];

                                if (compareFn) {
                                    newData = newData.sort(compareFn);
                                }

                                return {
                                    ...state,
                                    data: newData,
                                };
                            }
                        }
                    }

                    return {
                        status: "unloaded",
                    };
                }
            }
        }

        return state;
    }

    return {
        createAction,
        reducer,
        initialState,
    };
}
