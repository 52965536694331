export const analyticsLayoutConfigQueryFields = `
    folders {
        id
        displayName
        slug
        pages {
            id
            displayName
            slug
            tabs {
                id
                displayName
                permission
            }
        }
    }
`;
