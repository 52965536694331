import { FeatureConfiguration } from "features";
import { GroupTabs } from "./components/GroupTabs";
import { reducer } from "./reducers";
import * as actions from "./actions";

export * from "features/groupTabs/types";
export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.groupTabs = reducer;

    config.addRoutes(config.routes.locationRoutes, [<GroupTabs key="grouptabs" />]);
}
