import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { Teams } from "./components/Teams";
import * as actions from "./actions";

export * from "./types";
export { actions };
export { teamsRoute } from "./util/routes";

export default function (config: FeatureConfiguration) {
    config.reducers.team = reducer;

    config.addRoutes(config.routes.teamsRoutes, [<Teams key="teams" />]);
}
