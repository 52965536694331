import { region } from "features/region";
import { Popup } from "core/components/popup";
import { useActiveScope } from "common/hooks/useActiveScope";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { lazy } from "react";

const LocationListContainer = lazy(() =>
    import("../containers/LocationListContainer").then((module) => ({
        default: module.LocationListContainer,
    }))
);

export const LocationSelector = () => {
    const activeScope = useActiveScope();

    if (!region.hasAccess() || !activeScope) {
        return null;
    }

    return (
        <TestContext.Provider value={TestAttributePrefix.LOCATION_SELECTOR}>
            <Popup
                buttonLabel={activeScope?.displayName ?? "Select location..."}
                offset={{ x: -4, y: 4 }}
                align="bottom-left"
                clickInsideCloses={false}
                lazy={true}
            >
                <LocationListContainer />
            </Popup>
        </TestContext.Provider>
    );
};
