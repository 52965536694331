import { Timestamped } from "common/data/timetamped";
import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";

export enum TypeKeys {
    FETCH_BEGIN = "POS_CONFIGURATION/FETCH_DATA_BEGIN",
    FETCH_SUCCESS = "POS_CONFIGURATION/FETCH_DATA_SUCCESS",
    FETCH_FAILURE = "POS_CONFIGURATION/FETCH_DATA_FAILURE",

    SAVE_BEGIN = "POS_CONFIGURATION/SAVE_DATA_BEGIN",
    SAVE_SUCCESS = "POS_CONFIGURATION/SAVE__DATA_SUCCESS",
    SAVE_FAILURE = "POS_CONFIGURATION/SAVE_DATA_FAILURE",
}

const edit = scaffoldEdit<Timestamped<PosConfigurationSetting[]>, void, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
