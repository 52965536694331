import { useTestAttributes } from "../testAttributes";
import styles from "./Card.module.scss";

interface Props {
    title: string;
    titleAs?: React.ElementType;
}

export const Title = ({ title, titleAs: TitleComponent = "h2", ...rest }: Props & Record<string, any>) => {
    const { getTestClass } = useTestAttributes();

    return (
        <TitleComponent
            className={styles.title}
            data-testclass={getTestClass({ fragment: "section", element: "title" })}
            {...rest}
        >
            {title}
        </TitleComponent>
    );
};
