import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ViewBrandingPageContainer = lazy(() =>
    import("../containers/ViewBrandingPageContainer").then((module) => ({
        default: module.ViewBrandingPageContainer,
    }))
);

const EditBrandingPageContainer = lazy(() =>
    import("../containers/EditBrandingPageContainer").then((module) => ({
        default: module.EditBrandingPageContainer,
    }))
);

export function Branding() {
    return (
        <Route
            path={"/:region/venue/:location/branding"}
            key="branding"
            render={() => (
                <TestContext.Provider value={TestAttributePrefix.FEATURE_BRANDING}>
                    <Col span={24} xl={12} className="content__col content__col--branding">
                        <div className="content__col-view">
                            <Route component={withPermissions(ViewBrandingPageContainer, "location:update")} />
                        </div>
                    </Col>
                    <Col span={24} xl={12} className="content__col content__col--branding">
                        <div className="content__col-edit">
                            <Route component={withPermissions(EditBrandingPageContainer, "location:update")} />
                        </div>
                    </Col>
                </TestContext.Provider>
            )}
        />
    );
}
