import { opsApi } from "API";
import { VenueAddress } from "features/location";

export const saveVenueAddress = async (locationId: string | undefined, venueAddress: VenueAddress) => {
    const res = await opsApi.graphQLQuery<{ venueAddress: VenueAddress }>(updateQuery, {
        locationId,
        venueAddress,
    });

    return res.data.updateVenueAddress;
};

const updateQuery = `
    mutation ($locationId: ID!, $venueAddress: VenueAddressUpdateInput!) {
        updateVenueAddress(locationId: $locationId, venueAddress: $venueAddress) {
            id,
            slug,
            venueAddress {
                addressLine1,
                addressLine2,
                city,
                postcode,
                region,
                country,
                googleBusinessName
            }
        }
    }
`;
