export enum SectionPartyType {
    TABLE = "TABLE",
    TAB = "TAB",
}

export const PartyType = {
    [SectionPartyType.TABLE]: {
        text: "Tables",
        description: "Group Flex experience. Only one tab per table. Everyone will be sharing the same tab.",
    },
    [SectionPartyType.TAB]: {
        text: "Tabs",
        description: "Individual Flex experience. Multiple tabs on the same table.",
    },
};

export const partyTypeOptions = [SectionPartyType.TABLE, SectionPartyType.TAB].map((key) => {
    return { text: PartyType[key].text, description: PartyType[key].description, value: key };
});
