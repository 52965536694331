import { opsApi } from "API";
import { ImportLocationResult } from "../types";

type GraphQLPayload = { importLocationResult: ImportLocationResult };

export const importLocation = async (childLocationId: string): Promise<ImportLocationResult> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(fetchQuery, { childLocationId });
        return mapImportLocationResult(res.data.importParentLocation);
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const fetchQuery = `
    mutation ($childLocationId: ID!) {
        importParentLocation(childLocationId: $childLocationId) {
            # This is the full shape of the response, but we only need a subset for success/failure
            # TODO decide exactly what we need by end of Brand Catalogue epic https://meanduapp.atlassian.net/browse/ROCK-506 
            # summary {
            #     products {
            #         result,
            #         count,
            #     },
            #     variants {
            #         result,
            #         count,
            #     },
            #     modifierGroups {
            #         result,
            #         count,
            #     },
            #     modifierOptions {
            #         result
            #         count
            #     },
            #     productsMissingContent,
            #     modifierGroupsMissingContent,
            # },
            errors {
                sourceId,
                message,
                objectType,
                level,
            },
            # startedRunning,
            # runningTaskDetails {
            #     isRunning,
            #     taskId,
            #     startedAtUtc,
            #     user,
            #     tag
            # }
        }
    }
`;

const mapImportLocationResult = (importLocationResult: ImportLocationResult): ImportLocationResult => {
    // TODO confirm shape/types of API response
    return {
        ...importLocationResult,
    };
};
