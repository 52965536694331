// prevent iOS from auto scaling viewport when form font size < 16px
// TODO userAgent is deprecated. Waiting for alternate solution
export const preventAutoZoom = () => {
    try {
        // add conditionally because this would disable user scaling on Android (iOS10+ ignores)
        if (navigator.userAgent.match(/iPhone|iPod/i)) {
            const viewport = document.querySelector("meta[name=viewport]");
            viewport?.setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1");
        }
    } catch {}
};
