import { lazy } from "react";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const PosCataloguePageContainer = lazy(() =>
    import("../containers/PosCataloguePageContainer").then((module) => ({
        default: module.PosCataloguePageContainer,
    }))
);

const catalogueList: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: withPermissions(PosCataloguePageContainer, "poscatalogue:read"),
};

export function PosCatalogue() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_POS_CATALOGUE}>
            <ModalCrudRouteContainer
                name="catalogue"
                listComponent={catalogueList}
                listWidth={22}
                route="pos/catalogue"
            />
        </TestContext.Provider>
    );
}
