import { FeatureConfiguration } from "features";
import { reducer } from "features/posCatalogue/reducers";
import { PosCatalogue } from "./components/PosCatalogue";
import * as actions from "features/posCatalogue/actions";

export { actions };
export * from "features/posCatalogue/types";

export default function (config: FeatureConfiguration) {
    config.reducers.posCatalogue = reducer;

    config.addRoutes(config.routes.locationRoutes, [<PosCatalogue key="pos-catalogue" />]);
}
