import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";
import { CataloguePermissionsError } from "./CataloguePermissionsError";

const CataloguePage = lazy(() => import("./CataloguePage").then((module) => ({ default: module.CataloguePage })));

const EditProductPage = lazy(() =>
    import("./product/edit/EditProductPage").then((module) => ({ default: module.EditProductPage }))
);

const EditModifierPage = lazy(() =>
    import("./modifier/EditModifierPage/EditModifierPage").then((module) => ({
        default: module.EditModifierPage,
    }))
);

const catalogueList: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: withPermissions(CataloguePage, "catalogue:read", <CataloguePermissionsError />),
    contentWidth: ContentWidth.WIDE,
    wrapper: ListWrapper,
};

const productEdit: EditComponent = {
    route: "products/:id",
    component: withPermissions(EditProductPage, "catalogue:read", <CataloguePermissionsError />),
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

const modifierEdit: EditComponent = {
    route: "modifiers/:id",
    editTitle: "Edit modifier",
    createTitle: "Create modifier",
    component: withPermissions(EditModifierPage, "catalogue:read", <CataloguePermissionsError />),
    wrapper: ModalEditWrapper,
    contentWidth: ContentWidth.NARROW,
};

export function Catalogue() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_CATALOGUE}>
            <ModalCrudRouteContainer
                name="catalogue"
                listComponent={catalogueList}
                editComponents={[productEdit, modifierEdit]}
                route="menu/catalogue"
            />
        </TestContext.Provider>
    );
}
