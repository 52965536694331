import { createSelector } from "reselect";
import { AppState } from "features/state";
import { ProductSummary } from "features/catalogue/types";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

const getProducts = (state: AppState) => state.menuItems.list;

export const getProductsListStatus = createSelector(getProducts, (products) => products.status);

// consumers interested in the hidden or isLinked properties of products should use this selector and not the store directly
export const getProductsListData = createSelector(
    getProducts,
    getIsChildLocation,
    (products, isChildLocation): ProductSummary[] => {
        if (products.status !== "loaded") {
            return [];
        }

        return products.data.map((product) => {
            return {
                ...product,
                isLinked: !!(product.isLinked && isChildLocation),
                isHidden: !!(product.isHidden || (isChildLocation && product.isParentHidden)),
            };
        });
    }
);
