import { auth } from "common/auth";
import { AppDispatch, AppMiddleware } from "features/state";
import { region, fetchRegion } from ".";
import { actionCreators } from "./reducers/region";
import { actionCreators as availableRegionActions } from "./reducers/regions";

function handleRegion(store: AppMiddleware) {
    setTimeout(async () => {
        store.dispatch(actionCreators.loadingRegion("loading"));

        try {
            const activeRegion = await region.init(auth.getAvailableRegions());

            store.dispatch(actionCreators.regionChanged(activeRegion));
            store.dispatch(availableRegionActions.addRegion(activeRegion));

            const otherRegions = auth.getAvailableRegions()?.filter((region) => {
                return region !== activeRegion.id;
            });

            otherRegions?.forEach(async (r) => {
                const res = await fetchRegion(r);
                store.dispatch(availableRegionActions.addRegion(res));
            });
        } catch {
            store.dispatch(actionCreators.loadingRegion("failed"));
        }
    }, 0);
}

export const regionMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    if (auth.isAuthenticated()) {
        handleRegion(store);
    }

    auth.onLogin(() => {
        handleRegion(store);
    });

    return (action: any) => {
        return next(action);
    };
};
