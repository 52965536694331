import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ListTaxesPageContainer = lazy(() =>
    import("../containers/ListTaxesPageContainer").then((module) => ({
        default: module.ListTaxesPageContainer,
    }))
);

const EditTaxPageContainer = lazy(() =>
    import("../containers/EditTaxPageContainer").then((module) => ({
        default: module.EditTaxPageContainer,
    }))
);

const List: ListComponent = {
    route: "",
    component: withPermissions(ListTaxesPageContainer, "taxes:read"),
};

const Edit: EditComponent = {
    route: ":id",
    editTitle: "Edit tax",
    createTitle: "Add tax",
    component: withPermissions(EditTaxPageContainer, "taxes:update"),
};

export function Taxes() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_TAXES}>
            <ModalCrudRouteContainer
                name="taxes"
                listComponent={List}
                editComponents={[Edit]}
                listWidth={22}
                route="menu/taxes"
            />
        </TestContext.Provider>
    );
}
