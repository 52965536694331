import { withSvg } from "core/components/svg/Svg";

export const Marketing = withSvg(
    24,
    24
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.75024 13C6.16446 13 6.50024 13.3358 6.50024 13.75V16.4167C6.50024 17.1258 6.75451 17.7921 7.18639 18.272C7.61594 18.7492 8.18054 19 8.75024 19C9.31995 19 9.88455 18.7492 10.3141 18.272C10.746 17.7921 11.0002 17.1258 11.0002 16.4167V15.75C11.0002 15.3358 11.336 15 11.7502 15C12.1645 15 12.5002 15.3358 12.5002 15.75V16.4167C12.5002 17.4756 12.1224 18.505 11.429 19.2754C10.7334 20.0484 9.77183 20.5 8.75024 20.5C7.72865 20.5 6.76713 20.0484 6.07145 19.2754C5.37812 18.505 5.00024 17.4756 5.00024 16.4167V13.75C5.00024 13.3358 5.33603 13 5.75024 13Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7502 3C22.1644 3 22.5002 3.33579 22.5002 3.75V4.96437C22.5004 4.97561 22.5004 4.98683 22.5002 4.99802V18.502C22.5004 18.5132 22.5004 18.5244 22.5002 18.5356V19.75C22.5002 20.1642 22.1644 20.5 21.7502 20.5C21.336 20.5 21.0002 20.1642 21.0002 19.75V19.0898L2.50019 13.9667V14.2115C2.50019 14.6258 2.1644 14.9615 1.75019 14.9615C1.33598 14.9615 1.00019 14.6258 1.00019 14.2115V12.998C0.999937 12.9868 0.999936 12.9756 1.00019 12.9644V10.5356C0.999936 10.5244 0.999937 10.5132 1.00019 10.502V9.28846C1.00019 8.87425 1.33598 8.53846 1.75019 8.53846C2.1644 8.53846 2.50019 8.87425 2.50019 9.28846V9.53331L21.0002 4.41024V3.75C21.0002 3.33579 21.336 3 21.7502 3ZM21.0002 5.96669L2.50019 11.0898V12.4102L21.0002 17.5333V5.96669Z"
        />
    </>
));
