import { KeyedLoaded } from "common/loader";
import { UploadResult } from "../types/UploadResult";

export enum TypeKeys {
    LOADING = "POS_CATALOGUE_IMPORT/UPLOAD_BEGIN",
    SUCCESS = "POS_CATALOGUE_IMPORT/UPLOAD_SUCCESS",
    FAILURE = "POS_CATALOGUE_IMPORT/UPLOAD_FAILURE",
    RESET = "POS_CATALOGUE_IMPORT/RESET",
}
export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: UploadResult) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
    reset: () => ({ type: TypeKeys.RESET }),
};

type LoadingAction = { type: TypeKeys.LOADING; key: string };
type LoadedAction = { type: TypeKeys.SUCCESS; key: string; data: UploadResult };
type FailedAction = { type: TypeKeys.FAILURE; key: string; error: any };
type ResetAction = { type: TypeKeys.RESET };

type UploadAction = LoadingAction | LoadedAction | FailedAction | ResetAction;

export type State = KeyedLoaded<UploadResult, string | undefined>;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: UploadAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
            key: action.key,
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data, key } = action;

        return {
            status: "loaded",
            key,
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error, key } = action;

        return {
            status: "failed",
            key,
            error,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return initialState;
    }

    return state;
}
