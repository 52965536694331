import { createAction } from "features/catalogue/reducers/product/list";
import { ProductSummary, VariantSummary } from "features/catalogue/types";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { GraphQLMenuItemSummary, GraphQLVariantSummary, listProducts } from "features/catalogue/api/product";
import { scaffoldListAction } from "common/scaffolding/actions/scaffoldListAction";

export const list = (location: string, refresh: boolean = false, silent: boolean = false) =>
    scaffoldListAction<ProductSummary>(
        (state) => state.menuItems.list,
        createAction,
        async () => {
            const products = await listProducts(location);

            return products.map(mapListItem).sort(sortByDisplayName);
        },
        refresh,
        silent
    );

export const mapListItem = ({
    id,
    internalName,
    displayName,
    description,
    price,
    variants,
    sku,
    template: { type, shortName },
    categories,
    status,
    validationStatus,
    validationStatusReason,
    thumbnailImage,
    energyContent,
    isLinked,
    isHidden,
    isParentHidden,
    modifiers,
}: GraphQLMenuItemSummary): ProductSummary => ({
    id,
    displayName: displayName,
    price,
    variants: mapVariants(variants),
    sku,
    type: type as "food" | "drink",
    searchText: makeSearchText([internalName, displayName]),
    internalName: internalName ? internalName : undefined,
    description: description ? description : undefined,
    categories,
    status,
    validationStatus,
    validationStatusReason,
    thumbnailImage,
    energyContent,
    isLinked,
    templateShortName: shortName,
    isHidden: !!isHidden,
    isParentHidden,
    modifiers: modifiers.map((m) => m.id),
});

const mapVariants = (variants: GraphQLVariantSummary[]): VariantSummary[] =>
    variants.map((variant) => ({
        id: variant.id,
        displayName: variant.displayName,
        price: variant.price,
        taxRate: variant.taxRate,
        sku: variant.sku,
        modifiers: [],
        validationStatus: variant.validationStatus,
        validationStatusReason: variant.validationStatusReason,
        energyContent: variant.energyContent,
        isLinked: variant.isLinked,
        isHidden: !!variant.isHidden,
    }));

function makeSearchText(inputs: Array<string | null | undefined>): string {
    return inputs
        .filter((s) => s)
        .join("\n")
        .toLowerCase();
}
