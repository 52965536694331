import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const ListPriceListsPageContainer = lazy(() =>
    import("../containers/ListPriceListsPageContainer").then((module) => ({
        default: module.ListPriceListsPageContainer,
    }))
);

const EditPriceListsPageContainer = lazy(() =>
    import("../containers/EditPriceListsPageContainer").then((module) => ({
        default: module.EditPriceListsPageContainer,
    }))
);

export function ListPriceLists() {
    return (
        <Route
            path={`/:region/venue/:location/menu/pricelists`}
            key="pricelists-list"
            exact
            render={() => (
                <>
                    <Col span={24} md={24} className={`content__col content__col--white content__col--pricelists`}>
                        <div className="content__col-list-white">
                            <Route component={withPermissions(ListPriceListsPageContainer, "pricelist:read")} />
                        </div>
                    </Col>
                </>
            )}
        />
    );
}

export function EditPriceList() {
    return (
        <Route
            path={`/:region/venue/:location/menu/pricelists/:id`}
            exact
            key="pricelists-edit"
            render={(props) => (
                <>
                    <Col span={24} md={24} className={`content__col content__col--pricelistsedit`}>
                        <div className="content__col-edit-slim">
                            <Route component={withPermissions(EditPriceListsPageContainer, "pricelist:read")} />
                        </div>
                    </Col>
                </>
            )}
        />
    );
}
