import { EditableWaiter } from "..";
import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";
import { WaiterSummary } from "../types";

export enum TypeKeys {
    FETCH_BEGIN = "WAITER/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "WAITER/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "WAITER/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "WAITER/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "WAITER/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "WAITER/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditableWaiter, WaiterSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
