import styles from "./Popup.module.scss";

import { useWindowSize } from "@react-hook/window-size/throttled";
import {
    CSSProperties,
    Fragment,
    MouseEvent,
    PropsWithChildren,
    ReactElement,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { PopupButton } from "./PopupButton";
import classNames from "classnames";
import { PopupProvider, usePopupContext } from "./PopupContext";
import { Options as FocusTrapOptions } from "focus-trap";
import FocusTrap from "focus-trap-react";
import { useKeyboard, useOnClickOutside } from "common/hooks";
import { useTestAttributes } from "../testAttributes";

type Alignment = "bottom-left" | "bottom-right";

interface Bounds {
    width: number;
    height: number;
}
interface Point {
    x: number;
    y: number;
}
interface PositionProps {
    align: Alignment;
    buttonRect: DOMRect;
    contentBox: Bounds;
    bounds: Bounds;
    offset: Point;
}

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    expanded: boolean;
    label: string;
    onClick: React.MouseEventHandler<HTMLElement>;
}
export interface Props {
    button?: any;
    buttonLabel: string;
    offset: { x?: number; y?: number };
    align?: Alignment;
    initialFocus?: HTMLElement | SVGElement | string | false;
    clickInsideCloses?: boolean;
    lazy?: boolean;
}

// For now position to bottom left or right target. TODO more comprehensive alignment capabilities
function getPositionStyles(props: PositionProps) {
    const { align, buttonRect, bounds, contentBox, offset } = props;

    const alignmentParts = align.split("-");
    const yAlign = alignmentParts[0];
    const xAlign = alignmentParts[1];

    const styles: CSSProperties = { left: 0, top: 0 };

    if (xAlign === "left") {
        styles.left = buttonRect.left + offset.x;
    } else if (xAlign === "right") {
        styles.left = buttonRect.right + offset.x - contentBox.width;
    }

    if (yAlign === "bottom") {
        styles.top = buttonRect.bottom + offset.y;
    }

    // keep within window
    styles.maxHeight = bounds.height - 16 - (styles.top as number);

    switch (align) {
        case "bottom-right":
            styles.transformOrigin = "100% 10%";
            break;
        case "bottom-left":
            styles.transformOrigin = "0 10%";
            break;
    }

    return styles;
}

const PopupComponent = ({
    button: Button = PopupButton,
    buttonLabel,
    children,
    offset = {},
    align = "bottom-left",
    initialFocus,
    clickInsideCloses = true,
    lazy = false,
}: PropsWithChildren<Props>): ReactElement => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const { expanded, setExpanded } = usePopupContext();

    const [buttonRect, setButtonRect] = useState<DOMRect | undefined>(undefined);
    const [contentBox, setContentBox] = useState<Bounds | undefined>(undefined);

    const [bounds, setBounds] = useState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [width, height] = useWindowSize();

    useEffect(() => {
        const onScroll = () => {
            expanded && setExpanded(false);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [expanded, setExpanded]);

    useLayoutEffect(() => {
        setButtonRect(buttonRef.current?.getBoundingClientRect());
        setContentBox({
            width: popupRef?.current?.offsetWidth || 0,
            height: popupRef?.current?.offsetHeight || 0,
        });
        setBounds({ width, height });
    }, [width, height, expanded, Button, children]);

    const popupStyle = useMemo(() => {
        const mergedOffset = {
            x: offset.x || 0,
            y: offset.y || 0,
        };

        if (buttonRect && contentBox) {
            return getPositionStyles({ align, buttonRect, bounds, contentBox, offset: mergedOffset });
        }

        return { left: 0, top: 0 };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [align, buttonRect, bounds, contentBox, offset]);

    const handleButtonClick = useCallback(() => {
        setExpanded(true);
    }, [setExpanded]);

    const handleClose = useCallback(() => {
        setExpanded(false);
    }, [setExpanded]);

    const handlePopupClick = useCallback(
        (e: MouseEvent) => {
            if (expanded && clickInsideCloses && !popupRef.current?.contains(e.target as Node)) {
                handleClose();
            }
        },
        [clickInsideCloses, expanded, handleClose]
    );

    useKeyboard("Escape", handleClose, expanded);

    useOnClickOutside(popupRef, handleClose, expanded);

    const { getTestId } = useTestAttributes();

    const focusTrapOptions: FocusTrapOptions = useMemo(() => {
        return {
            initialFocus: initialFocus || popupRef.current || undefined,
        };
    }, [initialFocus]);

    const Wrapper = lazy ? LazyPopupComponent : Fragment;

    return (
        <>
            <Button
                expanded={expanded}
                label={buttonLabel}
                onClick={handleButtonClick}
                ref={buttonRef}
                data-testid={getTestId({ name: "popup", element: "button" })}
            />
            <FocusTrap active={expanded} focusTrapOptions={focusTrapOptions}>
                <div
                    className={expanded ? styles.containerExpanded : styles.containerCollapsed}
                    onClick={handlePopupClick}
                    data-testid={getTestId({ name: "popup" })}
                >
                    <div
                        className={classNames(expanded ? styles.popupExpanded : styles.popupCollapsed)}
                        style={popupStyle}
                        ref={popupRef}
                        tabIndex={expanded ? 0 : -1}
                        aria-hidden={!expanded}
                    >
                        <Wrapper>{children}</Wrapper>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};

export const Popup = ({ children, ...rest }: PropsWithChildren<Props>) => (
    <PopupProvider>
        <PopupComponent {...rest}>{children}</PopupComponent>
    </PopupProvider>
);
export const LazyPopupComponent = ({ children }: PropsWithChildren<any>) => {
    const [inited, setInited] = useState(false);

    const { expanded } = usePopupContext();

    useEffect(() => {
        if (!inited && expanded) {
            setInited(true);
        }
    }, [expanded, inited]);

    return (
        <Suspense fallback={<span className={styles.loading}>Loading...</span>}>{inited ? children : null}</Suspense>
    );
};
