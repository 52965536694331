import { withSvg } from "core/components/svg/Svg";

export const BurgerMenu = withSvg(
    24,
    24
)(() => (
    <>
        <path
            d="M5.25 12.0001H18.75M5.25 6.75012H18.75H5.25Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.25 17.2501H18.75"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
