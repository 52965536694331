import { useState, useEffect } from "react";

export function useMedia(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        let media: MediaQueryList = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        let listener = () => setMatches(media.matches);
        try {
            media.addEventListener("change", listener);
        } catch (err) {
            try {
                media.addListener(listener);
            } catch (err) {}
        }

        return () => {
            try {
                media.removeEventListener("change", listener);
            } catch (err) {
                try {
                    media.removeListener(listener);
                } catch (err) {}
            }
        };
    }, [query, matches]);

    return matches;
}
