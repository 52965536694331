import { State as ListState, reducer as listReducer } from "features/menuitemtemplate/reducers/list";
import { combineReducers } from "redux";

export interface State {
    menuItemTemplates: {
        list: ListState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
});
