import { PreviewWindow } from "../types/PreviewWindow";
import { opsApi } from "API";
import { PublishResult } from "features/location/types/PublishResult";
import { AppDispatch } from "features/state";
import { createAction } from "features/location/reducers/publish";

let previewWindows: PreviewWindow[] = [];

export function generatePreview(location: string) {
    return async (dispatch: AppDispatch) => {
        cleanupPreviewWindows();

        if (previewWindows.length === 0) {
            return;
        }

        const response = await callPreviewApi(location);

        const result: PublishResult = await response.json();

        dispatch(createAction.setResult(result));

        await Promise.all(previewWindows.map((previewWindow) => previewWindow.setMenuData(result.menuData)));
    };
}

async function callPreviewApi(location: string) {
    return await opsApi.fetch(`/location/${location}/publish?preview=1`, {
        method: "POST",
    });
}

function cleanupPreviewWindows() {
    previewWindows = previewWindows.filter((w) => !w.closed);
}

export function closePreviewWindows() {
    for (let previewWindow of previewWindows) {
        previewWindow.close();
    }
}

export async function registerPreviewWindow(previewWindow: PreviewWindow) {
    previewWindows.push(previewWindow);
}
