import { LocationRelations } from "../types";
import { LocationSummary } from "features/location/types/LocationSummary";

import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "LOCATION_RELATIONS/FETCH_ACTIVE_BEGIN",
    FETCH_SUCCESS = "LOCATION_RELATIONS/FETCH_ACTIVE_SUCCESS",
    FETCH_FAILURE = "LOCATION_RELATIONS/FETCH_ACTIVE_FAILURE",

    SAVE_BEGIN = "LOCATION_RELATIONS/SAVE_BEGIN",
    SAVE_SUCCESS = "LOCATION_RELATIONS/SAVE_SUCCESS",
    SAVE_FAILURE = "LOCATION_RELATIONS/SAVE_FAILURE",
}

const edit = scaffoldEdit<LocationRelations, LocationSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
