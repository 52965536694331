import { useEffect, useState } from "react";
import isObject from "lodash/isObject";
import { useFormikContext } from "formik";

// based on https://github.com/formium/formik/issues/146#issuecomment-745813277

const getFirstErrorKey = (object: {}, keys: string[] = []): string => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
        return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join(".");
};

export const ScrollToFormikError = () => {
    const formik = useFormikContext();
    const [submitCount, setSubmitCount] = useState(formik.submitCount);

    useEffect(() => {
        if (!formik.isValid && formik.submitCount > submitCount) {
            const firstErrorKey = getFirstErrorKey(formik.errors);

            let item: HTMLElement | null = null;

            if (window.document.getElementsByName(firstErrorKey).length) {
                item = window.document.getElementsByName(firstErrorKey)[0];
            }

            // fallback to finding element with error class
            if (!item) {
                item = document.querySelector(`[class*='ant-form-item-has-error'], [class*='field-errors--error']`);
            }

            item && item.scrollIntoView({ behavior: "smooth", block: "center" });
            setSubmitCount(formik.submitCount);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.submitCount, formik.isValid, formik.errors]);
    return null;
};
