import { isLoaded } from "common/loader/isLoaded";
import { AppState } from "features";
import { createSelector } from "reselect";

const getGroups = (state: AppState) => {
    return state.groups;
};

export const getActiveGroupState = createSelector(getGroups, (groups) => {
    return groups.active;
});

export const getActiveGroupStatus = createSelector(getActiveGroupState, (active) => {
    return active.status;
});

export const getActiveGroupData = createSelector(getActiveGroupState, (active) => {
    return isLoaded(active) ? active.data : undefined;
});
