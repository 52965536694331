import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const ChildLocationsPage = lazy(() =>
    import("./ChildLocationsPage").then((module) => ({
        default: module.ChildLocationsPage,
    }))
);

const Page = withPermissions(ChildLocationsPage, "settings:read");

export function LocationRelations() {
    return (
        <Route
            path={"/:region/venue/:location/venues"}
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <Page />
                </EditWrapper>
            )}
        />
    );
}
