import { AngleDown, CheckMark, CloseSmall } from "common/icons";
import { components, GroupBase, SelectComponentsConfig, StylesConfig } from "react-select";
import { CSSProperties } from "react";
import { Icon } from "core/components/icon/Icon";
import { Props, SELECT_ALL_OPTION } from ".";
import { Search } from "components/icons";
import { FormElementTestAttributeGenerator, TestAttributeProps } from "core/components/testAttributes";

type StateKeys = "disabled" | "error";

type CSSProps = { [key in StateKeys]: CSSProperties };

const control: CSSProps = {
    disabled: {
        backgroundColor: "var(--color-surface-background)",
        borderColor: "var(--color-border-primary-disabled)",
    },
    error: {
        backgroundColor: "var(--color-surface-critical)",
        borderColor: "var(--color-border-critical-default)",
    },
};

const indicators: CSSProps = {
    disabled: {
        color: "var(--color-icon-primary-disabled)",
    },
    error: {
        color: "var(--color-text-critical)",
    },
};

const singleValue: CSSProps = {
    disabled: {
        color: "var(--color-text-disabled)",
    },
    error: {
        color: "var(--color-text-critical)",
    },
};

const multiValue: CSSProps = {
    disabled: {
        backgroundColor: "var(--color-surface-background)",
    },
    error: {
        backgroundColor: "transparent",
    },
};

const multiValueLabel: CSSProps = {
    disabled: {
        color: "var(--color-text-disabled)",
    },
    error: {
        color: "var(--color-text-critical)",
    },
};

const isNestedOption = ({ innerProps }: any) => {
    return /option-\d+-\d+/gi.test(innerProps.id);
};

// Custom styling for react-select
// react-select uses emotion so custom styles passed as CSSProperties objects
export function getStyles<T>(props: Props<T>, hasError: boolean): StylesConfig<T, boolean, GroupBase<T>> {
    return {
        clearIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-icon-primary-default)",
            position: "absolute",
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            backgroundColor: hasError ? "var(--color-surface-critical)" : "var(--color-surface-default)",
            ":hover": {
                color: hasError ? "var(--color-action-critical-hover)" : "var(--color-icon-primary-hover)",
            },
            ...(hasError ? indicators.error : null),
        }),
        control: (provided, state) => {
            delete provided["&:hover"];
            return {
                ...provided,
                minHeight: 36,
                borderRadius: 6,
                borderColor: "var(--color-border-primary-subtle)",
                ...(state.isDisabled ? control.disabled : null),
                ...(hasError ? control.error : null),
                boxShadow: state.isFocused ? "0 0 0 2px var(--color-border-highlight-default)" : "none",
            };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-icon-primary-default)",
            ...(state.isDisabled ? indicators.disabled : null),
            ...(hasError ? indicators.error : null),
        }),
        group: (provided, state) => ({
            ...provided,
            paddingBottom: 0,
        }),
        groupHeading: (provided, state) => {
            delete provided.fontSize;
            delete provided.fontWeight;
            return {
                ...provided,
                color: "var(--color-text-subtle)",
            };
        },
        indicatorsContainer: () => ({
            alignSelf: "flex-start",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: "var(--color-surface-background)",
            borderRadius: 2,
            boxSizing: "border-box",
            display: "flex",
            label: "multiValue",
            margin: 2,
            minWidth: 0,
            ...(state.isDisabled ? multiValue.disabled : null),
            ...(hasError ? multiValue.error : null),
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            fontSize: "inherit",
            fontWeight: 600,
            padding: 1,
            paddingLeft: 8,
            ...(state.isDisabled ? multiValueLabel.disabled : null),
            ...(hasError ? multiValueLabel.error : null),
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            ":hover": {
                backgroundColor: "rgba(var(--color-action-secondary-depressed--rgb), 0.1)",
            },
            ...(state.isDisabled ? indicators.disabled : null),
            ...(hasError ? indicators.error : null),
        }),
        option: (provided, state) => {
            const { isDisabled, isFocused, isSelected, data } = state;
            const isSelectAll = (data as any)?.value === SELECT_ALL_OPTION.value;

            return {
                ...provided,
                ":active": {
                    backgroundColor: "hsl(var(--color-action-secondary-hover--rgb), 0.5)",
                    filter: "brightness(97%)",
                },
                ":hover": {
                    backgroundColor: "var(--color-surface-background)",
                },
                backgroundColor: isFocused
                    ? "var(--color-surface-background)"
                    : isSelected
                    ? "rgba(var(--color-action-secondary-hover--rgb), 0.5)"
                    : "transparent",
                color: `var(--color-text-${isDisabled ? "disabled" : "default"})`,
                fontWeight: isSelected || isSelectAll ? 600 : 400,
                fontStyle: (state.data as any).italicize ? "italic" : "normal",
                display: "flex",
                justifyContent: "space-between",
            };
        },
        placeholder: (provided, state) => ({
            ...provided,
            ...(state.isDisabled ? singleValue.disabled : null),
            ...(hasError ? singleValue.error : null),
            whiteSpace: "nowrap",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...(state.isDisabled ? singleValue.disabled : null),
            ...(hasError ? singleValue.error : null),
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingLeft: 12,
        }),
    };
}

// replace some react select components with custom ones
export function getComponents<T>({
    search,
    field,
    name,
    getTestAttributes,
}: Partial<Props<T>> & FormElementTestAttributeGenerator): Partial<SelectComponentsConfig<T, boolean, GroupBase<T>>> {
    const inputName = field?.name || name;

    return {
        ClearIndicator: (props) => (
            <components.ClearIndicator {...props}>
                <Icon size="small">
                    <CloseSmall />
                </Icon>
            </components.ClearIndicator>
        ),
        Control: (props) => {
            const mergedProps = mergePropsWithTestAttributes(props, getTestAttributes("select-control", inputName));

            return <components.Control {...mergedProps} />;
        },
        DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
                {search ? (
                    <Search />
                ) : (
                    <Icon size="small">
                        <AngleDown />
                    </Icon>
                )}
            </components.DropdownIndicator>
        ),
        Input: (props) => {
            // react select's internal implementation is different for input so we don't use mergePropsWithTestAttributes
            return <components.Input name={inputName} {...props} {...getTestAttributes("select-input", inputName)} />;
        },
        MultiValueRemove: (props) => (
            <components.MultiValueRemove {...props}>
                <Icon size="small">
                    <CloseSmall />
                </Icon>
            </components.MultiValueRemove>
        ),
        Option: (props) => {
            const { value } = (props as any) || {};

            let mergedProps;

            if (value !== undefined) {
                const id = value?.id || String(value);
                mergedProps = mergePropsWithTestAttributes(props, getTestAttributes("select-option", inputName, id));
            } else {
                mergedProps = props;
            }

            return (
                <components.Option {...mergedProps}>
                    <span style={isNestedOption(props) ? { paddingLeft: 12 } : undefined}>{props.children}</span>
                    {props.isSelected && props.isMulti && (
                        <Icon size="small" style={{ flex: "none" }}>
                            <CheckMark />
                        </Icon>
                    )}
                </components.Option>
            );
        },
        GroupHeading: (props) => <components.GroupHeading className="text-caption" {...props} />,
    };
}

function mergePropsWithTestAttributes(props: any, attributes: TestAttributeProps) {
    return {
        ...props,
        innerProps: {
            ...props.innerProps,
            ...attributes,
        },
    };
}
