import { withSvg } from "core/components/svg/Svg";

export const FlagCircularAU = withSvg(
    64,
    64
)(() => (
    <>
        <path
            data-name="blue"
            d="M64 32C64 49.673 49.673 64 32 64C14.327 64 0 49.673 0 32C0 32.0075 32 0.003625 32 0C49.673 0 64 14.327 64 32Z"
            fill="#0052B4"
        />
        <path data-name="white" d="M32 0C31.9983 0 31.9964 0.000125 31.9946 0.000125L32 0Z" fill="#F0F0F0" />
        <path
            data-name="white"
            d="M31.9146 32.0001H32.0002C32.0002 31.9711 32.0002 31.9433 32.0002 31.9144C31.9717 31.9431 31.9432 31.9716 31.9146 32.0001Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M32 16.6966C32 11.066 32 7.37635 32 0.000976562H31.9946C14.324 0.00385156 0 14.3295 0 32.0009H16.6956V22.5993L26.0971 32.0009H31.9145C31.9431 31.9724 31.9716 31.9439 32.0001 31.9152C32.0001 29.7594 32.0001 27.8357 32.0001 26.0982L22.5985 16.6966H32Z"
            fill="#F0F0F0"
        />
        <path
            data-name="red"
            d="M16.1893 4.17383C11.1845 7.0237 7.0237 11.1845 4.17383 16.1893V32H12.5217V12.5218V12.5217H32C32 9.88883 32 7.38058 32 4.17383H16.1893Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M32.0001 28.0646L20.6309 16.6956H16.6958C16.6958 16.6955 16.6958 16.6956 16.6958 16.6956L31.9999 31.9997H32.0001C32.0001 31.9997 32.0001 29.2866 32.0001 28.0646Z"
            fill="#D80027"
        />
        <path
            data-name="white"
            d="M19.2994 37.5653L21.0557 41.2376L25.0216 40.3209L23.2456 43.9837L26.4348 46.5128L22.4639 47.4078L22.4751 51.4783L19.2994 48.9317L16.1239 51.4783L16.1351 47.4078L12.1641 46.5128L15.3534 43.9837L13.5772 40.3209L17.5433 41.2376L19.2994 37.5653Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M47.9104 44.5215L48.7885 46.3576L50.7715 45.8992L49.8834 47.7306L51.4781 48.9952L49.4926 49.4427L49.4981 51.478L47.9104 50.2046L46.3226 51.478L46.3281 49.4427L44.3428 48.9952L45.9374 47.7306L45.0494 45.8992L47.0323 46.3576L47.9104 44.5215Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M39.7416 25.0435L40.6196 26.8797L42.6026 26.4212L41.7146 28.2526L43.3092 29.5172L41.3238 29.9647L41.3292 32L39.7416 30.7266L38.1538 32L38.1592 29.9647L36.1738 29.5172L37.7685 28.2526L36.8805 26.4212L38.8635 26.8797L39.7416 25.0435Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M47.9104 13.9128L48.7885 15.7491L50.7715 15.2907L49.8835 17.1221L51.478 18.3866L49.4926 18.8342L49.4981 20.8695L47.9104 19.5961L46.3226 20.8695L46.3281 18.8342L44.3428 18.3866L45.9373 17.1221L45.0494 15.2907L47.0323 15.7491L47.9104 13.9128Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M55.0458 22.2605L55.9238 24.0967L57.9068 23.6382L57.0188 25.4696L58.6134 26.7342L56.6279 27.1819L56.6334 29.217L55.0458 27.9437L53.458 29.217L53.4634 27.1819L51.478 26.7342L53.0727 25.4696L52.1847 23.6382L54.1675 24.0967L55.0458 22.2605Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M49.9437 32L50.6343 34.1258H52.8696L51.0612 35.4396L51.7521 37.5653L49.9437 36.2515L48.1354 37.5653L48.8261 35.4396L47.0178 34.1258H49.2529L49.9437 32Z"
            fill="#F0F0F0"
        />
    </>
));
