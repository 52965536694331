import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditGroupSettings } from "./EditGroupSettings";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

export function Group() {
    return (
        <Route
            path={`/:region/group/:id/settings`}
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <Route component={withPermissions(EditGroupSettings, "group:read")} />
                </EditWrapper>
            )}
        />
    );
}
