import { createSelector } from "reselect";
import { getModifierPosCatalogueItems } from "./getPosCatalogueItems";
import { PosCatalogueImportModifierOption } from "../types";

export const getPosCatalogueHasNestedModifiers = createSelector(
    getModifierPosCatalogueItems,
    (posModifiers): boolean => {
        if (!posModifiers.length) {
            return false;
        }

        return posModifiers.some((posModifier) => {
            return posModifier.children?.some((child) => {
                // TODO fix type hierachy
                const option = child as PosCatalogueImportModifierOption;
                return option.modifiers.length > 0;
            });
        });
    }
);
