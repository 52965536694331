import styles from "./UpdateButton.module.scss";

import { modifierActions, productActions } from "features/catalogue";
import { actions as categoryActions } from "features/category";
import { AlertCircle, CheckCircle } from "common/icons";
import { Badge } from "core/components/badge";
import { Button } from "core/components/button";
import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { fetchActive, fetchUpdateStatus } from "features/location/actions";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getTestId as getAppHeaderTestId } from "../CmsHeader";
import { importLocation } from "../api";
import { Row } from "core/components/card";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useOnApplicationFocus } from "common/hooks";
import timeago from "timeago.js";
import { useAppDispatch } from "common/hooks/useAppDispatch";

export const UpdateButton = () => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [importInProgress, setImportInProgress] = useState(false);
    const [importSuccess, setImportSuccess] = useState<boolean | undefined>(undefined);

    const activeLocation = useSelector(getActiveLocation);

    const dispatch = useAppDispatch();

    const { parentLocationUpdateStatus, id: locationId } = activeLocation || {};

    const { updatesAvailable = false, lastParentImportUtc } = parentLocationUpdateStatus || {};

    const lastUpdated = lastParentImportUtc ? timeago().format(lastParentImportUtc) : "Unknown";

    const handleFocus = useCallback(() => {
        dispatch(fetchUpdateStatus());
    }, [dispatch]);

    useOnApplicationFocus(handleFocus, 10000);

    const handleConfirm = useCallback(async () => {
        setImportInProgress(true);

        try {
            const result = await importLocation(activeLocation!.id);

            if (result.errors?.length) {
                setImportSuccess(false);
            } else {
                setImportSuccess(true);

                await dispatch(fetchActive(locationId, true));
            }
        } catch (err) {
            setImportSuccess(false);
        } finally {
            setImportInProgress(false);
        }
        // keep modal open until we get result
        return false;
    }, [activeLocation, dispatch, locationId]);

    const handleClose = useCallback(() => {
        setShowConfirmModal(false);
        setImportSuccess(undefined);
    }, []);

    const handleSuccessComplete = useCallback(() => {
        dispatch(productActions.list(locationId!, true, true));
        dispatch(modifierActions.list(locationId!, true, true));
        dispatch(categoryActions.list(locationId!, true, true));
        return true;
    }, [dispatch, locationId]);

    const canDismissModal = !importInProgress || importSuccess === false;

    const footer = useMemo(() => {
        if (importSuccess !== undefined) {
            return (
                <Confirm
                    confirmLabel={importSuccess ? "Done" : "OK"}
                    onConfirm={importSuccess ? handleSuccessComplete : undefined}
                    showCancel={false}
                />
            );
        }

        return (
            <Confirm
                confirmLabel="Update"
                onConfirm={handleConfirm}
                confirmProps={{ loading: importInProgress }}
                showCancel={!importInProgress}
            />
        );
    }, [importSuccess, handleConfirm, importInProgress, handleSuccessComplete]);

    const modalContent = useMemo(() => {
        if (importSuccess === false) {
            return (
                <Row className={styles.resultRow}>
                    <span className={styles.failIcon}>
                        <AlertCircle />
                    </span>
                    <p className={styles.resultHeading}>Update failed</p>
                </Row>
            );
        }

        if (importSuccess === true) {
            return (
                <Row className={styles.resultRow}>
                    <span className={styles.successIcon}>
                        <CheckCircle />
                    </span>
                    <p className={styles.resultHeading}>Updated successfully</p>
                </Row>
            );
        }

        return (
            <Row>
                Are you sure you want to get all updates from the brand?
                <br />
                This action cannot be undone.
            </Row>
        );
    }, [importSuccess]);

    if (!activeLocation) {
        return null;
    }

    return (
        <>
            <Tooltip
                content={
                    updatesAvailable
                        ? "There is an update to the brand catalogue. Click to recieve the most recent changes"
                        : `Last updated: ${lastUpdated}`
                }
                placement="top-end"
            >
                <Button
                    role="secondary"
                    onClick={() => setShowConfirmModal(true)}
                    data-testid={getAppHeaderTestId({ name: "update", element: "button" })}
                >
                    {updatesAvailable && (
                        <Badge className={styles.badge} shape="circle">
                            !
                        </Badge>
                    )}
                    <span>Update</span>
                </Button>
            </Tooltip>
            <ModalRenderer target="#modal">
                <Modal
                    footer={footer}
                    onClose={handleClose}
                    title="Get updates from brand"
                    visible={showConfirmModal}
                    width={CardWidth.NARROW}
                    hideClose={!canDismissModal}
                    escapeCloses={canDismissModal}
                    clickOutsideCloses={canDismissModal}
                    initialFocus="[data-confirm='true']"
                >
                    {modalContent}
                </Modal>
            </ModalRenderer>
        </>
    );
};
