import stylesModule from "./Select.module.scss";

import { FieldInputProps, FieldMetaProps, FormikProps, getIn } from "formik";
import ReactSelect, {
    ActionMeta,
    Props as SelectProps,
    PropsValue,
    ClassNamesConfig,
    MultiValue,
    SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { getComponents, getStyles } from "./SelectStyles";
import classNamesUtil from "classnames";
import { KeyboardEvent, useCallback, useId, useMemo, useRef, useState } from "react";
import { Label } from "../input/Label";
import { useFormElementTestAttributes } from "core/components/testAttributes";
import { SELECT_ALL_OPTION, defaultOptionSelectedTest, filterOptions, findOptions } from "./helpers";
import { Option, OptionSelectedTest } from "./types";

// Omit react-select's 'form' prop as it is incompatible with Formik's
interface BaseProps<T> extends Omit<SelectProps<T>, "form" | "onChange"> {
    addSelectAllOption?: boolean;
    allowCreate?: boolean;
    classNames?: ClassNamesConfig<T>;
    defaultMenuIsOpen?: boolean; // helpful for inspecting styles
    disabled?: boolean;
    disableError?: boolean;
    forceHasError?: boolean;
    label?: string;
    markRequired?: boolean;
    search?: boolean;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    optionSelectedTest?: OptionSelectedTest;
    field?: FieldInputProps<any>;
    meta?: FieldMetaProps<any>;
    form?: FormikProps<any>;
    setTouchedOnBlur?: boolean;
    validateOnChange?: boolean;
    validateOnTouched?: boolean;
}

interface SingleSelectProps<T> extends BaseProps<T> {
    isMulti?: false | undefined;
    onChange?: (value: SingleValue<T>, meta: ActionMeta<T>) => boolean | void;
}

interface MultiSelectProps<T> extends BaseProps<T> {
    isMulti: true;
    onChange?: (value: MultiValue<T>, meta: ActionMeta<T>) => boolean | void;
}

export type Props<T> = SingleSelectProps<T> | MultiSelectProps<T>;

export function Select<T extends Option>(props: Props<T>) {
    const {
        allowCreate = false,
        addSelectAllOption = false,
        blurInputOnSelect = true,
        className,
        classNames = {},
        defaultMenuIsOpen = false,
        disabled = false,
        disableError = false,
        field,
        filterOption,
        form,
        forceHasError = false,
        hideSelectedOptions = false,
        id,
        isClearable = false,
        isMulti,
        label,
        markRequired = false,
        meta,
        options,
        optionSelectedTest = defaultOptionSelectedTest,
        onChange,
        onMenuOpen,
        onMenuClose,
        search = false,
        setTouchedOnBlur = true,
        validateOnChange = true,
        validateOnTouched = false,
        styles,
        value,
        ...rest
    } = props;

    const createdOptions = useRef<T[] | T>([]);
    const { setFieldValue, setFieldTouched, values, touched, errors } = form || {};
    const { name } = field || {};

    const [searchText, setSearchText] = useState("");

    let selectedValue: PropsValue<T> | undefined;

    // if value supplied it takes precedence over formik value
    if (value) {
        selectedValue = value;
    } else {
        const formikValue = name && getIn(values, name);
        const allOptions = (options ?? []).concat(createdOptions.current);
        selectedValue = findOptions(formikValue, allOptions, optionSelectedTest);
    }

    const hasError = forceHasError || (name && getIn(errors, name) && getIn(touched, name) && !disableError);

    const [menuOpen, setMenuOpen] = useState(defaultMenuIsOpen);

    const enhancedOptions = useMemo(() => {
        if (addSelectAllOption) {
            if (isMulti && Array.isArray(options) && options.length > 1) {
                return [SELECT_ALL_OPTION, ...options];
            }
        }

        return null;
    }, [isMulti, addSelectAllOption, options]);

    const handleChange = (value: PropsValue<T>, meta: ActionMeta<T>) => {
        const selectAll =
            addSelectAllOption &&
            Array.isArray(options) &&
            Array.isArray(value) &&
            value.find((option) => option.value === SELECT_ALL_OPTION.value);

        if (allowCreate) {
            if (Array.isArray(value)) {
                createdOptions.current = value.filter((option) => "__isNew__" in option);
            } else if (value && "__isNew__" in value) {
                createdOptions.current = value as T;
            }
        }

        if (onChange) {
            if (isMulti) {
                const val = selectAll ? filterOptions(options, searchText, filterOption) : value;

                const proceed = onChange(val as MultiValue<T>, meta);

                if (proceed === false) {
                    return;
                }
            } else {
                const proceed = onChange(value as SingleValue<T>, meta);

                if (proceed === false) {
                    return;
                }
            }
        }

        if (name) {
            if (!value) {
                setFieldValue?.(name, "", validateOnChange);
                return;
            }
            if (Array.isArray(value)) {
                if (selectAll) {
                    setFieldValue?.(
                        name,
                        [
                            ...new Set<string>(
                                value
                                    .filter((option) => option.value !== SELECT_ALL_OPTION.value)
                                    .map((option) => option.value)
                                    .concat(
                                        filterOptions(options, searchText, filterOption).map((option) => option.value)
                                    )
                            ),
                        ],
                        validateOnChange
                    );
                } else {
                    setFieldValue?.(
                        name,
                        value.map((option) => option.value),
                        validateOnChange
                    );
                }
            } else {
                setFieldValue?.(name, (value as T).value, validateOnChange);
            }
        }
    };

    const handleMenuOpen = useCallback(() => {
        setMenuOpen(true);
        onMenuOpen?.();
    }, [onMenuOpen]);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        onMenuClose?.();
    }, [onMenuClose]);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape" && menuOpen) {
                e.stopPropagation();
            }
        },
        [menuOpen]
    );

    const getTestAttributes = useFormElementTestAttributes();

    // Creating custom component definitions every render is not only not performant,
    // but causes an issue with React Select where it doesn't handle clicks outside properly
    // with certain custom components e.g. Input and blurInputOnSelect=false and
    // closeMenuOnSelect=false.

    // These props are unlikely to change during typical lifecycle, so dependencies can
    // be ignored for now.

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const components = useMemo(() => getComponents<T>({ search, field, name, getTestAttributes }), []);

    const InternalSelect = allowCreate ? CreatableSelect : ReactSelect;

    const autoId = useId();

    return (
        <div className={hasError ? stylesModule.containerError : stylesModule.container}>
            {(label || markRequired) && (
                <Label label={label} markRequired={markRequired} name={name} htmlFor={id ?? autoId} />
            )}
            <InternalSelect
                blurInputOnSelect={blurInputOnSelect}
                className={classNamesUtil(className)}
                classNames={classNames}
                components={components}
                hideSelectedOptions={hideSelectedOptions}
                inputId={id ?? autoId}
                isClearable={isClearable}
                isDisabled={disabled}
                isMulti={isMulti}
                name={name}
                onBlur={() => {
                    if (name && setTouchedOnBlur) {
                        setFieldTouched?.(name, true, validateOnTouched);
                    }
                }}
                onInputChange={(newValue) => setSearchText(newValue)}
                onChange={handleChange}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                onKeyDown={handleKeyDown}
                options={enhancedOptions || options}
                styles={{ ...styles, ...getStyles<T>(props, hasError) }}
                value={selectedValue}
                defaultMenuIsOpen={defaultMenuIsOpen}
                filterOption={filterOption}
                {...rest}
            />
        </div>
    );
}
