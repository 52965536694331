import { config } from "common/config";
export class PermissionSet {
    private permissions: Set<string>;

    constructor(permissions: string[]) {
        this.permissions = new Set(permissions);
    }

    has = (permission: string) => {
        if (config.VITE_DISABLE_PERMISSIONS) {
            // for testing server permissions
            return true;
        }

        return this.permissions.has(permission);
    };

    hasAny = (...permissions: string[]) => permissions.some(this.has);

    hasAll = (...permissions: string[]) => permissions.every(this.has);
}
