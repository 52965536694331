import * as Yup from "yup";
import {
    EditableModifierOption,
    EditableModifier,
    ModifySelectionOptions,
    EditableModifierProducts,
    ModifierType,
    EditableModifierProductOption,
    ModifierSummary,
} from "features/catalogue/types";
import { ProductSummary } from "features/catalogue";
import { nonNullable } from "common/types/nonNullable";
import { energyContentSchema } from "./energyContentSchema";

const editableModificationOptionSchema = Yup.object<EditableModifierOption>().shape({
    displayName: Yup.string().required("Required"),
    sku: Yup.string().nullable(true),
    price: Yup.number().nullable(true),
    taxRate: Yup.number().nullable(true),
    energyContent: energyContentSchema,
    isLinked: Yup.boolean(),
    isHidden: Yup.boolean(),
});

const selectionNumberSchema = Yup.number()
    .min(1, "Must be 1 or more")
    .max(99, "Must be 99 or less")
    .integer("Value cannot contain decimals")
    .required("Required");

// modifier groups assigned to products
const modifierProductSchema = Yup.object<EditableModifierProducts>().shape({
    productIds: Yup.array().of(Yup.string().nullable(true)).nullable(true),
    variantIds: Yup.array().of(Yup.string().nullable(true)).nullable(true),
});

// products used as modifier options (upsells)
const modifierProductOptionSchema = Yup.object<EditableModifierProductOption>().shape({
    id: Yup.string().required(),
    variantIds: Yup.array().of(Yup.string()).nullable(true),
    recommended: Yup.boolean().nullable(true),
});

export const editableModifierSchema = Yup.object<EditableModifier>().shape({
    internalName: Yup.string().nullable(true).notRequired(),
    displayName: Yup.string().required("Required"),
    options: Yup.array()
        .nullable(true)
        .when(["modifierType"], {
            is: (modifierType: ModifierType) => {
                return modifierType === "standard";
            },
            then: Yup.array().of(editableModificationOptionSchema).min(1, "Please add at least one option"),
        }),
    required: Yup.boolean(),
    minMaxSelect: Yup.string().notRequired(), // Not saved, just sets the depending min and max selection
    minSelection: Yup.number()
        .nullable(true)
        .when(["minMaxSelect", "modifierType"], {
            is: (minMaxSelect: string, modifierType: ModifierType) => {
                const required =
                    modifierType === "standard" &&
                    (minMaxSelect === ModifySelectionOptions.AtLeast ||
                        minMaxSelect === ModifySelectionOptions.Exactly);
                return required;
            },
            then: selectionNumberSchema,
        })
        .when(["minMaxSelect", "maxSelection", "modifierType"], {
            is: (minMaxSelect: string, maxSelection: number | undefined, modifierType: ModifierType) => {
                return (
                    modifierType === "standard" &&
                    minMaxSelect === ModifySelectionOptions.Between &&
                    maxSelection !== undefined
                );
            },
            then: selectionNumberSchema.lessThan(
                Yup.ref("maxSelection"),
                "Maximum value cannot be the same or lower than the minimum"
            ),
        })
        .when(
            ["options", "maxSelectionPerOption", "modifierType"],
            (options: EditableModifierOption[], maxSelectionPerOption, modifierType, schema) =>
                modifierType === "standard" && maxSelectionPerOption
                    ? schema.max(
                          (options.filter((o) => !o.isHidden)?.length || 0) * maxSelectionPerOption,
                          "Current rules are invalid. Add more modifier options, change minimum or increase selections per option"
                      )
                    : schema
        ),
    maxSelection: Yup.number()
        .nullable(true)
        .when(["minMaxSelect", "modifierType"], {
            is: (minMaxSelect: string, modifierType: ModifierType) => {
                return modifierType === "standard" && minMaxSelect === ModifySelectionOptions.UpTo;
            },
            then: selectionNumberSchema,
        })
        .when(["minMaxSelect", "modifierType"], {
            is: (minMaxSelect: string, modifierType: ModifierType) => {
                return modifierType === "standard" && minMaxSelect === ModifySelectionOptions.Between;
            },
            then: selectionNumberSchema.moreThan(
                Yup.ref("minSelection"),
                "Maximum value cannot be the same or lower than the minimum"
            ),
        }),
    id: Yup.string().notRequired(),
    products: modifierProductSchema.when(
        // need to use context's $modifierType because this is a nested schema
        ["productOptions", "$modifierType", "$productsById"],
        (
            productOptions: EditableModifierProductOption[],
            modifierType: ModifierType,
            productsById: Record<string, ProductSummary | undefined>,
            schema
        ) =>
            schema.test({
                name: "invalidAssignments",
                message: "A modifier group cannot be assigned to products in its upsells list.",
                test: function (value: EditableModifierProducts) {
                    if (modifierType !== "upsell" || !productOptions || !productsById) {
                        return true;
                    }

                    const invalidProductOptions = productOptions.filter((option: EditableModifierProductOption) => {
                        return (
                            value.productIds.includes(option.id) ||
                            option.variantIds.some((id) => value.variantIds.includes(id))
                        );
                    });

                    if (invalidProductOptions.length > 0) {
                        return this.createError({
                            message: `This modifier group cannot be assigned to products which appear in its upsells list: ${invalidProductOptions
                                .map((option) => productsById[option.id]?.displayName || null)
                                .filter(Boolean)
                                .join(", ")}.`,
                        });
                    }

                    return true;
                },
            })
    ),

    upsell: Yup.boolean(),
    priceLists: Yup.array().of(Yup.string()),
    status: Yup.number(),
    smartSorting: Yup.boolean().nullable(true),
    maxSelectionPerOption: Yup.number()
        .nullable(true)
        .when(["modifierType"], {
            is: (modifierType: ModifierType) => {
                return modifierType === "standard";
            },
            then: selectionNumberSchema,
        }),
    type: Yup.mixed()
        .nullable(true)
        .when(["upsell", "$upsellTypes"], {
            is: true,
            then: Yup.mixed().oneOf(
                ["food", "drink", "alcoholic-drink"],
                "Must select food, non-alcoholic drink or alcoholic drink."
            ),
        }),
    isLinked: Yup.boolean(),
    productOptions: Yup.array()
        .of(
            modifierProductOptionSchema.when(
                [
                    "$modifierType",
                    "$productsById",
                    "$productsWithNestedModifiers",
                    "$modifiersById",
                    "$nestedModifierIds",
                ],
                (
                    modifierType: ModifierType,
                    productsById: Record<string, ProductSummary | undefined> | undefined,
                    productsWithNestedModifiers: Record<string, ProductSummary | undefined> | undefined,
                    modifiersById: Record<string, ModifierSummary | undefined>,
                    nestedModifierIds: Record<string, boolean>,
                    schema: any
                ) =>
                    schema
                        .test({
                            name: "nestedMods",
                            message: "Cannot upsell products with nested modifiers",
                            test: function (value: EditableModifierProductOption) {
                                if (modifierType !== "upsell" || !productsWithNestedModifiers?.[value.id]) {
                                    return true;
                                }

                                const nestedModifiers =
                                    productsWithNestedModifiers[value.id]?.modifiers
                                        .filter((modifierId) => nestedModifierIds[modifierId])
                                        .map((modifierId) => modifiersById[modifierId]?.displayName || null)
                                        .filter(nonNullable)
                                        .join(", ") || "";

                                return this.createError({
                                    message: `Cannot upsell products with nested modifiers: (${nestedModifiers})`,
                                } as any); // TS wants `path` but Yup doesn't seem to need it
                            },
                        })
                        .test({
                            name: "noVariants",
                            message: "Upsells must have at least one variant",
                            test: function (value: EditableModifierProductOption) {
                                if (modifierType !== "upsell" || !productsById) {
                                    return true;
                                }

                                const product = productsById[value.id];

                                if (!product?.variants || product.variants.length < 2) {
                                    return true;
                                }

                                return value.variantIds.length > 0;
                            },
                        })
            )
        )
        .nullable(true),
    modifierType: Yup.mixed().oneOf(["standard", "upsell"]).nullable(true), // Not saved, just a convenience field for FE
    isHidden: Yup.boolean().when(
        ["options", "$modifierType"],
        (options: EditableModifierOption[], modifierType, schema) =>
            schema.test({
                name: "isHidden",
                message: "Cannot hide all options. Hide the modifier group instead.",
                test: () => modifierType !== "standard" || !options.every((option) => option.isHidden),
            })
    ),
} as any);
