import { State as EditState, reducer as editReducer } from "./edit";
import { State as FiltersState, reducer as filtersReducer } from "./filters";
import { State as ListState, reducer as listReducer } from "./list";

import { combineReducers } from "redux";

export interface State {
    team: {
        edit: EditState;
        filters: FiltersState;
        list: ListState;
    };
}

export const reducer = combineReducers({
    edit: editReducer,
    filters: filtersReducer,
    list: listReducer,
});
