import { AppDispatch, AppState } from "features/state";
import { createAction, RoleSet } from "../reducers/roleReducer";
import { getOperatorPortalRolesApi } from "../api/getOperatorPortalRolesApi";
import { getTeamRolesApi } from "../api/getTeamRolesApi";

export const getRolesAction = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    if (getState().roles.status === "loading") {
        return;
    }
    dispatch(createAction.loading());

    try {
        const [operatorPortalRoles, teamRoles] = await Promise.all([getOperatorPortalRolesApi(), getTeamRolesApi()]);
        const newState: RoleSet = { operatorPortalRoles, teamRoles };
        dispatch(createAction.loaded(newState));
    } catch (e: any) {
        dispatch(createAction.failed(e));
    }
};
