import { createAction } from "../reducers/active";
import { AppDispatch, AppState } from "features/state";
import { fetchLocation } from "../api/fetchLocation";
import { fetchDefaultLocation } from "../api/fetchDefaultLocation";
import { setAppInsightsUserContext } from "common/appInsights";
import { actions as sectionActions } from "features/section";
import { ActiveLocation } from "features/location/types/ActiveLocation";
import { isLoaded } from "common/loader/isLoaded";

export const fetchActive =
    (slug: string | undefined, reload: boolean = false) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const {
                locations: { active },
                sections: { list },
            } = getState();

            if (active.status !== "unloaded" && (active.key === slug || !slug) && !reload) {
                return;
            }

            if (!reload || active.status === "unloaded") {
                dispatch(createAction.begin(slug));
            }

            const activeLocation = slug ? await fetchLocation(slug) : await fetchDefaultLocation();

            document.title = activeLocation
                ? `${activeLocation.displayName} - me&u Operator Portal`
                : "me&u Operator Portal";

            dispatch(createAction.success(activeLocation));
            setAppInsightsUserContext(activeLocation, getState);

            if (!isLoaded(list)) {
                dispatch(sectionActions.list((activeLocation as ActiveLocation).slug));
            }
        } catch (e) {
            dispatch(createAction.failure(slug, e));
        }
    };
