import styles from "./CmsHeader.module.scss";

import { Badge } from "core/components/badge";
import { Link, useRouteMatch } from "react-router-dom";
import { LocationContext } from "features/location";
import { LocationSelector } from "features/location/components/LocationSelector";
import { MeanduLogo } from "common/icons";
import { MenuButton } from "../nav/MenuButton";
import { MenuContext } from "../nav/MenuContainer";
import { PublishButton, PublishErrorsContainer } from "./publishButton";
import { RegionSelectorContainer } from "features/region/containers/RegionSelectorContainer";
import { useContext, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { UpdateButton } from "./updateButton/UpdateButton";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { LocationListProvider } from "features/location/context/LocationListContext";
import { useMedia, useScopeBasePath } from "common/hooks";
import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";
import { Scope, useScopeContext } from "features/scope";
import { VenueTime } from "../venueTime/VenueTime";
import { GroupRouteParams, LocationRouteParams } from "common/types";
import { getIsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";
import { getFakeRole } from "features/fakeRoles/selectors/getFakeRoles";
import { FakeRoleInput } from "./FakeRoleInput";
import { BackButton } from "./backButton/BackButton";
import { GROUP_PATH, LOCATION_PATH, TEAMS_PATH } from "features/scope/types/ScopePathParameter";

export const { getTestId } = getTestAttributeGenerators(TestAttributePrefix.APP_HEADER);
const { getTestId: getLocationSelectorTestId } = getTestAttributeGenerators(TestAttributePrefix.LOCATION_SELECTOR);
const { getTestId: getRegionSelectorTestId } = getTestAttributeGenerators(TestAttributePrefix.REGION_SELECTOR);

export const CmsHeader = () => {
    const basePath = useScopeBasePath();

    const locationMatch = useRouteMatch<LocationRouteParams>(basePath);

    const groupMatch = useRouteMatch<GroupRouteParams>(basePath);

    const { region, location } = locationMatch?.params || {};

    const { id: groupId } = groupMatch?.params || {};

    const activeLocation = useContext(LocationContext);

    const scope = useScopeContext();

    const isLocation = scope === Scope.LOCATION;

    const isGroup = scope === Scope.GROUP;

    const { isDemo } = (isLocation && activeLocation) || {};

    const isParentLocation = !!(useSelector(getIsParentLocation) && isLocation);

    const isChildLocation = !!(useSelector(getIsChildLocation) && isLocation);

    const fakeRole = useSelector(getFakeRole);

    const posMenuSupported = useSelector(getIsPosMenuSupported);

    const locationDefaultUrl = getLocationDefaultUrl({
        groupId,
        posMenuSupported,
        isParentLocation,
    });

    const permissions = useSelector(getLocationPermissions);

    const canImportParent = activeLocation && permissions.has("location:child:menudata:import");

    const mobileContainerRef = useRef<HTMLDivElement | null>(null);

    const { setMenuActive } = useContext(MenuContext);

    const isLarge = useMedia("(min-width: 896px)");

    const homeUrl = useMemo(() => {
        switch (scope) {
            case Scope.GROUP:
                return `/${region}/${GROUP_PATH}/${groupId}`;

            case Scope.TEAMS:
                return `/${region}/${TEAMS_PATH}`;

            case Scope.LOCATION:
            default:
                return `/${region}/${LOCATION_PATH}/${location}/${locationDefaultUrl}`;
        }
    }, [groupId, location, locationDefaultUrl, region, scope]);

    const showTime = isLocation && !isParentLocation && isLarge;

    const showBack = !isLocation && !isGroup;

    const handleMenuContainerClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e.target === mobileContainerRef.current) {
            e.stopPropagation();
            setMenuActive(false);
        }
    };

    return (
        <LocationListProvider>
            <div className={styles.root}>
                <div className={styles.menu}>
                    <MenuButton />
                </div>
                <div className={styles.logoContainer}>
                    <Link to={homeUrl} data-testid={getTestId({ name: "home", element: "link" })}>
                        <span className={styles.logo}>
                            <MeanduLogo />
                        </span>
                    </Link>
                </div>
                {showBack && <BackButton />}
                {isLarge && (
                    <div
                        className={styles.locationLargeScreens}
                        data-testid={getLocationSelectorTestId({ name: "large-screens" })}
                    >
                        <LocationSelector />
                    </div>
                )}
                {(isDemo || isParentLocation || isGroup) && (
                    <div className={styles.badges} data-testid={getTestId({ name: "badges" })}>
                        {isParentLocation && (
                            <Badge backgroundColorScheme="mid" data-testid={getTestId({ name: "badge" }, "brand")}>
                                Brand
                            </Badge>
                        )}
                        {isGroup && (
                            <Badge
                                backgroundColorScheme="white"
                                textColorScheme="dark"
                                outline
                                data-testid={getTestId({ name: "badge" }, "group")}
                            >
                                Group
                            </Badge>
                        )}
                        {isDemo && (
                            <Badge data-testid={getTestId({ name: "badge" }, "demo")}>
                                <span>
                                    <span>Demo</span>
                                    <span className="hide-until-medium"> mode</span>
                                </span>
                            </Badge>
                        )}
                    </div>
                )}
                <div className={styles.publish}>
                    {showTime && (
                        <div className="pr-4">
                            <VenueTime />
                        </div>
                    )}
                    {isLarge && (
                        <div
                            className={styles.regionLargeScreens}
                            data-testid={getRegionSelectorTestId({ name: "large-screens" })}
                        >
                            <RegionSelectorContainer />
                        </div>
                    )}
                    {isChildLocation && canImportParent && <UpdateButton />}
                    <div className={styles.fakeRoleButton}>
                        <FakeRoleInput />
                    </div>
                    {location && !isParentLocation && (
                        <>
                            <PublishButton />
                            <PublishErrorsContainer />
                        </>
                    )}
                </div>
                {fakeRole && (
                    <div className={styles.fakeRoleContainer}>
                        <div className={styles.fakeRoleBanner}>Role: {fakeRole.displayName}</div>
                    </div>
                )}
            </div>
            {!isLarge && (
                <div
                    className={styles.menuContainerSmallScreens}
                    onClick={handleMenuContainerClick}
                    ref={mobileContainerRef}
                >
                    <div
                        className={styles.menuSmallScreens}
                        data-testid={getLocationSelectorTestId({ name: "small-screens" })}
                    >
                        <div className={styles.locationSmallScreens}>
                            <LocationSelector />
                        </div>
                        <div data-testid={getRegionSelectorTestId({ name: "small-screens" })}>
                            <RegionSelectorContainer />
                        </div>
                    </div>
                </div>
            )}
        </LocationListProvider>
    );
};

function getLocationDefaultUrl(options: {
    groupId: string | undefined;
    posMenuSupported: boolean | undefined;
    isParentLocation: boolean;
}) {
    if (options.groupId) {
        return null;
    }

    if (options.posMenuSupported && !options.isParentLocation) {
        return "sections";
    }

    return "menu/catalogue/products";
}
