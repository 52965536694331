import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { createSelector } from "reselect";

export const getPosMetadata = createSelector(getActiveLocation, (location) => {
    if (!location) {
        return undefined;
    }

    return location.posMetadata;
});

export const getPosOperations = createSelector(getPosMetadata, (metadata) => {
    if (!metadata) {
        return [];
    }

    return metadata.operations;
});

export const getPosOperationsSupport = createSelector(getPosOperations, (posOperations) =>
    posOperations.reduce((all, op) => {
        all[op.name] = op.supported;
        return all;
    }, {} as { [operation: string]: boolean | undefined })
);

export const getPosOperationBehaviorsSupport = createSelector(getPosOperations, (posOperations) =>
    posOperations.reduce((all, op) => {

        for (const behavior of op.behaviors) {
            all[`${op.name}/${behavior.name}`] = op.supported && behavior.supported;
        }
        return all;
    }, {} as { [operation: string]: boolean | undefined })
);