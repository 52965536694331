import styles from "./CloseButton.module.scss";

import { CloseSmall } from "common/icons";
import { MouseEvent, ReactElement } from "react";
import { Icon, Size } from "../icon";
import classNames from "classnames";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface Props {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    iconSize?: Size;
}

export const CloseButton = ({
    className,
    iconSize = "extraLarge",
    onClick,
    ...rest
}: Props & ButtonProps): ReactElement => {
    return (
        <button className={classNames(styles.root, className)} onClick={onClick} {...rest} aria-label="close">
            <Icon size={iconSize} title="close">
                <CloseSmall />
            </Icon>
        </button>
    );
};
