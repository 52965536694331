import { State as ListState, reducer as listReducer } from "./list";
import { State as EditState, reducer as editReducer } from "./edit";
import { State as StatsState, reducer as statsReducer } from "./stats";

import { combineReducers } from "redux";
export interface State {
    groupTabs: {
        stats: StatsState;
        list: ListState;
        edit: EditState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    stats: statsReducer,
});
