import { batchGraphQLQuery, FetchApi } from "./batching";
import { opsApiFetch } from "./fetch";
import { ExecutionResult } from "graphql";

export async function graphQLQuery<T = any>(
    invoker: FetchApi,
    query: string,
    variables?: {},
    properties?: Array<keyof T>
): Promise<ExecutionResult<T>> {
    const result = await batchGraphQLQuery(query, variables, invoker);

    return result as ExecutionResult<T>;
}

export const opsApi = {
    graphQLQuery: <T = any>(query: string, variables?: {}, properties?: Array<keyof T>) =>
        graphQLQuery(opsApiFetch, query, variables),

    fetch: opsApiFetch,
};
