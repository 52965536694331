import styles from "./ActionsPopup.module.scss";

import { ComponentType, MouseEventHandler, PropsWithChildren, useCallback, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Button, Props as ButtonProps } from "../button";
import { Placement } from "@popperjs/core";
import { useKeyboard, useOnClickOutside, useOnFocusOutside } from "common/hooks";
import { More } from "common/icons/More";
import { ModalRenderer } from "../modal";
import { HTMLElementRef } from "common/types/ElementRef";

export type ActionPopupButtonProps = {
    onClick: MouseEventHandler<HTMLElement>;
    expanded: boolean;
};

type CommonProps = {
    closeOnMenuClick?: boolean;
    menuPlacement?: Placement;
    menuModifiers?: any;
    portal?: string | HTMLElement;
    externalContainerRef?: HTMLElementRef;
};

type StandardButtonProps = {
    buttonComponent?: never;
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
};

type CustomButtonProps = {
    buttonComponent?: ComponentType<ActionPopupButtonProps>;
    buttonProps?: never;
};

type Props = CommonProps & (StandardButtonProps | CustomButtonProps);

export const ActionsPopup = ({
    buttonComponent: ButtonComponent,
    buttonProps = {},
    children,
    closeOnMenuClick,
    externalContainerRef,
    menuPlacement = "bottom-end",
    menuModifiers = [
        {
            name: "offset",
            options: { offset: [0, 4] },
        },
    ],
    portal,
}: PropsWithChildren<Props>) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [menuElement, setMenuElement] = useState<HTMLDivElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const popper = usePopper(buttonRef.current, menuElement, {
        placement: menuPlacement,
        modifiers: menuModifiers,
    });

    const handleBtnClick = useCallback(() => {
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    }, []);

    const handleClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const handleActionClick = useCallback(() => {
        closeOnMenuClick && setMenuOpen(false);
    }, [closeOnMenuClick]);

    const containerRefs = externalContainerRef ? [externalContainerRef, containerRef] : containerRef;

    useOnClickOutside(containerRefs, handleClose, menuOpen);
    useOnFocusOutside(containerRefs, handleClose, menuOpen);
    useKeyboard("Escape", handleClose, menuOpen);

    if (!buttonProps?.children) {
        buttonProps = {
            children: <More />,
            padding: "icon",
            ...buttonProps,
        };
    }

    return (
        <div ref={containerRef}>
            <div className={styles.container} ref={buttonRef}>
                {ButtonComponent ? (
                    <ButtonComponent expanded={menuOpen} onClick={handleBtnClick} />
                ) : (
                    <Button role="secondary" type="button" onClick={handleBtnClick} {...buttonProps} />
                )}
            </div>
            <Wrapper portal={portal}>
                {menuOpen && (
                    <div
                        style={popper.styles.popper}
                        {...popper.attributes.popper}
                        ref={setMenuElement}
                        onClick={handleActionClick}
                    >
                        {children}
                    </div>
                )}
            </Wrapper>
        </div>
    );
};

interface WrapperProps {
    portal?: string | HTMLElement;
}

export const Wrapper = ({ children, portal }: PropsWithChildren<WrapperProps>) => {
    if (!portal) {
        return <>{children}</>;
    }

    return <ModalRenderer target={portal}>{children}</ModalRenderer>;
};
