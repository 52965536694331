import { withSvg } from "core/components/svg/Svg";

export const PictureOutlined = withSvg(
    58,
    64
)(() => (
    <>
        <path stroke="red" d="M2 10H56V54H2Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        <path
            d="M16 26C18.2091 26 20 24.2091 20 22C20 19.7909 18.2091 18 16 18C13.7909 18 12 19.7909 12 22C12 24.2091 13.7909 26 16 26Z"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 44C10 41.9 15 32 20 32C25 32 24 36 26 36C28 36 28.5 24 34 24C39.5 24 46 42.3 46 44H10Z"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
