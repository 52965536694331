import { Children, forwardRef, isValidElement, PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";
import { Spinner } from "core/components/spinner/Spinner";
import { useMedia } from "common/hooks";

export type ColorScheme = "default" | "critical" | "interactive" | "special";

export type Role = "primary" | "secondary" | "tertiary";

export type Shape = "button" | "link" | "pill" | "listItem";

export type Size = "regular" | "large";

export type Padding = "regular" | "icon" | "iconUntilMedium";

export interface Props {
    as?: React.ElementType;
    className?: string;
    colorScheme?: ColorScheme;
    role?: Role;
    shape?: Shape;
    size?: Size;
    padding?: Padding;
    disabled?: boolean;
    loading?: boolean;
    loadingDisables?: boolean;
    active?: boolean;
    noBackground?: boolean;
    noBorder?: boolean;
    nowrap?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onDisabledClick?: React.MouseEventHandler<HTMLElement>;
    style?: React.CSSProperties;
}

export type ColorProps = { color: ColorScheme; role: Role; shape: Shape };

export const Button = forwardRef<ReactElement | HTMLElement, PropsWithChildren<Props & Record<string, any>>>(
    (
        {
            as: Component = "button",
            children,
            className,
            role = "primary",
            colorScheme = "default",
            shape = "button",
            size = "regular",
            padding = "regular",
            loading = false,
            disabled = false,
            loadingDisables = true,
            active = false,
            noBackground = false,
            noBorder = false,
            nowrap = false,
            onClick,
            onDisabledClick = (e) => {
                e.preventDefault();
            },
            style = {},
            ...rest
        },
        ref
    ) => {
        // prevent error if as is null or empty string
        if (!Component) {
            Component = "button";
        }

        // secondary not supported for links
        if (shape === "link") {
            role = "primary";
        }

        // interactive only supports large links
        if (colorScheme === "interactive") {
            shape = "link";
            size = "large";
        }

        const isMedium = useMedia("(min-width: 42em)");

        const hideChildren = (padding === "icon" || (padding === "iconUntilMedium" && !isMedium)) && loading;

        const clickDisabled = disabled || active || (loading && loadingDisables);

        const clickHandler = clickDisabled ? onDisabledClick : onClick;

        return (
            <Component
                className={classNames(
                    styles[`color-${colorScheme}-${role}-${shape}`],
                    styles[`shape-${shape}`],
                    styles[`size-${size}-${shape}`],
                    styles[`padding-${padding}-${shape}`],
                    loading && styles.loading,
                    disabled && styles.disabled,
                    clickDisabled && styles["cursor-disabled"],
                    active && styles.active,
                    noBackground && styles.noBackground,
                    noBorder && styles.noBorder,
                    nowrap && styles.noWrap,
                    className
                )}
                ref={ref}
                onClick={clickHandler}
                style={style}
                {...rest}
            >
                {loading && (
                    <Spinner colorScheme={colorScheme === "default" && role === "primary" ? "light" : "dark"} />
                )}
                {!hideChildren && Children.map(children, (child) => renderChild(child))}
            </Component>
        );
    }
);

// Wrap top level child text nodes with elements so they get spacing margins
function renderChild(el: any) {
    if (isValidElement(el)) {
        return el;
    }

    if (el) {
        return <span>{el}</span>;
    }

    return null;
}
