import { SectionSummary } from "../types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "TABLE_SECTION_LIST/LOADING",
    LOADED = "TABLE_SECTION_LIST/LOADED",
    FAILED = "TABLE_SECTION_LIST/FAILED",
}

const list = scaffoldList<SectionSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
