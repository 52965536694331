import { KeyedLoaded } from "common/loader";
import { UploadResult } from "features/catalogue/types";

export enum TypeKeys {
    FETCH_BEGIN = "UPLOAD/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "UPLOAD/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "UPLOAD/FETCH_EDITABLE_FAILURE",
    RESET = "UPLOAD/RESET",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.FETCH_BEGIN }),
    loaded: (data: UploadResult) => ({ type: TypeKeys.FETCH_SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FETCH_FAILURE, error }),
    reset: () => ({ type: TypeKeys.RESET }),
};

type LoadingAction = { type: TypeKeys.FETCH_BEGIN; key: string };
type LoadedAction = { type: TypeKeys.FETCH_SUCCESS; key: string; data: UploadResult };
type FailedAction = { type: TypeKeys.FETCH_FAILURE; key: string; error: any };
type ResetAction = { type: TypeKeys.RESET };

type UploadAction = LoadingAction | LoadedAction | FailedAction | ResetAction;

export type State = KeyedLoaded<UploadResult, string | undefined>;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: UploadAction): State {
    if (action.type === TypeKeys.FETCH_BEGIN) {
        return {
            status: "loading",
            key: action.key,
        };
    }

    if (action.type === TypeKeys.FETCH_SUCCESS) {
        const { data, key } = action;

        return {
            status: "loaded",
            key,
            data,
        };
    }

    if (action.type === TypeKeys.FETCH_FAILURE) {
        const { error, key } = action;

        return {
            status: "failed",
            key,
            error,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return initialState;
    }

    return state;
}
