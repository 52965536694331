import { opsApi } from "API";
import { RoleSummary } from "../types/RoleSummary";

export const getOperatorPortalRolesApi = async (): Promise<RoleSummary[]> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, {});
    return res.data.operatorPortalRoles;
};

type GraphQLPayload = { roles: RoleSummary[] };

const query = `
    query {
        operatorPortalRoles {
            shortName,
            displayName,
            description,
            userType
        }
    }
`;
