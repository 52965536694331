import * as Yup from "yup";
import { blobToString } from "common/utility/blobToString";
import { calculateSvgComplexity } from "common/utility/svgComplexity";

export class FileSchemaType extends Yup.mixed {
    _typeCheck(value: any) {
        return Object.prototype.toString.call(value) === "[object File]";
    }

    // eslint-disable-next-line no-template-curly-in-string
    fileType(match: RegExp, message = "File type must match ${match}") {
        return this.test({
            name: "type",
            exclusive: true,
            message,
            params: { match },
            test(value: File | null) {
                return !value || match.test(value.type);
            },
        });
    }

    // eslint-disable-next-line no-template-curly-in-string
    fileSize(bytes: number, message = "File must be smaller than ${bytes} bytes") {
        return this.test({
            name: "fileSize",
            exclusive: true,
            message,
            params: { bytes },
            test(value: File | null) {
                return !value || value.size < bytes;
            },
        });
    }

    fileName(pattern: RegExp, message: string) {
        return this.test({
            name: "fileName",
            exclusive: true,
            message,
            params: { pattern },
            test(value: File | null) {
                return !value || pattern.test(value.name);
            },
        });
    }

    svgComplexity(maxComplexity: number, message: string) {
        return this.test({
            name: "svgComplexity",
            exclusive: true,
            message,
            params: { maxComplexity },
            async test(value: File | null) {
                if (!value) {
                    return true;
                }

                try {
                    const svgXml = await blobToString(value);

                    return calculateSvgComplexity(svgXml) < maxComplexity;
                } catch (e) {
                    console.error(e);
                    return false;
                }
            },
        });
    }
}
