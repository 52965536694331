import { withSvg } from "core/components/svg/Svg";

export const SearchMagnify = withSvg(
    24,
    24
)(() => (
    <>
        <path
            d="M14.322 14.3217L18.75 18.7501"
            stroke="#202020"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.875 15.7501C13.5674 15.7501 15.75 13.5675 15.75 10.8751C15.75 8.18273 13.5674 6.00012 10.875 6.00012C8.18261 6.00012 6 8.18273 6 10.8751C6 13.5675 8.18261 15.7501 10.875 15.7501Z"
            stroke="#202020"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
