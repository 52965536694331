import moment from "moment-timezone";

export type State = {
    previewDate?: moment.Moment;
};

enum TypeKeys {
    SET_PREVIEW_DATE = "SERVICELIST/SET_PREVIEW_DATE",
    RESET_PREVIEW_DATE = "SERVICELIST/RESET_PREVIEW_DATE",
}

export const createAction = {
    setPreviewDate: (previewDate: moment.Moment) => ({ type: TypeKeys.SET_PREVIEW_DATE, previewDate }),
    resetPreviewDate: () => ({ type: TypeKeys.RESET_PREVIEW_DATE }),
};

type SetPreviewDateAction = { type: TypeKeys.SET_PREVIEW_DATE; previewDate: moment.Moment };
type ResetPreviewDateAction = { type: TypeKeys.RESET_PREVIEW_DATE };

type PreviewAction = SetPreviewDateAction | ResetPreviewDateAction;

const initialState: State = {};

export function reducer(state: State = initialState, action: PreviewAction): State {
    if (action.type === TypeKeys.SET_PREVIEW_DATE) {
        return {
            previewDate: action.previewDate,
        };
    }

    if (action.type === TypeKeys.RESET_PREVIEW_DATE) {
        return initialState;
    }

    return state;
}
