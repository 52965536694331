import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTimer } from "common/hooks/useTimer";
import { trackPageView } from "..";
import { isId } from "common/utility/StringUtils";

// threshold prevents multiple page views when location.pathname changes rapidly e.g redirect
const THRESHOLD = 500;

interface PageViewInfo {
    uri: string;
    pathname: string;
}

// v. simple page name generation, strip region/location, prefix ids with 'edit'
const getPagename = (pathname: string): string => {
    let pageName = "";

    if (pathname && pathname.split("/").length > 3) {
        const [, , , ...rest] = pathname.split("/");
        pageName = (rest as string[]).map((part) => (isId(part) ? `EDIT/${part}` : part.toUpperCase())).join("/");
    }

    return pageName;
};

export const PageView = () => {
    const location = useLocation();
    const [lastQueuedTime, setLastQueuedTime] = useState(0);
    const [queue, setQueue] = useState<PageViewInfo[]>([]);
    const { setTimer, cancelTimer } = useTimer();

    // send page views to app insights
    const flush = useCallback(() => {
        queue.forEach((info) => {
            trackPageView(info.uri, {
                name: getPagename(info.pathname),
            });
        });
        setQueue([]);
    }, [queue]);

    // queue page views, using threshold to replace almost immediately changed paths
    const enqueue = (info: PageViewInfo) => {
        const now = Date.now();
        const replace = queue.length && now - lastQueuedTime < THRESHOLD;

        if (replace) {
            queue[queue.length - 1] = info;
        } else {
            queue.push(info);
        }

        cancelTimer();
        setTimer(flush, 500);
        setLastQueuedTime(Date.now());
    };

    // 'listen' for changes to react router location to trigger page views
    useEffect(() => {
        enqueue({
            uri: window.location.origin + location.pathname,
            pathname: location.pathname,
        });

        return cancelTimer;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return null;
};
