import { State as QuickSellData, reducer as editReducer } from "./edit";

import { combineReducers } from "redux";

export interface State {
    quickSell: {
        data: QuickSellData;
    };
}

export const reducer = combineReducers({
    data: editReducer,
});
