import { useParams } from "react-router-dom";
import { PageError } from "core/components/pageError";
import { TeamsRouteParams } from "common/types";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";

export const CataloguePermissionsError = () => {
    const { region } = useParams<TeamsRouteParams>();

    if (!region) {
        return null;
    }

    return (
        <PageError
            heading="Access denied"
            message="You don’t have permission to view the catalogue."
            actions={<Actions />}
        />
    );
};

const Actions = () => {
    const permissions = useSelector(getLocationPermissions);

    const canLaunchVenman = permissions.has("deviceenrolment:create");

    return (
        <ul className="list-reset cy-3">
            <li className="text-subheading">Looking for something else?</li>
            <li>Check the navigation and location menus for other areas or resources you have access to.</li>
            {canLaunchVenman && (
                <li>
                    Looking for the <strong>Venue Manager</strong>? Use the link in the navigation menu to launch it.
                </li>
            )}
        </ul>
    );
};
