import { Loaded } from "common/loader";
import { Region } from "../types/region";

export enum TypeKeys {
    LOADING_REGION = "REGIONS/LOADING",
    ADD_REGION = "REGIONS/ADD",
}

export type State = Loaded<Region[]>;

export const actionCreators = {
    loadingRegions: (status: "loading" | "failed"): LoadingRegionAction => ({ type: TypeKeys.LOADING_REGION, status }),
    addRegion: (region: Region): AddRegionAction => ({ type: TypeKeys.ADD_REGION, region }),
};

export type LoadingRegionAction = { type: TypeKeys.LOADING_REGION; status: "loading" | "failed" };
export type AddRegionAction = { type: TypeKeys.ADD_REGION; region: Region };

export type RegionAction = LoadingRegionAction | AddRegionAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: RegionAction): State {
    if (action.type === TypeKeys.LOADING_REGION) {
        if (action.status === "failed") {
            return {
                status: "failed",
                error: "failed to load",
            };
        }
        return {
            status: action.status,
        };
    }
    if (action.type === TypeKeys.ADD_REGION && state.status !== "loaded") {
        return {
            status: "loaded",
            data: [
                {
                    ...action.region,
                },
            ],
        };
    }
    if (action.type === TypeKeys.ADD_REGION && state.status === "loaded") {
        if (state.data.every((r: Region) => r.id !== action.region.id && r.displayName !== action.region.displayName)) {
            return {
                ...state,
                data: [...state.data, action.region],
            };
        }
        return state;
    }
    return state;
}
