export type CsvWriter<T> = (row: T) => void;

export function generateCsvBlob<TOut extends Array<string | null>>(generator: (writer: CsvWriter<TOut>) => void) {
    const tokens = generateCsvTokens(generator);

    return new Blob(tokens);
}

// TODO rename file to something more format agnostic

export function generateCsvString<TOut extends Array<string | null>>(
    generator: (writer: CsvWriter<TOut>) => void,
    rowDelimiter = "\n"
) {
    const tokens = generateCsvTokens(generator, rowDelimiter);

    return tokens.join("");
}

function generateCsvTokens<TOut extends Array<string | null>>(
    generator: (writer: CsvWriter<TOut>) => void,
    rowDelimiter = "\r\n"
) {
    const tokens: string[] = [];

    const CELL_DELIMITER = ",";

    generator((row: TOut) => {
        for (let i = 0; i < row.length; i++) {
            const cell = formatCell(row[i]);

            if (cell) {
                tokens.push(cell);
            }

            if (i === row.length - 1) {
                tokens.push(rowDelimiter);
            } else {
                tokens.push(CELL_DELIMITER);
            }
        }
    });

    return tokens;
}

const NEEDS_QUOTING = /^\s|\s$|,|"|\n/;

function formatCell(value: string | null): string | null {
    if (value === null) {
        return null;
    }

    if (NEEDS_QUOTING.test(value)) {
        value = value.replace(/"/g, '""');
        value = `"${value}"`;
    }

    return value.trim();
}
