import { useCallback, useRef, useState } from "react";

interface LongPressOptions {
    onLongPress: (event: React.MouseEvent | React.TouchEvent) => void;
    onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
    shouldPreventDefault?: boolean;
    delay?: number;
}

// based on https://stackoverflow.com/questions/48048957/react-long-press-event

export const useLongPress = ({ onLongPress, onClick, shouldPreventDefault = true, delay = 500 }: LongPressOptions) => {
    const [, setLongPressTriggered] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const target = useRef<EventTarget | null>(null);

    const start = useCallback(
        (event: React.MouseEvent | React.TouchEvent) => {
            if (shouldPreventDefault && event.target) {
                (event.target as HTMLElement).addEventListener("touchend", preventDefault, {
                    passive: false,
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event: React.MouseEvent | React.TouchEvent, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);

            setLongPressTriggered((prevLongPressTriggered) => {
                shouldTriggerClick && !prevLongPressTriggered && onClick?.(event);
                return false;
            });

            (target.current as HTMLElement)?.removeEventListener("touchend", preventDefault);
        },
        [onClick]
    );

    return {
        onMouseDown: (e: React.MouseEvent) => start(e),
        onTouchStart: (e: React.TouchEvent) => start(e),
        onMouseUp: (e: React.MouseEvent) => clear(e),
        onMouseLeave: (e: React.MouseEvent) => clear(e, false),
        onTouchEnd: (e: React.TouchEvent) => clear(e),
    };
};

const isTouchEvent = (event: Event): event is TouchEvent => {
    return "touches" in event;
};

const preventDefault = (event: Event) => {
    if (!isTouchEvent(event)) return;

    if ((event as TouchEvent).touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};
