import styles from "./Card.module.scss";

import { forwardRef, PropsWithChildren, ReactElement } from "react";
import { useTestAttributes } from "../testAttributes";
import classNames from "classnames";

type CardStyle = "default" | "popup";

interface Props {
    as?: React.ElementType;
    elevation?: string;
    className?: string;
    cardStyle?: CardStyle;
    hide?: boolean;
}

export const Card = forwardRef<ReactElement | HTMLElement, PropsWithChildren<Props & Record<string, any>>>(
    ({ as: Component = "div", cardStyle = "default", children, className, elevation = "small", hide = false }, ref) => {
        const { getTestClass } = useTestAttributes();

        // prevent error if as is null or empty string
        if (!Component) {
            Component = "div";
        }

        return (
            <Component
                className={classNames(
                    styles.container,
                    elevation && styles[`elevation-${elevation}`],
                    cardStyle === "popup" && styles.popup,
                    hide && styles.hidden,
                    className
                )}
                ref={ref}
                data-testclass={getTestClass({ fragment: "section" })}
            >
                {children}
            </Component>
        );
    }
);
