export enum TestAttributePrefix {
    APP_HEADER = "app-header",
    FEATURE_ANALYTICS = "analytics",
    FEATURE_BRANDING = "branding",
    FEATURE_CATALOGUE = "catalogue",
    FEATURE_CATEGORY = "category",
    FEATURE_DAILYSALES = "daily-sales-report",
    FEATURE_GROUPTABS = "grouptabs",
    FEATURE_LOCATION = "location",
    FEATURE_MEMBERSHIP = "membership",
    FEATURE_PROMOTIONS = "promotions",
    FEATURE_POS_CATALOGUE = "pos-catalogue",
    FEATURE_POS_CATALOGUE_IMPORT = "pos-catalogue-import",
    FEATURE_POS_CONFIGURATION = "pos-configuration",
    FEATURE_PAYMENT = "payment",
    FEATURE_QUICKSELL = "quicksell",
    FEATURE_SECTION = "section",
    FEATURE_SERVICE = "service",
    FEATURE_STRIPE = "stripe",
    FEATURE_SURCHARGE = "surcharge",
    FEATURE_TAKEAWAY = "takeaway",
    FEATURE_TAXES = "taxes",
    FEATURE_TEAMS = "teams",
    FEATURE_USER = "user",
    FEATURE_WAITER = "staff",
    FEATURE_MIGRATION = "migration",
    CATALOGUE_MODIFIER = "catalogue-modifier",
    CATALOGUE_PRODUCT = "catalogue-product",
    LAYOUT = "layout",
    LOCATION_SELECTOR = "location-selector",
    MAIN_NAV = "main-nav",
    REGION_SELECTOR = "region-selector",
}
