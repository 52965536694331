import { AppState } from "features";
import { createSelector } from "reselect";
import { ServiceCalendarSectionItem, ServiceSectionCalendarDay } from "../types";
import moment from "moment";
import { CalendarDateFormat } from "../context/CalendarContext";

export const getCalendarStatus = ({ services: { calendar } }: AppState) => calendar.status;

export const getCalendar = ({ services: { calendar } }: AppState) => {
    return calendar.status === "loaded" ? calendar.data : null;
};

export const haveDatesBeenFetched = createSelector(
    getCalendar,
    (_: AppState, dateStrings: string[] | undefined) => dateStrings,
    (data, dateStrings): boolean => {
        return !!dateStrings?.every((x) => data?.sections.find((s) => s.dates.find((d) => d.date === x)));
    }
);

export const getDateStringArray = (startDate: string | undefined, days: number) =>
    Array.from({ length: days }, (_, i) => {
        return moment(startDate, CalendarDateFormat).add(i, "days").format(CalendarDateFormat);
    });

type WeekCalendarSelectorParams = {
    weekStartDateString: string | undefined;
    sectionId: string | undefined;
};

export const getCalendarItemsForWeek = createSelector(
    getCalendar,
    (_: AppState, params: WeekCalendarSelectorParams) => params,
    (data, { weekStartDateString, sectionId }): ServiceSectionCalendarDay[] => {
        // We allow an empty sectionId to represent a special 'all services' section.
        // It's not a real section but a fake one created by `getCalendarSectionOptions`.
        // This matches a respective fake section created by the serviceCalendar API for a venues with no sections.
        if (!data || !weekStartDateString) {
            return [];
        }

        const dates = getDateStringArray(weekStartDateString, 7);
        const calendarSection = data?.sections.find((section) => section.id === sectionId);
        if (!calendarSection) {
            return [];
        }

        return calendarSection.dates.filter((x) => dates.includes(x.date));
    }
);

export const getCalendarItemsForDay = createSelector(
    getCalendar,
    (_: AppState, dateString: string | undefined) => dateString,
    (data, dateString): ServiceCalendarSectionItem[] => {
        return (
            data?.sections.map((x) => {
                return { ...x, dates: x.dates.filter((x) => x.date === dateString) };
            }) ?? []
        );
    }
);
