import { State as ListState, reducer as listReducer } from "./list";

import { combineReducers } from "redux";

export interface State {
    migration: {
        listExportIssues: ListState;
    };
}

export const reducer = combineReducers({
    listExportIssues: listReducer,
});
