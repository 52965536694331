import { State as UploadState, reducer as uploadReducer } from "./upload";
import { State as DeleteState, reducer as deleteReducer } from "./bulkDelete";
import { State as ImportState, reducer as importReducer } from "./import";
import { State as PosSyncState, reducer as posSyncReducer } from "./posSync";
import { State as FiltersState, reducer as filtersReducer } from "./filters";
import { combineReducers } from "redux";

export interface State {
    catalogue: {
        upload: UploadState;
        bulkDelete: DeleteState;
        bulkImport: ImportState;
        posSync: PosSyncState;
        filters: FiltersState;
    };
}

export const reducer = combineReducers({
    bulkDelete: deleteReducer,
    bulkImport: importReducer,
    posSync: posSyncReducer,
    upload: uploadReducer,
    filters: filtersReducer,
});
