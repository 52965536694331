import { generatePath } from "react-router";
import { GroupRouteParams, LocationRouteParams, TeamsRouteParams } from "common/types";
import { NavItem, NavItemGroup, NavNode, isNavItem, NavGroup, isNavGroup } from "../types/NavMenu";
import { NavItemFilter } from "../types/NavItemFilter";
import { nonNullable } from "common/types/nonNullable";

export function getMainMenu(
    navStructure: NavNode[],
    itemFilters: NavItemFilter[],
    routeParams: LocationRouteParams | GroupRouteParams | TeamsRouteParams
): NavNode[] {
    return navStructure
        .map((node) => {
            if (isNavGroup(node)) {
                const mapped = mapNavGroup(node, itemFilters, routeParams);
                return mapped.navItemGroups.length > 0 ? mapped : null;
            } else if (isNavItem(node)) {
                return mapNavItem(node, itemFilters, routeParams);
            } else {
                return null;
            }
        })
        .filter(nonNullable);
}

function mapNavGroup(group: NavGroup, itemFilters: NavItemFilter[], routeParams: any): NavGroup {
    return {
        ...group,
        navItemGroups: group.navItemGroups
            .map((item) => {
                const mappedNavItemGroup = mapNavItemGroup(item, itemFilters, routeParams);
                return mappedNavItemGroup.children.length > 0 ? mappedNavItemGroup : null;
            })
            .filter(nonNullable),
    };
}

function mapNavItemGroup(group: NavItemGroup, itemFilters: NavItemFilter[], routeParams: any): NavItemGroup {
    return {
        ...group,
        children: group.children.map((item) => mapNavItem(item, itemFilters, routeParams)).filter(nonNullable),
    };
}

function mapNavItem(item: NavItem, itemFilters: NavItemFilter[], routeParams: any): NavItem | null {
    return isValidItem(item, itemFilters) ? populateRouteParams(item, routeParams) : null;
}

function populateRouteParams(item: NavItem, params: any): NavItem {
    return {
        ...item,
        route: item.route !== undefined ? generatePath(item.route, params) : undefined,
        externalUrl: item.externalUrl ? replaceVariables(item.externalUrl, params) : undefined,
    };
}

// generatePath doesn't like the port :3003 (local dev) so we just do it ourselves
function replaceVariables(input: string, args: { [key: string]: string }) {
    return input.replace(/:([a-zA-Z]+)/g, (_, variable) => args[variable]);
}

function isValidItem(item: NavItem, filters: NavItemFilter[]) {
    return filters.every((filter) => filter(item));
}
