import { createContext, useContext } from "react";
import { ActiveLocation } from "../types/ActiveLocation";

// importing anything from location/index.ts bring unwanted dependencies that error in the test env,
// hence moved location and locale contexts here to allow independant imports
// TODO migrate existing containers to useContext(LocationContext)
// TODO define provider for LocationContext here

export const LocationContext = createContext<ActiveLocation>(null!);

export const useLocationContext = () => useContext(LocationContext);
