import { CategorySummary, EditableCategoryOrder } from "..";
import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    // fetch not relevant to editing category order, but required by scaffoldEdit
    FETCH_BEGIN = "CATEGORY/FETCH_EDITABLE_ORDER_BEGIN",
    FETCH_SUCCESS = "CATEGORY/FETCH_EDITABLE_ORDER_SUCCESS",
    FETCH_FAILURE = "CATEGORY/FETCH_EDITABLE_ORDER_FAILURE",

    SAVE_BEGIN = "CATEGORY/SAVE_EDITABLE_ORDER_BEGIN",
    SAVE_SUCCESS = "CATEGORY/SAVE_EDITABLE_ORDER_SUCCESS",
    SAVE_FAILURE = "CATEGORY/SAVE_EDITABLE_ORDER_FAILURE",
}

const editOrder = scaffoldEdit<EditableCategoryOrder, CategorySummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = editOrder;

export type State = ReturnType<typeof editOrder.reducer>;
export { createAction, reducer };
