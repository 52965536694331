import { opsApi } from "API";
import { auth } from "common/auth";
import { getLegacyRegion } from "./getLegacyRegion";
import { Region } from "./types/region";

const REGION_KEY = "MEANDU_REGION_ID";

const getRegionFromPath = () => {
    const regionPath = window.location.pathname.split("/")[1];
    return validRegionPath(regionPath) ? regionPath : null;
};

const validRegionPath = (regionId: string) => /^[a-z]{2,3}$/.test(regionId);

const isDefaultPath = (pathname: string) => pathname.length === 1 || pathname === "/auth/callback";

export class RegionHelper {
    private activeRegion: Region;

    constructor() {
        const regionPath = getRegionFromPath();
        const storageRegion = localStorage.getItem(REGION_KEY);

        const activeRegion = {
            id:
                regionPath ||
                (isDefaultPath(window.location.pathname) && storageRegion ? storageRegion : getLegacyRegion()),
        } as Region;

        this.activeRegion = activeRegion;
        localStorage.setItem(REGION_KEY, this.activeRegion.id);
    }

    init = async (availableRegions: string[] | null) => {
        const regionPath = getRegionFromPath();

        if (regionPath) {
            this.activeRegion.id = regionPath;
        }

        const initialId = this.activeRegion.id;
        if (
            isDefaultPath(window.location.pathname) &&
            availableRegions?.length &&
            availableRegions.every((r) => r !== this.activeRegion.id)
        ) {
            this.activeRegion.id = availableRegions[0];
        }

        const activeRegion = await fetchRegion(this.activeRegion.id);

        this.setActiveRegion(activeRegion);

        if (activeRegion.id !== initialId) {
            window.location.replace(`/${activeRegion.id}`);
        }

        return activeRegion;
    };

    getActiveRegion = () => this.activeRegion;

    private setActiveRegion = (activeRegion: Region) => {
        this.activeRegion = activeRegion;
        localStorage.setItem(REGION_KEY, this.activeRegion.id);
    };

    addRegion = (init?: RequestInit) => {
        if (init?.headers?.["x-meandu-region"]) {
            return init;
        }

        if (!this.activeRegion.id) {
            return undefined;
        }

        return {
            ...init,
            headers: {
                ...init?.headers,
                "x-meandu-region": this.activeRegion.id,
            },
        };
    };

    isLegacyRegion = () => this.activeRegion.id === getLegacyRegion();

    hasAccess = () => {
        const availableRegions = auth.getAvailableRegions();

        return availableRegions
            ? availableRegions.some((id) => this.activeRegion.id === id)
            : this.activeRegion.id === getLegacyRegion();
    };

    getStoredRegion = () => localStorage.getItem(REGION_KEY);
}

export async function fetchRegion(id?: string) {
    if (!id) {
        throw new Error("Invalid region");
    }

    const res = await opsApi.fetch(`/region?${id}`, {
        headers: {
            "x-meandu-region": id,
        },
    });

    const region: Region = await res.json();

    return region;
}
