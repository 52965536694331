import { createSelector } from "reselect";
import moment from "moment-timezone";
import { ServiceCalendarData, ServiceSummary } from "../types";
import { AppState } from "../../state";
import { isWithinTimes } from "common/utility/liveTimes";
import { getCalendar } from "./getCalendar";
import { getCalendarSections } from "./getCalendarSections";
import { CalendarDateFormat } from "../context/CalendarContext";
import { SectionSummary } from "features/section";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";

export const getServices = ({ services: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

export const getSections = ({ sections: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

// TODO make nullish types consistent
export const getActiveServicesNow = (
    services: ServiceSummary[] | null,
    sections: SectionSummary[] | undefined,
    calendar: ServiceCalendarData | null,
    now: moment.Moment
) => {
    if (!services || !calendar) return [];

    const activeServices: { [key: string]: ServiceSummary } = {};

    if (sections?.length) {
        for (const section of sections) {
            const datesForSection = calendar?.sections.find((s) => s.id === section.id)?.dates || [];

            const calendarItems =
                datesForSection.find((d) => d.date === now.format(CalendarDateFormat))?.calendarItems || [];

            const activeCalendarItems = calendarItems.filter(({ startTime, endTime }) =>
                isWithinTimes(startTime, endTime, now)
            );

            activeCalendarItems.forEach((item) => {
                const service = services.find((s) => s.id === item.service.id);

                if (service) {
                    if (!activeServices[service.id]) {
                        activeServices[service.id] = {
                            ...service,
                            sections: [section],
                        };
                    } else {
                        activeServices[service.id].sections.push(section);
                    }
                }
            });
        }
    }

    return Object.values(activeServices);
};

export const getTick = (_: AppState, tick: number) => {
    return tick;
};

export const getActiveServices = createSelector(
    getServices,
    getCalendarSections,
    getCalendar,
    getActiveLocation,
    getTick,
    (services, sections, calendar, location, _) => {
        const now = moment().tz(location?.timeZone || "Australia/Sydney");
        return getActiveServicesNow(services, sections, calendar, now);
    }
);
