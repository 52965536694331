import { auth } from "common/auth/auth";
import { region } from "features/region";
import { config } from "common/config";

const opsFetch = (url: string, init?: RequestInit) =>
    fetch(`${config.VITE_OPS_API}${url}`, region.addRegion(authorize(init)));

export { opsFetch as opsApiFetch };

function authorize(init?: RequestInit): RequestInit | undefined {
    const accessToken = auth.getAccessToken();

    if (accessToken === null) {
        return init;
    }

    return {
        ...init,
        headers: {
            ...(init ? init.headers : undefined),
            Authorization: `Bearer ${accessToken}`,
        },
    };
}
