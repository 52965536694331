import { CardWidth, Confirm, Modal, ModalRenderer } from "../modal";
import { Icon } from "../icon";
import { Bin } from "common/icons";
import { Row } from "../card";
import { ReactNode } from "react";

export interface ConfirmDeleteModalProps {
    title?: string;
    deleteLabel?: string;
    children?: ReactNode;
    onClick?: () => void;
    onClose?: () => void;
    visible: boolean;
}

export const ConfirmDeleteModal = ({
    title,
    deleteLabel,
    children,
    onClick,
    onClose,
    visible,
}: ConfirmDeleteModalProps) => {
    title = title ?? "Are you sure you want to delete this item?";
    deleteLabel = deleteLabel ?? "Delete";
    children = children ?? "This action cannot be undone.";
    return (
        <ModalRenderer target={"#modal"}>
            <Modal
                footer={
                    <Confirm
                        confirmProps={{ colorScheme: "critical" }}
                        confirmLabel={
                            <>
                                <Icon size="small">
                                    <Bin />
                                </Icon>
                                <span>{deleteLabel}</span>
                            </>
                        }
                        onConfirm={onClick}
                    />
                }
                onClose={() => onClose?.()}
                title={title}
                visible={visible}
                width={CardWidth.NARROW}
            >
                <Row>{children}</Row>
            </Modal>
        </ModalRenderer>
    );
};
