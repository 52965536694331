import { combineReducers } from "redux";
import { State as FiltersState, reducer as filtersReducer } from "./filters";
import { State as EditState, reducer as editReducer } from "./edit";

export interface State {
    user: {
        filters: FiltersState;
        edit: EditState;
    };
}

export const reducer = combineReducers({
    edit: editReducer,
    filters: filtersReducer,
});
