import { EditablePromotion, Promotion } from "../types";

import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "PROMOTIONS/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "PROMOTIONS/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "PROMOTIONS/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "PROMOTIONS/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "PROMOTIONS/SAVE_EDITABLE_SUCCESS",
    SAVE_FAILURE = "PROMOTIONS/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditablePromotion, Promotion, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
