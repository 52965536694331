import styles from "./LinkedItemBadge.module.scss";

import { Badge } from "core/components/badge";

interface Props {
    letter?: string;
    disabled?: boolean;
}

export const LinkedItemBadge = ({ letter = "B", disabled = false }: Props) => {
    return (
        <Badge className={styles.badge} backgroundColorScheme={disabled ? "disabled" : "mid"} shape="circle">
            <span>{letter}</span>
        </Badge>
    );
};
