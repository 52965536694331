import { withSvg } from "core/components/svg/Svg";

export const Synced = withSvg(
    16,
    16
)(() => (
    <>
        <path d="M9.98858 2.89794C9.35174 2.63431 8.66911 2.49908 7.97986 2.5L7.97935 2.50001C7.23947 2.50024 6.508 2.6568 5.83284 2.95942C5.15768 3.26204 4.55409 3.7039 4.06159 4.25605C3.5691 4.8082 3.19882 5.45817 2.97503 6.16339C2.75123 6.8686 2.67896 7.61315 2.76296 8.34824C2.79431 8.6226 2.59731 8.87042 2.32295 8.90177C2.0486 8.93312 1.80077 8.73612 1.76942 8.46177C1.66944 7.58671 1.75546 6.7004 2.02187 5.86091C2.28828 5.02142 2.72906 4.24768 3.31532 3.59041C3.90159 2.93313 4.62011 2.40714 5.42382 2.04689C6.22745 1.68669 7.09811 1.50032 7.97877 1.50001C8.79963 1.49893 9.61262 1.66001 10.3711 1.97398C11.1296 2.28799 11.8186 2.74874 12.3986 3.32978C12.5937 3.52522 12.5934 3.84181 12.3979 4.03689C12.2025 4.23197 11.8859 4.23167 11.6908 4.03623C11.2039 3.54841 10.6254 3.16157 9.98858 2.89794ZM7.97877 1.50001C7.97885 1.50001 7.97894 1.50001 7.97903 1.50001L7.97919 2.00001L7.97852 1.50001C7.9786 1.50001 7.97868 1.50001 7.97877 1.50001ZM13.6231 6.53888C13.8971 6.50456 14.147 6.69887 14.1813 6.97287C14.3432 8.26539 14.0972 9.57619 13.4778 10.7221C12.8583 11.868 11.8963 12.7917 10.7262 13.3642C9.55613 13.9367 8.23642 14.1293 6.95153 13.9151C5.66664 13.7009 4.48077 13.0906 3.55965 12.1696C3.36438 11.9743 3.36437 11.6577 3.55963 11.4625C3.75488 11.2672 4.07146 11.2672 4.26673 11.4624C5.04049 12.2361 6.03663 12.7488 7.11596 12.9287C8.19528 13.1086 9.30385 12.9468 10.2867 12.4659C11.2696 11.9851 12.0777 11.2091 12.5981 10.2465C13.1184 9.28397 13.3251 8.18288 13.1891 7.09714C13.1547 6.82314 13.3491 6.5732 13.6231 6.53888Z" />
        <path d="M0.20405 5.59705C0.426611 5.43359 0.739548 5.48149 0.903015 5.70405L2.35098 7.67546L4.17561 6.11957C4.38573 5.9404 4.70132 5.96549 4.88049 6.17561C5.05967 6.38573 5.03458 6.70132 4.82446 6.88049L2.59071 8.78524C2.48501 8.87537 2.34658 8.91747 2.2086 8.90144C2.07062 8.88542 1.94553 8.81272 1.8633 8.70077L0.0970521 6.29602C-0.066415 6.07346 -0.0185103 5.76052 0.20405 5.59705ZM13.7361 6.53763C13.8765 6.55198 14.0043 6.62497 14.0879 6.7386L15.9027 9.2036C16.0664 9.42598 16.0188 9.73897 15.7965 9.90268C15.5741 10.0664 15.2611 10.0188 15.0974 9.79647L13.6096 7.77554L11.8344 9.37183C11.629 9.55647 11.3129 9.53969 11.1282 9.33435C10.9436 9.12902 10.9604 8.81288 11.1657 8.62824L13.351 6.66324C13.4559 6.5689 13.5958 6.52327 13.7361 6.53763Z" />
        <path d="M8 6.74805C7.44772 6.74805 7 7.19576 7 7.74805C7 8.30033 7.44772 8.74805 8 8.74805C8.55228 8.74805 9 8.30033 9 7.74805C9 7.19576 8.55228 6.74805 8 6.74805ZM6 7.74805C6 6.64348 6.89543 5.74805 8 5.74805C9.10457 5.74805 10 6.64348 10 7.74805C10 8.85262 9.10457 9.74805 8 9.74805C6.89543 9.74805 6 8.85262 6 7.74805Z" />
    </>
));
