import { LocationSummary } from "../types/LocationSummary";
import { PublishResult } from "../types/PublishResult";

export type State = {
    publishing: boolean;
    publishingUpdate: boolean;
    result?: PublishResult;
    publishingDisabled?: boolean;
};

export enum TypeKeys {
    BEGIN = "LOCATION/PUBLISH_BEGIN",
    SUCCESS = "LOCATION/PUBLISH_SUCCESS",
    FAILURE = "LOCATION/PUBLISH_FAILURE",
    SET_RESULT = "LOCATION/PUBLISH_SET_RESULT",
    SET_DISABLED = "LOCATION/PUBLISH_SET_DISABLED",
}

export const createAction = {
    begin: () => ({ type: TypeKeys.BEGIN }),
    success: (data: LocationSummary, result: PublishResult) => ({ type: TypeKeys.SUCCESS, data, result }),
    setResult: (result: PublishResult) => ({ type: TypeKeys.SET_RESULT, result }),
    failure: (error: any, result?: PublishResult) => ({ type: TypeKeys.FAILURE, error, result }),
    setDisabled: (disabled: boolean) => ({ type: TypeKeys.SET_DISABLED, disabled }),
};

type PublishBeginAction = { type: TypeKeys.BEGIN };
export type PublishSuccessAction = { type: TypeKeys.SUCCESS; data: LocationSummary; result: PublishResult };
type PublishFailureAction = { type: TypeKeys.FAILURE; error: any; result: PublishResult };
type PublishSetResultAction = { type: TypeKeys.SET_RESULT; result: PublishResult };
export type PublishSetDisabledAction = { type: TypeKeys.SET_DISABLED; disabled: boolean };

type PublishAction =
    | PublishBeginAction
    | PublishSuccessAction
    | PublishFailureAction
    | PublishSetResultAction
    | PublishSetDisabledAction;

const initialState: State = {
    publishing: false,
    publishingUpdate: false,
};

export function reducer(state: State = initialState, action: PublishAction): State {
    if (action.type === TypeKeys.BEGIN) {
        return {
            ...state,
            publishing: true,
            result: undefined,
        };
    }

    if (action.type === TypeKeys.SET_RESULT) {
        return {
            ...state,
            result: action.result,
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        return {
            publishing: false,
            publishingUpdate: false,
            result: action.result,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        return {
            publishing: false,
            publishingUpdate: false,
            result: action.result,
        };
    }

    if (action.type === TypeKeys.SET_DISABLED) {
        return {
            ...state,
            publishingDisabled: action.disabled,
        };
    }

    return state;
}
