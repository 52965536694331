import styles from "./Select.module.scss";

import { CatalogueItemOption } from "./types";
import { LinkedItemBadge } from "core/components/linkedItem/LinkedItemBadge";
import { PublishStatus } from "core/components/pubishStatus";

interface Props {
    option: CatalogueItemOption;
}

export const CatalogueItemSelectOption = ({ option }: Props) => {
    const { isHidden, isLinked, label, secondaryLabel } = option;

    return (
        <>
            {isLinked && <LinkedItemBadge />}
            <span>{label}</span> {secondaryLabel && <span className={styles.labelSecondary}>({secondaryLabel})</span>}
            {isHidden && (
                <span className="ml-3">
                    <PublishStatus status="hidden" size="small">
                        Hidden
                    </PublishStatus>
                </span>
            )}
        </>
    );
};
