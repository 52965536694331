import { MenuItemTemplate } from "features/menuitemtemplate/types/MenuItemTemplate";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "MENUITEMTEMPLATELIST/LOADING",
    LOADED = "MENUITEMTEMPLATELIST/LOADED",
    FAILED = "MENUITEMTEMPLATELIST/FAILED",
}

const list = scaffoldList<MenuItemTemplate, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
