import { withSvg } from "core/components/svg/Svg";

/* close-3 */
export const CloseSmall = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M6.79295 6.79297C7.1835 6.40247 7.81667 6.40251 8.20716 6.79306L11.625 10.2113L15.0425 6.79377C15.4331 6.40324 16.0662 6.40324 16.4567 6.79377C16.8473 7.18429 16.8473 7.81745 16.4567 8.20798L13.0391 11.6256L16.4568 15.0438C16.8473 15.4344 16.8472 16.0675 16.4567 16.458C16.0661 16.8485 15.433 16.8485 15.0425 16.4579L11.6249 13.0399L8.20674 16.458C7.81622 16.8485 7.18305 16.8485 6.79253 16.458C6.402 16.0675 6.402 15.4343 6.79253 15.0438L10.2107 11.6255L6.79285 8.20718C6.40236 7.81663 6.4024 7.18346 6.79295 6.79297Z" />
    </>
));
