import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { useTestAttributes } from "core/components/testAttributes";

import "./PageTitle.scss";

export interface Props {
    title: string;
    description: string | React.ReactNode;
    additionalInfo?: string | React.ReactNode;
    disabled?: boolean;
    toggleState?: boolean;
    onToggleChange?: (value: boolean) => void;
    onToggleClick?: () => void;
}

export const PageTitle = ({
    title,
    description,
    additionalInfo,
    toggleState,
    disabled,
    onToggleChange,
    onToggleClick,
}: Props) => {
    const { getTestClass, getTestId } = useTestAttributes();

    return (
        <div className="data-listing-page__title" data-testclass={getTestClass({ fragment: "page", element: "title" })}>
            <h2>
                {title}
                {toggleState !== undefined && (!!onToggleChange || !!onToggleClick) && (
                    <Switch
                        className="data-listing-page__title__toggle"
                        checked={toggleState}
                        disabled={disabled}
                        defaultChecked={toggleState}
                        onChange={onToggleChange && ((checked) => onToggleChange(checked))}
                        onClick={onToggleClick && onToggleClick}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        data-testid={getTestId({ fragment: "page", element: "button", action: "enable" })}
                    />
                )}
            </h2>
            {description && <div className="data-listing-page__title__description">{description}</div>}
            <div className="data-listing-page__title__additional-info">{additionalInfo}</div>
        </div>
    );
};
