import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { Button, ColorScheme, Props as ButtonProps, Role } from "core/components/button";
import { Props as IconProps } from "core/components/icon/Icon";
import { SubmitMode } from "./CrudActionFooter";
import { Icon } from "../icon";

interface Props {
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    colorScheme?: ColorScheme;
    icon: ReactNode;
    iconProps?: Partial<IconProps>;
    label: string;
    role?: Role;
    saving: boolean;
    submitMode: SubmitMode;
    targetSubmitMode: SubmitMode;
}

export const ActionButton = ({
    buttonProps,
    colorScheme,
    icon,
    iconProps,
    label,
    role,
    saving,
    submitMode,
    targetSubmitMode,
}: Props): ReactElement => {
    return (
        <Button
            colorScheme={colorScheme}
            role={role}
            type="button"
            loading={submitMode === targetSubmitMode && saving}
            padding="iconUntilMedium"
            {...buttonProps}
        >
            {!(submitMode === targetSubmitMode && saving) && (
                <Icon size="small" title={label} {...iconProps}>
                    {icon}
                </Icon>
            )}
            <span className="hide-until-medium">{buttonProps?.children ? buttonProps.children : label}</span>
        </Button>
    );
};
