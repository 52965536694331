import styles from "./Tree.module.scss";

import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from "react";
import { Icon } from "../icon";
import { Input } from "../form/input";
import { Key } from "rc-tree/lib/interface";
import { normaliseText } from "common/utility/StringUtils";
import { Search } from "components/icons";
import { Title } from "./Title";
import { Tree as AntTree, TreeDataNode, TreeProps } from "antd";
import { size, TreeNode, TreeNodeParent, visit } from "common/types/TreeNode";
import { EmptyMessage } from "../card";

export type InitialSelectionsExpansionRule = "parents" | "nodes";

export type SearchFilter<T, S extends TreeDataNode = TreeDataNode> = (
    node: TreeNode<T, S>,
    searchTerm: string
) => boolean;

export type SearchHandler<T, S extends TreeDataNode = TreeDataNode> = (
    nodes: TreeNode<T, S>[],
    searchTerm: string,
    searchFilter?: SearchFilter<T, S>,
    searchHidesNodes?: boolean
) => { matchingKeys: string[]; numHidden: number };

export type TitleRenderer<T, S extends TreeDataNode = TreeDataNode> = (
    node: TreeNode<T, S>,
    searchTerm: string
) => ReactNode;

// Warning: Tree can mutate the dataSource! Create a copy if that's an issue
// We don't make the copy here in case the caller needs to mutate the dataSource as well
export interface Props<T, S extends TreeDataNode = TreeDataNode> {
    dataSource: TreeNode<T, S>[];
    defaultExpandAll?: boolean;
    defaultExpandedKeys?: string[];
    initialSelectionsExpansion?: InitialSelectionsExpansionRule[];
    inputId?: string;
    searchExpandsNodes?: boolean;
    searchHandler?: SearchHandler<T, S>;
    searchFilter?: SearchFilter<T, S>;
    searchHidesNodes?: boolean;
    searchPlaceholder?: string;
    titleRenderer?: TitleRenderer<T>;
}

export const Tree = <T, S extends TreeDataNode = TreeDataNode>({
    dataSource,
    defaultExpandAll = false,
    defaultExpandedKeys,
    initialSelectionsExpansion = ["nodes", "parents"],
    inputId,
    searchExpandsNodes = true,
    searchHandler = defaultHandleSearch,
    searchHidesNodes = false,
    searchFilter = defaultSearchFilter,
    searchPlaceholder,
    titleRenderer = defaultTitleRenderer,
    ...rest
}: Props<T, S> & TreeProps<S>) => {
    const treeSize = useMemo(() => size(dataSource), [dataSource]);

    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const [searchTerm, setSearchTerm] = useState("");

    const [noResults, setNoResults] = useState(false);

    const [expandedKeys, setExpandedKeys] = useState<Key[]>(
        defaultExpandedKeys ? [...defaultExpandedKeys] : defaultExpandAll ? getAllKeys(dataSource) : []
    );

    const handleSearch = useCallback(
        (value: string, _?: ChangeEvent<HTMLInputElement>) => {
            setAutoExpandParent(true);
            setSearchTerm(value);
            const { matchingKeys, numHidden } = searchHandler(dataSource, value, searchFilter, searchHidesNodes);
            setNoResults(numHidden === treeSize && value.length > 0);
            if (searchExpandsNodes) {
                setExpandedKeys(matchingKeys);
            }
        },
        [searchHandler, dataSource, searchFilter, searchHidesNodes, treeSize, searchExpandsNodes]
    );

    const handleClearSearch = useCallback(() => {
        handleSearch("");
    }, [handleSearch]);

    const handleExpand = useCallback((expandedKeys: Key[]) => {
        setAutoExpandParent(false);
        setExpandedKeys(expandedKeys);
    }, []);

    const titleRender = useCallback(
        (node: TreeNode<T, S>) => titleRenderer(node, searchTerm),
        [searchTerm, titleRenderer]
    );

    return (
        <div className="tree-wrapper">
            <Input
                id={inputId}
                placeholder={`${searchPlaceholder || "Search"} `}
                onClear={handleClearSearch}
                onChange={handleSearch}
                before={
                    <Icon verticalAlign="middle">
                        <Search />
                    </Icon>
                }
                value={searchTerm}
            />
            <AntTree
                autoExpandParent={autoExpandParent}
                checkable
                className={styles.tree}
                expandedKeys={expandedKeys}
                onExpand={handleExpand}
                selectable={false}
                titleRender={titleRender as any}
                treeData={dataSource}
                {...rest}
            />
            {noResults && <EmptyMessage size="standard" message="No matching results" />}
        </div>
    );
};

function defaultTitleRenderer<T, S extends TreeDataNode = TreeDataNode>(
    node: TreeNode<T, S>,
    searchTerm: string | undefined
) {
    const data: any = node?.data ?? {};
    const { displayName, internalName } = data;
    return <Title displayName={displayName} internalName={internalName || undefined} searchTerm={searchTerm} />;
}

export function getAllKeys<T, S extends TreeDataNode = TreeDataNode>(nodes: TreeNode<T, S>[]): string[] {
    const keys: string[] = [];

    visit(nodes, (node: TreeNode<T, S>) => {
        keys.push(String(node.key));
    });

    return keys;
}

function defaultHandleSearch<T, S extends TreeDataNode = TreeDataNode>(
    nodes: TreeNode<T, S>[],
    searchTerm: string | undefined = "",
    searchFilter: SearchFilter<T, S> = defaultSearchFilter,
    searchHidesNodes: boolean = false
) {
    const search = normaliseText(searchTerm);

    const matches: Record<string, boolean> = {};

    let numHidden = 0;

    // note we mutate the className property in place to avoid cloning the tree and causing unnecessary re-renders
    visit(nodes, (node: TreeNode<T, S>, parents: TreeNodeParent<T, S>[]) => {
        const isMatch = searchFilter(node, search);

        const key: string = String(node.key);

        if (isMatch) {
            [node, ...parents].forEach((node) => {
                if (!matches[key]) {
                    matches[node.key] = true;
                    node.className = undefined;
                }
            });
        } else if (searchHidesNodes) {
            if (!matches[key]) {
                node.className = "hide";
                numHidden++;
            }
        }
    });

    return { matchingKeys: Object.keys(matches), numHidden };
}

function defaultSearchFilter<T, S extends TreeDataNode = TreeDataNode>(
    node: TreeNode<T, S>,
    searchTerm: string | undefined = ""
): boolean {
    const data: any = node.data;
    const displayName = normaliseText(data?.displayName || "");
    const internalName = normaliseText(data?.internalName || "");
    const searchText = normaliseText(data?.searchText || "");

    return displayName.includes(searchTerm) || internalName.includes(searchTerm) || searchText.includes(searchTerm);
}
