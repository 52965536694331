import styles from "./Tree.module.scss";

import { HighlightSearchTerm } from "../highlightSearchTerm";

interface Props {
    displayName: string;
    internalName?: string;
    searchTerm?: string;
    isHidden?: boolean;
}

export const Title = ({ displayName, internalName, searchTerm }: Props): JSX.Element => {
    return (
        <>
            <HighlightSearchTerm source={displayName} searchTerm={searchTerm ?? ""} noHighlight={noHighlight} />{" "}
            {internalName && (
                <i>
                    (<HighlightSearchTerm source={internalName} searchTerm={searchTerm ?? ""} />)
                </i>
            )}
        </>
    );
};

const noHighlight = (text: string) => {
    return <span className={styles.noHiglight}>{text}</span>;
};
