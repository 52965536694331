import { FeatureConfiguration } from "..";
import { reducer } from "features/userManagement/reducers";
import UserManagement from "./components/UserManagement";

export * from "features/userManagement/types";

export default function (config: FeatureConfiguration) {
    config.reducers.userManagement = reducer;

    config.addRoutes(config.routes.groupRoutes, [<UserManagement key="users-list" />]);
}
