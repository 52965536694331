import { State as ListState, reducer as listReducer } from "./list";
import { State as EditState, reducer as editReducer } from "./edit";
import { State as EditOrderState, reducer as editOrderReducer } from "./editOrder";
import { State as FiltersState, reducer as filtersReducer } from "./filters";

import { combineReducers } from "redux";

export interface State {
    categories: {
        list: ListState;
        edit: EditState;
        editOrder: EditOrderState;
        filters: FiltersState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    editOrder: editOrderReducer,
    filters: filtersReducer,
});
