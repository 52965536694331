import styles from "./CardFooter.module.scss";

import { PropsWithChildren } from "react";
import classNames from "classnames";

interface Props {
    className?: string;
}

export const CardFooter = ({ children, className }: PropsWithChildren<Props>) => {
    return <div className={classNames(styles.footer, className)}>{children}</div>;
};
