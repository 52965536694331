import { withSvg } from "core/components/svg/Svg";

export const StripeLogo = withSvg(
    97,
    40
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.1537 20.5241C96.1537 13.7358 92.8417 8.37939 86.5116 8.37939C80.1547 8.37939 76.3086 13.7358 76.3086 20.4711C76.3086 28.4527 80.8492 32.4832 87.3663 32.4832C90.5447 32.4832 92.9485 31.7673 94.7648 30.7596V25.4562C92.9485 26.3578 90.8652 26.9147 88.221 26.9147C85.6302 26.9147 83.3332 26.0131 83.0394 22.8841H96.1003C96.1003 22.5394 96.1537 21.1605 96.1537 20.5241ZM82.9592 18.005C82.9592 15.0086 84.8022 13.7623 86.4849 13.7623C88.1141 13.7623 89.8503 15.0086 89.8503 18.005H82.9592Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.9993 8.37939C63.3818 8.37939 61.6991 9.59917 60.7643 10.4477L60.4171 8.80366H54.541V39.7223L61.2184 38.3169L61.2451 30.8127C62.2066 31.5021 63.6222 32.4832 65.9726 32.4832C70.7536 32.4832 75.1073 28.6648 75.1073 20.2589C75.0805 12.5691 70.6735 8.37939 65.9993 8.37939ZM64.3968 26.6495C62.8209 26.6495 61.8861 26.0927 61.2451 25.4032L61.2184 15.5655C61.9128 14.7965 62.8743 14.2661 64.3968 14.2661C66.8273 14.2661 68.51 16.9709 68.51 20.4446C68.51 23.9978 66.854 26.6495 64.3968 26.6495Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3525 6.81483L52.0566 5.38292V0L45.3525 1.40539V6.81483Z" />
        <path d="M52.0566 8.83008H45.3525V32.0323H52.0566V8.83008Z" fill="#635BFF" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.1673 10.7925L37.7399 8.83025H31.9707V32.0325H38.648V16.308C40.2239 14.2662 42.8948 14.6374 43.7228 14.9291V8.83025C42.8681 8.51204 39.7431 7.92867 38.1673 10.7925Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.8133 3.07617L18.2962 4.45505L18.2695 25.695C18.2695 29.6195 21.2343 32.5099 25.1873 32.5099C27.3774 32.5099 28.98 32.1121 29.8614 31.6348V26.2519C29.0067 26.5966 24.7866 27.8164 24.7866 23.8919V14.4784H29.8614V8.83033H24.7866L24.8133 3.07617Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75748 15.5655C6.75748 14.5313 7.61218 14.1336 9.02778 14.1336C11.0577 14.1336 13.6218 14.7434 15.6517 15.8306V9.59917C13.4348 8.72411 11.2447 8.37939 9.02778 8.37939C3.60577 8.37939 0 11.1902 0 15.8837C0 23.2023 10.1496 22.0356 10.1496 25.1911C10.1496 26.4109 9.0812 26.8086 7.58547 26.8086C5.36859 26.8086 2.53739 25.907 0.293803 24.6873V30.9983C2.77778 32.0589 5.28846 32.5097 7.58547 32.5097C13.141 32.5097 16.9605 29.7785 16.9605 25.032C16.9338 17.13 6.75748 18.5353 6.75748 15.5655Z"
        />
    </>
));
