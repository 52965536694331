import { MenuSummary } from "features/menu/types";
import { TypeKeys as ServiceTypeKeys } from "features/service/reducers/edit";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "MENU/LIST_BEGIN",
    LOADED = "MENU/LIST_SUCCESS",
    FAILED = "MENU/LIST_FAILURE",
}

const list = scaffoldList<MenuSummary, typeof TypeKeys, typeof EditTypeKeys | typeof ServiceTypeKeys>(TypeKeys, [
    EditTypeKeys,
    ServiceTypeKeys,
]);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
