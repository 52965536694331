import { nonNullable } from "common/types/nonNullable";
import { TestAttributePrefix } from "./TestAttributePrefix";
import { TestAttributeOptions } from "./types";

function getLevel(level: number | undefined) {
    return level !== undefined ? `level${level}` : undefined;
}

function buildString(parts: (string | number | undefined)[]) {
    return parts.filter(nonNullable).join("_");
}

export function getTestAttributeGenerators(prefix: TestAttributePrefix) {
    return {
        getTestId: (options: TestAttributeOptions, id?: string) => {
            const { action, element, fragment, level, name } = options;

            return buildString([prefix, name, fragment, action, element, getLevel(level), id]);
        },
        getTestClass: (options: TestAttributeOptions) => {
            const { action, element, fragment, level, name } = options;

            return buildString([prefix, name, fragment, action, element, getLevel(level)]);
        },
    };
}
