import styles from "./Badge.module.scss";

import { PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type TextColorScheme = "default" | "light" | "mid" | "dark";

export type BackgroundColorScheme = "action" | "dark" | "disabled" | "light" | "mid" | "white" | "none";

type Shape = "pill" | "circle" | "dot";

interface Props {
    className?: string;
    textColorScheme?: TextColorScheme;
    backgroundColorScheme?: BackgroundColorScheme;
    nowrap?: boolean;
    shape?: Shape;
    outline?: boolean;
}

export const Badge = ({
    children,
    className,
    textColorScheme = "light",
    backgroundColorScheme = "action",
    nowrap = true,
    shape = "pill",
    outline = false,
    ...rest
}: PropsWithChildren<Props & React.HTMLProps<HTMLElement>>): ReactElement => {
    return (
        <span
            className={classNames(
                styles.container,
                styles[`text--${textColorScheme}`],
                styles[`bg--${backgroundColorScheme}`],
                nowrap && styles.nowrap,
                outline && styles.outline,
                styles[shape],
                className
            )}
            {...rest}
        >
            {children}
        </span>
    );
};
