import { State as ActiveState, reducer as activeReducer } from "./active";
import { State as CreateState, reducer as createReducer } from "./create";
import { State as ListState, reducer as listReducer } from "./list";
import { State as EditState, reducer as editReducer } from "./edit";
import { State as PublishState, reducer as publishReducer } from "./publish";

import { combineReducers } from "redux";

export interface State {
    locations: {
        active: ActiveState;
        create: CreateState;
        list: ListState;
        edit: EditState;
        publish: PublishState;
    };
}

export const reducer = combineReducers({
    active: activeReducer,
    create: createReducer,
    list: listReducer,
    edit: editReducer,
    publish: publishReducer,
});
