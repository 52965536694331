import { AppState } from "features/state";
import { createSelector } from "reselect";
import { Role } from "../types/RolesType";
import { UserType } from "features/user";

export const getFakeRoles = (state: AppState) => state.fakeRoles.roles;
export const getSelectedFakeRole = (state: AppState) => state.fakeRoles.fakeRole;
export const getFakeRole = (state: AppState) => state.fakeRoles.fakeRole?.value;

export const getDisplayableRoles = createSelector(getFakeRoles, (roles) => {
    return roles?.map((r: Role) => ({
        label: `${r.displayName!}${r.userType === UserType.INTERNAL ? " (me&u)" : ""}`,
        value: r,
    }));
});
