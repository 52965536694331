import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { TrackEventData } from "../appInsights/types/TrackEventData";

export const getTrackingData = (name: string, customProperties: ICustomProperties): TrackEventData => {
    return {
        event: {
            name,
        },
        customProperties,
    };
};
