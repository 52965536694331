import { PreviewAction, TypeKeys } from "./reducers/preview";
import { AppDispatch, AppMiddleware } from "features";
import { PreviewWindow } from "./types/PreviewWindow";
import { registerPreviewWindow, generatePreview, closePreviewWindows } from "./actions/generatePreview";

import { getActiveLocation } from "features/location/selectors/getLocationPermissions";

const saveActions = [
    "MENUITEM/SAVE__EDITABLE_SUCCESS",
    "MODIFIER/SAVE_EDITABLE_SUCCESS",
    "CATEGORY/SAVE__EDITABLE_SUCCESS",
    "SERVICE/SAVE__EDITABLE_SUCCESS",
];

export const previewMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    window.addEventListener("beforeunload", () => closePreviewWindows());

    return (action: PreviewAction) => {
        if (action.type === TypeKeys.PREVIEW_SERVICE) {
            const locationId = getActiveLocation(store.getState())!.id;

            registerPreviewWindow(PreviewWindow.open(action.serviceId, action.membershipLevelId));

            store.dispatch(generatePreview(locationId));
        }

        if (saveActions.indexOf(action.type) !== -1) {
            const locationId = getActiveLocation(store.getState())!.id;

            store.dispatch(generatePreview(locationId));
        }

        return next(action);
    };
};
