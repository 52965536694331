import { createContext, PropsWithChildren, useEffect, useState } from "react";

export interface MenuContextProps {
    menuActive: boolean;
    setMenuActive: (active: boolean) => void;
}

export const MenuContext = createContext<MenuContextProps>({
    menuActive: false,
    setMenuActive: () => {},
});

export function MenuProvider({ children }: PropsWithChildren<{}>) {
    const [menuActive, setMenuActive] = useState<boolean>(false);

    useEffect(() => {
        if (menuActive) {
            document.body.classList.add("menu-active");
        } else {
            document.body.classList.remove("menu-active");
        }
    }, [menuActive]);

    return (
        <MenuContext.Provider
            value={{
                menuActive,
                setMenuActive,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
}
