import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const QuickSellPageContainer = lazy(() =>
    import("../containers/QuickSellPageContainer").then((module) => ({
        default: module.QuickSellPageContainer,
    }))
);

export function QuickSell() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_QUICKSELL}>
            <Route
                path={`/:region/venue/:location/quicksell`}
                exact
                render={() => (
                    <Col span={24} md={24} className="content__col content__col--white no-top-header">
                        <div className="content__col-list-white">
                            <Route component={withPermissions(QuickSellPageContainer, "quicksell:read")} />
                        </div>
                    </Col>
                )}
            />
        </TestContext.Provider>
    );
}
