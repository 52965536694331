export function scrollIntoView(element: string | HTMLElement, options?: ScrollIntoViewOptions) {
    const el = typeof element === "string" ? document.querySelector(element) : element;
    el?.scrollIntoView(options);
}

// Not supported on FF
export function scrollIntoViewIfNeeded(
    element: string | HTMLElement,
    centerIfNeeded?: boolean,
    options?: ScrollIntoViewOptions // only used for fallback
) {
    const el = typeof element === "string" ? document.querySelector(element) : element;

    if (el && (el as any).scrollIntoViewIfNeeded) {
        (el as any).scrollIntoViewIfNeeded(centerIfNeeded);
    } else {
        // fallback to scrollIntoView
        scrollIntoView(element, options);
    }
}
