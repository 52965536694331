import { CourseSummary } from "features/course/types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

enum TypeKeys {
    LOADING = "COURSELIST/LOADING",
    LOADED = "COURSELIST/LOADED",
    FAILED = "COURSELIST/FAILED",
}

const list = scaffoldList<CourseSummary, typeof TypeKeys, typeof EditTypeKeys>(
    TypeKeys,
    EditTypeKeys,
    sortByDisplayName
);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
