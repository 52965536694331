import { createSelector } from "reselect";
import { getActiveGroupData } from "./getActiveGroup";
import { ActiveGroup } from "../types/ActiveGroup";
import { EditableEngageLocationsEnabled, EditableGroup } from "../types/EditableGroup";
import { EditLocationsWithEngageNode, LocationsWithEngage, TreeLocationsWithEngage } from "../types/Group";
import { isParent, mapTree } from "common/types/TreeNode";

export const getGroupSettingsOptions = createSelector(
    getActiveGroupData,
    (group?: ActiveGroup): EditableGroup | undefined => {
        if (!group) return undefined;
        const existingCep = group.integrations?.find((i) => i.category === "CEP");
        return {
            id: group.id,
            slug: group.slug,
            displayName: group.displayName,
            privacyPolicyUrl: group.privacyPolicyUrl,
            hideCatalogueMigrationWarning: group.hideCatalogueMigrationWarning,
            engage: existingCep
                ? {
                      active: existingCep.active,
                      id: existingCep.id,
                      locationsEnabled: group.locationsWithEngage as EditableEngageLocationsEnabled[],
                  }
                : {
                      active: false,
                      locationsEnabled: group.locationsWithEngage as EditableEngageLocationsEnabled[],
                  },
        };
    }
);

// get enhanced team resource tree with properties for use with Ant tree component
export const getEditLocationsEnabledTree = createSelector(getActiveGroupData, (group) => {
    const tree = getLocationsEnabledTree(group?.locationsWithEngage ?? []);

    const keyedTree: EditLocationsWithEngageNode[] = mapTree(tree, (node, parents) => {
        return {
            ...node,
            key: node.id,
            displayName: node.data.displayName,
            checkable: true,
            icon: null,
        };
    });

    return keyedTree;
});

export const getLocationsEnabledTree = (resources: LocationsWithEngage[]): TreeLocationsWithEngage[] => {
    const sortedresource = [...resources].sort((a, b) => (a.childIds ? -1 : a.parentId ? 1 : 0));
    const resourceTree: TreeLocationsWithEngage[] = sortedresource.reduce((tree, location) => {
        if (location.parentId) {
            const brand = tree.find((t) => t.data.id === location.parentId);
            if (brand && isParent(brand)) {
                brand.children.push({ id: location.id, data: location });
                return tree;
            }
        }
        if (location.childIds) {
            tree.push({ id: location.id, data: location, children: [] });
            return tree;
        }
        tree.push({ id: location.id, data: location });

        return tree;
    }, [] as TreeLocationsWithEngage[]);

    return resourceTree;
};
