import { createSelector } from "reselect";
import { AppState } from "../..";
import { PosOperation } from "../types/ActiveLocation";
import { getActiveLocation } from "./getLocationPermissions";

const getPosMenuSupported = ({ locations: { active } }: AppState) => {
    return active.status === "loaded" && active.data
        ? operationIsSupported(active.data.posMetadata.operations, "PosMenu")
        : undefined;
};

const getPosCatalogueSupported = ({ locations: { active } }: AppState) => {
    return active.status === "loaded" && active.data
        ? operationIsSupported(active.data.posMetadata.operations, "PosCatalogue")
        : undefined;
};

const getPosMenuOperation = ({ locations: { active } }: AppState) => {
    return active.status === "loaded" && active.data
        ? getOperationByName(active.data.posMetadata.operations, "PosMenu")
        : undefined;
};

export const getIsPosMenuSupported = createSelector(getPosMenuSupported, (result) => result);

export const getIsPosCatalogueSupported = createSelector(getPosCatalogueSupported, (result) => result);

export const getIsPosImportSupported = getIsPosCatalogueSupported;

export const getIsPosSyncSupported = getIsPosCatalogueSupported;

export const getIsCatalogueDataSupported = createSelector(getPosMenuOperation, (op) =>
    behaviorIsSupported(op, "CatalogueData")
);

export const shouldHideCataloguePages = getIsCatalogueDataSupported;
export const shouldHideCategoriesPage = createSelector(getPosMenuOperation, (op) =>
    behaviorIsSupported(op, "Categories")
);
export const shouldHideServicesPage = createSelector(getPosMenuOperation, (op) => behaviorIsSupported(op, "Services"));
export const shouldHidePriceListPage = createSelector(getPosMenuOperation, (op) =>
    behaviorIsSupported(op, "PriceLists")
);
export const shouldHideTaxPage = createSelector(getPosMenuOperation, (op) => behaviorIsSupported(op, "Taxes"));

export const IsPosMenuSupported = (operations: PosOperation[]) => operationIsSupported(operations, "PosMenu");

export const getActiveLocationPosMetadata = createSelector(getActiveLocation, (location) => {
    return location && location.posMetadata ? location.posMetadata : undefined;
});

export const shouldListPublishedCategories = createSelector(getPosMenuOperation, (op) =>
    behaviorIsSupported(op, "CatalogueData")
);

function operationIsSupported(operations: PosOperation[], name: string): boolean {
    var op = getOperationByName(operations, name);

    return (op && op.supported)!;
}

function behaviorIsSupported(op: PosOperation | undefined, name: string): boolean {
    if (!op || !op.supported) {
        return false;
    }

    var behavior = getBehaviorByName(op, name);

    return (behavior && behavior.supported)!;
}

function getOperationByName(operations: PosOperation[], name: string) {
    if (!operations) {
        return undefined;
    }

    const op = operations.find((x) => x.name === name);

    return op;
}

function getBehaviorByName(operation: PosOperation, name: string) {
    if (!operation || !operation.behaviors) {
        return undefined;
    }

    const behavior = operation.behaviors.find((x) => x.name === name);

    return behavior;
}
