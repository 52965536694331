import { ServiceSummary } from "features/service/types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

enum TypeKeys {
    LOADING = "SERVICELIST/LOADING",
    LOADED = "SERVICELIST/LOADED",
    FAILED = "SERVICELIST/FAILED",
}

const list = scaffoldList<ServiceSummary, typeof TypeKeys, typeof EditTypeKeys>(
    TypeKeys,
    EditTypeKeys,
    sortByDisplayName
);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
