import { State as ListState, reducer as listReducer } from "./list";
import { State as UploadState, reducer as uploadReducer } from "./upload";
import { combineReducers } from "redux";

export interface State {
    posCatalogueImport: {
        list: ListState;
        upload: UploadState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    upload: uploadReducer,
});
