import styles from "./Tabs.module.scss";

import { PropsWithChildren } from "react";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useTestAttributes } from "../testAttributes";

// export with children for typings in RouteTabs which clones element
interface RouteTabLinkProps extends PropsWithChildren<any> {
    id: string;
    route: string;
}

export const RouteTabLink = ({ children, id, route }: RouteTabLinkProps) => {
    const active = !!useRouteMatch(route);

    const { getTestId } = useTestAttributes();

    return (
        <NavLink
            aria-controls={`${id}-tabpanel`}
            aria-selected={active}
            className={active ? styles.tabActive : styles.tab}
            id={id}
            role="tab"
            to={route}
            data-testid={getTestId({ fragment: "tab", element: "link" }, id)}
        >
            {children}
        </NavLink>
    );
};
