import { GraphQLError } from "graphql";

export function normalizeError(err: any): Error {
    if (err instanceof Error) {
        return err;
    }

    return new Error(String(err));
}

export type PathErrorCodes = { [key: string]: string };

export function getGraphQLErrorCodesByPath(err: any): PathErrorCodes {
    if (!Array.isArray(err?.errors)) return {};
    return (err.errors as GraphQLError[]).reduce((errors, error) => {
        const key = error.path?.[0];
        const code = error.extensions?.["code"];
        if (key && code) errors[key] = code;
        return errors;
    }, {});
}
