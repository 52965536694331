import { RoleSummary } from "../types/RoleSummary";
import { Loaded } from "common/loader";

export type State = Loaded<RoleSet>;

export type RoleSet = {
    operatorPortalRoles?: RoleSummary[];
    teamRoles?: RoleSummary[];
};

export enum TypeKeys {
    FETCH_BEGIN = "ROLES/FETCH_BEGIN",
    FETCH_SUCCESS = "ROLES/FETCH_SUCCESS",
    FETCH_FAILURE = "ROLES/FETCH_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.FETCH_BEGIN }),
    loaded: (data: RoleSet) => ({ type: TypeKeys.FETCH_SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FETCH_FAILURE, error }),
};

type LoadingAction = { type: TypeKeys.FETCH_BEGIN };
type LoadedAction = { type: TypeKeys.FETCH_SUCCESS; data: RoleSet };
type FailedAction = { type: TypeKeys.FETCH_FAILURE; error: any };

type FetchRolesAction = LoadingAction | LoadedAction | FailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: FetchRolesAction): State {
    if (action.type === TypeKeys.FETCH_BEGIN) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.FETCH_SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FETCH_FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }
    return state;
}
