import { AppState } from "features";
import { getActiveLocationMembership } from "features/location/selectors/getLocationMemberships";
import { posOperationBehaviorFilter } from "features/posConfiguration/selectors/posOperationFilter";

export const showMembershipsFilter = () => (state: AppState) => {
    return (
        !!getActiveLocationMembership(state)?.length ||
        posOperationBehaviorFilter("OrderCreate", "AcceptOverrideProductPrices")(state) ||
        posOperationBehaviorFilter("OrderCreate", "AcceptOverrideModifierPrices")(state)
    );
};
