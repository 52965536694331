import { AppAction, AppDispatch, AppState } from "features";
import { KeyedLoaded } from "common/loader";
import { Action } from "redux";
import { NotificationMessage } from "common/notificationMessage";
import { goBack } from "connected-react-router";
import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { Status } from "core/components/snackbar/types/Message";
import { getFakeRole } from "features/fakeRoles/selectors/getFakeRoles";
import { getGraphQLErrorCodesByPath, PathErrorCodes } from "common/error";

export interface SaveActionCreators<TKey, TData> {
    saved: (key: TKey | undefined, data?: TData | null) => Action;
    saving(key: TKey | undefined): Action;
    saveFailed(key: TKey | undefined, error: any): Action;
}

function isData<TKey extends string, TData extends {} | null>(input: TKey | TData | null): input is TData {
    return input == null || !(typeof input === "string");
}

export function scaffoldSaveAction<
    TKey extends string,
    TList extends {} | null,
    TSaveResult extends TKey | TList | null
>(
    editStateSelector: ((state: AppState) => KeyedLoaded<any, TKey | undefined>) | undefined,
    actionCreators: SaveActionCreators<TKey, TList>,
    saveAction: (key: TKey | undefined, dispatch: AppDispatch) => Promise<TSaveResult>,
    successNotification: NotificationMessage = NotificationMessage.SUCCESS,
    clone: boolean = false,
    successCallback: ((result: TSaveResult, dispatch: AppDispatch) => AppAction<any> | boolean | void) | null = null, // boolean is used as should goBack
    suppressNotification: boolean = false,
    getTrackingData?: (state: AppState, saveResult?: TSaveResult) => TrackEventData | TrackEventData[],
    getErrorMessage?: (errors: PathErrorCodes) => string | undefined
) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        let saveResult: TSaveResult | undefined = undefined;
        let shouldGoBack = false;
        let key: TKey | undefined;
        let successStatus = true;

        const snapshotState = getState();

        const fakeRole = getFakeRole(snapshotState);
        if (fakeRole) {
            return;
        }

        if (editStateSelector) {
            const editState = editStateSelector(snapshotState);
            if (editState.status !== "loaded") {
                return;
            }

            key = clone ? undefined : editState.key;
        }

        try {
            dispatch(actionCreators.saving(key));

            saveResult = await saveAction(key, dispatch);

            if (key === "new") {
                key = undefined;
            }

            if (isData<TKey, TList>(saveResult)) {
                await dispatch(actionCreators.saved(key, saveResult));
            } else {
                await dispatch(actionCreators.saved(key));
            }

            !suppressNotification &&
                window.addSnackbarMessage({ status: Status.SUCCESS, content: successNotification });

            if (successCallback) {
                const action = successCallback(saveResult, dispatch);
                if (!action) return; // callback is instead of goBack()

                if (action !== true) {
                    if (typeof action === "function") {
                        dispatch(action);
                    } else {
                        dispatch(action);
                    }

                    return;
                }
            }
            shouldGoBack = true;
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.error(e);
            }
            successStatus = false;
            dispatch(actionCreators.saveFailed(key, e));
            const errors = getGraphQLErrorCodesByPath(e);
            const content = getErrorMessage?.(errors) ?? NotificationMessage.ERROR;
            window.addSnackbarMessage({ status: Status.ERROR, content });
        } finally {
            let trackingData = getTrackingData?.(snapshotState, saveResult);
            trackingData = trackingData && !Array.isArray(trackingData) ? [trackingData] : trackingData;
            trackingData?.forEach((td) => {
                if (td?.event?.name) {
                    if (td.customProperties) {
                        td.customProperties!.status = successStatus ? "success" : "failure";
                    }
                    dispatch({ type: td.event.name, trackingData: td });
                }
            });
            if (shouldGoBack) {
                dispatch(goBack());
            }
        }
    };
}
