export const removeAccent = (text: string) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const normaliseText = (text: string, toLowerCase: boolean = true) =>
    toLowerCase ? removeAccent(text.trim().toLowerCase()) : removeAccent(text.trim());

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export const escapeRegex = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

export const isId = (s: string) => /^[0-9a-f]{24}$/.test(s);

export const toTitleCase = (text: string) => {
    return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const simplePlural = (n: number = 0) => {
    return n !== 1 ? "s" : "";
};

export const toCamelCase = (text: string) => {
    return text
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (l, index) {
            return index === 0 ? l.toLowerCase() : l.toUpperCase();
        })
        .replace(/\s+/g, "");
};

//https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s25.html
export const replaceInvalidFilenameCharacters = (text: string, replaceValue: string = "-") =>
    text.replace(/[\\/:"*?<>\s|]+/g, replaceValue);
