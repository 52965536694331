import { EditablePriceLevels } from "../types";
import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "PRICELIST_BULK/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "PRICELIST_BULK/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "PRICELIST_BULK/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "PRICELIST_BULK/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "PRICELIST_BULK/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "PRICELIST_BULK/SAVE_EDITABLE_FAILURE",
}

const bulkEdit = scaffoldEdit<EditablePriceLevels, {}, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = bulkEdit;

export type State = ReturnType<typeof bulkEdit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
