import { withPermissions } from "common/permissions/components/withPermission";
import { CrudRoute } from "common/scaffolding/components/CrudRoute";
import { TestAttributePrefix } from "core/components/testAttributes";
import { TestContext } from "core/components/testAttributes/TestContext";
import { lazy } from "react";

const ListWaitersPageContainer = lazy(() =>
    import("../containers/ListWaitersPageContainer").then((module) => ({
        default: module.ListWaitersPageContainer,
    }))
);

const EditWaiterPage = lazy(() =>
    import("../components/EditWaiterPage").then((module) => ({
        default: module.EditWaiterPage,
    }))
);

export function Waiter() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_WAITER}>
            <CrudRoute
                name="staff"
                section={null}
                listComponent={withPermissions(ListWaitersPageContainer, "staff:read")}
                editComponent={withPermissions(EditWaiterPage, "staff:read")}
            />
        </TestContext.Provider>
    );
}
