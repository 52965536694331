import { withSvg } from "core/components/svg/Svg";

export const Wallet = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M17.7437 1.17017C18.1314 1.02918 18.5602 1.22782 18.7034 1.61476L20.0909 5.36476C20.2346 5.75323 20.0362 6.18468 19.6477 6.32841C19.2592 6.47215 18.8278 6.27375 18.6841 5.88527L17.5554 2.83474L7.94377 6.32986C7.55449 6.47142 7.12417 6.2706 6.98261 5.88132C6.84106 5.49205 7.04188 5.06173 7.43115 4.92017L17.7437 1.17017Z" />
        <path d="M1.875 6.375C1.77554 6.375 1.68016 6.41451 1.60983 6.48483C1.53951 6.55516 1.5 6.65054 1.5 6.75V21C1.5 21.0995 1.53951 21.1948 1.60983 21.2652C1.68016 21.3355 1.77554 21.375 1.875 21.375H20.625C20.7245 21.375 20.8198 21.3355 20.8902 21.2652C20.9605 21.1948 21 21.0995 21 21V16.125C21 15.7108 21.3358 15.375 21.75 15.375C22.1642 15.375 22.5 15.7108 22.5 16.125V21C22.5 21.4973 22.3025 21.9742 21.9508 22.3258C21.5992 22.6775 21.1223 22.875 20.625 22.875H1.875C1.37772 22.875 0.900805 22.6775 0.549175 22.3258C0.197544 21.9742 0 21.4973 0 21V6.75C0 6.25272 0.197544 5.77581 0.549175 5.42417C0.900806 5.07254 1.37772 4.875 1.875 4.875H20.625C21.1223 4.875 21.5992 5.07254 21.9508 5.42417C22.3025 5.7758 22.5 6.25272 22.5 6.75V11.625C22.5 12.0392 22.1642 12.375 21.75 12.375C21.3358 12.375 21 12.0392 21 11.625V6.75C21 6.65054 20.9605 6.55516 20.8902 6.48483C20.8198 6.41451 20.7245 6.375 20.625 6.375H1.875Z" />
        <path d="M15 12.375C15 11.5466 15.6716 10.875 16.5 10.875H22.5C23.3284 10.875 24 11.5466 24 12.375V15.375C24 16.2034 23.3284 16.875 22.5 16.875H16.5C15.6716 16.875 15 16.2034 15 15.375V12.375ZM22.5 12.375H16.5V15.375H22.5V12.375Z" />
        <path d="M3.75 7.875C3.75 7.46079 4.08579 7.125 4.5 7.125H5.25C5.66421 7.125 6 7.46079 6 7.875C6 8.28921 5.66421 8.625 5.25 8.625H4.5C4.08579 8.625 3.75 8.28921 3.75 7.875ZM7.5 7.875C7.5 7.46079 7.83579 7.125 8.25 7.125H9C9.41421 7.125 9.75 7.46079 9.75 7.875C9.75 8.28921 9.41421 8.625 9 8.625H8.25C7.83579 8.625 7.5 8.28921 7.5 7.875ZM11.25 7.875C11.25 7.46079 11.5858 7.125 12 7.125H12.75C13.1642 7.125 13.5 7.46079 13.5 7.875C13.5 8.28921 13.1642 8.625 12.75 8.625H12C11.5858 8.625 11.25 8.28921 11.25 7.875ZM15 7.875C15 7.46079 15.3358 7.125 15.75 7.125H16.5C16.9142 7.125 17.25 7.46079 17.25 7.875C17.25 8.28921 16.9142 8.625 16.5 8.625H15.75C15.3358 8.625 15 8.28921 15 7.875ZM5.25 19.875C5.25 19.4608 5.58579 19.125 6 19.125H6.75C7.16421 19.125 7.5 19.4608 7.5 19.875C7.5 20.2892 7.16421 20.625 6.75 20.625H6C5.58579 20.625 5.25 20.2892 5.25 19.875ZM9 19.875C9 19.4608 9.33579 19.125 9.75 19.125H10.5C10.9142 19.125 11.25 19.4608 11.25 19.875C11.25 20.2892 10.9142 20.625 10.5 20.625H9.75C9.33579 20.625 9 20.2892 9 19.875ZM12.75 19.875C12.75 19.4608 13.0858 19.125 13.5 19.125H14.25C14.6642 19.125 15 19.4608 15 19.875C15 20.2892 14.6642 20.625 14.25 20.625H13.5C13.0858 20.625 12.75 20.2892 12.75 19.875ZM16.5 19.875C16.5 19.4608 16.8358 19.125 17.25 19.125H18C18.4142 19.125 18.75 19.4608 18.75 19.875C18.75 20.2892 18.4142 20.625 18 20.625H17.25C16.8358 20.625 16.5 20.2892 16.5 19.875Z" />
        <path d="M18.375 14.25C18.5821 14.25 18.75 14.0821 18.75 13.875C18.75 13.6679 18.5821 13.5 18.375 13.5C18.1679 13.5 18 13.6679 18 13.875C18 14.0821 18.1679 14.25 18.375 14.25ZM17.25 13.875C17.25 13.2537 17.7537 12.75 18.375 12.75C18.9963 12.75 19.5 13.2537 19.5 13.875C19.5 14.4963 18.9963 15 18.375 15C17.7537 15 17.25 14.4963 17.25 13.875Z" />
    </>
));
