import { ActiveGroup } from "features/group/types/ActiveGroup";
import { ActiveLocation } from "features/location";

export type ActiveScope = ActiveLocation | ActiveGroup | undefined | null;

export function isActiveLocation(activeScope: ActiveScope): activeScope is ActiveLocation {
    if (!activeScope) {
        return false;
    }
    return "locale" in activeScope;
}

export function isActiveGroup(activeScope: ActiveScope): activeScope is ActiveGroup {
    return !isActiveLocation(activeScope);
}
