import { SVGProps } from "react";
import styles from "./Svg.module.scss";

export type OwnProps = {
    children?: any;
    title?: string;
};

export const Svg: React.FC<OwnProps & SVGProps<SVGElement>> = (props) => {
    const { width, height, children, title } = props;

    let { "aria-hidden": ariaHidden, role } = props;

    // typically we want to hide svg markup from screen readers
    // but do so only if no title supplied
    if (ariaHidden === undefined && !title) {
        ariaHidden = true;
    }

    // if a title is supplied, automatically make role img
    if (role === undefined && title) {
        role = "img";
    }

    let svgProps = {
        ...(role && { role }),
        ...(ariaHidden && { "aria-hidden": ariaHidden }),
    };

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
            className={styles.svg}
            {...svgProps}
        >
            {title && <title>{title}</title>}
            {children}
        </svg>
    );
};

export const withSvg = (width: number = 64, height: number = 64) => {
    return function <T extends OwnProps = OwnProps>(WrappedComponent: React.ComponentType<T>) {
        // Try to create a nice displayName for React Dev Tools.
        const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

        const withSvg: React.FC<T> = (props: T) => {
            return (
                <Svg width={width} height={height} {...props}>
                    <WrappedComponent {...(props as T)} />
                </Svg>
            );
        };

        withSvg.displayName = `withSvg(${displayName})`;

        return withSvg;
    };
};
