import { Analytics } from "./components/Analytics";
import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.analytics = reducer;
    config.addRoutes(config.routes.locationRoutes, [<Analytics key="analytics" />]);
    config.addRoutes(config.routes.groupRoutes, [<Analytics key="analytics" />]);
}
