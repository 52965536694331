import { withSvg } from "core/components/svg/Svg";

export const Duplicate = withSvg(
    16,
    16
)(() => (
    <>
        <path
            d="M4 12H0.5V0.5H12V4"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4 4H15.5V15.5H4V4Z"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
