import styles from "./ListWrapper.module.scss";

import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListWrapperProps } from "../ModalCrudRoute";
import { PropsWithChildren } from "react";
import { useTestAttributes } from "core/components/testAttributes";
import classNames from "classnames";

export const ListWrapper = ({
    children,
    contentWidth = ContentWidth.STANDARD,
    visible = true,
}: PropsWithChildren<ListWrapperProps>) => {
    const { getTestId } = useTestAttributes();

    return (
        <div className={visible ? styles.container : styles.containerHidden}>
            <div
                className={classNames(styles.content, `content--${contentWidth}`)}
                data-testid={getTestId({ name: "list-page" })}
            >
                {children}
            </div>
        </div>
    );
};
