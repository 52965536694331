// import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { AppState } from "features";
import { createSelector } from "reselect";
import { EditableSurcharge } from "../types";
import { getSurchargesEnabled } from "./getActiveSurcharges";

const isActiveSurcharge = (surcharge: EditableSurcharge): boolean => {
    if (!surcharge) {
        return false;
    }

    if (!surcharge.applyToDrink && !surcharge.applyToFood) {
        return false;
    }

    // since we are working with saved surcharges, we can assume that other
    // fields would be set; they are required by validation

    return true;
};

// means active as in has an effect, not active as in on right now
const getActiveSurcharges = ({ surcharges: { edit } }: AppState): EditableSurcharge[] => {
    if (edit.status !== "loaded") {
        return [];
    }

    const recurringSurcharges = edit.data.recurringSurcharges || [];

    const oneTimeSurcharges = edit.data.oneTimeSurcharges || [];

    return [...recurringSurcharges, ...oneTimeSurcharges].filter(isActiveSurcharge);
};

export const getEnableSurchargesTrackingEventData = createSelector(
    getSurchargesEnabled,
    getActiveSurcharges,
    (surchargesEnabled, activeSurcharges): TrackEventData => {
        return {
            event: {
                name: "SURCHARGE/TOGGLE_SAVED",
            },
            customProperties: {
                surcharge_activated: surchargesEnabled,
                nb_surcharges: `${activeSurcharges.length}`,
            },
        };
    }
);
