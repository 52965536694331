import styles from "./BackButton.module.scss";

import { AngleDown } from "common/icons";
import { Button, Props as ButtonProps } from "core/components/button/Button";
import { Icon } from "core/components/icon";
import { Link, useLocation, useParams } from "react-router-dom";
import { RegionRouteParams } from "common/types";
import { useEffect, useState } from "react";

export const BackButton = (props: ButtonProps) => {
    const { state } = useLocation<{ from?: string }>();

    // `from` state passed by 'My Teams' Link
    const [fromPage, setFromPage] = useState<string | undefined>(undefined);

    useEffect(() => {
        // only update when from is defined i.e. when first navigating to teams
        state?.from && setFromPage(state.from);
    }, [state?.from]);

    const { region } = useParams<RegionRouteParams>();

    const backLink = fromPage ?? `/${region}`;

    return (
        <Button as={Link} to={backLink} className={styles.root} role="secondary" noBackground noBorder {...props}>
            <span className={styles.container}>
                <Icon size="small" className={styles.icon}>
                    <AngleDown />
                </Icon>
                <span>Back</span>
            </span>
        </Button>
    );
};
