import { Loaded } from "common/loader";
import { BulkDeleteResult } from "features/catalogue/types";

export type State = Loaded<BulkDeleteResult>;

export enum TypeKeys {
    FETCH_BEGIN = "BULK_DELETE/DELETE_BEGIN",
    FETCH_SUCCESS = "BULK_DELETE/DELETE_SUCCESS",
    FETCH_FAILURE = "BULK_DELETE/DELETE_FAILURE",
    RESET = "BULK_DELETE/RESET",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.FETCH_BEGIN }),
    loaded: (data: BulkDeleteResult) => ({ type: TypeKeys.FETCH_SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FETCH_FAILURE, error }),
    reset: () => ({ type: TypeKeys.RESET }),
};

type LoadingAction = { type: TypeKeys.FETCH_BEGIN };
type LoadedAction = { type: TypeKeys.FETCH_SUCCESS; data: BulkDeleteResult };
type FailedAction = { type: TypeKeys.FETCH_FAILURE; error: any };
type ResetAction = { type: TypeKeys.RESET };

type BulkDeleteAction = LoadingAction | LoadedAction | FailedAction | ResetAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: BulkDeleteAction): State {
    if (action.type === TypeKeys.FETCH_BEGIN) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.FETCH_SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FETCH_FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return initialState;
    }

    return state;
}
