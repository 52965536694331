import { auth } from "common/auth";
import { AppState } from "features";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { createSelector } from "reselect";

export const getSelectedRegion = ({ region }: AppState) =>
    region.selectedRegion.status === "loaded" ? region.selectedRegion.data : undefined;

export const getAvailableRegions = ({ region }: AppState) =>
    region.availableRegions.status === "loaded" ? region.availableRegions.data : [];

export const getAuthorisedRegions = (_: AppState) => {
    const authorised = auth.getAvailableRegions();
    return authorised || [];
};

export const getAvailableRegionsOptions = createSelector(
    getAvailableRegions,
    getAuthorisedRegions,
    getSelectedRegion,
    (available, authorised, selected) => {
        const options = available.map((region) =>
            authorised.includes(region.id)
                ? region
                : {
                      ...region,
                      displayName: region.displayName + " [access denied]",
                  }
        );
        return options.sort(sortByDisplayName);
    }
);
