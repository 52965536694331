import { AppState } from "../../state";
import moment from "moment-timezone";
import { createSelector } from "reselect";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";

const getPreviewDate = (state: AppState) => state.services.preview.previewDate;

export const getNow = createSelector(
    getActiveLocation,
    getPreviewDate,
    (location, previewDate) => previewDate || moment().tz(location?.timeZone || "Australia/Sydney")
);

export const getVenueTime = createSelector(getNow, (now) => now.format("dddd h:mm A [GMT]ZZ"));

export const getPreviewVenueTime = createSelector(getPreviewDate, (previewDate) =>
    previewDate ? previewDate.format("dddd, DD/MM/YYYY [at] h:mm A") : undefined
);

export const getCurrentDayOfWeek = createSelector(getNow, (now) => now.format("dd"));
