import { opsApi } from "API";
import { GraphQLTeamSummary } from "../types";

export async function listTeams(): Promise<GraphQLTeamSummary[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location: window.location });
    return res.data.teams;
}

type GraphQLPayload = { teams: GraphQLTeamSummary[] };

export const teamListFields = `
    id
    dateCreated
    dateUpdated
    displayName
    groups
    locations
    slug
    authInfo {
        permissions
        userRoles
    }
`;

const query = `
    query {
        teams {
            ${teamListFields}
        }
    }
`;
