import { nonNullable } from "common/types/nonNullable";
import { ElementName, TestAttributeProps } from "./types";
import { useTestAttributes } from "./useTestAttributes";

export function useFormElementTestAttributes() {
    const { getTestId, getTestClass } = useTestAttributes();

    return (element: ElementName, fieldName?: string, value?: string): TestAttributeProps => {
        if (!fieldName) {
            return { "data-testclass": getTestClass({ element }) };
        }

        if (value) {
            return {
                // add value to testid to disambiguate e.g. radio options
                "data-testid": getTestId({ element }, [fieldName, value].filter(nonNullable).join("_")),
                // if ids have value use field name in class so tests can target all options of class
                "data-testclass": getTestId({ element }, fieldName),
            };
        }

        return {
            "data-testid": getTestId({ element }, fieldName),
            "data-testclass": getTestId({ element }),
        };
    };
}
