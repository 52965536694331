import { withLocation } from "features/location/containers/withLocation";
import { ActiveLocation } from "features/location/types/ActiveLocation";
import { MigrationReady } from "./MigrationReady";
import { useSelector } from "react-redux";
import { getIsMigratedLocation } from "features/structure/selectors/getLocationMainMenu";

interface Props {
    restaurantLocation: ActiveLocation | null;
    children: React.ReactElement;
}

const MigratedHiddenInner = ({ restaurantLocation, children }: Props) => {
    const isMigratedLocation = useSelector(getIsMigratedLocation);

    if (restaurantLocation == null || !isMigratedLocation) {
        return children;
    }

    return <MigrationReady />;
};

export const MigratedHidden = withLocation(MigratedHiddenInner);
