import { LayoutGroup, motion } from "framer-motion";
import { Snackbar } from "./Snackbar";
import { useSnackbarContext } from "./SnackbarContext";
import styles from "./SnackbarList.module.scss";

export const SnackbarList = () => {
    const { messages } = useSnackbarContext();

    return (
        <div className={styles.root}>
            <LayoutGroup>
                <motion.ul className={styles.list} layout>
                    {messages.map((message) => (
                        <Snackbar message={message} key={message.id} />
                    ))}
                </motion.ul>
            </LayoutGroup>
        </div>
    );
};
