import { withSvg } from "core/components/svg/Svg";

export const ArrowRight = withSvg(16, 16)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.83301 7.99967C2.83301 7.63148 3.13148 7.33301 3.49967 7.33301H12.4997C12.8679 7.33301 13.1663 7.63148 13.1663 7.99967C13.1663 8.36786 12.8679 8.66634 12.4997 8.66634H3.49967C3.13148 8.66634 2.83301 8.36786 2.83301 7.99967Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.52827 4.02876C8.78862 3.76841 9.21073 3.76841 9.47108 4.02876L12.9711 7.52876C13.2314 7.78911 13.2314 8.21122 12.9711 8.47157L9.47108 11.9716C9.21073 12.2319 8.78862 12.2319 8.52827 11.9716C8.26792 11.7112 8.26792 11.2891 8.52827 11.0288L11.5569 8.00016L8.52827 4.97157C8.26792 4.71122 8.26792 4.28911 8.52827 4.02876Z"
        />
    </>
));
