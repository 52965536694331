import { getActiveGroupData } from "features/group/selectors";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { Scope, useScopeContext } from "features/scope";
import { ActiveScope } from "features/scope/types/ActiveScope";
import { useSelector } from "react-redux";
import invariant from "warn-once";

/**
 * Get the current scope's target – the active location or group
 *
 * Only available within `<ScopeContext.Provider>`
 *
 * @returns `ActiveLocation` or `ActiveGroup` instance
 */
export function useActiveScope(): ActiveScope {
    const scope = useScopeContext();

    const activeLocation = useSelector(getActiveLocation);

    const activeGroup = useSelector(getActiveGroupData);

    invariant(
        !scope,
        "ScopeContext is undefined, verify the component you are calling useCurrentScope() from is within <ScopeContext.Provider>"
    );

    if (scope === Scope.GROUP) {
        return activeGroup;
    }

    if (scope === Scope.LOCATION) {
        return activeLocation;
    }

    // Scope.TEAMS intentionally ignored – teams scope does not have an active instance

    return null;
}
