import { createAction } from "../reducers/publish";
import { Dispatch } from "redux";

import { opsApi } from "API";
import { PublishResult } from "../types/PublishResult";
import { message } from "antd";
import { fetchLocation } from "../api/fetchLocation";
import { createAction as activeLocationActions } from "../reducers/active";
import { getFakeRole } from "features/fakeRoles/selectors/getFakeRoles";
import { AppState } from "features/state";

export const publish = (locationId: string) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState();
        const {
            locations: {
                publish: { publishing, publishingUpdate },
            },
        } = state;

        const fakeRole = getFakeRole(state);

        if (publishing || publishingUpdate || fakeRole) {
            return;
        }

        dispatch(createAction.begin());

        try {
            const response = await callPublishApi(locationId);

            const result: PublishResult = await response.json();

            if (result.errors.length) {
                message.error("Publish failed validation");
                dispatch(createAction.failure(result.errors, result));
            } else {
                const location = await fetchLocation(locationId);
                message.success("Menu published");
                dispatch(createAction.success(location, result));
                dispatch(activeLocationActions.success(location));
            }
        } catch (e) {
            message.error("Publish failed - unknown error");

            dispatch(createAction.failure(e));
        }
    };
};

async function callPublishApi(location: string) {
    const results: Promise<Response>[] = [];

    results.push(
        opsApi.fetch(`/location/${location}/publish`, {
            method: "POST",
        })
    );

    if (results.length === 2) {
        // Ignore errors if both
        results[1].catch(() => {});
    }

    return await results[0];
}
