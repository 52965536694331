import styles from "./Layout.module.scss";

import { ReactElement, PropsWithChildren, useMemo, Suspense } from "react";
import { CmsHeader } from "./cmsheader/CmsHeader";
import { NavLayout } from "./nav/NavLayout";
import { Nav } from "./nav";
import classNames from "classnames";
import { Row } from "antd";
import { matchPath, useLocation } from "react-router";
import { SnackbarList } from "core/components/snackbar/SnackbarList";
import { SnackbarProvider } from "core/components/snackbar/SnackbarContext";
import { ActiveLocation } from "features/location";
import { useSelector } from "react-redux";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { ZendeskChatPlugin } from "features/integrations/zendesk/ZendeskChatPlugin";
import { Permissions } from "common/permissions/components/Permissions";
import { PermissionSet } from "common/permissions/types/PermissionSet";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getTestId as getAppHeaderTestId } from "./cmsheader/CmsHeader";
import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";
import { PageLoading } from "core/components/pageLoading";

const { getTestId } = getTestAttributeGenerators(TestAttributePrefix.LAYOUT);

export interface Props {
    empty?: boolean;
}

function getRedesignedRoutes(activeLocation?: ActiveLocation): string[] {
    return [
        "/:region/group",
        "/:region/teams",
        "/:region/venue/:location/promotions",
        "/:region/venue/:location/stripe",
        "/:region/venue/:location/pos/import/:tab(products|modifiers)?",
        "/:region/venue/:location/analytics",
        "/:region/venue/:location/menu/catalogue/:tab(products|modifiers)?",
        "/:region/venue/:location/venues",
        "/:region/venue/:location/menu/services",
        "/:region/venue/:location/menu/categories/:id?",
    ];
}

// TODO remove when all routes have been migrated to new designs
const useLegacyLayout = (activeLocation?: ActiveLocation): boolean => {
    const location = useLocation();

    const usesLegacyLayout: boolean = useMemo(
        () => !getRedesignedRoutes(activeLocation).some((path) => matchPath(location.pathname, { path, exact: false })),
        [location.pathname, activeLocation]
    );

    return usesLegacyLayout;
};

// TODO prevent keyboard focus on content when popup menu open
export const Layout = ({ empty, children }: PropsWithChildren<Props>): ReactElement => {
    const activeLocation = useSelector(getActiveLocation);
    const isLegacyLayout = useLegacyLayout(activeLocation);
    const isParentLocation = useSelector(getIsParentLocation);
    const isChildLocation = useSelector(getIsChildLocation);
    const locationClass = isParentLocation ? "is-parent-location" : isChildLocation ? "is-child-location" : "";

    return (
        <div className={classNames(styles.root, locationClass)} data-testid={getTestId({ name: "root" })}>
            {!empty && (
                <header className={styles.header} data-testid={getAppHeaderTestId({ name: "root" })}>
                    <CmsHeader />
                </header>
            )}
            <div className={styles.notifications} />
            <div className={styles.outerContainer} data-testid={getTestId({ name: "outer-container" })}>
                <SnackbarProvider>
                    <NavLayout>
                        <Nav />
                    </NavLayout>
                    <Suspense
                        fallback={<PageLoading message=" " background={isLegacyLayout ? "transparent" : "neutral"} />}
                    >
                        {/* TODO legacy styling support until content ready for redesign*/}
                        {isLegacyLayout ? (
                            <div
                                className={classNames(
                                    styles.contentContainerLegacy,
                                    "content--container",
                                    "content--containerlegacy"
                                )}
                                data-testid={getTestId({ name: "content-container" })}
                            >
                                <Row justify="start" className="content--container__row">
                                    {children}
                                </Row>
                                <SnackbarList />
                            </div>
                        ) : (
                            <section
                                className={styles.contentContainer}
                                data-testid={getTestId({ name: "content-container" })}
                            >
                                {children}
                                <SnackbarList />
                            </section>
                        )}
                    </Suspense>
                </SnackbarProvider>
            </div>
            <Permissions>
                {(permissions: PermissionSet) => (
                    <>
                        {permissions.has("zendesk:plugin") && (
                            <div className={styles.container}>
                                <ZendeskChatPlugin />
                            </div>
                        )}
                    </>
                )}
            </Permissions>
        </div>
    );
};
