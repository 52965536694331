import { AppState } from "features";
import { createSelector } from "reselect";
import { isLoaded } from "common/loader/isLoaded";

export const getTeams = ({ team: { list } }: AppState) => list;

export const getTeamsStatus = createSelector(getTeams, (list) => {
    return list.status;
});

export const getTeamsData = createSelector(getTeams, (list) => {
    return isLoaded(list) ? list.data : [];
});
