import { AppState } from "features";
import { useSelector } from "react-redux";
import { getPosOperationsSupport } from "../selectors/getPosOperationsSupport";

export const requirePosOperationSupport =
    (operation: string) =>
    <P extends {}>(Component: React.ComponentType<P>): React.FunctionComponent<P> =>
    (props: P) => {
        const posOperationSupport: ReturnType<typeof getPosOperationsSupport> = useSelector<AppState>((state) =>
            getPosOperationsSupport(state)
        ) as any;

        const supported = posOperationSupport[operation] === true;

        if (!supported) {
            return <div className="error__no-pos-support">Not supported by this POS</div>;
        }

        return <Component {...(props as any)} />;
    };
