import { FeatureConfiguration } from "features";
import { reducer } from "features/catalogue/reducers";
import { reducer as productsReducer } from "./reducers/product";
import { reducer as modifiersReducer } from "./reducers/modifier";
import * as actions from "features/catalogue/actions";
import { Catalogue } from "./components/Catalogue";

export { actions };
export { productActions, modifierActions } from "./actions";

export * from "features/catalogue/types";

export default function (config: FeatureConfiguration) {
    config.reducers.catalogue = reducer;
    config.reducers.menuItems = productsReducer;
    config.reducers.modifiers = modifiersReducer;

    config.addRoutes(config.routes.locationRoutes, [<Catalogue key="catalogue" />]);
}
