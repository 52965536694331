import { NavNode } from "../types/NavMenu";
import { User } from "common/icons/User";

const navStructure: NavNode[] = [
    {
        text: "My Teams",
        icon: User,
        testId: "myteams",
        permission: "", // TODO - what permission? https://meanduapp.atlassian.net/browse/ROCK-1251
        route: "",
    },
];

export const getNavStructure = () => navStructure;
