import { FeatureConfiguration } from "features";
import { Promotions } from "./components/Promotions";
import { reducer } from "./reducers";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.promotions = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Promotions key="promotions" />]);
}
