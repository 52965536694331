import { AppState } from "features";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { EditablePackage, PackageUpdateInput } from "..";
import { isNew } from "common/utility/modelUtils";

// use the menu item in state.menuItems.edit as 'existing' item
const getExisting = ({ groupTabs: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : null;
};

export const getSaveGroupTabTrackingEventData = createSelector(
    [
        (state: AppState) => getExisting(state),
        (_: AppState, formData: PackageUpdateInput) => formData,
        (_: AppState, __: PackageUpdateInput, saveResult?: string) => saveResult,
    ],
    (existing, formData, saveResult) => {
        const isNewModel = isNew(formData.id);
        const packageId = !isNewModel ? formData.id : saveResult;
        const changes: any = {};

        if (packageId) {
            changes.package_id = packageId;
        }

        if (isNewModel || (existing && existing.displayName !== formData.displayName)) {
            changes.product_name = formData.displayName;
        }

        if (isNewModel || (existing && getCategoriesChanged(existing, formData))) {
            const products = formData.categories.reduce((prev: string[], { items }) => {
                return prev.concat(items);
            }, []);
            changes.content = [...new Set<string>(products)];
        }

        return {
            event: {
                name: `GROUP_TAB/PACKAGE/${getGroupTabTrackingEventName(
                    isNewModel,
                    formData.enabled,
                    existing?.enabled !== formData.enabled
                )}_SAVED`,
            },
            customProperties: changes,
        };
    }
);

const getGroupTabTrackingEventName = (
    isNew: boolean,
    isPackageAvailable: boolean,
    isPackageAvailabilityChanged: boolean
) =>
    isNew ? "ADDITION" : !isPackageAvailabilityChanged ? "MODIFICATION" : isPackageAvailable ? "ENABLED" : "DISABLED";

function getCategoriesChanged(existing: EditablePackage, update: PackageUpdateInput) {
    return !isEqual(existing.categories, update.categories);
}
