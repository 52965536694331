import { Component, ErrorInfo, PropsWithChildren, ReactNode } from "react";

interface Props {
    defaultMessage?: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

const isDev = process.env.NODE_ENV === "development";

export class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    public static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        isDev && console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        const { defaultMessage = <h1>Oops, something went wrong. Please try again</h1> } = this.props;

        if (this.state.hasError) {
            return isDev ? (
                <div className="p-6">
                    {defaultMessage}
                    <pre>{this.state.error?.stack}</pre>
                </div>
            ) : (
                defaultMessage
            );
        }

        return this.props.children;
    }
}
