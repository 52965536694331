import { PropsWithChildren } from "react";
import { Button } from "../button";
import { useTestAttributes } from "../testAttributes";
import { EmptyMessage } from "./EmptyMessage";

export type Size = "standard" | "large";
interface Props {
    heading?: string;
    message?: string;
    size?: Size;
    onClear?: () => void;
}

export const EmptyMessageForFilters = ({
    heading = "Oops, there’s nothing here!",
    message = "We couldn’t find anything that matches your search. Try searching for a different keyword or altering the filters.",
    onClear,
    size = "standard",
}: PropsWithChildren<Props>) => {
    const { getTestId } = useTestAttributes();

    return (
        <EmptyMessage message={message} heading={heading} size={size}>
            <div className="pt-3">
                <Button
                    nowrap
                    role="secondary"
                    type="button"
                    onClick={onClear}
                    data-testid={(getTestId({ fragment: "empty", element: "button" }), "clear-filters")}
                >
                    Clear filters
                </Button>
            </div>
        </EmptyMessage>
    );
};
