import { createAction } from "../reducers/active";
import { AppState, AppDispatch } from "features/state";
import { fetchLocationUpdateStatus } from "../api/fetchLocationUpdateStatus";
import { getIsChildLocation } from "../selectors/getIsChildLocation";

export const fetchUpdateStatus = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
        const state = getState();

        const {
            locations: { active },
        } = state;

        const isChild = getIsChildLocation(state);

        if (active.status !== "loaded" || !isChild || active.data.parentLocationUpdateStatus?.updatesAvailable) {
            // Don't fetch if we don't have a location yet, or if we already know updates are available
            return;
        }

        const locationPartial = await fetchLocationUpdateStatus(active.data.slug);

        if (locationPartial.parentLocationUpdateStatus !== undefined) {
            dispatch(createAction.parentUpdateStatusSuccess(locationPartial.parentLocationUpdateStatus));
        }
    } catch (e) {}
};
