import classNames from "classnames";
import { PropsWithChildren, useRef } from "react";
import styles from "./Content.module.scss";

interface Props {
    className?: string;
}

export const Content = ({ children, className }: PropsWithChildren<Props>) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} className={classNames(styles.container, className)} data-fallbackfocus tabIndex={-1}>
            {children}
        </div>
    );
};
