import { FeatureConfiguration } from "features";
import { PosConfiguration } from "./components/PosConfiguration";
import { reducer } from "./reducers";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.posConfiguration = reducer.posConfiguration;
    config.reducers.posLookups = reducer.posLookups;

    config.addRoutes(config.routes.locationRoutes, [<PosConfiguration key="posconfiguration" />]);
}
