import { withSvg } from "core/components/svg/Svg";

export const FlagCircularNZ = withSvg(
    64,
    64
)(() => (
    <>
        <path
            data-name="blue"
            d="M64 32C64 49.673 49.673 64 32 64C14.327 64 0 49.673 0 32C0 32.0076 32 0.0035 32 0C49.673 0 64 14.327 64 32Z"
            fill="#0052B4"
        />
        <path
            data-name="white"
            d="M31.9146 32.0001H32.0002C32.0002 31.9713 32.0002 31.9433 32.0002 31.9144C31.9717 31.9431 31.9432 31.9716 31.9146 32.0001Z"
            fill="#F0F0F0"
        />
        <path
            data-name="white"
            d="M32 16.6956C32 11.065 32 7.37563 32 0H31.9946C14.324 0.003 0 14.3286 0 32H16.6956V22.5986L26.0971 32H31.9145C31.943 31.9715 31.9716 31.943 32.0001 31.9144C32.0001 29.7585 32.0001 27.8349 32.0001 26.0972L22.5985 16.6956H32Z"
            fill="#F0F0F0"
        />
        <path
            data-name="red"
            d="M16.1893 4.17383C11.1845 7.0237 7.0237 11.1845 4.17383 16.1893V32H12.5217V12.522V12.5217H32C32 9.88883 32 7.38058 32 4.17383H16.1893Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M31.9998 28.065L20.6307 16.6959C20.6307 16.6959 16.6956 16.6962 16.6956 16.6959V16.6962L31.9997 32.0002H31.9998C31.9998 32.0002 31.9998 29.2868 31.9998 28.065Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M55.4162 23.7449L56.1069 25.8706H58.342L56.5337 27.1844L57.2245 29.31L55.4162 27.9964L53.6079 29.31L54.2986 27.1844L52.4902 25.8706H54.7254L55.4162 23.7449Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M47.4263 39.0491L48.4625 42.2378H51.8151L49.1026 44.2083L50.1388 47.3969L47.4263 45.4263L44.7138 47.3969L45.7501 44.2083L43.0376 42.2378H46.3902L47.4263 39.0491Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M47.7282 14.0059L48.5917 16.6632H51.3855L49.125 18.3051L49.9885 20.9624L47.7282 19.32L45.4679 20.9624L46.3313 18.3051L44.071 16.6632H46.8648L47.7282 14.0059Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M40.0401 23.6519L41.0761 26.8406H44.4289L41.7164 28.8111L42.7526 31.9997L40.0401 30.0291L37.3276 31.9997L38.3639 28.8111L35.6514 26.8406H39.004L40.0401 23.6519Z"
            fill="#D80027"
        />
    </>
));

<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
