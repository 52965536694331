import { getActiveLocationPrivacyPolicy } from "features/location/selectors/getLocationCompanyDetails";
import { AppState } from "features/state";
import { createSelector } from "reselect";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { isNew } from "common/utility/modelUtils";
import { EditableVenueMembershipProgram, MembershipProgram } from "../types";

export * from "./getMembershipSummaryList";

export const getArchiveMembershipTrackingEventData = createSelector(
    [
        (state: AppState) => getExistingMembership(state),
        (_: AppState, membershipProgram: EditableVenueMembershipProgram) => membershipProgram,
    ],
    (membershipProgram: EditableVenueMembershipProgram) => {
        const eventName = "MEMBERSHIP/DELETION_SAVED";
        const trackingData = {
            membership_id: membershipProgram.id,
            pricelist: membershipProgram.priceListId,
        };
        return getTrackingData(eventName, trackingData);
    }
);

export const getSaveMembershipTrackingEventData = createSelector(
    [
        (state: AppState) => getExistingMembership(state),
        (_: AppState, formData: EditableVenueMembershipProgram) => formData,
        (_: AppState, __: EditableVenueMembershipProgram, saveResult?: MembershipProgram) => saveResult,
    ],
    (existing, formData, saveResult) => {
        const membershipTrackingData = getMembershipChanges(existing, formData, saveResult);
        const eventName = isNew(existing.id) ? "MEMBERSHIP/ADDITION_SAVED" : "MEMBERSHIP/MODIFICATION_SAVED";
        return getTrackingData(eventName, membershipTrackingData);
    }
);

const getExistingMembership = ({ memberships: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : ({} as EditableVenueMembershipProgram);
};

export const getMembershipChanges = (
    existing: EditableVenueMembershipProgram,
    formData: EditableVenueMembershipProgram,
    saveResult?: MembershipProgram
) => {
    const isNewModel = isNew(formData.id);
    const membershipId = !isNewModel ? formData.id : saveResult?.id;
    const changes: any = {};

    changes.timestamp = new Date().toISOString();
    if (membershipId) {
        changes.membership_id = membershipId;
    }
    if (isNewModel || existing.displayName !== formData.displayName) {
        changes.name = formData.displayName;
    }

    if (isNewModel || existing.priceListId !== formData.priceListId) {
        changes.price_list_id = formData.priceListId;
    }

    if (isNewModel || existing.privacyPolicyLink !== formData.privacyPolicyLink) {
        changes.privacy_policy_link = formData.privacyPolicyLink;
    }

    return changes;
};

export const getEditMembershipData = (state: AppState) =>
    state.memberships.edit.status === "loaded" ? state.memberships.edit.data : undefined;

export const getEditMembershipObject = createSelector(
    getEditMembershipData,
    getActiveLocationPrivacyPolicy,
    (membership, privacyPolicyLink): EditableVenueMembershipProgram => {
        if (!membership) {
            return {
                displayName: "",
                privacyPolicyLink: privacyPolicyLink,
                priceListId: "",
                type: "Venue",
            };
        }
        return {
            ...membership,
            privacyPolicyLink: membership.privacyPolicyLink || privacyPolicyLink,
        };
    }
);
