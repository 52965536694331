import { getTeams } from "../selectors/getTeams";
import { LayoutLoading } from "core/components/layoutLoading";
import { actions as teamActions } from "features/teams";
import { createAction } from "../reducers/filters";
import { PropsWithChildren, useCallback } from "react";
import { Scope, ScopeContext } from "features/scope";
import { useAppDispatch } from "common/hooks/useAppDispatch";
import { useLoadStatus } from "common/hooks";
import { useSelector } from "react-redux";
import { Layout } from "features/structure";
import { getRolesAction } from "features/roles/actions/getRolesAction";
import { getRoles } from "features/roles/selectors/getRoles";

export function TeamsContainer({ children }: PropsWithChildren<{}>) {
    const dispatch = useAppDispatch();

    const teams = useSelector(getTeams);
    const roles = useSelector(getRoles);

    const fetch = useCallback(() => {
        dispatch(getRolesAction());
        dispatch(teamActions.list());
        dispatch(createAction.clearFilters());
    }, [dispatch]);

    const loadStatus = useLoadStatus([teams.status, roles.status], fetch, {
        refetchOnMount: true,
    });

    const isLoading = loadStatus === "loading" || loadStatus === "unloaded";

    const hasFailed = loadStatus === "failed";

    if (isLoading || hasFailed) {
        return (
            <LayoutLoading
                loading={isLoading}
                failed={hasFailed}
                onRetry={fetch}
                failedMessage="Teams failed to load. Try reloading this page or try again later"
            />
        );
    }

    if (!teams) {
        return <Layout>You do not have access to any teams</Layout>;
    }

    return <ScopeContext.Provider value={Scope.TEAMS}>{children}</ScopeContext.Provider>;
}
