import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { Surcharges } from "./components/Surcharges";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.surcharges = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Surcharges key="surcharges" />]);
}
