import styles from "./EditGroupSettings.module.scss";

import { Field, FieldProps, FormikProps } from "formik";
import { EditableGroup } from "../types/EditableGroup";
import { Row, Title } from "core/components/card";
import { FieldErrors } from "components/forms";
import { Tree } from "core/components/tree";
import { EditLocationsWithEngageNode, LocationsWithEngage } from "..";
import { useSelector } from "react-redux";
import { getEditLocationsEnabledTree } from "../selectors/getGroupSettingsOptions";
import { useCallback, useMemo } from "react";
import { clone } from "common/types/TreeNode";
import { CheckInfo } from "common/types";
import { TreeDataNode } from "antd";
import { Checkbox } from "core/components/form/checkbox";

interface Props {
    disabled: boolean;
    form: FormikProps<EditableGroup>;
}

export const EditLocationsWithEngage = ({ disabled, form }: Props) => {
    const resourceTree = useSelector(getEditLocationsEnabledTree);
    const resourceTreeCopy = useMemo(() => clone(resourceTree), [resourceTree]);

    const handleCheck = useCallback(
        (_: any, info: CheckInfo<TreeDataNode>) => {
            const checkedKeys = info.checkedNodes.map((n) => n.key);
            form.setFieldTouched("engage.locationsEnabled", true);
            form.setFieldValue(
                "engage.locationsEnabled",
                form.values.engage?.locationsEnabled?.map((l) => {
                    let checked = checkedKeys.includes(l.id);

                    // udpate all nodes when parent is checked\unchecked
                    if (l.parentId && info.node.key === l.parentId) {
                        checked = !info.node.checked;
                    }

                    // update the parent when the child is updated
                    if (l.childIds?.some((c) => info.node.key === c)) {
                        checked = l.childIds.every((c) => checkedKeys.includes(c));
                    }
                    return {
                        ...l,
                        isActive: checked,
                    };
                })
            );
        },
        [form]
    );

    const handleAllCheck = useCallback(
        (checked: boolean) => {
            form.setFieldTouched("engage.locationsEnabled", true);
            form.setFieldValue(
                "engage.locationsEnabled",
                form.values.engage?.locationsEnabled?.map((l) => ({
                    ...l,
                    isActive: checked,
                }))
            );
        },
        [form]
    );

    if (!form.values.engage?.locationsEnabled?.length) {
        return null;
    }

    return (
        <>
            <Row hide={!form.values.engage?.active} border="top">
                <div>
                    <Title className={styles.inputTitle} titleAs="h3" title="Venues with Engage" />
                    <div>
                        Control which Venues offer Engage to customers. Once available, customers can see their Rewards
                        tab, and start to claim and use offers.
                    </div>
                </div>
            </Row>
            <Row collapse="up" hide={!form.values.engage?.active}>
                <FieldErrors name="engage.locationsEnabled" form={form} className={styles.inputFull}>
                    <Checkbox
                        className={styles.checkbox}
                        label="Select all venues"
                        disabled={disabled}
                        onChange={handleAllCheck}
                    />
                    <Field name="engage.locationsEnabled">
                        {({ form }: FieldProps<FormikProps<EditableGroup>, EditableGroup>) => (
                            <Tree<LocationsWithEngage, EditLocationsWithEngageNode>
                                checkStrictly={true}
                                checkedKeys={form.values.engage?.locationsEnabled
                                    ?.filter((l) => l.isActive)
                                    .map((l) => l.id)}
                                dataSource={resourceTreeCopy}
                                defaultExpandAll={true}
                                disabled={disabled}
                                initialSelectionsExpansion={["parents"]}
                                onCheck={handleCheck}
                                searchPlaceholder="Search for brands and venues"
                                showIcon={true}
                                searchHidesNodes={true}
                            />
                        )}
                    </Field>
                </FieldErrors>
            </Row>
        </>
    );
};
