import { createSelector } from "reselect";
import { AppState } from "../..";
import { PermissionSet } from "common/permissions/types/PermissionSet";
import { getActiveGroupData } from "features/group/selectors";

export const getActiveLocation = ({ locations: { active } }: AppState) =>
    active.status === "loaded" ? active.data : undefined;

export const getLocationPermissions = createSelector(
    getActiveLocation,
    (location) => new PermissionSet(location ? location.authInfo.permissions : [])
);

export const getGroupPermissions = createSelector(
    getActiveGroupData,
    (group) => new PermissionSet(group ? group.authInfo.permissions : [])
);
