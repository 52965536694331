import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features/state";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { generateCsvBlob } from "common/data/generateCsvBlob";
import { getModifierPosCatalogueItems } from "../selectors/getPosCatalogueItems";
import { FEATURE_CONFIG } from "common/feature-variables";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { CatalogueItemSelections, PosCatalogueImportItem } from "../types";
import { trackEventExportCSV } from "./trackEventImportPos";
import { generateModifierRow, getModifierCSVHeader } from "features/catalogue/csv/ModifierCSVFormat";
import { CatalogueExportItem } from "features/catalogue/types/CatalogueExportItem";
import { CatalogueItemType } from "features/catalogue/types/CatalogueItemType";

export const exportModifiersCSV =
    (selections: CatalogueItemSelections | null) => (_: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const items = getModifierPosCatalogueItems(state)!;
        const modifiers = filterModifiers(items, selections);
        const activeLocation = getActiveLocation(state)!;
        const energyContent = getIsEnergyContentSupported(state) || false;
        const upsellTypes = !!FEATURE_CONFIG.enableUpsellTypes;

        const csvBlob = generateCsvBlob<Array<string | null>>((writer) => {
            writer(getModifierCSVHeader(energyContent, upsellTypes));

            for (const modifier of modifiers) {
                generateModifierRow(modifier as CatalogueExportItem, writer, energyContent, upsellTypes, selections);
            }
        });

        downloadBlob(csvBlob, `${activeLocation.slug}-modifiers.csv`);
        trackEventExportCSV(state, CatalogueItemType.Modifier, modifiers);
    };

function filterModifiers(
    catalogue: PosCatalogueImportItem[],
    selections: CatalogueItemSelections | null
): PosCatalogueImportItem[] {
    const modifiers = catalogue.filter((p) => p.type === "modifier" || p.type === "option");

    if (!selections) return modifiers;

    return modifiers.filter((m) => m.id && selections[m.id]);
}
