import { EditableService, ServiceSummary } from "features/service/types";
import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "SERVICE/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "SERVICE/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "SERVICE/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "SERVICE/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "SERVICE/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "SERVICE/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditableService, ServiceSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
