import { State as AnalyticsState, reducer as analyticsReducer } from "./analytics";

import { combineReducers } from "redux";
export interface State {
    analytics: {
        dashboardUrl: AnalyticsState;
    };
}

export const reducer = combineReducers({
    dashboardUrl: analyticsReducer,
});
