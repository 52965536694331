import styles from "./DiagnosticsModal.module.scss";

import { CardWidth, Confirm, Modal } from "core/components/modal";
import { CopyOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { Row } from "core/components/card";
import { config } from "common/config";
import { useActiveScope } from "common/hooks";
import { isActiveGroup } from "features/scope/types/ActiveScope";
import { isActiveLocation } from "features/scope/types/ActiveScope";
import { useParams } from "react-router-dom";
import { GroupRouteParams, LocationRouteParams } from "common/types";
import { PropsWithChildren, useCallback } from "react";
import { Button } from "core/components/button";
import { useSnackbarContext } from "core/components/snackbar/SnackbarContext";
import { Status } from "core/components/snackbar/types/Message";
import { auth } from "common/auth";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getSelectedRegion } from "features/region/selectors";
import { useRouteMatch } from "react-router";
import { getAvailableRegions } from "features/region/selectors";
import { getEditTeamData } from "features/teams/selectors/getEditTeam";

interface Props {
    onClose: () => void;
    visible: boolean;
}

export const DiagnosticsModal = ({ onClose, visible }: Props) => {
    const scope = useActiveScope();

    const group = isActiveGroup(scope) ? scope : null;

    const location = isActiveLocation(scope) ? scope : null;

    const locationParams = useParams<LocationRouteParams>();

    const groupParams = useParams<GroupRouteParams>();

    const locationUrl = location ? `/${locationParams.region}/venue/${locationParams.location}` : null;

    const groupUrl = group ? `/${groupParams.region}/group/${groupParams.id}` : null;

    const linkUrl = locationUrl || groupUrl;

    const editTeam = useSelector(getEditTeamData);

    const scopeRoles = scope?.authInfo?.userRoles;

    const teamRoles = editTeam?.authInfo?.userRoles;

    const roles = (scopeRoles ?? teamRoles ?? ["Unavailable"]).join(", ");

    const { id: userId } = auth.getState() || {};

    const activeRegion = useSelector(getSelectedRegion);

    const match = useRouteMatch();

    const regions = useSelector(getAvailableRegions);

    const regionsDisplay = regions.map((r) => r.displayName).join(", ");

    const { addMessage } = useSnackbarContext();

    const handleCopyAll = useCallback(() => {
        const el = document.querySelector("#diagnostics");

        let text = "";

        el?.childNodes.forEach((node) => {
            const row = node as HTMLElement;

            const title = row.querySelector("span")?.textContent || "";

            let value;

            switch (title) {
                case "Current page":
                    value = window.location.href;
                    break;
                case "Link":
                    value = `${window.location.origin}${linkUrl}`;
                    break;
                default:
                    value = row.querySelector("span:last-child")?.textContent || "";
            }

            text += `${title}: ${value}\n`;
        });

        copy(text);

        addMessage({ status: Status.INFO, content: "Copied to clipboard" });

        return false;
    }, [addMessage, linkUrl]);

    return (
        <Modal
            onClose={onClose}
            visible={visible}
            width={CardWidth.NARROW}
            title={`me&u Operator Portal`}
            footer={<Confirm onConfirm={handleCopyAll} confirmLabel="Copy all" showCancel={false} />}
        >
            <div id="diagnostics">
                <Row paddingY="medium" collapse="down">
                    <span className={styles.subheading}>Version</span>
                    <span>
                        <span>{config.VERSION}</span>
                        <Copy text={config.VERSION} name="Version" />
                    </span>
                </Row>
                {location && (
                    <>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Region</span>
                            <span>
                                {regions.find((r) => r.id === locationParams.region)?.displayName} (
                                {locationParams.region})
                            </span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Location</span>
                            <span>
                                <span>{location.displayName}</span>
                                <Copy text={location.displayName} name="Location" />
                            </span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Location ID</span>
                            <span>
                                <span>{location.id}</span>
                                <Copy text={location.id} name="Id" />
                            </span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Slug</span>
                            <span>
                                <span>{location.slug}</span>
                                <Copy text={location.slug} name="Slug" />
                            </span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Link</span>
                            <span className={styles.constrain}>
                                <span>...{locationUrl}</span>
                                <Copy text={`${window.location.origin}${locationUrl}`} name="Link" />
                            </span>
                        </Row>
                    </>
                )}
                {group && (
                    <>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Region</span>
                            <span>{groupParams.region}</span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Group</span>
                            <span>{group.displayName}</span>
                        </Row>
                        <Row paddingY="medium">
                            <span className={styles.subheading}>ID</span>
                            <span>
                                <span>{group.id}</span>
                                <Copy text={group.id} name="Slug" />
                            </span>
                        </Row>
                        <Row paddingY="medium" collapse="down">
                            <span className={styles.subheading}>Link</span>
                            <span className={styles.constrain}>
                                <span>...{groupUrl}</span>
                                <Copy text={`${window.location.origin}${groupUrl}`} name="Link" />
                            </span>
                        </Row>
                    </>
                )}
                <Row paddingY="medium">
                    <span className={styles.subheading}>Current page</span>
                    <span className={styles.constrain}>
                        ...{match?.url}
                        <Copy text={window.location.href} name="Current page" />
                    </span>
                </Row>
                <Row paddingY="medium" collapse="down" border="top">
                    <span className={styles.subheading}>User ID</span>
                    <span>
                        <span>{userId}</span>
                        <Copy text={userId} name="User id" />
                    </span>
                </Row>
                <Row paddingY="medium" collapse="down">
                    <span className={styles.subheading}>Role(s)</span>
                    <span>
                        <span>{roles}</span>
                        <Copy text={roles} name="Roles" />
                    </span>
                </Row>
                <Row paddingY="medium">
                    <span className={styles.subheading}>Region access</span>
                    <span>
                        <span>{regionsDisplay}</span>
                        <Copy text={regionsDisplay} name="Regions" />
                    </span>
                </Row>
                <Row paddingY="medium" collapse="down" border="top">
                    <span className={styles.subheading}>User-agent</span>
                    <span className={styles.constrain}>
                        {navigator.userAgent}
                        <Copy text={navigator.userAgent} name="User-agent" />
                    </span>
                </Row>
                <Row paddingY="medium">
                    <span className={styles.subheading}>App Insights</span>
                    {activeRegion?.appInsightsConnectionString ? (
                        <span>
                            {activeRegion?.appInsightsConnectionString}
                            <Copy text={activeRegion?.appInsightsConnectionString} name="App insights" />
                        </span>
                    ) : (
                        <span>---</span>
                    )}
                </Row>
            </div>
        </Modal>
    );
};

interface LinkProps {
    href?: string | null;
}

export const Link = ({ children, href }: PropsWithChildren<LinkProps>) => {
    if (!href || !children) {
        return null;
    }

    return (
        <Button as="a" colorScheme="interactive" href={href} target="_blank">
            {children}
        </Button>
    );
};

interface CopyProps {
    text?: string | null;
    name?: string;
}

export const Copy = ({ text, name }: CopyProps) => {
    const { addMessage } = useSnackbarContext();

    const message = name ? `${name} copied to clipboard` : "Copied to clipboard";

    const handleClick = useCallback(() => {
        if (!text) {
            return;
        }

        copy(text);

        addMessage({ status: Status.INFO, content: message });
    }, [text, message, addMessage]);

    if (!text) {
        return null;
    }
    return (
        <span className={classNames(styles.copy)}>
            <CopyOutlined onClick={handleClick} />
        </span>
    );
};
