import { withSvg } from "core/components/svg/Svg";

export const OpenInWindow = withSvg(
    32,
    32
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7C2 4.23858 4.23858 2 7 2H25C27.7614 2 30 4.23858 30 7V25C30 27.7614 27.7614 30 25 30H17.7333C17.181 30 16.7333 29.5523 16.7333 29C16.7333 28.4477 17.181 28 17.7333 28H25C26.6569 28 28 26.6569 28 25V7C28 5.34315 26.6569 4 25 4H7C5.34315 4 4 5.34315 4 7V14.2667C4 14.819 3.55228 15.2667 3 15.2667C2.44772 15.2667 2 14.819 2 14.2667V7Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 20C4.94772 20 4.5 19.5523 4.5 19C4.5 18.4477 4.94772 18 5.5 18H13C13.5523 18 14 18.4477 14 19V26.5C14 27.0523 13.5523 27.5 13 27.5C12.4477 27.5 12 27.0523 12 26.5V21.4142L3.70711 29.7071C3.31658 30.0976 2.68342 30.0976 2.29289 29.7071C1.90237 29.3166 1.90237 28.6834 2.29289 28.2929L10.5858 20H5.5Z"
        />
    </>
));
