import { State as EditState, reducer as editReducer } from "./edit";

import { combineReducers } from "redux";

export interface State {
    paymentUser: {
        edit: EditState;
    };
}

export const reducer = combineReducers({
    edit: editReducer,
});
