import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { User } from "./components/User";

export * from "./types";

export default function (config: FeatureConfiguration) {
    config.reducers.user = reducer;

    config.addRoutes(config.routes.teamsRoutes, [<User key="users" />]);
}
