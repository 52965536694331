import { withSvg } from "core/components/svg/Svg";

export const TypeDrink = withSvg(
    16,
    16
)(() => (
    <>
        <path
            d="M7.25 3.49999C7.2986 2.87013 7.52971 2.26809 7.91506 1.76751C8.30041 1.26693 8.82329 0.889504 9.41976 0.681402C10.0162 0.4733 10.6604 0.443543 11.2735 0.59577C11.8866 0.747997 12.4421 1.07561 12.8719 1.53853C13.3018 2.00146 13.5874 2.57963 13.6939 3.20232C13.8003 3.82501 13.723 4.46523 13.4714 5.04467C13.2197 5.62411 12.8046 6.11764 12.2769 6.46491C11.7492 6.81217 11.1317 6.99811 10.5 6.99999"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M10.25 6V3.5H2.25V6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.25 13V6H2.25V13" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M2.25 13V15C2.25 15.1326 2.30268 15.2598 2.39645 15.3536C2.49021 15.4473 2.61739 15.5 2.75 15.5H9.75C9.88261 15.5 10.0098 15.4473 10.1036 15.3536C10.1973 15.2598 10.25 15.1326 10.25 15V13H2.25Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
