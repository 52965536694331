import { opsApi } from "API";
import { GraphQLItem } from "common/scaffolding/types";
import { ItemValidationStatus } from "features/catalogue/types";
import { GraphQLModifier } from "features/catalogue/api/modifier/editModifier";

export const listProducts = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.items;
};

type GraphQLPayload = { items: GraphQLMenuItemSummary[] };

export interface GraphQLMenuItemSummary extends GraphQLItem {
    price: number;
    variants: GraphQLVariantSummary[];
    sku: string;
    template: { type: string; shortName: string };
    status: number;
    categories: GraphQLItem[];
    validationStatus: ItemValidationStatus;
    validationStatusReason: string | null;
    thumbnailImage: string | null;
    description?: string | null;
    energyContent?: number | null;
    isLinked: boolean;
    isHidden?: boolean;
    isParentHidden?: boolean;
    modifiers: GraphQLModifier[];
}

export interface GraphQLVariantSummary {
    id: string;
    price: number;
    taxRate: number | null;
    sku: string;
    displayName: string;
    validationStatus: ItemValidationStatus;
    validationStatusReason: string | null;
    energyContent?: number | null;
    isLinked: boolean;
    isHidden?: boolean;
}

export const menuItemListFields = `
    id,
    internalName,
    displayName,
    description
    price,
    variants {
        id
        price
        taxRate
        sku
        displayName,
        validationStatus,
        validationStatusReason,
        energyContent,
        isLinked
        isHidden
    },
    sku,
    template {
        type
        shortName
    },
    status,
    categories {
        id,
        displayName,
        internalName
    },
    validationStatus,
    validationStatusReason,
    thumbnailImage,
    energyContent,
    isLinked,
    isHidden,
    isParentHidden,
    modifiers {
        id,
        displayName,
        productVariantReferences {
            productId,
            variantId
        }
    }
`;

const query = `
    query($locationId: ID!) {
        items(locationId: $locationId) {
            ${menuItemListFields}
        }
    }
`;
