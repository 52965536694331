import { AppState } from "features/state";
import { getPosOperationBehaviorsSupport, getPosOperationsSupport } from "./getPosOperationsSupport";

export const posOperationFilter = (operation: string) => (state: AppState) => {
    const posOperationSupport: ReturnType<typeof getPosOperationsSupport> = getPosOperationsSupport(state);

    return posOperationSupport[operation] === true;
};

export const posOperationBehaviorFilter = (operation: string, behavior: string) => (state: AppState) => {
    const posOperationBehaviorSupport = getPosOperationBehaviorsSupport(state);

    return posOperationBehaviorSupport[`${operation}/${behavior}`] === true;
};
