import { FeatureConfiguration } from "features";
import * as actions from "features/service/actions";
import { reducer } from "features/service/reducers";
import { Service } from "./components/Service";

export * from "features/service/types";

export { actions };

export { listRoute, servicesRoute } from "./util/routes";

export default function (config: FeatureConfiguration) {
    config.reducers.services = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Service key="services" />]);
}
