import { withSvg } from "core/components/svg/Svg";

export const CheckCircle = withSvg(
    32,
    32
)(() => (
    <>
        <path d="M6.10051 6.10051C0.633171 11.5678 0.633171 20.4322 6.10051 25.8995C11.5679 31.3668 20.4322 31.3668 25.8995 25.8995C31.3668 20.4322 31.3668 11.5678 25.8995 6.10051C20.4322 0.633165 11.5679 0.633165 6.10051 6.10051ZM4.6863 27.3137C-1.56209 21.0653 -1.56209 10.9347 4.6863 4.68629C10.9347 -1.5621 21.0653 -1.5621 27.3137 4.68629C33.5621 10.9347 33.5621 21.0653 27.3137 27.3137C21.0653 33.5621 10.9347 33.5621 4.6863 27.3137Z" />
        <path d="M23.7076 11.7909C24.0981 12.1814 24.0981 12.8146 23.7076 13.2051L15.7021 21.2106C15.3116 21.6011 14.6784 21.6011 14.2879 21.2106L9.79189 16.7146C9.40136 16.3241 9.40136 15.6909 9.79189 15.3004C10.1824 14.9099 10.8156 14.9099 11.2061 15.3004L14.995 19.0893L22.2934 11.7909C22.6839 11.4004 23.3171 11.4004 23.7076 11.7909Z" />
    </>
));
