import { useCallback, useState } from "react";

export function useLocalStorage<T>(key: string, initialValue: T, onError?: (error: unknown) => void) {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            onError?.(error);
            return initialValue;
        }
    });
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                if (typeof window !== "undefined") {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                }
            } catch (error) {
                console.log(error);
                onError?.(error);
            }
        },
        [key, storedValue, onError]
    );

    return [storedValue, setValue] as const;
}
