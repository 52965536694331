import { createSelector } from "reselect";
import { LocationType } from "../types/LocationType";
import { getActiveLocation } from "./getLocationPermissions";

export const getIsParentLocation = createSelector(getActiveLocation, (location) => {
    if (!location) {
        return undefined;
    }

    return location.locationType === LocationType.BRAND;
});
