import { State as ListState, reducer as listReducer } from "./list";
import { State as DetailsState, reducer as detailsReducer } from "./details";
import { combineReducers } from "redux";

export interface State {
    dailySalesReport: {
        list: ListState;
        details: DetailsState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    details: detailsReducer,
});
