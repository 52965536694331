import styles from "./EditWrapper.module.scss";

import { PropsWithChildren, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { EditWrapperProps } from "../ModalCrudRoute";
import classNames from "classnames";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { useTestAttributes } from "core/components/testAttributes";

export const scrollClass = "content-has-scroll";

export const EditWrapper = ({
    children,
    contentWidth = ContentWidth.STANDARD,
    visible,
}: PropsWithChildren<EditWrapperProps>) => {
    const [hasScroll, setHasScroll] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const { getTestId } = useTestAttributes();

    useResizeObserver<HTMLDivElement>({
        ref,
        onResize: () => {
            const container = ref.current;
            if (container) {
                setHasScroll(container.scrollHeight > container.clientHeight);
            }
        },
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.position}>
                <div data-scrollcontainer className={classNames(styles.container, hasScroll && scrollClass)} ref={ref}>
                    <div className={classNames(styles.content, `content--${contentWidth}`)}>
                        <div className={styles.body} data-testid={getTestId({ name: "edit-page" })}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
