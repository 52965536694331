import { Loaded } from "common/loader";
import { PosMenuData } from "../types";

export type State = Loaded<PosMenuData>;

enum TypeKeys {
    LOADING = "POS_CATALOGUE_IMPORT/FETCH_BEGIN",
    SUCCESS = "POS_CATALOGUE_IMPORT/FETCH_SUCCESS",
    FAILURE = "POS_CATALOGUE_IMPORT/FETCH_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: PosMenuData) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type PosCatalogueListLoadingAction = { type: TypeKeys.LOADING };
type PosCatalogueListLoadedAction = { type: TypeKeys.SUCCESS; data: PosMenuData };
type PosCatalogueListFailedAction = { type: TypeKeys.FAILURE; error: any };

type PosCatalogueListAction =
    | PosCatalogueListLoadingAction
    | PosCatalogueListLoadedAction
    | PosCatalogueListFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: PosCatalogueListAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
