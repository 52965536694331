import { createContext, PropsWithChildren, useState } from "react";
import { Filters } from "../types";

export const FiltersContext = createContext<Filters>({
    categories: [],
    filterCount: 0,
    filterText: "",
    setCategories: () => {},
    setFilterText: () => {},
});

export const FiltersProvider = ({ children }: PropsWithChildren<{}>) => {
    const [filterText, setFilterText] = useState("");
    const [categories, setCategories] = useState<string[]>([]);

    return (
        <FiltersContext.Provider
            value={{
                categories,
                filterCount: categories.length,
                filterText,
                setCategories,
                setFilterText,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};
