import { CsvWriter } from "common/data/generateCsvBlob";
import { CatalogueExportItem, CatalogueItemSelections, PosCategory } from "features/catalogue/types";

export function getProductCSVHeader(energyContent: boolean): Array<string | null> {
    return [
        "Type",
        "Master Product Name",
        "Internal Name",
        "Description",
        "Variant Name",
        "Price",
        "SKU",
        "Categories",
        ...(energyContent ? ["Energy Content"] : []),
    ];
}

export function generateProductRow(
    catalogueItem: CatalogueExportItem,
    categories: PosCategory[],
    writer: CsvWriter<Array<string | null>>,
    exportEnergyContent: boolean,
    selections: CatalogueItemSelections | null
): void {
    const type = catalogueItem.templateShortName;

    writer(getProductRow(catalogueItem, type, exportEnergyContent));

    if (!selections) {
        catalogueItem.children?.forEach((variant) => {
            writer(getProductRow(variant, type, exportEnergyContent));
        });
    } else {
        const childSelections: string[] | boolean = selections[catalogueItem.id] || [];

        if (!catalogueItem.children || childSelections === true) {
            return;
        }

        catalogueItem.children
            .filter((child) => childSelections.includes(child.id))
            .forEach((child) => {
                writer(getProductRow(child, type, exportEnergyContent));
            });
    }
}

function getProductRow(item: CatalogueExportItem, type: string, exportEnergyContent: boolean): Array<string | null> {
    return [
        item.type === "product" ? type ?? "other" : null,
        item.type === "product" ? item.displayName : null,
        item.type === "product" ? item.internalName ?? "" : null,
        item.description || null,
        item.type === "variant" ? item.displayName : null,
        item.price ? String(item.price) : null,
        item.sku || null,
        item.categories?.map((c) => c.displayName).join(",") || null,
        ...(exportEnergyContent ? [item.energyContent ? String(item.energyContent) : null] : []),
    ];
}
