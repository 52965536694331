import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditComponent, ListComponent, ModalCrudRouteContainer } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";

const ListUsersPage = lazy(() =>
    import("./ListUsersPage/ListUsersPage").then((module) => ({
        default: module.ListUsersPage,
    }))
);

const EditUserPage = lazy(() =>
    import("./EditUserPage").then((module) => ({
        default: module.EditUserPage,
    }))
);

const userList: ListComponent = {
    route: ":teamSlug/users",
    component: ListUsersPage,
    contentWidth: ContentWidth.WIDE,
    wrapper: ListWrapper,
};

const userEdit: EditComponent = {
    route: ":teamSlug/users/:encodedUserId",
    component: EditUserPage,
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

export function User() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_USER}>
            <ModalCrudRouteContainer name="teamsusers" listComponent={userList} editComponents={[userEdit]} route="" />
        </TestContext.Provider>
    );
}
