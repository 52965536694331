import { withSvg } from "core/components/svg/Svg";

export const FlagCircularGB = withSvg(
    64,
    64
)(() => (
    <>
        <path
            data-name="white"
            d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
            fill="#F0F0F0"
        />
        <path
            data-name="blue"
            d="M6.61492 12.5181C4.10129 15.7884 2.20592 19.5578 1.10229 23.6527H17.7495L6.61492 12.5181Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M62.8975 23.6523C61.7939 19.5576 59.8984 15.7882 57.3849 12.5178L46.2505 23.6523H62.8975Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M1.10229 40.3483C2.20604 44.443 4.10142 48.2124 6.61492 51.4826L17.7492 40.3483H1.10229Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M51.4822 6.61529C48.2118 4.10166 44.4425 2.20629 40.3477 1.10254V17.7497L51.4822 6.61529Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M12.5176 57.385C15.788 59.8986 19.5573 61.794 23.6521 62.8977V46.2507L12.5176 57.385Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M23.652 1.10254C19.5572 2.20629 15.7878 4.10166 12.5176 6.61516L23.652 17.7495V1.10254Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M40.3477 62.8977C44.4424 61.794 48.2118 59.8986 51.482 57.3851L40.3477 46.2507V62.8977Z"
            fill="#0052B4"
        />
        <path
            data-name="blue"
            d="M46.2505 40.3483L57.3849 51.4828C59.8984 48.2125 61.7939 44.443 62.8975 40.3483H46.2505Z"
            fill="#0052B4"
        />
        <path
            data-name="red"
            d="M63.7291 27.8261H36.1741H36.174V0.270875C34.8076 0.093 33.4146 0 32 0C30.5851 0 29.1924 0.093 27.8261 0.270875V27.8259V27.826H0.270875C0.093 29.1924 0 30.5854 0 32C0 33.4149 0.093 34.8076 0.270875 36.1739H27.8259H27.826V63.7291C29.1924 63.907 30.5851 64 32 64C33.4146 64 34.8076 63.9071 36.1739 63.7291V36.1741V36.174H63.7291C63.907 34.8076 64 33.4149 64 32C64 30.5854 63.907 29.1924 63.7291 27.8261Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M40.3479 40.3478L54.6274 54.6273C55.2842 53.9708 55.9107 53.2844 56.5084 52.5729L44.2832 40.3477H40.3479V40.3478Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M23.6522 40.3479H23.6519L9.37256 54.6273C10.0291 55.284 10.7154 55.9105 11.4269 56.5083L23.6522 44.2828V40.3479Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M23.652 23.6524V23.6522L9.37246 9.37256C8.71571 10.0291 8.08921 10.7154 7.49146 11.4269L19.7168 23.6523H23.652V23.6524Z"
            fill="#D80027"
        />
        <path
            data-name="red"
            d="M40.3479 23.6523L54.6275 9.37257C53.971 8.71582 53.2847 8.08932 52.5732 7.4917L40.3479 19.7171V23.6523Z"
            fill="#D80027"
        />
    </>
));
