import { createSelector } from "reselect";
import { ActiveLocation } from "../../location";
import { getActiveLocation } from "../../location/selectors/getLocationPermissions";
import { getCountry } from "../actions";
import { EditableTakeawayOptions } from "../types";

export const getTakeawayOptions = createSelector(getActiveLocation, (location?: ActiveLocation) => {
    if (!location) return undefined;
    return {
        ...location.takeawayOptions,
        venueAddress: location.venueAddress || {
            addressLine1: "",
            city: "",
            postcode: "",
            country: getCountry(location.locale, location.currency),
        },
    } as EditableTakeawayOptions;
});
