import { Loaded } from "common/loader";
import { Stats } from "../types";

export type State = Loaded<Stats>;

enum TypeKeys {
    LOADING = "GROUP_TABS_STATS/LOAD_BEGIN",
    SUCCESS = "GROUP_TABS_STATS/LOAD_SUCCESS",
    FAILURE = "GROUP_TABS_STATS/LOAD_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: Stats) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type GroupTabsStatsLoadingAction = { type: TypeKeys.LOADING };
type GroupTabsStatsLoadedAction = { type: TypeKeys.SUCCESS; data: Stats };
type GroupTabsStatsFailedAction = { type: TypeKeys.FAILURE; error: any };

type GroupTabsStatsAction = GroupTabsStatsLoadingAction | GroupTabsStatsLoadedAction | GroupTabsStatsFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: GroupTabsStatsAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
