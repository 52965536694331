import { WaiterSummary } from "features/waiter/types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "WAITER/LIST_BEGIN",
    LOADED = "WAITER/LIST_SUCCESS",
    FAILED = "WAITER/LIST_FAILURE",
}

const list = scaffoldList<WaiterSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
