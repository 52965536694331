import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";

export const getIsChildLocation = createSelector(getActiveLocation, (location) => {
    if (!location) {
        return undefined;
    }

    return !!location?.parentLocationId;
});
