import React, { PropsWithChildren } from "react";

export interface Props {
    action: () => void;
}

export class Trigger extends React.Component<PropsWithChildren<Props>> {
    constructor(props: Props) {
        super(props);

        if (props.action) {
            props.action();
        }
    }

    render() {
        return this.props.children;
    }
}
