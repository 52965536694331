import { AppState } from "features";
import { createSelector } from "reselect";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { isNew } from "common/utility/modelUtils";
import { EditablePromotion, Promotion } from "../types";
import { equalsIgnoreOrder } from "common/utility/arrayUtils";
import { DaysOfWeek } from "common/utility/dateUtils";

export const getArchivePromotionTrackingEventData = createSelector(
    [(state: AppState) => getExistingPromotion(state), (_: AppState, promotion: EditablePromotion) => promotion],
    (promotion: EditablePromotion) => {
        const eventName = "PROMOTION/DELETION_SAVED";
        const trackingData = {
            promotion_id: promotion.id,
            promo_code: promotion.code,
        };
        return getTrackingData(eventName, trackingData);
    }
);

export const getSavePromotionTrackingEventData = createSelector(
    [
        (state: AppState) => getExistingPromotion(state),
        (_: AppState, formData: EditablePromotion) => formData,
        (_: AppState, __: EditablePromotion, saveResult?: Promotion) => saveResult,
    ],
    (existing, formData, saveResult) => {
        const promotionTrackingData = getPromotionChanges(existing, formData, saveResult);
        const eventName = isNew(existing.id) ? "PROMOTION/ADDITION_SAVED" : "PROMOTION/MODIFICATION_SAVED";
        return getTrackingData(eventName, promotionTrackingData);
    }
);

const getExistingPromotion = ({ promotions: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : ({} as EditablePromotion);
};

export const getPromotionsList = ({ promotions: { list } }: AppState) => {
    return list.status === "loaded" ? list.data : ([] as Promotion[]);
};

export const getPromotionChanges = (
    existing: EditablePromotion,
    formData: EditablePromotion,
    saveResult?: Promotion
) => {
    const isNewModel = isNew(formData.id);
    const promotionId = !isNewModel ? formData.id : saveResult?.id;
    const existingSchedule = existing?.restrictions?.schedule ?? {};
    const schedule = formData.restrictions?.schedule ?? {};
    const existingRequiredItems = existing?.restrictions?.requiredItems;
    const requiredItems = formData.restrictions?.requiredItems;
    const changes: any = {};

    changes.timestamp = new Date().toISOString();
    if (promotionId) {
        changes.promotion_id = promotionId;
    }
    if (isNewModel || existing.displayName !== formData.displayName) {
        changes.name = formData.displayName;
    }

    if (isNewModel || existing.internalName !== formData.internalName) {
        changes.internal_name = formData.internalName;
    }

    if (isNewModel || existing.posId !== formData.posId) {
        changes.pos_id = formData.posId;
    }

    if (isNewModel || existing.promotionType !== formData.promotionType) {
        changes.promo_code_type = formData.promotionType.toLowerCase();
    }

    if (isNewModel || existing.code !== formData.code) {
        changes.promo_code = formData.code;
    }

    if (isNewModel || existing.generatedCodeCount !== formData.generatedCodeCount) {
        changes.nb_promo_code_requested = formData.generatedCodeCount;
    }

    if (isNewModel || (existing.discount && existing.discount.type !== formData.discount?.type)) {
        changes.discount_type = formData.discount.type;
    }

    if (isNewModel || (existing.discount && existing.discount.value !== formData.discount?.value)) {
        changes.discount = formData.discount.value;
    }

    if (isNewModel || existing.priceListId !== formData.priceListId) {
        changes.priceListId = formData.priceListId;
    }

    if (isNewModel || (existing.restrictions && existing.restrictions.minSpend !== formData.restrictions?.minSpend)) {
        changes.min_spend = formData.restrictions?.minSpend;
    }

    if (isNewModel || existing.memberUsage !== formData.memberUsage) {
        changes.customer_usage = formData.memberUsage;
    }

    if (
        (isNewModel || existing.restrictions.maxMemberUsage !== formData.restrictions.maxMemberUsage) &&
        formData.memberUsage === "limited"
    ) {
        changes.max_customer_usage = formData.restrictions.maxMemberUsage;
    }

    if (isNewModel || existing.codeUsage !== formData.codeUsage) {
        changes.code_usage = formData.codeUsage;
    }

    if (
        (isNewModel || existing.restrictions.maxCodeUsage !== formData.restrictions.maxCodeUsage) &&
        formData.codeUsage === "limited"
    ) {
        changes.max_code_usage = formData.restrictions.maxCodeUsage;
    }

    if (isNewModel || existingSchedule.startDate !== schedule.startDate) {
        changes.start_date = new Date(schedule.startDate).toISOString();
    }

    if (isNewModel || existingSchedule.endDate !== schedule.endDate) {
        changes.end_date = schedule.endDate ? new Date(schedule.endDate).toISOString() : undefined;
    }

    if (isNewModel || existingSchedule.activeDaysOfWeek !== schedule.activeDaysOfWeek) {
        changes.repeat = schedule.activeDaysOfWeek.map((dow) => DaysOfWeek[dow]);
    }

    if (isNewModel || existingSchedule.dailyStartTime !== schedule.dailyStartTime) {
        changes.start_time = schedule.dailyStartTime?.toString().padStart(4, "0") || undefined;
    }

    if (isNewModel || existingSchedule.dailyEndTime !== schedule.dailyEndTime) {
        changes.end_time = schedule.dailyEndTime?.toString().padStart(4, "0") || undefined;
    }

    if (
        (isNewModel && schedule.specificInactiveDates) ||
        !equalsIgnoreOrder(existingSchedule.specificInactiveDates, schedule.specificInactiveDates)
    ) {
        changes.blackout_dates = schedule.specificInactiveDates;
    }

    if (
        (isNewModel && requiredItems?.productIds?.length) ||
        !equalsIgnoreOrder(existingRequiredItems?.productIds || [], requiredItems?.productIds || [])
    ) {
        changes.products = requiredItems?.productIds || [];
    }

    if (
        (isNewModel && requiredItems?.variantIds?.length) ||
        !equalsIgnoreOrder(existingRequiredItems?.variantIds || [], requiredItems?.variantIds || [])
    ) {
        changes.variants = requiredItems?.variantIds || [];
    }

    return changes;
};
