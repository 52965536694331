import styles from "./StickyElement.module.scss";

import { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
interface Props {
    scrollContainer?: string | HTMLElement;
}

export const StickyElement = ({ children, scrollContainer = "[data-scrollcontainer]" }: PropsWithChildren<Props>) => {
    const [offsetY, setOffsetY] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<number | undefined>(undefined);
    const [sticky, setSticky] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        setOffsetY(ref.current?.offsetTop);
        setHeight(ref.current?.getBoundingClientRect().height);
    }, []);

    useResizeObserver(ref, () => {
        setOffsetY(ref.current?.offsetTop);
        setHeight(ref.current?.getBoundingClientRect().height);
    });

    useEffect(() => {
        const container: HTMLElement | null =
            typeof scrollContainer === "string" ? document.querySelector(scrollContainer) : scrollContainer;

        if (!container) {
            return;
        }

        const listener = () => {
            if (offsetY !== undefined) {
                setSticky(offsetY - container.scrollTop < 4);
            }
        };

        container.addEventListener("scroll", listener);

        return () => {
            container.removeEventListener("scroll", listener);
        };
    }, [offsetY, scrollContainer]);

    return (
        <div>
            {sticky && <div className={styles.cover} />}
            <div style={sticky ? { height } : undefined} ref={ref}>
                {!sticky && children}
            </div>
            {sticky && (
                <div style={{ height }} className={styles.stickyContainer}>
                    <div className={styles.innerContainer}>{children}</div>
                </div>
            )}
        </div>
    );
};
