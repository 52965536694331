import { AppState } from "features";
import { createSelector } from "reselect";
import { getPosName } from "features/location/selectors/getPosName";

const getPosSyncResult = ({ catalogue }: AppState) => {
    return catalogue.posSync.status === "loaded" ? catalogue.posSync.data : null;
};

export const getSyncFromPosTrackingEventData = createSelector(
    [getPosName, getPosSyncResult],
    (posName, posSyncResult) => {
        const success: boolean = !!posSyncResult;

        const syncStats = posSyncResult?.syncStats;

        const syncDuration = posSyncResult?.syncStats?.syncDurationMilliseconds ?? undefined;

        const data = {
            event: {
                name: `CATALOGUE/POS_SYNC_${success ? "SUCCESS" : "FAILED"}`,
            },
            customProperties: {
                pos_type: posName,
                ...(posSyncResult
                    ? {
                          catalogue_retrieved: posSyncResult!.catalogueRetrieved,
                          catalogue_synced: posSyncResult!.catalogueSynced,
                          nb_products_synced: syncStats?.productStats?.syncedCount,
                          nb_products_ignored: syncStats?.productStats?.ignoredCount,
                          nb_products_missing_from_pos: syncStats?.productStats?.missingFromPosCount,
                          nb_variants_synced: syncStats?.variantStats?.syncedCount,
                          nb_variants_ignored: syncStats?.variantStats?.ignoredCount,
                          nb_variants_missing_from_pos: syncStats?.variantStats?.missingFromPosCount,
                          nb_modifiers_synced: syncStats?.modifierGroupStats?.syncedCount,
                          nb_modifiers_ignored: syncStats?.modifierGroupStats?.ignoredCount,
                          nb_modifiers_missing_from_pos: syncStats?.modifierGroupStats?.missingFromPosCount,
                          nb_mod_options_synced: syncStats?.modifierOptionStats?.syncedCount,
                          nb_mod_options_ignored: syncStats?.modifierOptionStats?.ignoredCount,
                          nb_mod_options_missing_from_pos: syncStats?.modifierOptionStats?.missingFromPosCount,
                          sync_duration_seconds:
                              syncDuration === undefined ? undefined : Math.round(syncDuration / 1000),
                          completed_time: syncStats?.completedTimeUtc
                              ? new Date(syncStats.completedTimeUtc).toISOString()
                              : undefined,
                      }
                    : null),
            },
        };

        return data;
    }
);
