import { TypeKeys as EditTypeKeys } from "./edit";
import { ModifierSummary, Status } from "features/catalogue/types";
import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { PublishSuccessAction, TypeKeys as PublishTypeKeys } from "features/location/reducers/publish";

enum TypeKeys {
    LOADING = "MODIFIERS_LIST/LOADING",
    LOADED = "MODIFIERS_LIST/LOADED",
    FAILED = "MODIFIERS_LIST/FAILED",
}

const list = scaffoldList<ModifierSummary, typeof TypeKeys, typeof EditTypeKeys>(
    TypeKeys,
    EditTypeKeys,
    sortByDisplayName
);
const { createAction, reducer: scaffoldReducer } = list;

export type State = ReturnType<typeof list.reducer>;
type ReducerParams = Parameters<typeof list.reducer>;
type ReducerActions = ReducerParams[1];

function reducer(state: State, action: ReducerActions | PublishSuccessAction): State {
    if (action.type === PublishTypeKeys.SUCCESS) {
        if (state.status !== "loaded") {
            return state;
        }

        return {
            ...state,
            data: state.data.map((item) => {
                const wasPublished = !!action.result.menuData.modifiers[item.id];

                if (!wasPublished) {
                    return item;
                }

                return {
                    ...item,
                    status: Status.Published,
                };
            }),
        };
    }

    return scaffoldReducer(state, action);
}

export { createAction, reducer };
