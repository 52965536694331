import styles from "./Tabs.module.scss";

import { PropsWithChildren } from "react";
import { useTestAttributes } from "../testAttributes";
import classNames from "classnames";

// export with children for typings in RouteTabs which clones element
export interface RouteTabPanelProps extends PropsWithChildren<any> {
    className?: string;
    id: string;
    label: string;
}

export const RouteTabPanel = ({ children, className, id }: RouteTabPanelProps) => {
    const { getTestId } = useTestAttributes();

    return (
        <div
            id={`${id}-tabpanel`}
            role="tabpanel"
            aria-labelledby={id}
            className={classNames(styles.tabPanel, className)}
            data-testid={getTestId({ fragment: "tab", element: "panel" }, id)}
        >
            {children}
        </div>
    );
};
