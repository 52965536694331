import styles from "./LayoutLoading.module.scss";

import { Spinner } from "../spinner";
import { PageError } from "../pageError";
import { Button } from "../button";

interface Props {
    loading?: boolean;
    failed?: boolean;
    failedHeading?: string;
    failedMessage?: string;
    onRetry?: () => void;
}

export const LayoutLoading = ({
    loading = true,
    failed,
    failedHeading = "Oh no! Something went wrong.",
    failedMessage = "Try reloading this page or try again later.",
    onRetry,
}: Props) => {
    if (loading) {
        return (
            <div className={styles.container}>
                <Spinner size="large" />
            </div>
        );
    }

    if (failed) {
        return (
            <div className={styles.container}>
                <PageError
                    heading={failedHeading}
                    message={failedMessage}
                    actions={onRetry ? <Button onClick={onRetry}>Retry</Button> : undefined}
                />
            </div>
        );
    }

    return null;
};
