import { withSvg } from "core/components/svg/Svg";

export const Menu = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M4.52109 0.497052C4.91158 0.106496 5.54474 0.106445 5.9353 0.496938L10.5688 5.12969C11.1073 5.66833 11.4099 6.39896 11.4099 7.1606C11.4099 7.92224 11.1074 8.65269 10.569 9.19134L10.4069 9.3534L12.346 11.2925C12.7365 11.683 12.7365 12.3162 12.346 12.7067C11.9555 13.0972 11.3223 13.0972 10.9318 12.7067L8.99253 10.7675L8.73691 11.023C8.19823 11.5613 7.46766 11.8639 6.70612 11.8639C5.94459 11.8639 5.21421 11.5615 4.67553 11.0232L0.0427788 6.38897C-0.347682 5.99838 -0.34758 5.36522 0.0430078 4.97476C0.433595 4.58429 1.06676 4.5844 1.45722 4.97498L6.08922 9.60849C6.08926 9.60852 6.08919 9.60845 6.08922 9.60849C6.25287 9.77196 6.47481 9.86388 6.70612 9.86388C6.93739 9.86388 7.1592 9.77209 7.32284 9.60867C7.3229 9.60861 7.32278 9.60873 7.32284 9.60867L7.57831 9.35325L2.32852 4.10346C1.93799 3.71293 1.93799 3.07977 2.32852 2.68924C2.71904 2.29872 3.35221 2.29872 3.74273 2.68924L8.99267 7.93918L9.15453 7.77736C9.31807 7.61377 9.40994 7.39192 9.40994 7.1606C9.40994 6.92928 9.31807 6.70744 9.15453 6.54384L4.5212 1.91127C4.13064 1.52077 4.13059 0.887608 4.52109 0.497052ZM14.7827 15.1441C15.1732 14.7536 15.8064 14.7536 16.1969 15.1441L23.5952 22.5429C23.9858 22.9334 23.9857 23.5666 23.5952 23.9571C23.2047 24.3476 22.5715 24.3476 22.181 23.9571L14.7826 16.5583C14.3921 16.1678 14.3921 15.5346 14.7827 15.1441Z" />
        <path d="M22.555 2.21487L21.5651 2.07339C22.555 2.21487 22.555 2.21451 22.555 2.21487L22.5544 2.2189L22.5533 2.2263L22.5496 2.25111C22.5464 2.27205 22.5418 2.30175 22.5356 2.33982C22.5232 2.41596 22.5045 2.52566 22.4786 2.66599C22.4268 2.94659 22.346 3.35002 22.2284 3.85278C21.9931 4.85766 21.6096 6.26314 21.0137 7.88025C19.8252 11.1059 17.7747 15.221 14.3286 18.6674C14.141 18.8549 13.8867 18.9603 13.6214 18.9603C13.3562 18.9603 13.1019 18.8549 12.9143 18.6674L9.64956 15.4026L2.41852 22.6334C2.02799 23.0239 1.39482 23.0239 1.00431 22.6334C0.613789 22.2428 0.613801 21.6097 1.00433 21.2191L20.858 1.36627C21.1623 1.06191 21.6273 0.986469 22.0123 1.17898C22.3973 1.3715 22.6159 1.78877 22.555 2.21487ZM11.0638 13.9884L13.6058 16.5304C16.3704 13.5113 18.093 10.0225 19.1371 7.18879C19.389 6.50498 19.6006 5.8617 19.7772 5.27539L11.0638 13.9884Z" />
    </>
));
