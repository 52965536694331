import { AppState } from "features";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { EditableLocationSettings } from "..";
import { isNew } from "common/utility/modelUtils";

const getExisting = ({ LocationSettings: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : null;
};

const getSections = ({ sections: { list } }: AppState) => {
    return list.status === "loaded" ? list.data : null;
};

export const getSaveLocationSettingsTrackingEventData = createSelector(
    // allow newItem to be passed as param to selector
    getExisting,
    getSections,
    (_: AppState, update: EditableLocationSettings) => update,
    (existing, sections, update) => {
        const isNewModel = isNew(update.id);
        const changes: any = {};

        if (!isNewModel) {
            changes.location_id = update.id;
        }

        if (isNewModel || (existing && existing.allowNotesToKitchen !== update.allowNotesToKitchen)) {
            changes.docket_notes = update.allowNotesToKitchen;
        }

        if (isNewModel || (existing && existing.confirmEighteenPlus !== update.confirmEighteenPlus)) {
            changes["18_alert"] = update.confirmEighteenPlus;
        }

        if (isNewModel || (existing && existing.enableAnotherRound !== update.enableAnotherRound)) {
            changes.easy_drink_reorders = update.enableAnotherRound;
        }

        if (isNewModel || (existing && existing.isBuzzer !== update.isBuzzer)) {
            changes.buzzer = update.isBuzzer;
        }

        if (
            isNewModel ||
            (existing && !isEqual(existing.sectionsWithDocketMerging, update.sectionsWithDocketMerging))
        ) {
            changes.docket_merging = update.sectionsWithDocketMerging.map((id) => ({
                id,
                name: sections?.find((section) => section.id === id)?.displayName,
            }));
        }

        if (isNewModel || (existing && existing.unusedPartyTimeoutMinutes !== update.unusedPartyTimeoutMinutes)) {
            changes.table_time_out_min = `${update.unusedPartyTimeoutMinutes}`;
        }

        if (isNewModel || (existing && existing.idleTimeoutMinutes !== update.idleTimeoutMinutes)) {
            changes.pos_time_out_min = `${update.idleTimeoutMinutes}`;
        }

        if (isNewModel || (existing && existing.isLiveOrdersEnabled !== update.isLiveOrdersEnabled)) {
            changes.live_orders = update.isLiveOrdersEnabled;
        }

        if (isNewModel || (existing && existing.allowTips !== update.allowTips)) {
            changes.tips = update.allowTips;
        }

        if (isNewModel || (existing && !isEqual(existing.tipOptions?.levels, update.tipOptions?.levels))) {
            changes.suggested_tip_amounts = update.tipOptions.levels?.reduce((prev, option, index) => {
                prev[`tip_${index + 1}`] = `${option}`;
                return prev;
            }, {});
        }

        if (isNewModel || (existing && preselectedTipExistsChanged(existing, update))) {
            changes.preselected_tip = update.tipOptions.defaultIndex >= 0;
        }

        if (isNewModel || (existing && preselectedTipAmountChanged(existing, update))) {
            changes.preselected_tip_amount = update.tipOptions?.levels?.[update.tipOptions.defaultIndex]?.toString();
        }

        if (isNewModel || (existing && existing.enableDietaryNotifier !== update.enableDietaryNotifier)) {
            changes.dietary_notifier = update.enableDietaryNotifier;
        }

        if (isNewModel || (existing && existing.mustAcceptAllergenNotice !== update.mustAcceptAllergenNotice)) {
            changes.dietary_notifier_acknowledgement = update.mustAcceptAllergenNotice;
        }

        if (isNewModel || (existing && existing.orderBatchTimeSeconds !== update.orderBatchTimeSeconds)) {
            changes.order_holding_sec = `${update.orderBatchTimeSeconds}`;
        }

        if (isNewModel || (existing && existing.drinkOrderBatchTimeSeconds !== update.drinkOrderBatchTimeSeconds)) {
            changes.drink_order_holding_sec = `${update.drinkOrderBatchTimeSeconds}`;
        }

        if (isNewModel || (existing && existing.foodOrderBatchTimeSeconds !== update.foodOrderBatchTimeSeconds)) {
            changes.food_order_holding_sec = `${update.foodOrderBatchTimeSeconds}`;
        }

        if (isNewModel || (existing && existing.openTableBatchTimeSeconds !== update.openTableBatchTimeSeconds)) {
            changes.open_table_order_holding_sec = `${update.openTableBatchTimeSeconds}`;
        }

        if (isNewModel || (existing && existing.marketingOptions.enabled !== update.marketingOptions.enabled)) {
            changes.marketing = update.marketingOptions.enabled;
        }

        if (isNewModel || (existing && existing.marketingOptions.prompt !== update.marketingOptions.prompt)) {
            changes.supernova_opt_in = update.marketingOptions.prompt;
        }

        if (isNewModel || (existing && existing.groupTabGratuityFactor !== update.groupTabGratuityFactor)) {
            changes.grouptab_gratuity = update.groupTabGratuityFactor;
        }

        if (isNewModel || existing?.trayCharge?.enabled !== update.trayCharge?.enabled) {
            changes.tray_charge_enabled = update.trayCharge!.enabled;
        }

        if (isNewModel || existing?.trayCharge?.fixedAmount !== update.trayCharge?.fixedAmount) {
            changes.tray_charge_amount = update.trayCharge!.fixedAmount;
        }

        if (isNewModel || existing?.trayCharge?.name !== update.trayCharge?.name) {
            changes.tray_charge_name = update.trayCharge!.name;
        }

        if (isNewModel || existing?.venueCause?.enabled !== update.venueCause?.enabled) {
            changes.venue_cause_enabled = update.venueCause!.enabled;
        }

        if (isNewModel || existing?.venueCause?.messageTitle !== update.venueCause?.messageTitle) {
            changes.venue_cause_message_title = update.venueCause!.messageTitle;
        }

        if (isNewModel || existing?.venueCause?.messageDescription !== update.venueCause?.messageDescription) {
            changes.venue_cause_message_description = update.venueCause!.messageDescription;
        }

        if (
            isNewModel ||
            (existing && !isEqual(existing.venueCause?.venueCauseOptions, update.venueCause?.venueCauseOptions))
        ) {
            changes.venue_cause_options = update.venueCause!.venueCauseOptions?.reduce((prev, option, index) => {
                prev[`option_${index + 1}_amount`] = `${option.amount}`;
                prev[`option_${index + 1}_posId`] = `${option.posId}`;
                return prev;
            }, {});
        }

        if (
            isNewModel ||
            existing?.alcoholicDrinksRestriction?.enabled !== update.alcoholicDrinksRestriction?.enabled
        ) {
            changes.alcohol_restriction = update.alcoholicDrinksRestriction!.enabled;
        }

        if (
            isNewModel ||
            existing?.alcoholicDrinksRestriction?.maxDrinksPerOrder !==
                update.alcoholicDrinksRestriction?.maxDrinksPerOrder
        ) {
            changes.max_nb_drinks = update.alcoholicDrinksRestriction!.maxDrinksPerOrder;
        }

        if (isNewModel || (existing && !isEqual(existing.splitPaymentOptions, update.splitPaymentOptions))) {
            changes.split_payment_options = [...update.splitPaymentOptions];
        }

        if (isNewModel || (existing && existing.walkOutGratuityFactor !== update.walkOutGratuityFactor)) {
            changes.walk_out_gratuity = update.walkOutGratuityFactor;
        }

        if (isNewModel || (existing && existing.posPaymentSourceLabel !== update.posPaymentSourceLabel)) {
            changes.pos_payment_source_label = update.posPaymentSourceLabel;
        }

        return {
            event: {
                name: "SETTINGS/CHANGES_SAVED",
            },
            customProperties: changes,
        };
    }
);

function preselectedTipAmountChanged(existing: EditableLocationSettings, update: EditableLocationSettings): boolean {
    const a = existing?.tipOptions?.levels?.[existing.tipOptions.defaultIndex];
    const b = update?.tipOptions?.levels?.[update.tipOptions.defaultIndex];
    return a !== b;
}

function preselectedTipExistsChanged(existing: EditableLocationSettings, update: EditableLocationSettings): boolean {
    const existingPreselectedTipExists = existing.tipOptions?.defaultIndex >= 0;
    const updatePreselectedTipExists = update.tipOptions?.defaultIndex >= 0;
    return existingPreselectedTipExists !== updatePreselectedTipExists;
}
