import { FeatureConfiguration } from "..";

import { reducer } from "features/tags/reducers";

import * as actions from "features/tags/actions";

export * from "features/tags/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.tags = reducer;
}
