import { EditablePackage, PackageSummary } from "../types";
import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "PACKAGE/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "PACKAGE/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "PACKAGE/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "PACKAGE/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "PACKAGE/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "PACKAGE/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditablePackage, PackageSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
