import { Option } from "core/components/form/select";
import { UserType } from "./UserType";

export interface UserTypeOption extends Option {
    value: UserType | undefined;
}

export const USER_TYPE_OPTIONS: UserTypeOption[] = [
    {
        label: "Any",
        value: undefined,
    },
    {
        label: "External",
        value: UserType.EXTERNAL,
    },
    {
        label: "Internal",
        value: UserType.INTERNAL,
    },
];

export const USER_TYPE_OPTION_ANY: UserTypeOption = USER_TYPE_OPTIONS[0];
