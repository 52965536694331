import { simplePlural } from "common/utility/StringUtils";
import { createSelector } from "reselect";
import {
    CollectionInfo,
    CollectionName,
    PosCatalogueImportItem,
    PosCatalogueImportSelections,
    SelectionInfo,
    SelectionState,
} from "../types";

// collapse means a selected single child selection is not counted, just it's parent
// this matches the way items with single child are presented in UI
const getSelectionCount = (value: string[] | boolean, collapse: boolean) => {
    if (Array.isArray(value)) {
        return !collapse || value.length !== 1 ? value.length : 0;
    }

    return 0;
};

const getSelections = (state: SelectionState) => state.selections;

const getModifiers = (state: SelectionState) => state.modifiers;

const getProducts = (state: SelectionState) => state.products;

export const getCollectionParentItemName = (collectionName: CollectionName) => {
    return collectionName === "products" ? "Product" : "Modifier group";
};

export const getCollectionChildItemName = (collectionName: CollectionName) => {
    return collectionName === "products" ? "Variant" : "Modifier option";
};

const getIsParentOfSingleChild = (parentId: string, items: PosCatalogueImportItem[]): boolean => {
    const parent = items.find(({ id }) => id === parentId);
    if (!parent) {
        throw new Error(`Could not find parent with id ${parentId}`);
    }
    return parent?.children?.length === 1;
};

const getCollectionInfo = (
    values: PosCatalogueImportSelections,
    collectionName: CollectionName,
    items: PosCatalogueImportItem[],
    allowCollapse: boolean = false
): CollectionInfo => {
    const collectionValues = Object.values(values[collectionName] || {});

    const numParentsSelected = collectionValues.filter(Boolean).length;

    const collectionEntries = Object.entries(values[collectionName] || {});

    const numChildrenSelected = collectionEntries.reduce<number>((prev, [key, value]) => {
        const collapse = allowCollapse && getIsParentOfSingleChild(key, items);
        return prev + getSelectionCount(value as string[] | boolean, collapse);
    }, 0);

    const parentItemName = getCollectionParentItemName(collectionName);
    const childItemName = getCollectionChildItemName(collectionName);

    return {
        collectionName,
        numParentsSelected,
        numChildrenSelected,
        parentItemName,
        childItemName,
        parentSummary: `${numParentsSelected} ${parentItemName}${simplePlural(numParentsSelected)}`,
        childSummary: `${numChildrenSelected} ${childItemName}${simplePlural(numChildrenSelected)}`,
    };
};

export const getSelectionInfo = createSelector(
    getSelections,
    getModifiers,
    getProducts,
    (selections, modifiers, products): SelectionInfo => {
        const modifiersInfo = getCollectionInfo(selections, "modifiers", modifiers);
        const productsInfo = getCollectionInfo(selections, "products", products, true);

        let parentSummary = [productsInfo, modifiersInfo]
            .filter(({ numParentsSelected }) => numParentsSelected)
            .map(({ parentSummary }) => parentSummary)
            .join(", ");

        let childSummary = [productsInfo, modifiersInfo]
            .filter(({ numChildrenSelected }) => numChildrenSelected)
            .map(({ childSummary }) => childSummary)
            .join(", ");

        return {
            modifiers: modifiersInfo,
            products: productsInfo,
            parentSummary: parentSummary && `${parentSummary} selected`,
            childSummary: childSummary && `${childSummary} selected`,
        };
    }
);
