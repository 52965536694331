import { Loaded } from "common/loader";
import { DailySalesReport } from "../types";

export type State = Loaded<DailySalesReport>;

enum TypeKeys {
    LOADING = "DAILY_SALES_REPORT/LIST_BEGIN",
    SUCCESS = "DAILY_SALES_REPORT/LIST_SUCCESS",
    FAILURE = "DAILY_SALES_REPORT/LIST_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: DailySalesReport) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type DailySalesReportListLoadingAction = { type: TypeKeys.LOADING };
type DailySalesReportListLoadedAction = { type: TypeKeys.SUCCESS; data: DailySalesReport };
type DailySalesReportListFailedAction = { type: TypeKeys.FAILURE; error: any };

type DailySalesReportListAction =
    | DailySalesReportListLoadingAction
    | DailySalesReportListLoadedAction
    | DailySalesReportListFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: DailySalesReportListAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
