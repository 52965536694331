import { createAction } from "../reducers/list";
import { fetchPosCatalogue as fetchPosCatalogueApi } from "../api/fetchPosCatalogue";
import { AppDispatch, AppState } from "features/state";
import { getPosName } from "features/location/selectors/getPosName";
import { trackEventFetchImportSucceed, trackEventFetchImportFailed } from "./trackEventImportPos";

export const fetchPosCatalogue = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    if (getState().posCatalogueImport.list.status === "loading") {
        return;
    }

    dispatch(createAction.loading());
    try {
        const result = await fetchPosCatalogueApi(location);
        dispatch(createAction.loaded(result));
        trackEventFetchImportSucceed(getState());
    } catch (e: any) {
        dispatch(createAction.failed(e));
        trackEventFetchImportFailed(e.message, getPosName(getState()));
    }
};
