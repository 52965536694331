import { State as PosConfigurationState, reducer as editReducer } from "./edit";
import { State as PosLookupsState, reducer as lookupsReducer } from "./lookups";

export interface State {
    posConfiguration: PosConfigurationState;
    posLookups: PosLookupsState;
}

export const reducer = {
    posConfiguration: editReducer,
    posLookups: lookupsReducer,
};
