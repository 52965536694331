import { LocationLocale } from "features/location/types/LocationLocale";
import { useMemo } from "react";

export function useCurrencyFormatter(locationLocale: LocationLocale, decimalPlaces: number = 2) {
    if (!locationLocale) {
        throw new Error("useCurrencyFormatter requires locationLocale");
    }

    const { getNumberFormatter, currency } = locationLocale;

    // intl format number to 2dp including currency symbol
    return useMemo(() => {
        return getNumberFormatter({
            style: "currency",
            currency,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    }, [currency, decimalPlaces, getNumberFormatter]);
}
