import { CatalogueFilters, StatusFilter, SyncStatusFilter } from "features/catalogue/types";

export enum TypeKeys {
    CLEAR_FILTERS = "CATALOGUE/CLEAR_FILTERS",
    SET_ACTIVE_FILTERS = "CATALOGUE/SET_ACTIVE_FILTERS",
    SET_STATUS_FILTERS = "CATALOGUE/SET_STATUS_FILTERS",
    SET_SYNC_STATUS_FILTERS = "CATALOGUE/SET_SYNC_STATUS_FILTERS",
    SET_TEXT_FILTER = "CATALOGUE/SET_TEXT_FILTER",
    SET_MISSING_CONTENT_FILTER = "CATALOGUE/SET_MISSING_CONTENT_FILTER",
}

export const createAction = {
    clearFilters: () => ({ type: TypeKeys.CLEAR_FILTERS }),
    setFilters: (filters: CatalogueFilters) => ({ type: TypeKeys.SET_ACTIVE_FILTERS, filters }),
    setStatusFilters: (filters: StatusFilter[]) => ({ type: TypeKeys.SET_STATUS_FILTERS, filters }),
    setSyncStatusFilters: (filters: SyncStatusFilter[]) => ({ type: TypeKeys.SET_SYNC_STATUS_FILTERS, filters }),
    setTextFilter: (text: string) => ({ type: TypeKeys.SET_TEXT_FILTER, text }),
};

type ClearFiltersAction = { type: TypeKeys.CLEAR_FILTERS };
type SetActiveFiltersAction = { type: TypeKeys.SET_ACTIVE_FILTERS; filters: CatalogueFilters };
type SetFilterTextAction = { type: TypeKeys.SET_TEXT_FILTER; text: string };
type SetStatusFilters = { type: TypeKeys.SET_STATUS_FILTERS; filters: StatusFilter[] };
type SetSyncStatusFilters = { type: TypeKeys.SET_SYNC_STATUS_FILTERS; filters: SyncStatusFilter[] };

type FilterAction =
    | ClearFiltersAction
    | SetActiveFiltersAction
    | SetStatusFilters
    | SetSyncStatusFilters
    | SetFilterTextAction;

export interface State {
    active: CatalogueFilters;
    text: string;
}

export function getInitialState(): State {
    return {
        active: {
            categories: [],
            sku: { label: "All", value: undefined },
            status: [],
            syncStatus: [],
            isLinked: { label: "All", value: undefined },
            isHidden: { label: "All", value: undefined },
        },
        text: "",
    };
}

export function reducer(state: State = getInitialState(), action: FilterAction): State {
    if (action.type === TypeKeys.CLEAR_FILTERS) {
        return getInitialState();
    }

    if (action.type === TypeKeys.SET_ACTIVE_FILTERS) {
        const { filters } = action;

        return {
            ...state,
            active: filters,
        };
    }

    if (action.type === TypeKeys.SET_STATUS_FILTERS) {
        const { filters } = action;

        const initialState = getInitialState();

        return {
            ...initialState,
            active: {
                ...initialState.active,
                status: filters,
            },
        };
    }

    if (action.type === TypeKeys.SET_SYNC_STATUS_FILTERS) {
        const { filters } = action;

        const initialState = getInitialState();

        return {
            ...initialState,
            active: {
                ...initialState.active,
                syncStatus: filters,
            },
        };
    }

    if (action.type === TypeKeys.SET_TEXT_FILTER) {
        const { text } = action;

        return {
            ...state,
            text,
        };
    }

    return state;
}
