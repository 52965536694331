import { CategoryType } from "../types";
import { CategoryFilters } from "../types/CategoryFilters";

export enum TypeKeys {
    CLEAR_FILTERS = "CATEGORY/CLEAR_FILTERS",
    SET_ACTIVE_FILTERS = "CATEGORY/SET_ACTIVE_FILTERS",
    SET_CATEGORY_TYPE = "CATEGORY/SET_CATEGORY_TYPE",
    SET_TEXT_FILTER = "CATEGORY/SET_TEXT_FILTER",
}
export const createAction = {
    clearFilters: () => ({ type: TypeKeys.CLEAR_FILTERS }),
    setFilters: (filters: CategoryFilters) => ({ type: TypeKeys.SET_ACTIVE_FILTERS, filters }),
    setCategoryType: (categoryType: CategoryType) => ({ type: TypeKeys.SET_CATEGORY_TYPE, categoryType }),
    setTextFilter: (text: string) => ({ type: TypeKeys.SET_TEXT_FILTER, text }),
};

type ClearFiltersAction = { type: TypeKeys.CLEAR_FILTERS };
type SetActiveFiltersAction = { type: TypeKeys.SET_ACTIVE_FILTERS; filters: CategoryFilters };
type SetCategoryTypeAction = { type: TypeKeys.SET_CATEGORY_TYPE; categoryType: CategoryType };
type SetFilterTextAction = { type: TypeKeys.SET_TEXT_FILTER; text: string };

type FilterAction = ClearFiltersAction | SetActiveFiltersAction | SetFilterTextAction | SetCategoryTypeAction;

export interface State {
    active: CategoryFilters;
    text: string;
}

export function getInitialState(): State {
    return {
        active: {
            categoryType: "drink",
            isLinked: { label: "All", value: undefined },
            services: [],
        },
        text: "",
    };
}

export function reducer(state: State = getInitialState(), action: FilterAction): State {
    if (action.type === TypeKeys.CLEAR_FILTERS) {
        return getInitialState();
    }

    if (action.type === TypeKeys.SET_ACTIVE_FILTERS) {
        const { filters } = action;

        return {
            ...state,
            active: filters,
        };
    }

    if (action.type === TypeKeys.SET_CATEGORY_TYPE) {
        const { categoryType } = action;

        return {
            ...state,
            active: {
                ...state.active,
                categoryType,
            },
        };
    }

    if (action.type === TypeKeys.SET_TEXT_FILTER) {
        const { text } = action;

        return {
            ...state,
            text,
        };
    }

    return state;
}
