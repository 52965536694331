import { ActiveLocation } from "features/location";
import { NavItemFilter } from "../types/NavItemFilter";

export function isFeatureEnabled(location: ActiveLocation | undefined): NavItemFilter {
    return (item) => !!(!item.featureToggle || (location && location[item.featureToggle]));
}

export function isParentLocationEnabled(): NavItemFilter {
    return (item) => !!(item.includeForParentLocation || item.includeForParentLocationOnly);
}

export function isNonParentLocationEnabled(): NavItemFilter {
    return (item) => !item.includeForParentLocationOnly;
}
