import { State as ActiveState, reducer as activeReducer } from "./active";
import { State as ListState, reducer as listReducer } from "./list";

import { combineReducers } from "redux";

export interface State {
    groups: {
        list: ListState;
        active: ActiveState;
    };
}

export const reducer = combineReducers({
    active: activeReducer,
    list: listReducer,
});
