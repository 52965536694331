import { EditableTeam, TeamSummary } from "../types";
import { scaffoldEdit } from "common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "TEAM/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "TEAM/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "TEAM/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "TEAM/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "TEAM/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "TEAM/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditableTeam, TeamSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };

export type SavedAction = ReturnType<typeof createAction.saved>;
