import { createAction } from "../reducers/list";
import { applySummaryDisplayName } from "foundation/dataConventions/applySummaryDisplayName";
import { listSections } from "../api/listSections";
import { scaffoldListAction } from "common/scaffolding/actions/scaffoldListAction";
import { SectionSummary } from "../types";

export const list = (location: string) =>
    scaffoldListAction<SectionSummary>(
        (state) => state.sections.list,
        createAction,
        async () => {
            const sections = await listSections(location);

            return sections.map(applySummaryDisplayName);
        }
    );
