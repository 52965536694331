import styles from "./PublishStatus.module.scss";

import { PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type Status = "published" | "staged" | "not-published" | "warning" | "hidden";

export type Size = "default" | "small";
interface Props {
    size?: Size;
    status?: Status;
    nowrap?: boolean;
}

export const PublishStatus = ({
    children,
    size = "default",
    status = "published",
    nowrap = true,
}: PropsWithChildren<Props>): ReactElement => {
    return (
        <span
            className={classNames(
                styles.default,
                styles[status],
                nowrap && styles.nowrap,
                size === "small" && styles.small
            )}
        >
            {children}
        </span>
    );
};
