import { withSvg } from "core/components/svg/Svg";

export const Chatbot = withSvg(
    64,
    64
)(() => (
    <>
        <path d="M50 24.7C50 14.1 39.3 5.5 26 5.5C12.7 5.5 2 14.1 2 24.7C2 30.1 4.8 35 9.3 38.4L4.1 49.3L19.4 43.2C21.5668 43.68 23.7807 43.9148 26 43.9C39.3 43.9 50 35.3 50 24.7Z" />
        <path d="M61.9996 41.1C61.9996 36 58.5996 31.6 53.3996 29.3C51.0996 38.8 41.6996 46.2 30.0996 47.6C32.9996 51.8 38.5996 54.7 44.9996 54.7C46.5801 54.7107 48.1568 54.543 49.6996 54.2L59.9996 58.5L56.8996 50.8C58.4361 49.682 59.6951 48.2258 60.5794 46.544C61.4636 44.8621 61.9496 42.9995 61.9996 41.1Z" />
    </>
));

<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
