import { FeatureConfiguration } from "features";
import { previewMiddleware } from "./previewMiddleware";

import { actionCreators } from "./reducers/preview";

export const previewService = actionCreators.previewService;

export default function (config: FeatureConfiguration) {
    config.middleware.push(previewMiddleware());
}
