export enum TypeKeys {
    CLEAR_FILTERS = "TEAM/CLEAR_FILTERS",
    SET_TEXT_FILTER = "TEAM/SET_TEXT_FILTER",
}
export const createAction = {
    clearFilters: () => ({ type: TypeKeys.CLEAR_FILTERS }),
    setTextFilter: (text: string) => ({ type: TypeKeys.SET_TEXT_FILTER, text }),
};

type ClearFiltersAction = { type: TypeKeys.CLEAR_FILTERS };
type SetFilterTextAction = { type: TypeKeys.SET_TEXT_FILTER; text: string };

type FilterAction = ClearFiltersAction | SetFilterTextAction;

export interface State {
    text: string;
}

export function getInitialState(): State {
    return {
        text: "",
    };
}

export function reducer(state: State = getInitialState(), action: FilterAction): State {
    if (action.type === TypeKeys.CLEAR_FILTERS) {
        return getInitialState();
    }

    if (action.type === TypeKeys.SET_TEXT_FILTER) {
        const { text } = action;

        return {
            ...state,
            text,
        };
    }

    return state;
}
