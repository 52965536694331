import { AppState } from "features/state";
import { createSelector } from "reselect";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { isLoaded } from "common/loader/isLoaded";
import { ModifierSummary } from "features/catalogue/types";

export const getModifiersList = (state: AppState) => state.modifiers.list;

export const getModifiersListStatus = createSelector(getModifiersList, (list) => list.status);

// consumers interested in the hidden or isLinked properties of modifiers should use this selector and not the store directly
export const getModifiersListData = createSelector(
    getModifiersList,
    getIsChildLocation,
    (modifiers, isChildLocation): ModifierSummary[] => {
        if (!isLoaded(modifiers)) {
            return [];
        }

        return modifiers.data.map((modifier) => {
            return {
                ...modifier,
                isLinked: !!(modifier.isLinked && isChildLocation),
                isHidden: !!(modifier.isHidden || (isChildLocation && modifier.isParentHidden)),
                options: modifier.options?.map((option) => ({
                    ...option,
                    isLinked: !!(option.isLinked && isChildLocation),
                    isHidden: !!option.isHidden,
                })),
            };
        });
    }
);
