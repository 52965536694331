import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const GroupTabsPageContainer = lazy(() =>
    import("../containers/GroupTabsPageContainer").then((module) => ({
        default: module.GroupTabsPageContainer,
    }))
);

const GroupTabsEditPackageContainer = lazy(() =>
    import("../containers/GroupTabsEditPackageContainer").then((module) => ({
        default: module.GroupTabsEditPackageContainer,
    }))
);

const packageList: ListComponent = {
    route: "",
    component: withPermissions(GroupTabsPageContainer, ["grouptabs:management:read", "grouptabs:read"]),
};

const packageEdit: EditComponent = {
    route: ":id",
    editTitle: "",
    createTitle: "",
    component: withPermissions(GroupTabsEditPackageContainer, ["grouptabs:management:read", "grouptabs:read"]),
};

export function GroupTabs() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_GROUPTABS}>
            <ModalCrudRouteContainer
                name="grouptabs"
                listComponent={packageList}
                editComponents={[packageEdit]}
                listWidth={22}
                route="menu/grouptabs"
            />
        </TestContext.Provider>
    );
}
