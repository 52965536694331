import { AppState } from "features";
import { NavItemFilter } from "../types/NavItemFilter";
import { NavItem, NavItemDisplayFilter } from "../types/NavMenu";
import { PermissionSet } from "common/permissions/types/PermissionSet";

function matchesFilter(filter: NavItemDisplayFilter | NavItemDisplayFilter[], state: AppState) {
    if (Array.isArray(filter)) {
        return filter.every((f) => f(state));
    }

    return filter(state);
}

export function isPermitted(permissions: PermissionSet): NavItemFilter {
    return (item) => permissions.has(item.permission);
}

export function isDisplayable(state: AppState): NavItemFilter {
    return (item) => !item.displayFilter || matchesFilter(item.displayFilter, state);
}

export function isConfigEnabled(): NavItemFilter {
    return (item) => item.configEnabled !== false;
}

export function isCurrencyEnabled(currency: string): NavItemFilter {
    return (item) => !item.includeForCurrencies?.length || item.includeForCurrencies.includes(currency);
}

export function isNotHiddenPage(pagesToHide: string[]): NavItemFilter {
    return (item: NavItem) => {
        if (!item.route) {
            return true;
        }

        return !pagesToHide.includes(item.route);
    };
}
