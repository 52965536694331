type SupportedNumberType = number | string | undefined | null;

export const numberOrUndefined = (value?: SupportedNumberType) =>
    value === "" || value === null || isNaN(Number(value)) ? undefined : Number(value);
export const numberOrZero = (value: SupportedNumberType) => Number(value) || 0;

export const toPercentage = (value: SupportedNumberType) => numberOrZero(value) * 100;

export const fromPercentage = (value: SupportedNumberType) => numberOrZero(value) / 100;

export const withPrecision = (value: number, precision = 12) => parseFloat(value.toPrecision(precision));

export const toPercentageWithPrecision = (value: SupportedNumberType, precision = 12) =>
    withPrecision(toPercentage(value), precision);

export const fromPercentageWithPrecision = (value: SupportedNumberType, precision = 12) =>
    withPrecision(fromPercentage(value), precision);

export const formatLeadingDecimal = (value: string) => {
    if (value === ".") {
        return "0.";
    }
    return value;
};

var ranges = [
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
];

export const abbreviateNumber = (value: SupportedNumberType) => {
    if (!value) return value;
    const n = typeof value === "string" ? parseInt(value) : value;
    for (var i = 0; i < ranges.length; i++) {
        if (Math.abs(n) >= ranges[i].divider) {
            return (Math.round((n / ranges[i].divider) * 10) / 10).toString() + ranges[i].suffix;
        }
    }
    return n.toString();
};
