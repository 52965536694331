import styles from "./MigrationReady.module.scss";

import { ActiveLocation, withLocation } from "features/location";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { CardsContainer } from "core/components/card/CardsContainer";
import { Card, Row } from "core/components/card";
import { Alert, AlertStatus } from "core/components/alert";
import { Button } from "core/components/button";
import { Icon } from "core/components/icon";
import { OpenInWindow } from "common/icons";
import { PageHeader } from "core/components/pageHeader";

interface Props {
    restaurantLocation: ActiveLocation;
}

const MigrationReadyInner = ({ restaurantLocation }: Props) => {
    function navigateToMigratedVenue() {
        window.open(restaurantLocation.manageMigrationUrl!);
    }

    // Temporary UI
    return (
        <div className={styles.container}>
            <CardsContainer>
                <Card>
                    <Row>
                        <div className={styles.successStepContainer}>
                            <div className={styles.pageHeaderContainer}>
                                <PageHeader title="Migration Complete!" />
                            </div>
                            <SuccessStep title="Menu data has been successfully synced with me&u 2.0" />
                            <SuccessStep title="Your guests will be automatically redirected to me&u 2.0 when they scan a QR code" />
                            <SuccessStep title="Past orders can be refunded through the Venue manager (up to 90 days)" />
                            <SuccessStep title="You can continue to access me&u 1.0 business and finance reporting from the Operator Portal" />
                            <div className={styles.buttonContainer}>
                                <Button onClick={navigateToMigratedVenue}>
                                    Go to me&amp;u 2.0
                                    <Icon size="small">
                                        <OpenInWindow />
                                    </Icon>
                                </Button>
                            </div>
                        </div>
                    </Row>
                </Card>
            </CardsContainer>
        </div>
    );
};

const SuccessStep = ({ title }: { title: string }) => (
    <Alert className={styles.successStep} title={title} status={AlertStatus.SUCCESS}>
        <>{/*Just to force the Alert layout to be "standard"*/}</>
    </Alert>
);

const MigrationReadyWithLocation = withLocation(MigrationReadyInner);

export const MigrationReady = () => (
    <EditWrapper contentWidth={ContentWidth.NARROW}>
        <MigrationReadyWithLocation />
    </EditWrapper>
);
