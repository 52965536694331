import styles from "./Tooltip.module.scss";

import { ModalRenderer } from "../modal";
import { OffsetsFunction } from "@popperjs/core/lib/modifiers/offset";
import { Placement } from "@popperjs/core";
import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

export interface Props extends PropsWithChildren {
    containerClassName?: string;
    content: ReactNode;
    disabled?: boolean;
    flip?: boolean;
    offset?: OffsetsFunction | [number, number];
    placement?: Placement;
    portal?: false | string | HTMLElement;
}

export const Tooltip = ({
    children,
    containerClassName,
    disabled,
    content,
    flip = true,
    offset = [0, 12],
    placement = "right",
    portal = "#tooltip",
}: Props) => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
    const [visible, setVisible] = useState(false);

    const popper = usePopper(targetRef.current, popperElement, {
        placement,
        modifiers: [
            { name: "arrow", options: { element: arrowElement, padding: 10 } },
            {
                name: "offset",
                options: {
                    offset,
                },
            },
            {
                name: "flip",
                enabled: flip,
            },
        ],
    });

    const handleMouseEnter = useCallback(() => {
        setVisible(true);
        popper.update?.();
    }, [popper]);

    const handleMouseLeave = useCallback(() => {
        setVisible(false);
    }, []);

    useEffect(() => {
        if (visible && disabled) {
            setVisible(false);
        }
    }, [disabled, visible]);

    if (disabled) {
        return <>{children}</>;
    }

    return (
        <>
            <span
                className={containerClassName ?? styles.container}
                ref={targetRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </span>
            {visible && (
                <TooltipRenderer portal={portal}>
                    <div
                        {...popper.attributes.popper}
                        className={styles.tooltip}
                        ref={setPopperElement}
                        role="tooltip"
                        style={popper.styles.popper}
                    >
                        {content}
                        <div ref={setArrowElement} style={popper.styles.arrow} className={styles.arrow}></div>
                    </div>
                </TooltipRenderer>
            )}
        </>
    );
};

interface TooltipRendererProps extends Pick<Props, "portal"> {}

const TooltipRenderer = ({ children, portal }: PropsWithChildren<TooltipRendererProps>) => {
    if (portal) {
        return <ModalRenderer target={portal}>{children}</ModalRenderer>;
    }

    return <>{children}</>;
};
