import cuid from "cuid";
import { useContext } from "react";
import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { Message } from "./types/Message";

type SnackbarState = {
    messages: Message[];
    addMessage: (message: Message) => string;
    removeMessage: (id: string) => void;
};

interface MessageEntry {
    startTime: number;
    message: Message;
}

const SnackbarContext = createContext<SnackbarState>({
    messages: [],
    addMessage: () => "",
    removeMessage: () => {},
});

// provide no-ops until component initialized
window.addSnackbarMessage = (message: Message) => "";
window.removeSnackbarMessage = (id: string) => {};

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }: PropsWithChildren<{}>) => {
    // messages state - changes trigger renders
    const [messages, setMessages] = useState<Message[]>([]);

    // message queue - internal mutable array for precise entry management
    const messageQueue = useRef<MessageEntry[]>([]);

    const requestRef = useRef<number | undefined>(undefined);

    const checkExpiry = useCallback(() => {
        const now = Date.now();
        const expired: string[] = [];
        let i = messageQueue.current.length;

        // iterate in reverse, oldest entries at end
        while (--i > -1) {
            const { startTime, message } = messageQueue.current[i];
            if (now > startTime + message.duration!) {
                expired.push(message.id!);
            }
        }

        if (expired.length) {
            messageQueue.current = messageQueue.current.filter((entry) => !expired.includes(entry.message.id!));
            setMessages(messageQueue.current.map((entry) => entry.message));
        }

        const messagesRequiringCheck = messageQueue.current.filter((entry) => entry.message.duration !== Infinity);

        if (messagesRequiringCheck.length) {
            requestRef.current = requestAnimationFrame(checkExpiry);
        } else {
            requestRef.current = undefined;
        }
    }, []);

    const addMessage = (window.addSnackbarMessage = useCallback(
        (message: Message) => {
            const id = (message.id = cuid());

            if (message.duration === undefined) {
                message.duration = 3000;
            }

            messageQueue.current.unshift({
                message,
                startTime: Date.now(),
            });

            setMessages(messageQueue.current.map((entry) => entry.message));

            if (requestRef.current === undefined && message.duration !== Infinity) {
                requestRef.current = requestAnimationFrame(checkExpiry);
            }

            return id;
        },
        [checkExpiry]
    ));

    const removeMessage = (window.removeSnackbarMessage = useCallback(
        (id: string) => {
            const entry = messageQueue.current.find((entry) => entry.message.id === id);
            if (entry) {
                entry.message.duration = 0;
            }

            if (requestRef.current === undefined) {
                requestRef.current = requestAnimationFrame(checkExpiry);
            }
        },
        [checkExpiry]
    ));

    // clean up
    useEffect(
        () => () => {
            window.addSnackbarMessage = (message: Message) => "";
            window.removeSnackbarMessage = (id: string) => {};

            if (requestRef.current !== undefined) {
                cancelAnimationFrame(requestRef.current);
            }
        },
        []
    );

    return (
        <SnackbarContext.Provider
            value={{
                messages,
                addMessage,
                removeMessage,
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};
