import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const EditLocationSettingsPageContainer = lazy(() =>
    import("../containers/EditLocationSettingsPageContainer").then((module) => ({
        default: module.EditLocationSettingsPageContainer,
    }))
);

export function LocationSettings() {
    return (
        <Route
            path={"/:region/venue/:location/settings"}
            render={() => (
                <Col span={24} md={24} className="content__col">
                    <div className="content__col-edit">
                        <Route component={withPermissions(EditLocationSettingsPageContainer, "settings:read")} />
                    </div>
                </Col>
            )}
        />
    );
}
