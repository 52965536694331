export enum TypeKeys {
    PREVIEW_SERVICE = "PREVIEW/SERVICE",
}

export const actionCreators = {
    previewService: (serviceId: string, membershipLevelId?: string): PreviewServiceAction => ({
        type: TypeKeys.PREVIEW_SERVICE,
        serviceId,
        membershipLevelId,
    }),
};

export type PreviewServiceAction = { type: TypeKeys.PREVIEW_SERVICE; serviceId: string; membershipLevelId?: string };

export type PreviewAction = PreviewServiceAction;
