import styles from "./HighlightSearchTerm.module.scss";

import { normaliseText } from "common/utility/StringUtils";
import { ReactNode } from "react";

interface Props {
    highlight?: (text: string) => ReactNode;
    noHighlight?: (text: string) => ReactNode;
    noSearchTerm?: (text: string) => ReactNode;
    source: string;
    searchTerm: string;
}

const defaultHighlight = (text: string) => {
    return <span className={styles.highlighted}>{text}</span>;
};

const defaultText = (text: string) => text;

export const HighlightSearchTerm = ({
    highlight = defaultHighlight,
    source = "",
    searchTerm = "",
    noHighlight = defaultText,
    noSearchTerm = defaultText,
}: Props) => {
    if (!searchTerm) {
        return <>{noSearchTerm(source)}</>;
    }

    const normalisedSearchTerm = normaliseText(searchTerm);
    const normalisedString = normaliseText(source);
    const index = normalisedString.indexOf(normalisedSearchTerm);

    if (!normalisedSearchTerm || index === -1) {
        return <>{noHighlight(source)}</>;
    }

    const beforeStr = source.substring(0, index);
    const afterStr = source.substring(index + normalisedSearchTerm.length);

    return (
        <>
            {beforeStr}
            {highlight(source.substring(index, index + normalisedSearchTerm.length))}
            {afterStr}
        </>
    );
};
