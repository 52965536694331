import { createAction } from "features/teams/reducers/list";
import { GraphQLTeamSummary } from "../types";
import { listTeams } from "../api/listTeams";
import { scaffoldListAction } from "common/scaffolding/actions/scaffoldListAction";
import { TeamSummary } from "../types";

export const list = (refresh: boolean = false, silent: boolean = false) =>
    scaffoldListAction<TeamSummary>(
        (state) => state.team.list,
        createAction,
        async () => {
            const teams = await listTeams();
            return teams.map(mapListItem);
        },
        refresh,
        silent
    );

export function mapListItem({
    id,
    dateCreated,
    dateUpdated,
    displayName,
    slug,
    authInfo: { permissions },
}: GraphQLTeamSummary): TeamSummary {
    return {
        id,
        dateCreated,
        dateUpdated,
        displayName,
        slug,
        authInfo: {
            permissions,
        },
    };
}
