import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";
import { MigrationReady } from "./MigrationReady";

const MigrationPage = lazy(() =>
    import("./MigrationPage").then((module) => ({
        default: module.MigrationPage,
    }))
);

const Component = withPermissions(MigrationPage, "location:migration:create");

export function Migration() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_MIGRATION}>
            <Route
                path={"/:region/venue/:location/migration"}
                render={() => (
                    <EditWrapper contentWidth={ContentWidth.NARROW}>
                        <Component />
                    </EditWrapper>
                )}
            />
            <Route path={"/:region/venue/:location/meandu2"} render={(props) => <MigrationReady />} />
        </TestContext.Provider>
    );
}
