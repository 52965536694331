import { State as EditState, reducer as editReducer } from "./edit";
import { State as FiltersState, reducer as filtersReducer } from "./filters";
import { combineReducers } from "redux";

export interface State {
    locationRelations: {
        edit: EditState;
        filters: FiltersState;
    };
}

export const reducer = combineReducers({
    edit: editReducer,
    filters: filtersReducer,
});
