import { AppState } from "features";
import { CatalogueItemType } from "features/catalogue/types/CatalogueItemType";
import { createSelector } from "reselect";
import { PosCatalogueImportItem, PosCatalogueImportSelections } from "../types";
import { getModifierPosCatalogueItems, getProductPosCatalogueImportItems } from "./getPosCatalogueItems";
import { getSelectionInfo } from "./getSelectionInfo";

export const getPosCatalogueItemsAdd = createSelector(
    getProductPosCatalogueImportItems,
    getModifierPosCatalogueItems,
    (_: AppState, selected: PosCatalogueImportSelections) => selected,
    (
        products: PosCatalogueImportItem[],
        modifiers: PosCatalogueImportItem[],
        selected: PosCatalogueImportSelections
    ) => {
        const selectedInfo = getSelectionInfo({ selections: selected, products, modifiers });
        return {
            import_type: "pos",
            nb_products_available: products.length,
            nb_mods_available: modifiers.length,
            nb_products_imported: selectedInfo.products.numParentsSelected,
            nb_mods_imported: selectedInfo.modifiers.numParentsSelected,
        };
    }
);

export const getPosCatalogueExportCSV = createSelector(
    getProductPosCatalogueImportItems,
    getModifierPosCatalogueItems,
    (_: AppState, catType: CatalogueItemType, selected: PosCatalogueImportItem[]) => ({ catType, selected }),
    (products: PosCatalogueImportItem[], modifiers: PosCatalogueImportItem[], param: any) => {
        const itemCount = param.catType === CatalogueItemType.Product ? products.length : modifiers.length;
        return {
            import_type: "pos",
            exported_info: param.catType,
            nb_items_exported: param.selected.length,
            nb_items_available: itemCount,
        };
    }
);
