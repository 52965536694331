import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { PosCatalogueImportPage } from "./PosCatalogueImportPage";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";

const List: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: PosCatalogueImportPage,
    contentWidth: ContentWidth.STANDARD,
    wrapper: EditWrapper,
};

export function PosCatalogueImport() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_POS_CATALOGUE_IMPORT}>
            <ModalCrudRouteContainer name="import" listComponent={List} route="pos/import" />
        </TestContext.Provider>
    );
}
