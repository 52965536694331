import { CategorySummary } from "features/category/types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { TypeKeys as MenuTypeKeys } from "../../menu/reducers/edit";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

enum TypeKeys {
    LOADING = "CATEGORY/LIST_BEGIN",
    LOADED = "CATEGORY/LIST_SUCCESS",
    FAILED = "CATEGORY/LIST_FAILURE",
}

const list = scaffoldList<CategorySummary, typeof TypeKeys, typeof EditTypeKeys | typeof MenuTypeKeys>(
    TypeKeys,
    [EditTypeKeys, MenuTypeKeys],
    sortByDisplayName
);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
