import { AppState } from "features/state";
import { createSelector } from "reselect";
import { getMainMenu } from "./getMainMenu";
import { getNavStructure } from "../api/teamsNavStructure";
import { isConfigEnabled, isDisplayable } from "./menuFilters";
import { NavItemFilter } from "../types/NavItemFilter";
import { NavNode } from "../types/NavMenu";
import { region } from "features/region";

const getState = (state: AppState) => state;

// TODO get teams permissions https://meanduapp.atlassian.net/browse/ROCK-1251
export const getTeamsMainMenu = createSelector(
    getState,
    // getTeamsPermissions,
    getNavStructure,
    (state, /*permissions, */ navStructure: NavNode[]): NavNode[] => {
        const itemFilters: NavItemFilter[] = [/* isPermitted(permissions), */ isDisplayable(state), isConfigEnabled()];

        const routeParams = {
            region: region.getActiveRegion().id,
        };

        return getMainMenu(navStructure, itemFilters, routeParams);
    }
);
