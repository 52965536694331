import styles from "./ActionFooter.module.scss";

import { PropsWithChildren, ReactElement, useContext } from "react";
import { FormikContext } from "formik";
import classNames from "classnames";
import { useTestAttributes } from "../testAttributes";

export type Alignment = "left" | "right";

export type Position = "relative" | "fixed";
interface Props {
    forceDirty?: boolean;
    align?: Alignment;
    position?: Position;
}

const useDirty = (forceDirty?: boolean) => {
    // emits no warning if we are not in FormikProvider (as opposed to useFormikContext())
    const formikContext = useContext(FormikContext);

    if (forceDirty !== undefined) {
        return forceDirty;
    }

    return formikContext?.dirty ?? false;
};

export const ActionFooter = ({
    children,
    forceDirty,
    align = "right",
    position = "relative",
}: PropsWithChildren<Props>): ReactElement => {
    const dirty = useDirty(forceDirty);

    const classes = classNames({
        [styles.container]: position === "relative",
        [styles.containerFixed]: position === "fixed",
        [styles.containerDirty]: dirty,
        [styles.alignLeft]: align === "left",
        [styles.alignRight]: align !== "left",
    });

    return (
        <div className={classes}>
            <div className={styles.innerContainer}>{children}</div>
        </div>
    );
};

interface StatusMessageProps {
    forceDirty?: boolean;
    dirtyMessage?: string;
    cleanMessage?: string;
}

export const StatusMessage = ({
    forceDirty,
    dirtyMessage = "Unsaved changes",
    cleanMessage = "",
}: StatusMessageProps): ReactElement => {
    const dirty = useDirty(forceDirty);

    const { getTestId } = useTestAttributes();

    return (
        <span
            className={styles.message}
            data-testid={getTestId({ fragment: "action-footer", element: "form-status" }, dirty ? "unsaved" : "saved")}
        >
            {dirty ? dirtyMessage : cleanMessage}
        </span>
    );
};
