import styles from "./PageLoading.module.scss";

import { Spinner } from "../spinner";
import classNames from "classnames";

interface Props {
    message?: string;
    background?: "transparent" | "neutral" | "auto";
}

export const PageLoading = ({ message, background = "transparent" }: Props) => {
    const containerClasses = classNames(
        styles.container,
        background !== "transparent" && styles[`background--${background}`]
    );

    return (
        <div className={containerClasses}>
            <Spinner size="large" />
            {message && <p className={styles.message}>{message}</p>}
        </div>
    );
};
