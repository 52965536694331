import { State as SurchargeData, reducer as editReducer } from "./edit";

import { combineReducers } from "redux";

export interface State {
    surcharges: {
        edit: SurchargeData;
    };
}

export const reducer = combineReducers({
    edit: editReducer,
});
