import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import { Payment } from "./components/Payment";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.paymentUser = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Payment key="payment" />]);
}
