import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";
import { AuditLogEntry } from "../types";

enum TypeKeys {
    LOADING = "AUDITLOG/LIST_BEGIN",
    LOADED = "AUDITLOG/LIST_SUCCESS",
    FAILED = "AUDITLOG/LIST_FAILURE",
    RELOAD = "AUDITLOG/LIST_RELOAD",
}
const list = scaffoldList<AuditLogEntry, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
