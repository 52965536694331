import { Loaded } from "common/loader";
import { PosLookup } from "../types/PosLookup";

export enum TypeKeys {
    FETCH_BEGIN = "POS_LOOKUPS/FETCH_DATA_BEGIN",
    FETCH_SUCCESS = "POS_LOOKUPS/FETCH_DATA_SUCCESS",
    FETCH_FAILURE = "POS_LOOKUPS/FETCH_DATA_FAILURE",
    CLEAR = "POS_LOOKUPS/CLEAR",
}

type FetchingAction = { type: TypeKeys.FETCH_BEGIN; key: string };
type FetchedAction = { type: TypeKeys.FETCH_SUCCESS; key: string; data: PosLookup };
type FetchFailedAction = { type: TypeKeys.FETCH_FAILURE; key: string; error: any };
type ClearActon = { type: TypeKeys.CLEAR };

type AllActions = FetchingAction | FetchedAction | FetchFailedAction | ClearActon;

export const createAction = {
    loading: (key: string): FetchingAction => ({ type: TypeKeys.FETCH_BEGIN, key }),
    loaded: (key: string, data: PosLookup): FetchedAction => ({ type: TypeKeys.FETCH_SUCCESS, key, data }),
    loadFailed: (key: string, error: any): FetchFailedAction => ({ type: TypeKeys.FETCH_FAILURE, key, error }),
    clear: () => ({ type: TypeKeys.CLEAR }),
};

export type State = { [lookup: string]: Loaded<PosLookup> };

const initialState: State = {};

function isAction<TAction extends AllActions>(action: AllActions, type: string): action is TAction {
    return action.type === type;
}

export function reducer(state: State = initialState, action: AllActions): State {
    if (isAction<ClearActon>(action, TypeKeys.CLEAR)) {
        return initialState;
    }

    if (isAction<FetchingAction>(action, TypeKeys.FETCH_BEGIN)) {
        return updateLookupState(state, action.key, { status: "loading" });
    }

    if (isAction<FetchedAction>(action, TypeKeys.FETCH_SUCCESS)) {
        return updateLookupState(state, action.key, { status: "loaded", data: action.data });
    }

    if (isAction<FetchFailedAction>(action, TypeKeys.FETCH_FAILURE)) {
        const { error } = action;

        return updateLookupState(state, action.key, { status: "failed", error });
    }

    return state;
}

function updateLookupState(state: State, key: string, loadedLookup: Loaded<PosLookup>): State {
    const newState = {
        ...state,
    };

    newState[key] = loadedLookup;

    return newState;
}
