import styles from "./UserProfileLink.module.scss";

import { auth } from "common/auth";
import { config } from "common/config";
import { Button } from "core/components/button";
import { useLongPress } from "common/hooks/useLongPress";
import { useCallback, useState } from "react";
import { DiagnosticsModal } from "./DiagnosticsModal";
import { ModalRenderer } from "core/components/modal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSupportalLink } from "features/structure/selectors/getLocationMainMenu";
import { Scope, useScopeContext } from "features/scope";
import { TeamsLink } from "./TeamsLink";
import { useActiveScope } from "common/hooks";

export interface Props {
    onLogout: () => void;
}

export const UserProfileLink = () => {
    const user = auth.getState();
    const [diagnosticsVisible, setDiagnosticsVisible] = useState(false);
    const supportalLink = useSelector(getSupportalLink);

    const onLongPress = useCallback(() => {
        setDiagnosticsVisible(true);
    }, []);

    const longPressHandlers = useLongPress({ onLongPress });

    const scope = useScopeContext();

    const activeScope = useActiveScope();

    if (user === null) {
        return null;
    }

    const { VERSION, VITE_ENABLE_DIAGNOSTICS } = config;

    const enableDiagnostics = VITE_ENABLE_DIAGNOSTICS === "1";

    const showTeamsLink = scope !== Scope.TEAMS && activeScope?.userHasTeams;

    return (
        <div className={styles.root}>
            {showTeamsLink && (
                <div className="mb-4">
                    <TeamsLink />
                </div>
            )}
            <div className={styles.name}>
                Signed in as{" "}
                <span className={styles.value} data-testid="user-name">
                    {user.name}
                </span>
            </div>
            <Button
                colorScheme="interactive"
                onClick={() => auth.logout()}
                className={styles.logout}
                data-testid="user-logout"
            >
                Log out
            </Button>
            {supportalLink && (
                <Button
                    as={Link}
                    colorScheme="interactive"
                    to={{ pathname: supportalLink }}
                    target="_blank"
                    className={styles.supportal}
                    data-testid="supportal-link"
                >
                    Supportal
                </Button>
            )}
            <div {...longPressHandlers} className={styles.version}>
                <span className={styles.value}>Version: {VERSION}</span>
            </div>
            {enableDiagnostics && (
                <ModalRenderer target="#modal">
                    <DiagnosticsModal onClose={() => setDiagnosticsVisible(false)} visible={diagnosticsVisible} />
                </ModalRenderer>
            )}
        </div>
    );
};
