import { AppState } from "features";
import { getActiveLocation } from "../../location/selectors/getLocationPermissions";
import { getSectionsData } from "features/service/selectors/getSections";
import { SectionOrderFlowType } from "features/section/types/SectionOrderFlow";

export const showCustomFeedbackFilter = () => (state: AppState) =>
    !getActiveLocation(state)?.yumpingoOptions?.enabled &&
    !!getSectionsData(state)?.some(
        (s) => s.orderFlow === SectionOrderFlowType.PAYONLY || s.orderFlow === SectionOrderFlowType.OPENTABLE
    );
