import {
    generatePath,
    matchPath,
    Route,
    RouteComponentProps,
    StaticContext,
    useRouteMatch,
    withRouter,
} from "react-router";
import { Col, Drawer, DrawerProps } from "antd";
import { useMedia, useScopeBasePath } from "common/hooks";
import { isNew } from "../../utility/modelUtils";
import { ContentWidth } from "../types/ContentWidth";
import { PropsWithChildren } from "react";
import { useTestAttributes } from "core/components/testAttributes";
import { MigratedHidden } from "features/migration/components/MigratedHidden";

export interface EditWrapperProps {
    onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
    className?: string;
    contentWidth?: ContentWidth;
    visible?: boolean;
}

export interface EditComponent {
    route: string;
    component: React.ComponentType<RouteComponentProps<any> | any> | React.ComponentType<any>;
    editTitle?: string;
    createTitle?: string;
    contentWidth?: ContentWidth;
    wrapper?: React.ComponentType<EditWrapperProps>;
}

export interface ListWrapperProps {
    name: string;
    contentWidth?: ContentWidth;
    visible?: boolean;
}

export interface ListComponent {
    route?: string;
    component: React.ComponentType<RouteComponentProps<any> | any> | React.ComponentType<any>;
    contentWidth?: ContentWidth;
    wrapper?: React.ComponentType<ListWrapperProps>;
}

export interface Props {
    name: string;
    route: string; // feature route e.g. "menu/catalogue"
    listComponent: ListComponent;
    editComponents?: EditComponent[];
    listWidth?: number;
    editWidth?: number;
    showBackLink?: boolean;
}

export const ModalCrudRoute = ({
    name,
    listComponent,
    editComponents,
    history,
    location,
    route,
}: Props & RouteComponentProps<any, StaticContext, any>) => {
    const ListComponent = listComponent.component;

    const ListWrapper = listComponent.wrapper || LegacyListWrapper;

    const basePath = useScopeBasePath();

    const pathPattern = [basePath, route, listComponent.route].filter(Boolean).join("/");

    const onCancel = (props: RouteComponentProps<any, StaticContext, any>) => {
        history.replace(generatePath(pathPattern, matchPath(location.pathname, { path: pathPattern })!.params));
    };

    return (
        <>
            <Route
                path={pathPattern}
                render={(props) => (
                    <MigratedHidden>
                        <>
                            <ListWrapper name={name} contentWidth={listComponent.contentWidth}>
                                <ListComponent {...props} />
                            </ListWrapper>
                            {editComponents &&
                                editComponents.map((edit, i) => (
                                    <EditRoute
                                        name={name}
                                        key={`${listComponent.route}-${edit.route}`}
                                        edit={edit}
                                        route={route}
                                        onCancel={onCancel}
                                        props={props}
                                        basePath={basePath}
                                    />
                                ))}
                        </>
                    </MigratedHidden>
                )}
            />
        </>
    );
};

export const ModalCrudRouteContainer = withRouter(ModalCrudRoute);

interface EditRouteProps {
    name: string;
    edit: EditComponent;
    route: string;
    onCancel: (props: RouteComponentProps<any, StaticContext, any>) => void;
    props: RouteComponentProps<any, StaticContext, any>;
    basePath: string;
}

export const EditRoute = ({ name, edit, route, onCancel, props, basePath }: EditRouteProps) => {
    const Component = edit.component;
    const Wrapper = edit.wrapper || Drawer;
    const pathPattern = [basePath, route, edit.route].filter(Boolean).join("/");
    const match = useRouteMatch<{ id: string }>(pathPattern);
    const isActive = !!match;
    const large = useMedia("(min-width: 896px)");
    const { getTestId } = useTestAttributes();

    const dynamicProps: DrawerProps | EditWrapperProps = edit.wrapper
        ? {
              contentWidth: edit.contentWidth,
              visible: isActive,
          }
        : ({
              title: isNew(match?.params.id) ? edit.createTitle : edit.editTitle,
              placement: "right",
              width: large ? 680 : "100%",
              open: isActive,
              "data-testid": getTestId({ name: "edit-page" }),
          } as DrawerProps);

    return (
        <Wrapper onClose={() => onCancel(props)} className={`edit-drawer edit-drawer--${name}`} {...dynamicProps}>
            <Route
                exact
                path={pathPattern}
                render={(props) => <Component key={props.match.params.id} onClose={() => onCancel(props)} {...props} />}
            />
        </Wrapper>
    );
};

interface LegacyListWrapperProps {
    name: string;
}

export const LegacyListWrapper = ({ children, name }: PropsWithChildren<LegacyListWrapperProps>) => {
    const { getTestId } = useTestAttributes();
    return (
        <Col span={24} md={24} className={`content__col content__col--${name} content__col--white`}>
            <div className="content__col-list-white" data-testid={getTestId({ name: "list-page" })}>
                {children}
            </div>
        </Col>
    );
};
