import timeago from "timeago.js";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { Button } from "core/components/button/Button";
import { FileTextOutlined } from "@ant-design/icons";
import { menuDataUrl } from "common/menuData/menuDataUrl";
import { getTestId as getAppHeaderTestId } from "../../CmsHeader";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";
import { getPublishing } from "../selectors/getPublishing";
import { useAppDispatch } from "common/hooks/useAppDispatch";
import { publish } from "features/location/actions/publish";
import { useCallback } from "react";
import { getIsPublishDisabled } from "features/location/selectors/getPublishDisabled";

export const PublishButton = () => {
    const dispatch = useAppDispatch();

    const activeLocation = useSelector(getActiveLocation);

    const { lastPublished, lastPublishedUrl, slug } = activeLocation || {};

    const publishing = useSelector(getPublishing);

    const disabled = useSelector(getIsPublishDisabled);

    const permissions = useSelector(getLocationPermissions);

    const visible = permissions.hasAny("menudata:publish");

    const canViewMenuData = permissions.hasAll("menudata:raw:read");

    const handleClick = useCallback(() => {
        if (!slug) {
            return;
        }
        dispatch(publish(slug));
    }, [dispatch, slug]);

    if (!visible) {
        return null;
    }

    return (
        <>
            {canViewMenuData && (
                <div className="hide-until-medium">
                    <Button
                        disabled={!lastPublishedUrl}
                        padding="icon"
                        role="secondary"
                        shape="button"
                        href={lastPublishedUrl ? menuDataUrl(lastPublishedUrl) : null}
                        as="a"
                        target="_blank"
                        data-testid={getAppHeaderTestId({ name: "menu-data", element: "button" })}
                    >
                        <FileTextOutlined />
                    </Button>
                </div>
            )}
            <Tooltip
                content={lastPublished ? `Last published ${timeago().format(lastPublished)}` : `Not published`}
                placement="top-end"
            >
                <Button
                    loading={publishing}
                    onClick={handleClick}
                    disabled={disabled}
                    data-testid={getAppHeaderTestId({ name: "publish", element: "button" })}
                >
                    Publish
                </Button>
            </Tooltip>
        </>
    );
};
