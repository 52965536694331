import { opsApi } from "API";
import { ParentLocationUpdateStatus } from "..";

export const fetchLocationUpdateStatus = async (slug: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId: slug });
    return res.data.location;
};

type GraphQLPayload = { updateStatus: ParentLocationUpdateStatus };

export const parentLocationUpdateStatusFields = `
    parentLocationUpdateStatus {
        lastMenuUpdateUtc,
        lastParentImportUtc,
        hasBeenSynced,
        updatesAvailable,
    },
`;

const query = `
    query ($locationId: ID!) {
        location(locationId: $locationId) {
            ${parentLocationUpdateStatusFields}
        }
    }
`;
