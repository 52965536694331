import { withSvg } from "core/components/svg/Svg";

export const CheckMark = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M18.6905 7.52677C19.09 7.90811 19.1047 8.5411 18.7234 8.9406L10.8484 17.1906C10.6624 17.3854 10.4059 17.4969 10.1366 17.5001C9.8674 17.5032 9.60828 17.3976 9.41789 17.2072L5.29289 13.0822C4.90237 12.6917 4.90237 12.0585 5.29289 11.668C5.68342 11.2775 6.31658 11.2775 6.70711 11.668L10.1084 15.0693L17.2766 7.55965C17.658 7.16015 18.291 7.14543 18.6905 7.52677Z" />
    </>
));
