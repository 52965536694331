import { Scope, useScopeContext } from "features/scope";
import { GROUP_PATH, LOCATION_PATH, TEAMS_PATH } from "features/scope/types/ScopePathParameter";
import invariant from "warn-once";

/**
 * Get base path for the current scope; either a group or location or teams.
 *
 * This is a `pathToRegexp` pattern that can be used by react router, not an actual path.
 *
 * Only available within `<ScopeContext.Provider>`
 *
 * @returns `string`
 */
export function useScopeBasePath() {
    const scope = useScopeContext();

    invariant(
        !scope,
        "ScopeContext is undefined, verify the component you are calling useScopeBasePath() from is within <ScopeContext.Provider>"
    );

    if (scope === Scope.GROUP) {
        return `/:region/${GROUP_PATH}/:id`;
    }

    if (scope === Scope.TEAMS) {
        return `/:region/${TEAMS_PATH}`;
    }

    return `/:region/${LOCATION_PATH}/:location`;
}
