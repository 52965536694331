import styles from "./Alert.module.scss";

import {
    isValidElement,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import { CloseButton } from "../closeButton";
import classNames from "classnames";
import { Icon } from "../icon";
import { InfoSolid, WarningSolid } from "common/icons";
import { CheckedSolid } from "common/icons/CheckedSolid";
import { useAccordion } from "common/hooks";

interface Props {
    className?: string;
    closeable?: boolean;
    icon?: ReactNode;
    onClose?: (e: MouseEvent<HTMLButtonElement>) => boolean | void;
    onClosed?: () => void;
    removeOnClosed?: boolean;
    status?: AlertStatus;
    alertStyle?: "default" | "strong";
    title?: string;
}

export enum AlertStatus {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
}

const svgs = {
    [AlertStatus.INFO]: <InfoSolid />,
    [AlertStatus.SUCCESS]: <CheckedSolid />,
    [AlertStatus.WARNING]: <WarningSolid />,
};

export const Content = ({ children }: PropsWithChildren<{}>) => {
    if (!children) {
        return null;
    }

    if (isValidElement(children)) {
        return children;
    }

    return <p className="text-reset">{children}</p>;
};

interface TitleProps {
    title: ReactNode;
    layout: "standard" | "slim";
}

export const Title = ({ title, layout }: TitleProps) => {
    if (!title) {
        return null;
    }

    if (isValidElement(title)) {
        return title;
    }

    return <h2 className={layout === "standard" ? styles.titleLarge : styles.titleSmall}>{title}</h2>;
};

export const Alert = ({
    children,
    className,
    closeable,
    icon,
    onClose,
    onClosed,
    removeOnClosed = true,
    status = AlertStatus.INFO,
    alertStyle = "default",
    title,
}: PropsWithChildren<Props>) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [removed, setRemoved] = useState(false);

    const onCollapsed = useCallback(() => {
        onClosed?.();
        if (removeOnClosed) {
            setRemoved(true);
        }
    }, [onClosed, removeOnClosed]);

    const options = useMemo(
        () => ({
            startExpanded: true,
            onCollapseEnd: onCollapsed,
        }),
        [onCollapsed]
    );

    const { expanded, setExpanded, contentStyle } = useAccordion(contentRef, options);

    const handleClose = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            const proceed = onClose?.(e);
            if (proceed !== false) {
                setExpanded(false);
            }
        },
        [onClose, setExpanded]
    );

    const layout = title && children ? "standard" : "slim";

    const hasChildren = isValidElement(children) || !!children;

    if (removed) {
        return null;
    }

    return (
        <div className={styles.root} ref={contentRef} style={contentStyle} aria-hidden={!expanded}>
            <article className={classNames(styles[`${alertStyle}--${status}`], className)}>
                <div className={classNames(styles.container, layout === "slim" && styles.noChildren)}>
                    <Icon
                        verticalAlign="middle"
                        className={styles.icon}
                        size={layout === "standard" ? "extraLarge" : "small"}
                    >
                        {icon || svgs[status]}
                    </Icon>
                    <div className={styles.header}>
                        {title && <Title title={title} layout={layout} />}
                        {!title && hasChildren && <Content>{children}</Content>}
                        {!title && !hasChildren && <span />}
                        {closeable && (
                            <CloseButton className={styles.closeButton} iconSize="large" onClick={handleClose} />
                        )}
                    </div>
                    {title && hasChildren && (
                        <div className={styles.body}>
                            <Content>{children}</Content>
                        </div>
                    )}
                </div>
            </article>
        </div>
    );
};
