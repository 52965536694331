import styles from "./Snackbar.module.scss";

import { CheckedSolid } from "common/icons/CheckedSolid";
import { Icon } from "../icon";
import { InfoSolid, WarningSolid } from "common/icons";
import { isValidElement, PropsWithChildren, ReactNode } from "react";
import { Message, Status } from "./types/Message";
import { CloseCircle } from "common/icons/CloseCircle";
import { Spinner } from "../spinner";
import { motion } from "framer-motion";

interface Props {
    message: Message;
    icon?: ReactNode;
}

const svgs = {
    [Status.INFO]: <InfoSolid />,
    [Status.SUCCESS]: <CheckedSolid />,
    [Status.WARNING]: <WarningSolid />,
    [Status.ERROR]: <CloseCircle />,
    [Status.LOADING]: <Spinner />,
};

export const Content = ({ children }: PropsWithChildren<{}>) => {
    if (!children) {
        return null;
    }

    if (isValidElement(children)) {
        return children;
    }

    return <span>{children}</span>;
};

export const Snackbar = ({ message }: Props) => {
    return (
        <motion.li
            className={styles.container}
            key={message.id}
            layout
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
        >
            <Icon verticalAlign="middle" className={styles[`icon--${message.status}`]} size={"small"}>
                {message.icon || svgs[message.status]}
            </Icon>
            <div className={styles.body}>
                <Content>{message.content}</Content>
            </div>
        </motion.li>
    );
};
