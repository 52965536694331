import { config } from "common/config";
import { AnalyticsLayoutConfig } from "features/analytics/types/AnalyticsConfig";
import { StatBar } from "common/icons";
import { NavGroup } from "../types/NavMenu";

export function getAnalyticsNavGroup(analyticsLayoutConfig: AnalyticsLayoutConfig | undefined) {
    return analyticsLayoutConfig && config.VITE_ENABLE_ANALYTICS_LAYOUT_CONFIG === "1"
        ? mapAnalyticsLayoutConfig(analyticsLayoutConfig)
        : undefined;
}

function mapAnalyticsLayoutConfig(analyticsLayoutConfig: AnalyticsLayoutConfig): NavGroup {
    return {
        text: "Analytics",
        testId: "analytics",
        navItemGroups: analyticsLayoutConfig.folders.map((folder) => ({
            text: folder.displayName,
            icon: StatBar,
            testId: folder.slug,
            children: folder.pages
                .filter((p) => p.tabs.length > 0)
                .map((page) => ({
                    text: page.displayName,
                    permission: page.tabs[0].permission,
                    route: `analytics/${folder.slug}/${page.slug}`,
                    testId: page.slug,
                })),
        })),
    };
}
