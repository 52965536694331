import { createAction } from "../reducers/active";
import { AppState, AppDispatch } from "features/state";
import { fetchGroup } from "../api/fetchGroup";
import { fetchDefaultGroup } from "../api/fetchDefaultGroup";
// import { setAppInsightsUserContext } from "common/appInsights";

export const fetchActive =
    (id: string | undefined, reload: boolean = false) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const {
                groups: { active },
            } = getState();

            if (active.status !== "unloaded" && (active.key === id || !id) && !reload) {
                return;
            }

            if (reload || active.status === "unloaded") {
                dispatch(createAction.begin(id));
            }

            const activeGroup = id ? await fetchGroup(id) : await fetchDefaultGroup();

            const portalTitle = "me&u Operator Portal";
            document.title = activeGroup ? `${activeGroup.displayName} - ${portalTitle}` : portalTitle;

            dispatch(createAction.success(activeGroup));

            // TODO app insights for groups https://meanduapp.atlassian.net/browse/ROCK-920
            // setAppInsightsUserContext(activeGroup, getState);
        } catch (e) {
            console.error("fetchActiveGroup", e);
            dispatch(createAction.failure(id, e));
        }
    };
