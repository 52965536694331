import { opsApi } from "API";
import { PosCatalogueUploadInput } from "../types";
import { UploadResult } from "../types/UploadResult";

export const uploadPosCatalogue = async (
    locationId: string,
    catalogue: PosCatalogueUploadInput,
    preview: boolean = false
) => {
    const res = await opsApi.graphQLQuery<{ uploadResult: UploadResult }>(query, { locationId, catalogue, preview });

    return res.data.uploadPosCatalogue;
};

// TODO update when API ready
// https://meanduapp.atlassian.net/browse/ROCK-11
const query = `
mutation($locationId: ID!, $catalogue: PosCatalogueUploadInput!, $preview:Boolean! ) {
  uploadPosCatalogue(locationId: $locationId, catalogue: $catalogue, preview: $preview) {
    summary {
      products {
        result
        count
      }
      variants {
        result 
        count
      }
      modifierGroups {
        result
        count
      }
      modifierOptions {
        result
        count
      }
      productsMissingContent
      modifierGroupsMissingContent
    }
    errors {
      sourceId
      message
      objectType
      level
    }
    startedRunning
  }
}
`;
