import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ListPromotionsPageContainer = lazy(() =>
    import("../containers/ListPromotionsPageContainer").then((module) => ({
        default: module.ListPromotionsPageContainer,
    }))
);

const EditPromotionPageContainer = lazy(() =>
    import("../containers/EditPromotionPageContainer").then((module) => ({
        default: module.EditPromotionPageContainer,
    }))
);

const List: ListComponent = {
    route: "",
    component: withPermissions(ListPromotionsPageContainer, "promotions:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

const Edit: EditComponent = {
    route: ":id",
    editTitle: "Edit promotion",
    createTitle: "Add promotion",
    component: withPermissions(EditPromotionPageContainer, "promotions:update"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ModalEditWrapper,
};

export function Promotions() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_PROMOTIONS}>
            <ModalCrudRouteContainer
                name="promotions"
                listComponent={List}
                editComponents={[Edit]}
                listWidth={22}
                route="promotions"
            />
        </TestContext.Provider>
    );
}
