import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import { EmptySvg } from "../card/EmptySvg";
import styles from "./PageError.module.scss";

interface BaseProps {
    heading?: string;
    message?: string;
}

interface ActionsProps extends BaseProps {
    actions?: ReactNode;
}

interface DefaultActionProps extends BaseProps {
    defaultActionText: string;
    defaultActionTo: string;
    defaultActionClick: () => void;
}

export const PageError = (props: ActionsProps | DefaultActionProps) => {
    const {
        heading = "Oh no! failed to connect",
        message = "There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later.",
    } = props;
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <EmptySvg />
            </div>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.message}>{message}</p>
            {props["defaultActionClick"] ? (
                <>
                    <Button as={Link} to={props["defaultActionTo"]} role="secondary">
                        {props["defaultActionText"]}
                    </Button>
                    <Button onClick={props["defaultActionClick"]}>Retry</Button>
                </>
            ) : (
                props["actions"] && <div className={styles.actions}>{props["actions"]}</div>
            )}
        </div>
    );
};
