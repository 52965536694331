import { AppState } from "features";
import { Loaded } from "common/loader";
import { Dispatch, Action } from "redux";

type ListData<T> = T[];

type ListState<TData> = Loaded<ListData<TData>>;

export interface ListActionCreators<T> {
    loading(): Action;

    loaded(data: ListData<T>): Action;

    loadFailed(error: any): Action;
}

export function scaffoldListAction<TData>(
    listSelector: (state: AppState) => ListState<TData>,
    actionCreators: ListActionCreators<TData>,
    listAction: (state: AppState) => Promise<ListData<TData>>,
    refresh: boolean = false,
    silent: boolean = false
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const listState = listSelector(getState());
        if ((listState.status === "loaded" && !refresh) || listState.status === "loading") {
            return;
        }

        try {
            if (!silent) {
                dispatch(actionCreators.loading());
            }

            const loadedData = await listAction(getState());

            dispatch(actionCreators.loaded(loadedData));
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.error(e);
            }
            dispatch(actionCreators.loadFailed(e));
        }
    };
}
