import { Folder, Page } from "../types/AnalyticsConfig";
import { lazy } from "react";
import { PlainWrapper } from "common/scaffolding/components/PlainWrapper";
import { Redirect, Switch } from "react-router-dom";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { useActiveScope, useScopeBasePath } from "common/hooks";

const AnalyticsPage = lazy(() => import("./AnalyticsPage").then((module) => ({ default: module.AnalyticsPage })));

export function Analytics() {
    const basePath = useScopeBasePath();

    const activeScope = useActiveScope();

    const analyticsLayoutConfig = activeScope?.analyticsLayoutConfig;

    const firstFolder = analyticsLayoutConfig?.folders[0];

    const firstPage = firstFolder?.pages[0];

    const defaultPage = getDefaultAnalyticsPage(firstFolder, firstPage);

    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_ANALYTICS}>
            <Switch>
                <Route path={`${basePath}/analytics/:folderSlug/:pageSlug`} component={AnalyticsPageLayout} />
                <Redirect from={`${basePath}/analytics`} to={`${basePath}/analytics/${defaultPage}`} />
            </Switch>
        </TestContext.Provider>
    );
}

function getDefaultAnalyticsPage(firstFolder: Folder | undefined, firstPage: Page | undefined) {
    if (!firstFolder) {
        return "";
    }

    if (!firstPage) {
        return firstFolder.slug;
    }

    return `${firstFolder.slug}/${firstPage.slug}`;
}

function AnalyticsPageLayout() {
    return (
        <PlainWrapper name="analytics">
            <AnalyticsPage />
        </PlainWrapper>
    );
}
