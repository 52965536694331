import { useState, useEffect, MutableRefObject, useCallback } from "react";
import { usePrevious } from ".";

type ContentStyle = {
    height?: number;
    transition?: string;
};

interface Options {
    startExpanded?: boolean;
    onCollapseEnd?: () => void;
    onExpandEnd?: () => void;
}

export const useAccordion = (contentRef: MutableRefObject<HTMLElement | null>, options?: Options) => {
    const startExpanded = options?.startExpanded || false;

    const [expanded, setExpanded] = useState(startExpanded);
    const [contentStyle, setContentStyle] = useState<ContentStyle>(!startExpanded ? { height: 0 } : {});
    const prevExpanded = usePrevious(expanded);

    const updateContentStyle = useCallback(() => {
        const el = contentRef.current;
        if (el) {
            setContentStyle({
                height: el.scrollHeight,
            });
        }
    }, [contentRef]);

    const handleCollapseEnd = useCallback(() => {
        options?.onCollapseEnd?.();
    }, [options]);

    const handleExpandEnd = useCallback(() => {
        contentRef.current?.style.removeProperty("height");
        options?.onExpandEnd?.();
    }, [options, contentRef]);

    // expand/collapse effect
    useEffect(() => {
        const el = contentRef.current;

        if (!el) {
            return;
        }

        if (!expanded && prevExpanded) {
            // collapse

            // store content height before collapse
            const contentHeight = el.scrollHeight;
            const elemTransition = el.style.transition;

            setContentStyle({ transition: "" });

            //  @see https://css-tricks.com/using-css-transitions-auto-dimensions/
            requestAnimationFrame(() => {
                setContentStyle({
                    height: contentHeight,
                    transition: elemTransition,
                });

                el.removeEventListener("transitionend", handleCollapseEnd);
                el.removeEventListener("transitionend", handleExpandEnd);
                el.addEventListener("transitionend", handleCollapseEnd, { once: true });

                requestAnimationFrame(() => {
                    setContentStyle({ height: 0 });
                });
            });
        } else if (expanded && prevExpanded === false) {
            // expand
            setContentStyle({
                height: el.scrollHeight,
            });

            el.removeEventListener("transitionend", handleCollapseEnd);
            el.removeEventListener("transitionend", handleExpandEnd);
            el.addEventListener("transitionend", handleExpandEnd, { once: true });
        }
    }, [expanded, prevExpanded, contentRef, setContentStyle, handleCollapseEnd, handleExpandEnd]);

    return { expanded, setExpanded, contentStyle, updateContentStyle };
};
