import { KeyedLoaded } from "common/loader";
import { ActiveGroup } from "../types/ActiveGroup";
import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { Role } from "features/fakeRoles/types/RolesType";

export type State = KeyedLoaded<ActiveGroup, string | undefined>;

export enum TypeKeys {
    BEGIN = "GROUP/FETCH_ACTIVE_BEGIN",
    SUCCESS = "GROUP/FETCH_ACTIVE_SUCCESS",
    OVERRIDE_PERMISSION = "GROUP/OVERRIDE_PERMISSION",
    FAILURE = "GROUP/FETCH_ACTIVE_FAILURE",
}

export const createAction = {
    begin: (slug: string | undefined) => ({ type: TypeKeys.BEGIN, slug }),
    success: (data: ActiveGroup, trackingData?: TrackEventData) => ({ type: TypeKeys.SUCCESS, data, trackingData }),
    overridePermission: (role?: Role) => ({ type: TypeKeys.OVERRIDE_PERMISSION, role }),
    failure: (slug: string | undefined, error: any) => ({ type: TypeKeys.FAILURE, error, slug }),
};

type FetchActiveBeginAction = { type: TypeKeys.BEGIN; slug: string | undefined };
type FetchActiveSuccessAction = { type: TypeKeys.SUCCESS; data: ActiveGroup };
type FetchActiveFailureAction = { type: TypeKeys.FAILURE; error: any; slug: string | undefined };
type OverridePermissionAction = { type: TypeKeys.OVERRIDE_PERMISSION; role?: Role };

type FetchActiveAction =
    | FetchActiveBeginAction
    | FetchActiveSuccessAction
    | FetchActiveFailureAction
    | OverridePermissionAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: FetchActiveAction): State {
    if (action.type === TypeKeys.BEGIN) {
        return {
            status: "loading",
            key: action.slug,
        };
    }

    if (action.type === TypeKeys.SUCCESS && state.status !== "unloaded") {
        if (!state.key || state.key === action.data.id || state.key === action.data.id) {
            return {
                status: "loaded",
                key: action.data?.id,
                data: action.data,
            };
        }
    }

    if (action.type === TypeKeys.FAILURE && state.status !== "unloaded") {
        if (!state.key || state.key === action.slug) {
            return {
                ...state,
                status: "failed",
                error: action.error,
            };
        }
    }

    if (action.type === TypeKeys.OVERRIDE_PERMISSION && state.status === "loaded") {
        if (action.role || state.data.origAuthInfo) {
            const origAuthInfo = action.role ? state.data.origAuthInfo ?? state.data.authInfo : undefined;
            return {
                ...state,
                data: {
                    ...state.data,
                    origAuthInfo,
                    authInfo: !action.role
                        ? state.data.origAuthInfo!
                        : {
                              userRoles: [action.role.shortName],
                              permissions: action.role.permissions,
                          },
                },
            };
        }
    }

    return state;
}
