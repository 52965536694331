import { connect } from "react-redux";

import { AppState } from "features";
import { Props, PublishErrors } from "../components/PublishErrors";
import { DataProps } from "types/utils";
import { getPublishDisplayErrors } from "features/location/selectors/publishResultSelectors";
import { RouteComponentProps, withRouter } from "react-router";
import { LocationRouteParams } from "common/types";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<LocationRouteParams & { region: string }>
): DataProps<Props> => {
    const errors = getPublishDisplayErrors(state);

    return {
        region: params.region,
        location: params.location,
        visible: errors.length > 0,
        errors,
    };
};

export default withRouter(connect(mapStateToProps)(PublishErrors));
