import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";

export const getActiveLocationCompanyDetails = createSelector(
    getActiveLocation,
    (activeLocation) => activeLocation?.companyDetails
);

export const getActiveLocationPrivacyPolicy = createSelector(
    getActiveLocationCompanyDetails,
    (companyDetails) => companyDetails?.privacyPolicyUrl
);
