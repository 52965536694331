import { EditPriceList, ListPriceLists } from "./components/PriceList";
import { FeatureConfiguration } from "..";
import { reducer } from "features/priceList/reducers";
import * as actions from "features/priceList/actions";

export * from "features/priceList/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.priceLists = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ListPriceLists key="pricelists-list" />,
        <EditPriceList key="pricelists-edit" />,
    ]);
}
