import range from "lodash/range";

export interface PaginationOptions {
    pageSize: number;
    pageIndex: number;
    count: number;
    buffer: number;
}

export interface PaginationMeta {
    items: number[];
    prevEnabled: boolean;
    nextEnabled: boolean;
    numPages: number;
    start: number;
    end: number;
}

export type PaginationItem = number | "gapBefore" | "gapAfter";

function getItems(pageIndex: number, numPages: number, buffer: number): any[] {
    const maxItems = buffer * 2 + 1;

    // easy case numPages < max
    if (numPages <= maxItems) {
        return range(1, numPages + 1);
    }

    let start = pageIndex - buffer;
    let end = pageIndex + buffer;

    // keep items within bounds
    if (start < 1) {
        start = 1;
        end = start + (maxItems - 1);
    }

    if (end >= numPages) {
        end = numPages;
        start = end - (maxItems - 1);
    }

    // create items
    let items: PaginationItem[] = range(start, end + 1);

    // also add first/last if they are not in items
    // null used as placeholder for ellipsis indicator
    if (start > 1) {
        if (start > 2) items.unshift("gapBefore");
        items.unshift(1);
    }

    if (end < numPages) {
        if (end < numPages - 1) items.push("gapAfter");
        items.push(numPages);
    }

    return items;
}

export function usePagination({ pageSize, pageIndex, count, buffer }: PaginationOptions): PaginationMeta {
    const numPages = Math.ceil(count / pageSize);

    return {
        prevEnabled: pageIndex > 1,
        items: getItems(pageIndex, numPages, buffer),
        nextEnabled: pageIndex < numPages,
        numPages,
        start: !count ? 0 : (pageIndex - 1) * pageSize + 1,
        end: !count ? 0 : Math.min(count, (pageIndex - 1) * pageSize + pageSize),
    };
}
