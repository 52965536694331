import { useAppDispatch } from "./useAppDispatch";
import { useSelector } from "react-redux";
import { AppState } from "../../features";
import { useCallback } from "react";
import { isLoaded } from "../loader/isLoaded";
import { useLoadStatus } from "./useLoadStatus";
import { actions } from "features/group";

export const useFetchGroup = (slugOrId: string) => {
    const dispatch = useAppDispatch();
    const group = useSelector((state: AppState) => state.groups.active);

    const fetch = useCallback(() => {
        if (!isLoaded(group)) {
            dispatch(actions.fetchActive(slugOrId));
        }
    }, [dispatch, slugOrId, group]);

    const fetchStatus = useLoadStatus([group.status], fetch);

    return {
        isLoading: fetchStatus === "loading" || fetchStatus === "unloaded",
        hasErrored: fetchStatus === "failed",
        retry: fetch,
    };
};
