import { AuditLog } from "./components/AuditLog";
import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { TeamAuditLog } from "./components/TeamAuditLog";

export { actions };
export * from "./types";

export default function (config: FeatureConfiguration) {
    config.reducers.auditlog = reducer;

    config.addRoutes(config.routes.locationRoutes, [<AuditLog key="auditlog" />]);
    config.addRoutes(config.routes.teamsRoutes, [<TeamAuditLog key="auditlog" />]);
}
