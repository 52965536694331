import { ComponentType, FC, ReactNode } from "react";
import { Permissions } from "common/permissions/components/Permissions";

export const withPermissions =
    <P extends {}>(
        Component: ComponentType<P>,
        requiredPermissions: string | string[],
        errorComponent?: ReactNode
    ): FC<P> =>
    (props: P) =>
        (
            <Permissions>
                {(permissions) =>
                    permissions.hasAll(...([] as string[]).concat(requiredPermissions)) ? (
                        <Component {...props} />
                    ) : (
                        <PermissionsError component={errorComponent} />
                    )
                }
            </Permissions>
        );

interface Props {
    component: ReactNode;
}

export const PermissionsError = ({ component }: Props) => {
    if (component) {
        return <>{component}</>;
    }

    return <p>Access denied</p>;
};
