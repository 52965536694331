import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalCrudRouteContainer, ListComponent, EditComponent } from "common/scaffolding/components/ModalCrudRoute";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ListMembershipsPage = lazy(() =>
    import("./ListMembershipsPage").then((module) => ({
        default: module.ListMembershipsPage,
    }))
);

const EditMembershipPage = lazy(() =>
    import("./EditMembershipPage").then((module) => ({
        default: module.EditMembershipPage,
    }))
);

const List: ListComponent = {
    route: "",
    component: withPermissions(ListMembershipsPage, "membership:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

const Edit: EditComponent = {
    route: ":id",
    component: withPermissions(EditMembershipPage, "membership:update"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ModalEditWrapper,
};

export function Memberships() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_MEMBERSHIP}>
            <ModalCrudRouteContainer
                name="membership"
                listComponent={List}
                editComponents={[Edit]}
                listWidth={22}
                route="membership"
            />
        </TestContext.Provider>
    );
}
