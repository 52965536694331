import { FeatureConfiguration } from "features";
import { QuickSell } from "./components/QuickSell";
import { reducer } from "features/quickSell/reducers";
import * as actions from "features/quickSell/actions";

export { actions };
export * from "features/quickSell/types";

export default function (config: FeatureConfiguration) {
    config.reducers.quickSell = reducer;

    config.addRoutes(config.routes.locationRoutes, [<QuickSell key="quicksell" />]);
}
