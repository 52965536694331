import { AppState } from "features/state";
import { createSelector } from "reselect";
import { getActiveGroupData, getGroupPermissions } from "features/group/selectors";
import { getAnalyticsNavGroup } from "./getAnalyticsNavGroup";
import { getMainMenu } from "./getMainMenu";
import { getNavStructure } from "../api/groupNavStructure";
import { isConfigEnabled, isDisplayable, isPermitted } from "./menuFilters";
import { NavItemFilter } from "../types/NavItemFilter";
import { NavNode } from "../types/NavMenu";
import { region } from "features/region";

const getState = (state: AppState) => state;

export const getGroupMainMenu = createSelector(
    getState,
    getActiveGroupData,
    getGroupPermissions,
    getNavStructure,
    (state, group, permissions, navStructure: NavNode[]): NavNode[] => {
        if (!group) {
            return [];
        }

        const itemFilters: NavItemFilter[] = [isPermitted(permissions), isDisplayable(state), isConfigEnabled()];

        const routeParams = {
            id: group.id,
            region: region.getActiveRegion().id,
        };

        const analyticsNavGroup = getAnalyticsNavGroup(group?.analyticsLayoutConfig);

        return getMainMenu(
            analyticsNavGroup ? [...navStructure, analyticsNavGroup] : navStructure,
            itemFilters,
            routeParams
        );
    }
);
