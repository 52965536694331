import { PropsWithChildren, ReactElement } from "react";
import { Props as ButtonProps } from "core/components/button";
import { SubmitMode } from "./CrudActionFooter";
import { Duplicate } from "common/icons";
import { ActionButton } from ".";
import { useTestAttributes } from "../testAttributes";

interface Props {
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    saving: boolean;
    submitMode: SubmitMode;
}

export const DuplicateButton = ({ buttonProps, saving, submitMode }: Props): ReactElement => {
    const { getTestId } = useTestAttributes();

    return (
        <ActionButton
            buttonProps={{
                ...buttonProps,
                "data-testid": getTestId({ fragment: "action-footer", element: "button", action: "duplicate" }),
            }}
            icon={<Duplicate />}
            iconProps={{ outline: true }}
            label="Duplicate"
            role="secondary"
            saving={saving}
            submitMode={submitMode}
            targetSubmitMode="clone"
        />
    );
};
