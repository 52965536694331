import { withSvg } from "core/components/svg/Svg";

export const PromoCode = withSvg(
    24,
    24
)(() => (
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.125 14.4L9.6 22.875L22.875 9.6L22.35 1.65L14.4 1.125L1.125 14.4ZM19.5 6C19.5 6.82843 18.8284 7.5 18 7.5C17.1716 7.5 16.5 6.82843 16.5 6C16.5 5.17157 17.1716 4.5 18 4.5C18.8284 4.5 19.5 5.17157 19.5 6Z"
    />
));
