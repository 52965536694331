import { useRef } from "react";

type CallbackFunc = () => void;

export function useTimer(): {
    setTimer: (fn: CallbackFunc, delay: number) => void;
    cancelTimer: CallbackFunc;
} {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const cancelTimer = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const setTimer = (fn: CallbackFunc, delay: number) => {
        cancelTimer();

        if (typeof fn === "function") {
            timeoutRef.current = setTimeout(() => {
                fn();
                cancelTimer();
            }, delay);
        }
    };

    return { setTimer, cancelTimer };
}
