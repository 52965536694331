import { createSelector } from "reselect";
import moment from "moment-timezone";
import { EditableSurcharge } from "../types";
import { getNow } from "features/service/selectors/getVenueTime";
import { AppState } from "../../state";
import { isWithinTimes } from "common/utility/liveTimes";

export const getOneTimeSurcharges = ({ surcharges: { edit } }: AppState) =>
    edit.status === "loaded" ? edit.data.oneTimeSurcharges : null;

export const getSurchargesEnabled = ({ LocationSettings: { edit } }: AppState) =>
    edit.status === "loaded" ? edit.data.enableSurcharges : false;

export const isActiveSurcharge = (surcharge: EditableSurcharge, now?: moment.Moment) => {
    const { startTime, endTime, date } = surcharge;

    if (!now || !startTime || !endTime || !date) {
        return false;
    }

    return now.isSame(date, "day") && isWithinTimes(startTime, endTime, now);
};

export const getOneTimeSurchargesWithLive = createSelector(
    getOneTimeSurcharges,
    getSurchargesEnabled,
    getNow,
    (surcharges, enabled, now) => {
        if (!surcharges) return [];

        return surcharges.map((s) => {
            return {
                ...s,
                isLive: enabled && isActiveSurcharge(s, now),
            };
        });
    }
);

export const getActiveSurcharge = createSelector(getOneTimeSurcharges, getNow, (surcharges, now) => {
    if (!surcharges) return;

    return surcharges.find((s) => isActiveSurcharge(s));
});
