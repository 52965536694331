import { withSvg } from "core/components/svg/Svg";

export const ImportantWarning = withSvg(
    16,
    16
)(() => (
    <>
        <path d="M8 1.5C8.18098 1.5 8.34783 1.59779 8.43625 1.7557L15.4363 14.2557C15.523 14.4105 15.5211 14.5997 15.4314 14.7528C15.3416 14.9059 15.1775 15 15 15H1C0.822538 15 0.658372 14.9059 0.568635 14.7528C0.478899 14.5997 0.477038 14.4105 0.563747 14.2557L7.56375 1.7557C7.65217 1.59779 7.81902 1.5 8 1.5ZM1.85306 14H14.1469L8 3.02332L1.85306 14Z" />
        <path d="M8 5.75C8.27614 5.75 8.5 5.97386 8.5 6.25V10.25C8.5 10.5261 8.27614 10.75 8 10.75C7.72386 10.75 7.5 10.5261 7.5 10.25V6.25C7.5 5.97386 7.72386 5.75 8 5.75Z" />
        <path d="M8 12.5C8.27614 12.5 8.5 12.2761 8.5 12C8.5 11.7239 8.27614 11.5 8 11.5C7.72386 11.5 7.5 11.7239 7.5 12C7.5 12.2761 7.72386 12.5 8 12.5Z" />
    </>
));
