import { withSvg } from "core/components/svg/Svg";

export const StatBar = withSvg(
    16,
    16
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 2.5C12.5 2.22386 12.7239 2 13 2H15C15.2761 2 15.5 2.22386 15.5 2.5V13.5C15.5 13.7761 15.2761 14 15 14C14.7239 14 14.5 13.7761 14.5 13.5V3H13.5V13.5C13.5 13.7761 13.2761 14 13 14C12.7239 14 12.5 13.7761 12.5 13.5V2.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 7.5C8.5 7.22386 8.72386 7 9 7H11C11.2761 7 11.5 7.22386 11.5 7.5V13.5C11.5 13.7761 11.2761 14 11 14C10.7239 14 10.5 13.7761 10.5 13.5V8H9.5V13.5C9.5 13.7761 9.27614 14 9 14C8.72386 14 8.5 13.7761 8.5 13.5V7.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 5.5C4.5 5.22386 4.72386 5 5 5H7C7.27614 5 7.5 5.22386 7.5 5.5V13.5C7.5 13.7761 7.27614 14 7 14C6.72386 14 6.5 13.7761 6.5 13.5V6H5.5V13.5C5.5 13.7761 5.27614 14 5 14C4.72386 14 4.5 13.7761 4.5 13.5V5.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 10.5C0.5 10.2239 0.723858 10 1 10H3C3.27614 10 3.5 10.2239 3.5 10.5V13.5C3.5 13.7761 3.27614 14 3 14C2.72386 14 2.5 13.7761 2.5 13.5V11H1.5V13.5C1.5 13.7761 1.27614 14 1 14C0.723858 14 0.5 13.7761 0.5 13.5V10.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 13.5C0 13.2239 0.223858 13 0.5 13H15.5C15.7761 13 16 13.2239 16 13.5C16 13.7761 15.7761 14 15.5 14H0.5C0.223858 14 0 13.7761 0 13.5Z"
        />
    </>
));
