import { State as ListState, reducer as listReducer } from "features/service/reducers/list";
import { State as EditState, reducer as editReducer } from "features/service/reducers/edit";
import { State as PreviewState, reducer as previewReducer } from "features/service/reducers/preview";
import { State as CalendarState, reducer as calendarReducer } from "features/service/reducers/calendar";
import { State as FiltersState, reducer as filtersReducer } from "features/service/reducers/filters";
import { combineReducers } from "redux";

export interface State {
    services: {
        list: ListState;
        edit: EditState;
        preview: PreviewState;
        calendar: CalendarState;
        filters: FiltersState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    preview: previewReducer,
    calendar: calendarReducer,
    filters: filtersReducer,
});
