import { FeatureConfiguration } from "..";

import { reducer } from "features/course/reducers";

import * as actions from "features/course/actions";

export * from "features/course/types";

export { actions };

export default function (config: FeatureConfiguration) {
    // legacy course pages have been removed but we need the
    // redux state for other sections (services) to work correctly
    config.reducers.courses = reducer;
}
