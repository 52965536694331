import { normalizeImageId } from "common/data/imageUtil";
import { AppState } from "features";
import { CategorySummary } from "features/category";
import { MenuItemTemplate } from "features/menuitemtemplate";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { EditableProduct, ProductSummary } from "features/catalogue/types";
import { isNew } from "common/utility/modelUtils";
import { getModifiersById } from "./getModifiersById";

const getCategories = ({ categories: { list } }: AppState) => {
    return list.status === "loaded" ? list.data : null;
};

const getTags = ({ tags: { list } }: AppState) => {
    return list.status === "loaded" ? list.data.map((tagGroup) => tagGroup.tags).flat() : null;
};

const getTemplates = ({ menuItemTemplates: { list } }: AppState) => {
    return list.status === "loaded" ? list.data : null;
};

// use the menu item in state.menuItems.edit as 'existing' item
const getExistingMenuItem = ({ menuItems: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : null;
};

export const getSaveProductTrackingEventData = createSelector(
    [
        (state: AppState) => getCategories(state),
        (state: AppState) => getTags(state),
        (state: AppState) => getTemplates(state),
        (state: AppState) => getExistingMenuItem(state),
        (state: AppState) => getModifiersById(state),
        (_: AppState, formData: EditableProduct) => formData,
        (_: AppState, __: EditableProduct, saveResult?: ProductSummary) => saveResult,
    ],
    (categories, tags, templates, existing, modifiersById, formData, saveResult) => {
        const isNewModel = isNew(formData.id);
        const productId = !isNewModel ? formData.id : saveResult?.id;
        const changes: any = {};

        if (productId) {
            changes.product_id = productId;
        }

        if (isNewModel || (existing && existing.displayName !== formData.displayName)) {
            changes.product_name = formData.displayName;
        }

        if (isNewModel || (existing && getImageChanged(existing, formData))) {
            changes.picture_status = !!formData.image;
        }

        if (isNewModel || (existing && getCategoriesChanged(existing, formData))) {
            changes.categories = formData.categories.map((categoryId) => {
                return {
                    id: categoryId,
                    name: categories?.find(({ id }: CategorySummary) => id === categoryId)?.displayName,
                };
            });
        }

        if (isNewModel || (existing && getPricesChanged(existing, formData))) {
            changes.variants = formData.prices.map(({ id, displayName, price, recommended }) => {
                return {
                    id,
                    name: displayName,
                    price,
                    preselect: recommended,
                };
            });
        }

        if (isNewModel || (existing && getModifiersChanged(existing, formData))) {
            changes.modifiers = formData.modifiers.map((id) => {
                const modifier = modifiersById[id];

                const { displayName: name, required } = modifier || {};

                return {
                    id,
                    name,
                    required,
                };
            });
        }

        if (isNewModel || (existing && getTagsChanged(existing, formData))) {
            changes.tags = Object.values(formData.tags)
                .flat()
                .map((tagId) => ({
                    id: tagId,
                    name: tags?.find((tag) => tag.id === tagId)?.displayName,
                }));
        }

        if (isNewModel || (existing && getTypeChanged(existing, formData, templates))) {
            changes.product_type = getType(formData, templates);
        }

        if (isNewModel || (existing && getSubtypeChanged(existing, formData, templates))) {
            changes.product_subtype = getSubtype(formData, templates);
        }

        if (isNewModel || (existing && existing.special !== formData.special)) {
            changes.flag_recommended_active = formData.special;
        }

        if (isNewModel || (existing && existing.popular !== formData.popular)) {
            changes.flag_popular_active = formData.popular;
        }

        return {
            event: {
                name: `PRODUCT/${isNewModel ? "ADDITION" : "MODIFICATION"}_SAVED`,
            },
            customProperties: changes,
        };
    }
);

function getImageChanged(existing: EditableProduct, newItem: EditableProduct) {
    if (newItem.image instanceof File) {
        return true;
    }

    const newItemId = newItem.image as string | undefined;
    const existingId = existing.image as string | undefined;

    return normalizeImageId(newItemId) !== normalizeImageId(existingId);
}

function getCategoriesChanged(existing: EditableProduct, newItem: EditableProduct) {
    const existingCats = [...existing.categories].sort();
    const newCats = [...newItem.categories].sort();

    return !isEqual(existingCats, newCats);
}

function getModifiersChanged(existing: EditableProduct, newItem: EditableProduct) {
    const existingModifiers = [...existing.modifiers].sort();
    const newModifiers = [...newItem.modifiers].sort();

    return !isEqual(existingModifiers, newModifiers);
}

function getPricesChanged(existing: EditableProduct, newItem: EditableProduct) {
    const existingPrices = [...existing.prices].sort();
    const newPrices = [...newItem.prices].sort();

    return !isEqual(existingPrices, newPrices);
}

function getTagsChanged(existing: EditableProduct, newItem: EditableProduct) {
    const existingTags = Object.values(existing.tags).flat().sort();
    const newTags = Object.values(newItem.tags).flat().sort();

    return !isEqual(existingTags, newTags);
}

// get template object
function getTemplate(menuItem: EditableProduct, templates: MenuItemTemplate[] | null): MenuItemTemplate | undefined {
    return templates?.find((template) => template.id === menuItem?.template);
}

function getType(menuItem: EditableProduct, templates: MenuItemTemplate[] | null): string | undefined {
    if (!templates) {
        return undefined;
    }
    return getTemplate(menuItem, templates)?.type;
}

function getTypeChanged(existing: EditableProduct, newItem: EditableProduct, templates: MenuItemTemplate[] | null) {
    if (!templates) {
        return false;
    }
    const existingType = getType(existing, templates);
    const newType = getType(newItem, templates);
    return existingType !== newType;
}

function getSubtype(menuItem: EditableProduct, templates: MenuItemTemplate[] | null): string | undefined {
    if (!templates) {
        return undefined;
    }
    return getTemplate(menuItem, templates)?.displayName;
}

function getSubtypeChanged(existing: EditableProduct, newItem: EditableProduct, templates: MenuItemTemplate[] | null) {
    if (!templates) {
        return false;
    }
    const existingType = getSubtype(existing, templates);
    const newType = getSubtype(newItem, templates);
    return existingType !== newType;
}
