import { Row } from "core/components/card";
import { CardWidth, CloseEvent, CloseMeta, Confirm, ConfirmProps, Modal } from "core/components/modal";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { Prompt as RouterPrompt } from "react-router-dom";
import { Location } from "history";
import cuid from "cuid";

interface Props extends Pick<ConfirmProps, "cancelLabel" | "confirmLabel" | "cancelProps" | "confirmProps"> {
    blockTransition?: (location: Location | null) => boolean;
    onClose?: () => void;
    title: string;
    warnOnWindowUnload?: boolean;
    when: boolean | undefined;
}

export const Prompt = ({
    blockTransition,
    cancelLabel,
    cancelProps,
    children = "Exit without saving changes?",
    confirmLabel,
    confirmProps,
    onClose,
    title = "Unsaved changes",
    warnOnWindowUnload = false,
    when,
}: PropsWithChildren<Props>) => {
    const [visible, setVisible] = useState(false);

    const allowTransitionCallback = useRef<(ok: boolean) => void | undefined>();

    const idRef = useRef<string>();

    useEffect(() => {
        idRef.current = `__PreventTransitionPrompt_${cuid()}`;
        const id = Symbol.for(idRef.current);

        window[id] = (cb: (ok: boolean) => void) => {
            setVisible(true);
            allowTransitionCallback.current = cb;
        };

        return () => {
            delete window[id];
        };
    }, []);

    const handleClose = (event: CloseEvent, meta: CloseMeta) => {
        if (meta.source !== "confirm") {
            setVisible(false);
        }
        onClose?.();
    };

    const handleCancel = () => {
        allowTransitionCallback.current?.(false);
    };

    const handleConfirm = () => {
        allowTransitionCallback.current?.(true);
    };

    const handleTransition = useCallback(
        (location: Location | null) => {
            const block = blockTransition?.(location) ?? true;
            const id = Symbol.for(idRef.current!);

            if (!block || !id) {
                return true;
            }

            return Symbol.keyFor(id) || true;
        },
        [blockTransition]
    );

    useEffect(() => {
        const listener = (event: BeforeUnloadEvent) => {
            if (warnOnWindowUnload && when) {
                if (handleTransition(null) !== true) {
                    event.preventDefault();
                    event.returnValue = "";
                }
            }
        };

        window.addEventListener("beforeunload", listener);

        return () => {
            window.removeEventListener("beforeunload", listener);
        };
    }, [handleTransition, warnOnWindowUnload, when]);

    return (
        <>
            <RouterPrompt when={when} message={handleTransition} />
            <Modal
                footer={
                    <Confirm
                        cancelProps={cancelProps}
                        cancelLabel={cancelLabel}
                        confirmLabel={confirmLabel}
                        confirmProps={confirmProps}
                        onCancel={handleCancel}
                        onConfirm={handleConfirm}
                    />
                }
                onClose={handleClose}
                title={title}
                visible={visible}
                width={CardWidth.NARROW}
            >
                <Row>{children}</Row>
            </Modal>
        </>
    );
};
