import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { AppState } from "features";
import { getActiveGroupData } from "features/group/selectors";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { region } from "features/region";

export type RouteParams = {
    location?: string;
    region?: string;
};

// Add region/location data to all App Insights tracking events
export const getScopeTelemetryInitializer = (getState: () => AppState) => (item: ITelemetryItem) => {
    const state = getState();

    const activeLocation = getActiveLocation(state);

    const activeGroup = getActiveGroupData(state);

    item.data!.region = region.getActiveRegion()?.id;

    if (activeLocation) {
        item.data!.location = activeLocation.slug;
        item.data!.location_id = activeLocation.id;
    }

    if (activeGroup) {
        item.data!.group = activeGroup.displayName;
        item.data!.group_id = activeGroup.id;
    }

    if (activeLocation?.parentLocationId) {
        item.data!.parent_location_id = activeLocation.parentLocationId;
    }

    if (activeLocation?.childLocationIds?.length) {
        item.data!.child_location_ids = JSON.stringify(activeLocation.childLocationIds);
    }

    return true;
};
