import { modifierActions, productActions } from "features/catalogue";
import { AppDispatch, AppState } from "features";
import { uploadPosCatalogue } from "../api/uploadPosCatalogue";
import { createAction } from "../reducers/upload";
import { getPosName } from "features/location/selectors/getPosName";
import {
    ModifierGroupImportInput,
    ModifierOptionImportInput,
    PosCatalogueImportItem,
    PosCatalogueImportModifier,
    PosCatalogueImportModifierOption,
    PosCatalogueImportVariant,
    PosCatalogueUpload,
    PosCatalogueUploadInput,
    ProductImportInput,
    VariantImportInput,
} from "../types";
import { trackEventAddImportFailed, trackEventAddImportSucceed } from "./trackEventImportPos";
import { addNestedModifiers } from "features/posCatalogueImport/selectors/addNestedModifiers";
import { getModifierPosCatalogueItemsById } from "../selectors/getModifierPosCatalogueItemsById";
import { posOperationBehaviorFilter } from "features/posConfiguration/selectors/posOperationFilter";

export function upload(locationId: string, posCatalogue: PosCatalogueUpload) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (state.posCatalogueImport.upload.status === "loading") {
            return;
        }
        dispatch(createAction.loading());
        const posName = getPosName(state);
        try {
            const payload: PosCatalogueUploadInput = mapPosCatalogueUpload(posCatalogue, state);

            const result = await uploadPosCatalogue(locationId, payload, false);

            if (!result.startedRunning) {
                throw "Import failed - there is already an operation in progress for this venue";
            }
            dispatch(createAction.loaded(result));

            const modifierChanges = result.summary?.modifierGroups?.length || result.summary?.modifierOptions?.length;
            const productChanges = result.summary?.products?.length || result.summary?.variants?.length;

            if (modifierChanges) {
                dispatch(modifierActions.list(locationId, true));
            }

            if (productChanges) {
                dispatch(productActions.list(locationId, true));
            }

            trackEventAddImportSucceed(result, posName, payload);
        } catch (e: any) {
            dispatch(createAction.failed(e));
            trackEventAddImportFailed(e.message, posName);
        }
    };
}

function mapPosCatalogueUpload(
    { products, modifiers, includeProductModifierRelationships, includeNestedModifiers }: PosCatalogueUpload,
    state: AppState
): PosCatalogueUploadInput {
    if (includeNestedModifiers) {
        const allPosModifiers = getModifierPosCatalogueItemsById(state);
        const supportsMultiLevelNestedModifiers = posOperationBehaviorFilter(
            "PosCatalogue",
            "MultipleLevelNestedModifiers"
        )(state);

        addNestedModifiers(
            modifiers as PosCatalogueImportModifier[],
            allPosModifiers,
            supportsMultiLevelNestedModifiers
        );
    }

    return {
        products: mapPosCatalogueUploadProducts(products),
        modifiers: mapPosCatalogueUploadModifierGroups(modifiers as PosCatalogueImportModifier[]),
        includeProductModifierRelationships,
        includeNestedModifiers,
    };
}

function mapPosCatalogueUploadProducts(products: PosCatalogueImportItem[]): ProductImportInput[] {
    return products.map(({ children, displayName, key, id, energyContent, description }) => ({
        description,
        displayName,
        // TODO https://meanduapp.atlassian.net/browse/INT-277
        // energyContent,
        externalId: id!,
        sourceId: key || "",
        variants: children ? mapPosCatalogueUploadVariants(children as PosCatalogueImportVariant[]) : [],
    }));
}

function mapPosCatalogueUploadVariants(children: PosCatalogueImportVariant[]): VariantImportInput[] {
    return children.map(
        ({ displayName, key, id, energyContent, modifiers, price, sku, selected }) =>
            ({
                description: "",
                displayName,
                doImport: selected || false,
                energyContent: energyContent ?? null,
                externalId: id!,
                modifierGroupIds: modifiers,
                sourceId: key,
                internalName: displayName,
                price: price || 0,
                sku: sku!,
            } || [])
    );
}

function mapPosCatalogueUploadModifierGroups(modifierGroups: PosCatalogueImportModifier[]): ModifierGroupImportInput[] {
    return modifierGroups.map(
        ({ children, displayName, key, id, sku, maxSelection, minSelection, maxSelectionPerOption }) => ({
            displayName,
            externalId: id!,
            maxSelection: maxSelection,
            minSelection: minSelection,
            maxSelectionPerOption: maxSelectionPerOption,
            options: children ? mapPosCatalogueUploadModifierOptions(children) : [],
            sourceId: key,
            upsell: false,
            sku: sku!,
        })
    );
}

function mapPosCatalogueUploadModifierOptions(
    children: PosCatalogueImportModifierOption[]
): ModifierOptionImportInput[] {
    return children
        .filter((child) => child.selected)
        .map(
            ({ displayName, key, id, energyContent, price, sku, modifiers }) =>
                ({
                    displayName,
                    externalId: id!,
                    sourceId: key,
                    price: price || 0,
                    sku: sku!,
                    modifiers,
                } || [])
        );
}
