import { State as ListState, reducer as listReducer } from "features/waiter/reducers/list";
import { State as EditState, reducer as editReducer } from "features/waiter/reducers/edit";
import {
    State as AccessLevelListState,
    reducer as accessLevelListReducer,
} from "features/waiter/reducers/accessLevelList";
import { combineReducers } from "redux";

export interface State {
    waiters: {
        list: ListState;
        edit: EditState;
        accessList: AccessLevelListState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
    accessList: accessLevelListReducer,
});
