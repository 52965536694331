import { PosCatalogueImportModifier } from "../types";

export const addNestedModifiers = (
    modifiers: PosCatalogueImportModifier[],
    allPosModifiers: Record<string, PosCatalogueImportModifier | undefined>,
    supportsMultiLevelNestedModifiers: boolean
) => {
    const addedModifierIds = modifiers.reduce<Record<string, boolean>>((ids, { id }) => {
        ids[id] = true;
        return ids;
    }, {});
    [...modifiers].forEach((modifier) => {
        addNestedModifiersRecursive(
            modifier,
            modifiers,
            allPosModifiers,
            addedModifierIds,
            supportsMultiLevelNestedModifiers
        );
    });
};

const addNestedModifiersRecursive = (
    modifier: PosCatalogueImportModifier,
    modifiers: PosCatalogueImportModifier[],
    allPosModifiers: Record<string, PosCatalogueImportModifier | undefined>,
    addedModifierIds: Record<string, boolean>,
    supportsMultiLevelNestedModifiers: boolean
) => {
    modifier.children?.forEach((option) => {
        option.modifiers?.forEach((modifierId) => {
            if (addedModifierIds[modifierId]) return;
            addedModifierIds[modifierId] = true;
            const nestedModifier = allPosModifiers[modifierId];
            if (!nestedModifier) return;
            nestedModifier.selected = true;
            nestedModifier.children?.forEach((nestedOption) => {
                nestedOption.selected = true;
            });
            modifiers.push(nestedModifier);
            if (!supportsMultiLevelNestedModifiers) return;
            addNestedModifiersRecursive(
                nestedModifier,
                modifiers,
                allPosModifiers,
                addedModifierIds,
                supportsMultiLevelNestedModifiers
            );
        });
    });
};
