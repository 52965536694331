import { FeatureConfiguration } from "..";
import { Location } from "./components/Location";
import { reducer } from "features/location/reducers";

import * as actions from "features/location/actions";

import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";

export * from "features/location/types/LocationSummary";
export * from "features/location/types/ActiveLocation";

export { actions };

export * from "features/location/containers/withLocation";

export const { getTestId, getTestClass } = getTestAttributeGenerators(TestAttributePrefix.FEATURE_LOCATION);

export default function (config: FeatureConfiguration) {
    config.reducers.locations = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Location key="location-edit" />]);
}
