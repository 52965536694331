import { FeatureConfiguration } from "features";
import { regionMiddleware } from "./regionMiddleware";
import { reducer } from "./reducers";
import { RegionHelper } from "./regionHelper";

export { fetchRegion } from "./regionHelper";

export const region = new RegionHelper();

export default function (config: FeatureConfiguration) {
    config.middleware.push(regionMiddleware());
    config.reducers.region = reducer;
}
