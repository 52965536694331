import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features/state";
import { CatalogueItemSelections, PosCatalogueImportItem } from "../types";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { generateCsvBlob } from "common/data/generateCsvBlob";
import { getProductPosCatalogueImportItems, getSortedCategories } from "../selectors/getPosCatalogueItems";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { trackEventExportCSV } from "./trackEventImportPos";
import { getProductCSVHeader, generateProductRow } from "features/catalogue/csv/ProductCSVFormat";
import { CatalogueExportItem } from "features/catalogue/types/CatalogueExportItem";
import { CatalogueItemType } from "features/catalogue/types/CatalogueItemType";

export const exportProductsCSV =
    (selections: CatalogueItemSelections | null) => (_: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const items = getProductPosCatalogueImportItems(state)!;
        const products = filterProducts(items, selections);
        const categories = getSortedCategories(state);
        const activeLocation = getActiveLocation(state)!;
        const energyContent = getIsEnergyContentSupported(state) || false;

        const csvBlob = generateCsvBlob<Array<string | null>>((writer) => {
            writer(getProductCSVHeader(energyContent));

            for (const product of products) {
                generateProductRow(product as CatalogueExportItem, categories, writer, energyContent, selections);
            }
        });
        downloadBlob(csvBlob, `${activeLocation.slug}-products.csv`);
        trackEventExportCSV(state, CatalogueItemType.Product, products);
    };

function filterProducts(
    catalogue: PosCatalogueImportItem[],
    selections: CatalogueItemSelections | null
): PosCatalogueImportItem[] {
    const products = catalogue.filter((p) => p.type === "product" || p.type === "variant");

    if (!selections) return products;

    return products.filter((p) => p.id && selections[p.id]);
}
