import { trackEvent } from "common/appInsights";
import { AppState } from "features";
import { getPosCatalogueExportCSV, getPosCatalogueItemsAdd } from "../selectors/getPosCatalogueImportTrackingInfo";
import {
    getModifierPosCatalogueItems,
    getProductPosCatalogueImportItems,
    getProductVariantsPosCatalogueImportItems,
    getTotalNumberOfModifierGroups,
    getTotalNumberOfModifierOptions,
    getTotalNumberOfProducts,
    getTotalNumberOfProductVariants,
} from "../selectors/getPosCatalogueItems";
import { getPosName } from "features/location/selectors/getPosName";
import { PosCatalogueImportItem, PosCatalogueImportSelections } from "../types";
import { SummaryResult, UploadResult } from "../types/UploadResult";
import { CatalogueItemType } from "features/catalogue/types/CatalogueItemType";

export const trackEventFilterText = (filterText: string, itemType: CatalogueItemType) => {
    trackEvent({ name: "CATALOGUE/ITEMS_FILTERED" }, { item_type: itemType, filter_text: filterText });
};

export const trackEventFilterCategory = (selectedCategories: any) =>
    trackEvent(
        { name: "CATALOGUE/ITEMS_FILTERED" },
        { item_type: CatalogueItemType.Product, filter_category: selectedCategories }
    );

export const trackEventItemAdd = (state: AppState, selectedItems: PosCatalogueImportSelections) =>
    trackEvent({ name: "CATALOGUE/ITEMS_ADDED" }, getPosCatalogueItemsAdd(state, selectedItems));

export const trackEventExportCSV = (state: AppState, itemType: CatalogueItemType, items: PosCatalogueImportItem[]) =>
    trackEvent({ name: "CATALOGUE/ITEMS_EXPORTED" }, getPosCatalogueExportCSV(state, itemType, items));

const uploadedStatusCount = (itemList: SummaryResult[]) => {
    return itemList
        .filter((item: any) => uploadedStatus.includes(item.result))
        .map((d: SummaryResult): number => d.count)
        .reduce((prev: number, next: number) => prev + next, 0);
};

const uploadFailedCount = (itemList: SummaryResult[]) => {
    return itemList
        .filter((item: any) => uploadedFailedStatus.includes(item.result))
        .map((d: SummaryResult): number => d.count)
        .reduce((prev: number, next: number) => prev + next, 0);
};

export const trackEventAddImportSucceed = (uploadResult: UploadResult, posName: string, payload: any) => {
    const productUploadedCount = uploadedStatusCount(uploadResult.summary.products);
    const productUploadedFailedCount = uploadFailedCount(uploadResult.summary.products);

    const variantUploadedCount = uploadedStatusCount(uploadResult.summary.variants);
    const variantUploadedFailedCount = uploadFailedCount(uploadResult.summary.variants);

    const modifierGroupUploadedCount = uploadedStatusCount(uploadResult.summary.modifierGroups);
    const modifierGroupUploadedFailedCount = uploadFailedCount(uploadResult.summary.modifierGroups);

    const modifierOptionUploadedCount = uploadedStatusCount(uploadResult.summary.modifierOptions);
    const modifierOptionUploadedFailedCount = uploadFailedCount(uploadResult.summary.modifierOptions);

    const products = payload.products;
    const variants = payload.products.map((prod: any) => prod.variants).flat(1);
    const mods = payload.modifiers.map((modifier: any) => modifier.options).flat(1);

    trackEvent(
        { name: "CATALOGUE/ADD_IMPORT_SUCCEED" },
        {
            import_type: "pos",
            pos_provider: posName,
            nb_products: productUploadedCount,
            nb_variants: variantUploadedCount,
            nb_mod_groups: modifierGroupUploadedCount,
            nb_mod_options: modifierOptionUploadedCount,
            nb_products_failed: productUploadedFailedCount,
            nb_variants_failed: variantUploadedFailedCount,
            nb_mod_groups_failed: modifierGroupUploadedFailedCount,
            nb_mod_options_failed: modifierOptionUploadedFailedCount,
            nb_products_displayname_filled: getProductDisplayNameFilledCount(products),
            nb_variants_displayname_filled: getVariantDisplayNameFilledCount(variants),
            nb_variants_price_filled: getVariantPriceFilledCount(variants),
            nb_variants_sku_filled: getVariantSkuFilledCount(variants),
            nb_mod_displayname_filled: getModDisplayNameFilledCount(mods),
            nb_mod_price_filled: getModPriceFilledCount(mods),
            nb_mod_sku_filled: getModSkuFilledCount(mods),
            nb_variants_energyContent_filled: getVariantEnergyContentFilledCount(variants),
            nb_mod_energyContent_filled: getModEnergyContentFilledCount(mods),
        }
    );
};

export const trackEventAddImportFailed = (errorMessage: string, posName: string) => {
    trackEvent(
        { name: "CATALOGUE/ADD_IMPORT_FAILED" },
        {
            import_type: "pos",
            pos_provider: posName,
            error: errorMessage,
        }
    );
};

export const trackEventFetchImportSucceed = (state: AppState) => {
    const posName = getPosName(state);
    const products = getProductPosCatalogueImportItems(state);
    const variants = getProductVariantsPosCatalogueImportItems(state);
    const mods = getModifierPosCatalogueItems(state);

    trackEvent(
        { name: "CATALOGUE/FETCH_IMPORT_SUCCEED" },
        {
            import_type: "pos",
            pos_provider: posName,
            nb_products: getTotalNumberOfProducts(state),
            nb_variants: getTotalNumberOfProductVariants(state),
            nb_mod_groups: getTotalNumberOfModifierGroups(state),
            nb_mod_options: getTotalNumberOfModifierOptions(state),
            nb_products_displayname_filled: getProductDisplayNameFilledCount(products),
            nb_variants_displayname_filled: getVariantDisplayNameFilledCount(variants),
            nb_variants_price_filled: getVariantPriceFilledCount(variants),
            nb_variants_sku_filled: getVariantSkuFilledCount(variants),
            nb_mod_displayname_filled: getModDisplayNameFilledCount(mods),
            nb_mod_price_filled: getModPriceFilledCount(mods),
            nb_mod_sku_filled: getModSkuFilledCount(mods),
            nb_variant_energyContent_filled: getVariantEnergyContentFilledCount(variants),
            nb_mod_energyContent_filled: getModEnergyContentFilledCount(mods),
        }
    );
};

export const trackEventFetchImportFailed = (errorMessage: string, posName: string) => {
    trackEvent(
        { name: "CATALOGUE/FETCH_IMPORT_FAILED" },
        {
            import_type: "pos",
            pos_provider: posName,
            error: errorMessage,
        }
    );
};

enum ImportEntityResult {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DELETED = "DELETED",
    IGNORED = "IGNORED",
    ABORTED = "ABORTED",
}

const uploadedStatus = [ImportEntityResult.CREATED, ImportEntityResult.UPDATED];
const uploadedFailedStatus = [ImportEntityResult.IGNORED, ImportEntityResult.ABORTED];

const getProductDisplayNameFilledCount = (itemList: any) => {
    return itemList.filter((product: any) => product.displayName).length;
};

const getVariantDisplayNameFilledCount = (itemList: any) => {
    return itemList
        .filter((variant: any) => variant.doImport === true || variant.doImport === undefined)
        .filter((variant: any) => variant.displayName).length;
};

const getVariantSkuFilledCount = (itemList: any) => {
    return itemList
        .filter((variant: any) => variant.doImport === true || variant.doImport === undefined)
        .filter((variant: any) => variant.sku).length;
};

const getVariantPriceFilledCount = (itemList: any) => {
    return itemList
        .filter((variant: any) => variant.doImport === true || variant.doImport === undefined)
        .filter((variant: any) => variant.price).length;
};

const getModSkuFilledCount = (itemList: any) => {
    return itemList.filter((variant: any) => variant.sku).length;
};

const getModPriceFilledCount = (itemList: any) => {
    return itemList.filter((variant: any) => variant.price).length;
};

const getModDisplayNameFilledCount = (itemList: any) => {
    return itemList.filter((variant: any) => variant.displayName).length;
};

const getModEnergyContentFilledCount = (itemList: any) => {
    return itemList.filter((variant: any) => variant.energyContent).length;
};
const getVariantEnergyContentFilledCount = (itemList: any) => {
    return itemList.filter((variant: any) => variant.energyContent).length;
};
