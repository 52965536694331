import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { AppState } from "features";
import { auth } from "common/auth/auth";

// Add user data to all App Insights tracking events
export const getUserTelemetryInitializer = (getState: () => AppState) => (item: ITelemetryItem) => {
    const authState = auth.getState();
    const state = getState();

    item.data!.user_domain = authState?.email?.split("@")[1];

    const activeLocation = state.locations.active.status === "loaded" ? state.locations.active.data : null;
    item.data!.user_role = activeLocation?.authInfo?.userRoles?.join(",");

    return true;
};
