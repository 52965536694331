import { Col } from "antd";
import { lazy } from "react";
import { requirePosOperationSupport } from "./requirePosOperationSupport";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const PosConfigurationPageContainer = lazy(() =>
    import("../containers/PosConfigurationPageContainer").then((module) => ({
        default: module.PosConfigurationPageContainer,
    }))
);

export function PosConfiguration() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_POS_CONFIGURATION}>
            <Route
                path={`/:region/venue/:location/pos/configuration`}
                exact
                render={() => (
                    <Col span={24} md={24} className="content__col content__col--white">
                        <div className="content__col-edit">
                            <Route
                                component={requirePosOperationSupport("SetConfiguration")(
                                    withPermissions(PosConfigurationPageContainer, "location:pos:configuration:read")
                                )}
                            />
                        </div>
                    </Col>
                )}
            />
        </TestContext.Provider>
    );
}
