import { createSelector } from "reselect";
import { AppState } from "features/state";
import { mergeStatus } from "common/loader";
import { PosLookupFieldDefinition } from "features/location/types/ActiveLocation";
import { getPosConfigurationFields } from "./getPosConfigurationFields";

const getPosLookups = (state: AppState) => state.posLookups;

export const getPosConfigurationLookupFields = createSelector(getPosConfigurationFields, (fields) =>
    fields.filter((f) => f.input === "lookup").map((field) => field as PosLookupFieldDefinition)
);

export const getPosConfigurationLookups = createSelector(getPosConfigurationLookupFields, (fields) =>
    fields.map((field) => field.lookup!)
);

const getPosConfigurationLookupsState = createSelector(
    getPosConfigurationLookups,
    getPosLookups,
    (fieldLookups, lookups) => fieldLookups.map((lookup) => {
        // We don't have access to scopes here, but this is only used to hide
        // initial visibility of the POS Wizard (PosConfigurationPage) so any matching lookup
        // will suffice
        const key = Object.getOwnPropertyNames(lookups).find(k => k.startsWith(lookup + "!!"));

        return lookups[key!] || { status: "unloaded" };
    })
);

export const getPosConfigurationLookupsStatus = createSelector(getPosConfigurationLookupsState, (lookups) => {
    if (lookups.length === 0) {
        return "loaded";
    }

    // 'failed' will be handled by the lookup component, so consider it 'loaded'
    const resolvedStatus = lookups.map((l) => (l.status === "failed" ? "loaded" : l.status));

    return mergeStatus(...resolvedStatus);
});
