import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { numberOrZero } from "common/utility/numberUtils";
import { ActiveLocation, TakeawayOptions, VenueAddress } from "features/location";
import { fetchActive } from "features/location/actions";
import { createAction } from "features/location/reducers/edit";
import { createAction as listActions } from "features/location/reducers/list";
import { saveTakeawayOptions } from "features/takeaway/api/saveTakeawayOptions";
import { saveVenueAddress } from "features/takeaway/api/saveVenueAddress";
import { getSaveTakeawayOptionsTrackingEventData } from "features/takeaway/selectors";
import { EditableTakeawayOptions } from "features/takeaway/types";

export const save = (takeawayOptions: EditableTakeawayOptions, location: ActiveLocation) =>
    scaffoldSaveAction(
        (state) => state.locations.active,
        createAction,
        async (locationId: string | undefined, dispatch) => {
            await saveTakeawayOptions(locationId, mapTakeawayOptionsToGraphQLPayload(takeawayOptions));
            await saveVenueAddress(locationId, mapVenueAddressToGraphQLPayload(takeawayOptions.venueAddress, location));
            await dispatch(fetchActive(locationId, true));
            return null;
        },
        undefined,
        false,
        listActions.reload,
        undefined,
        (appState) => getSaveTakeawayOptionsTrackingEventData(appState, takeawayOptions)
    );

const mapTakeawayOptionsToGraphQLPayload = (takeawayOptions: EditableTakeawayOptions): TakeawayOptions => {
    return {
        enabled: takeawayOptions.enabled,
        minReadyTimeMins: numberOrZero(takeawayOptions.minReadyTimeMins),
        sectionId: takeawayOptions.sectionId || undefined,
        contactPhoneNumber: takeawayOptions.contactPhoneNumber,
        collectionInstructions: takeawayOptions.collectionInstructions,
        scheduleOptions: takeawayOptions.scheduleOptions,
    };
};

const mapVenueAddressToGraphQLPayload = (address: VenueAddress, location: ActiveLocation): VenueAddress => {
    const country = address.country ?? getCountry(location.locale, location.currency);
    return {
        ...address,
        country,
    };
};

export const getCountry = (locale: string, currency: string) => {
    switch (locale.toLowerCase()) {
        case "en-au":
            return "Australia";
        case "en-gb":
            return "United Kingdom";
        case "en-nz":
            return "New Zealand";
        case "en-us":
            return "United States of America";
        default:
            throw new Error(`We currently do not support identifying a country from - ${locale} - ${currency}`);
    }
};
