import styles from "./PlainWrapper.module.scss";

import { ListWrapperProps } from "../ModalCrudRoute";
import { PropsWithChildren } from "react";
import { useTestAttributes } from "core/components/testAttributes";

export const PlainWrapper = ({ children }: PropsWithChildren<ListWrapperProps>) => {
    const { getTestId } = useTestAttributes();

    return (
        <div className={styles.container} data-testid={getTestId({ name: "page" })}>
            {children}
        </div>
    );
};
