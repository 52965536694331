import { ActiveGroup } from "../types/ActiveGroup";
import { AppDispatch } from "features";
import { useDispatch, useSelector } from "react-redux";
import { getActiveGroupData, getActiveGroupState, getSelectedRegion } from "../selectors";
import { GroupContext } from "../context";
import { GroupRouteParams } from "common/types";
import { Layout } from "features/structure/screen/Layout";
import { LayoutLoading } from "core/components/layoutLoading";
import { PropsWithChildren, useCallback } from "react";
import { Scope, ScopeContext } from "features/scope";
import { useLoadStatus } from "common/hooks";
import { useParams } from "react-router-dom";
import * as actions from "../actions";

export * from "../context";

export interface GroupComponentProps {
    activeGroup: ActiveGroup;
}

export function GroupContainer({ children }: PropsWithChildren<{}>) {
    const dispatch = useDispatch<AppDispatch>();

    const activeGroupState = useSelector(getActiveGroupState);

    const activeGroup = useSelector(getActiveGroupData);

    const region = useSelector(getSelectedRegion);

    const params = useParams<GroupRouteParams>();

    const fetch = useCallback(
        (reload: boolean) => {
            dispatch(actions.fetchActive(params.id, reload));
        },
        [dispatch, params.id]
    );

    const loadStatus = useLoadStatus([activeGroupState.status, region.status], fetch);

    const isLoading = loadStatus === "loading" || loadStatus === "unloaded";

    const hasFailed = loadStatus === "failed";

    if (isLoading || hasFailed) {
        return (
            <LayoutLoading
                loading={loadStatus === "loading" || loadStatus === "unloaded"}
                failed={hasFailed}
                onRetry={() => fetch(true)}
                failedMessage="Group failed to load. Try reloading this page or try again later"
            />
        );
    }

    // TODO handle more scenarios
    if (!activeGroup) {
        return <Layout>You do not have access to any groups</Layout>;
    }

    return (
        <ScopeContext.Provider value={Scope.GROUP}>
            <GroupContext.Provider value={activeGroup}>{children}</GroupContext.Provider>
        </ScopeContext.Provider>
    );
}
