import { AppState } from "features";
import { createSelector } from "reselect";
import { isLoaded } from "common/loader/isLoaded";

export const getSections = ({ sections: { list } }: AppState) => list;

export const getSectionsStatus = createSelector(getSections, (list) => {
    return list.status;
});

export const getSectionsData = createSelector(getSections, (list) => {
    return isLoaded(list) ? list.data : undefined;
});

export const getSectionsSelectOptions = createSelector(getSectionsData, (list) =>
    list ? list.map((item) => ({ label: item.displayName, value: item.id })) : []
);
