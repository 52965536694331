import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const EditCompanyDetailsPageContainer = lazy(() =>
    import("../containers/EditCompanyDetailsContainer").then((module) => ({
        default: module.EditCompanyDetailsPageContainer,
    }))
);

export function Location() {
    return (
        <Route
            path={"/:region/venue/:location/edit"}
            render={() => (
                <>
                    <Col span={24} md={24} className="content__col">
                        <div className="content__col-edit">
                            <Route component={withPermissions(EditCompanyDetailsPageContainer, "location:update")} />
                        </div>
                    </Col>
                </>
            )}
        />
    );
}
