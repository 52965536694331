import * as React from "react";

import { RouteComponentProps } from "react-router";
import { AppState } from "../..";
import { isLoaded } from "common/loader/isLoaded";
import * as actions from "../actions";
import { connect } from "react-redux";
import { LocationLocale } from "../types/LocationLocale";
import { getActiveLocation } from "../selectors/getLocationPermissions";
import { getLocationLocale } from "../selectors/getLocationLocale";
import { mergeStatus } from "common/loader";
import { Layout } from "features/structure/screen/Layout";
import { SubType } from "types/utils";
import { AppDispatch } from "features/state";
import { withLoader } from "common/loader/withLoader";
import { ActiveLocation } from "..";
import { LocationContext, LocationLocaleContext } from "../context";
import { Scope, ScopeContext } from "features/scope";

export * from "../context";

const EditNewLocationPage = React.lazy(() =>
    import("../components/EditNewLocationPage").then((module) => ({
        default: module.EditNewLocationPage,
    }))
);

export interface RequireLocationProps {
    restaurantLocation: ActiveLocation | null;
    locationLocale: LocationLocale | null;
}

export interface LocationComponentProps {
    restaurantLocation: ActiveLocation;
}
export interface LocaleComponentProps {
    locale: LocationLocale | null;
}

const requireLocation =
    <P extends {}>(Component: React.ComponentType<P>): React.FC<P & RequireLocationProps> =>
    ({ restaurantLocation, locationLocale, ...props }: RequireLocationProps & P) => {
        if (!restaurantLocation || !locationLocale) {
            return (
                <Layout>
                    <EditNewLocationPage />
                </Layout>
            );
        }

        return (
            <ScopeContext.Provider value={Scope.LOCATION}>
                <LocationContext.Provider value={restaurantLocation}>
                    <LocationLocaleContext.Provider value={locationLocale}>
                        <Component {...(props as any)} />
                    </LocationLocaleContext.Provider>
                </LocationContext.Provider>
            </ScopeContext.Provider>
        );
    };

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<RouteParams>): any => {
    const {
        locations: { active },
        region: { selectedRegion },
    } = state;

    const mergedStatus = mergeStatus(active.status, selectedRegion.status);

    if (isLoaded(active) && isLoaded(selectedRegion)) {
        const restaurantLocation = getActiveLocation(state);
        const locationLocale = getLocationLocale(state);

        return {
            loadStatus: mergedStatus,
            restaurantLocation,
            locationLocale,
            locationRegion: selectedRegion.data.id,
            ...ownProps,
        } as any;
    }

    return {
        loadStatus: mergedStatus,
        ...ownProps,
    } as any;
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: RouteComponentProps<RouteParams>): any => ({
    fetch: () => {
        dispatch(actions.fetchActive(ownProps.match.params.location));
    },
});

interface RouteParams {
    location: string;
}

export const locationRoute = <P extends RouteComponentProps<RouteParams>>(
    Component: React.ComponentType<P> | React.FC<P>
): React.FC<P & RouteComponentProps<RouteParams>> => {
    return (connect as any)(mapStateToProps, mapDispatchToProps)(withLoader(requireLocation<P>(Component)));
};

export const withLocation = <P,>(
    Component: React.ComponentType<P & LocationComponentProps>
): React.FC<Omit<P & LocationComponentProps, keyof LocationComponentProps>> =>
    ((props: P) => (
        <LocationContext.Consumer>
            {(restaurantLocation) => <Component restaurantLocation={restaurantLocation} {...props} />}
        </LocationContext.Consumer>
    )) as any;

type LocationSettingProperties = SubType<ActiveLocation, boolean>;

export const withLocationFeature = <P,>(
    setting: keyof LocationSettingProperties,
    Component: React.ComponentType<P>
): React.FC<P> =>
    ((props: P) => (
        <LocationContext.Consumer>
            {(restaurantLocation) =>
                restaurantLocation[setting!] ? <Component restaurantLocation={restaurantLocation} {...props} /> : null
            }
        </LocationContext.Consumer>
    )) as any;

export const withLocale = <P,>(
    Component: React.ComponentType<P & LocaleComponentProps>
): React.FC<Omit<P & LocaleComponentProps, keyof LocaleComponentProps>> =>
    ((props: P) => (
        <LocationLocaleContext.Consumer>
            {(locale) => <Component locale={locale} {...props} />}
        </LocationLocaleContext.Consumer>
    )) as any;
