import { Loaded } from "common/loader";
import { Region } from "../types/region";

export enum TypeKeys {
    LOADING_REGION = "REGION/LOADING",
    REGION_CHANGED = "REGION/CHANGED",
}

export type State = Loaded<Region>;

export const actionCreators = {
    loadingRegion: (status: "loading" | "failed"): LoadingRegionAction => ({ type: TypeKeys.LOADING_REGION, status }),
    regionChanged: (region: Region): RegionChangedAction => ({ type: TypeKeys.REGION_CHANGED, region }),
};

export type LoadingRegionAction = { type: TypeKeys.LOADING_REGION; status: "loading" | "failed" };
export type RegionChangedAction = { type: TypeKeys.REGION_CHANGED; region: Region };

export type RegionAction = LoadingRegionAction | RegionChangedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: RegionAction): State {
    if (action.type === TypeKeys.LOADING_REGION) {
        if (action.status === "failed") {
            return {
                status: "failed",
                error: "failed to load",
            };
        }
        return {
            status: action.status,
        };
    }
    if (action.type === TypeKeys.REGION_CHANGED) {
        return {
            status: "loaded",
            data: {
                ...action.region,
            },
        };
    }
    return state;
}
