import * as React from "react";
import { Spinner } from "core/components/spinner";

export interface LoginProps {
    handleLogin: () => {};
}

export class LoginPage extends React.Component<LoginProps> {
    constructor(props: any) {
        super(props);
        this.props.handleLogin();
    }

    render() {
        return (
            <div className="loader__container loader__container--overlay">
                <Spinner size="large" />
            </div>
        );
    }
}
