import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { Taxes } from "./components/Taxes";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.taxes = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Taxes key="taxes" />]);
}
