import { FeatureConfiguration } from "..";
import { reducer } from "features/waiter/reducers";
import { Waiter } from "./components/Waiter";
import * as actions from "features/waiter/actions";
import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";

export * from "features/waiter/types";
export { actions };
export const { getTestId, getTestClass } = getTestAttributeGenerators(TestAttributePrefix.FEATURE_WAITER);

export default function (config: FeatureConfiguration) {
    config.reducers.waiters = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Waiter key="staff" />]);
}
