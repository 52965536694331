import { withSvg } from "core/components/svg/Svg";

export const TypeFoodAndDrink = withSvg(
    17,
    16
)(() => (
    <>
        <path
            d="M5.96953 3.35852C6.13554 2.8737 6.43406 2.44961 6.82856 2.13813C7.22306 1.82666 7.69646 1.6413 8.19083 1.60473C8.6852 1.56816 9.17913 1.68196 9.61218 1.93222C10.0452 2.18247 10.3987 2.55833 10.6292 3.01383C10.8598 3.46932 10.9574 3.98469 10.9103 4.49691C10.9103 5.62241 10.3094 6.09806 10 6.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M8.46398 5.88712V3.85083H2.1875V5.88712" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.1875 11.5887V5.88708H8.46398L8.5 7" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M2.1875 11.5887V13.2178C2.1875 13.3258 2.22883 13.4294 2.3024 13.5058C2.37596 13.5821 2.47574 13.625 2.57978 13.625H8.0717M8.0717 13.625C8.17574 13.625 8.27552 13.5821 8.34908 13.5058C8.42265 13.4294 7.7448 13.5973 7.7448 13.4893L8.0717 13.625Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.8547 12.0983V12.7971C14.8547 12.9824 14.781 13.1602 14.65 13.2912C14.5189 13.4223 14.3412 13.4959 14.1558 13.4959H8.28574C8.1004 13.4959 7.92265 13.4223 7.79159 13.2912C7.66054 13.1602 7.58691 12.9824 7.58691 12.7971V12.0983"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.8547 9.58618H7.58691C7.58691 6.99722 9.11114 6.79089 11.2208 6.79089C13.3304 6.79089 14.8547 6.99722 14.8547 9.58618Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M7.58691 10.8679L14.8634 10.8679" strokeLinecap="round" />
    </>
));
