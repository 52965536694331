import { Breakpoint, Breakpoints } from "../types/Breakpoint";

// @see src/core/styles/settings/_media-query.scss
export const breakpoints: Breakpoints = {
    xs: 0,
    sm: 320,
    md: 672,
    lg: 896,
    xl: 1056,
};

export function getMinWidthQuery(breakpoint: Breakpoint) {
    return `(min-width: ${breakpoints[breakpoint]}px)`;
}
