import { useEffect, useMemo } from "react";

import throttle from "lodash/throttle";

export function useOnApplicationFocus(handler: () => void, reenableDelay: number = 0) {
    const throttledFocusHandler = useMemo(() => {
        return throttle(handler, reenableDelay, { leading: true, trailing: false });
    }, [reenableDelay, handler]);

    useEffect(() => {
        const visibiltyListener = () => {
            if (!document.hidden) {
                throttledFocusHandler();
            }
        };

        const focusListener = () => {
            throttledFocusHandler();
        };

        document.addEventListener("visibilitychange", visibiltyListener);

        // add focus as a backup in case visibilitychange doesn't change e.g. tabbing windows
        window.addEventListener("focus", focusListener);

        return () => {
            throttledFocusHandler.cancel();
            document.removeEventListener("visibilitychange", visibiltyListener);
            window.removeEventListener("focus", focusListener);
        };
    }, [throttledFocusHandler]);
}
