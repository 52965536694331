import { useEffect } from "react";
import { HTMLElementRef } from "common/types/ElementRef";

/* `handler` should be memoised for best performance */
export function useOnFocusOutside(
    ref: HTMLElementRef | HTMLElementRef[],
    handler: (event: FocusEvent) => void,
    enable: boolean
) {
    useEffect(() => {
        const listener = (event: FocusEvent) => {
            if (Array.isArray(ref)) {
                for (const r of ref) {
                    if (!r.current || r.current.contains(event.target as Node | null)) {
                        // focus inside
                        return;
                    }
                }
            } else {
                if (!ref.current || ref.current.contains(event.target as Node | null)) {
                    // focus inside
                    return;
                }
            }
            handler(event);
        };

        if (enable) {
            document.addEventListener("focusin", listener);
        }

        return () => {
            document.removeEventListener("focusin", listener);
        };
    }, [ref, handler, enable]);
}
