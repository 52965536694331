import { withSvg } from "core/components/svg/Svg";

export const TypeFood = withSvg(
    16,
    16
)(() => (
    <>
        <path
            d="M8.45 7.825L7.55 6.925C8.05 6.175 8.075 5.275 7.375 4.6L3.1 0.75L0.5 3.325L4.375 7.625C5.05 8.3 5.95 8.3 6.7 7.8L7.6 8.7M4.6 5.725L1.375 2.475L4.6 5.725ZM2.225 1.6L5.475 4.85L2.225 1.6ZM9.6 10.725L13.9 15C14.0127 15.1127 14.1656 15.176 14.325 15.176C14.4844 15.176 14.6373 15.1127 14.75 15C14.8627 14.8873 14.926 14.7344 14.926 14.575C14.926 14.4156 14.8627 14.2627 14.75 14.15L10.625 10L9.6 10.725Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.05014 9.25L2.07514 14.225C2.01535 14.2811 1.9677 14.3489 1.93512 14.4241C1.90255 14.4994 1.88574 14.5805 1.88574 14.6625C1.88574 14.7445 1.90255 14.8256 1.93512 14.9009C1.9677 14.9761 2.01535 15.0439 2.07514 15.1C2.13125 15.1598 2.19902 15.2074 2.27427 15.24C2.34952 15.2726 2.43064 15.2894 2.51264 15.2894C2.59464 15.2894 2.67576 15.2726 2.75101 15.24C2.82626 15.2074 2.89403 15.1598 2.95014 15.1L7.90014 10.1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.05005 9.24999L14.6 1.67499C14.885 1.95842 15.1112 2.29538 15.2655 2.66649C15.4198 3.03761 15.4992 3.43557 15.4992 3.83749C15.4992 4.23941 15.4198 4.63736 15.2655 5.00848C15.1112 5.3796 14.885 5.71656 14.6 5.99999L10.275 10.325C10.1331 10.4694 9.96386 10.584 9.77715 10.6623C9.59043 10.7406 9.39 10.7809 9.18755 10.7809C8.98509 10.7809 8.78466 10.7406 8.59795 10.6623C8.41124 10.584 8.24198 10.4694 8.10005 10.325L7.05005 9.24999Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
