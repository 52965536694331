import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route, RouteComponentProps } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withLocationFeature } from "features/location";
import { withPermissions } from "common/permissions/components/withPermission";

const StripeAccountPage = lazy(() =>
    import("./StripeAccountPage").then((module) => ({
        default: module.StripeAccountPage,
    }))
);

const Component = withLocationFeature<RouteComponentProps<any>>(
    "enableStripeOnboarding",
    withPermissions(StripeAccountPage, "stripe:read")
);

export function Stripe() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_STRIPE}>
            <Route
                path={"/:region/venue/:location/stripe"}
                render={(props) => (
                    <EditWrapper contentWidth={ContentWidth.NARROW}>
                        <Component {...props} />
                    </EditWrapper>
                )}
            />
        </TestContext.Provider>
    );
}
