import styles from "./PageHeader.module.scss";
import { ReactElement, ReactNode } from "react";
import { Button } from "../button";
import { ArrowLeft } from "common/icons/ArrowLeft";
import { Link } from "react-router-dom";
import { useTestAttributes } from "../testAttributes";

interface Props {
    title: string;
    titleAfter?: ReactNode;
    actions?: ReactNode;
    className?: string;
    backHandler?: () => void;
    backLink?: string;
    subtitle?: string;
    titleAs?: React.ElementType;
}

export const PageHeader = ({
    actions = null,
    className,
    backHandler,
    backLink,
    subtitle,
    title,
    titleAfter,
    titleAs: TitleComponent,
}: Props): ReactElement => {
    if (!TitleComponent) {
        TitleComponent = "h1";
    }

    const { getTestClass } = useTestAttributes();

    return (
        <header className={className} data-testclass={getTestClass({ fragment: "page", element: "header" })}>
            {backHandler || backLink ? (
                <>
                    <div className={styles.topRow}>
                        {(backHandler || backLink) && (
                            <div className={styles.backButton}>
                                <Button
                                    as={backLink ? Link : "button"}
                                    onClick={backHandler}
                                    role="secondary"
                                    padding="icon"
                                    to={backLink}
                                    data-testclass={getTestClass({ fragment: "page", action: "back", element: "link" })}
                                >
                                    <ArrowLeft />
                                </Button>
                            </div>
                        )}
                        <div className={styles.actionsContainer}>{actions}</div>
                    </div>
                    <div className={styles.row}>
                        <TitleComponent
                            className={styles.title}
                            data-testclass={getTestClass({ fragment: "page", element: "title" })}
                        >
                            {title}
                        </TitleComponent>
                        {titleAfter && <div className={styles.titleAfter}>{titleAfter}</div>}
                    </div>
                </>
            ) : (
                <div className={styles.topRow}>
                    <TitleComponent
                        className={styles.title}
                        data-testclass={getTestClass({ fragment: "page", element: "title" })}
                    >
                        {title}
                    </TitleComponent>
                    {titleAfter && <div className={styles.titleAfter}>{titleAfter}</div>}
                    <div
                        className={styles.actionsContainer}
                        data-testclass={getTestClass({ fragment: "page", element: "actions" })}
                    >
                        {actions}
                    </div>
                </div>
            )}
            {subtitle && (
                <p className={styles.subtitle} data-testclass={getTestClass({ fragment: "page", element: "subtitle" })}>
                    {subtitle}
                </p>
            )}
        </header>
    );
};
