import { useEffect, useRef } from "react";

type CallbackFunc = () => void;

export function useInterval(callback: CallbackFunc, delay: number | null) {
    const savedCallback = useRef<CallbackFunc>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => {};
    }, [delay]);
}
