import { Loaded } from "common/loader";
import { Analytics } from "../types/Analytics";

export type State = Loaded<Analytics>;

enum TypeKeys {
    LOADING = "ANALYTICS_DASHBOARD_URL/LOAD_BEGIN",
    SUCCESS = "ANALYTICS_DASHBOARD_URL/LOAD_SUCCESS",
    FAILURE = "ANALYTICS_DASHBOARD_URL/LOAD_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: Analytics) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type AnalyticsDashboardUrlLoadingAction = { type: TypeKeys.LOADING };
type AnalyticsDashboardUrlLoadedAction = { type: TypeKeys.SUCCESS; data: Analytics };
type AnalyticsDashboardUrlFailedAction = { type: TypeKeys.FAILURE; error: any };

type AnalyticsDashboardUrlAction =
    | AnalyticsDashboardUrlLoadingAction
    | AnalyticsDashboardUrlLoadedAction
    | AnalyticsDashboardUrlFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: AnalyticsDashboardUrlAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
