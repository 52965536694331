import { createSelector } from "reselect";
import { CatalogueItem, ProductSummary, StatusDictionary } from "features/catalogue/types";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getProductsListData } from "./getProductsList";

export const getProductCatalogueItems = createSelector(
    getProductsListData,
    getIsChildLocation,
    (products, isChildLocation): CatalogueItem[] => {
        return products.map((product) => {
            return {
                children: mapProductChildren(product, !!isChildLocation),
                id: product.id,
                type: "product",
                displayName: product.displayName,
                internalName: product.internalName,
                description: product.description,
                sku: product.sku,
                status: product.status,
                statusName: StatusDictionary[product.status] || StatusDictionary[0],
                price: product.price,
                categories: product.categories,
                validationStatus: product.validationStatus,
                validationStatusReason: product.validationStatusReason,
                image: product.thumbnailImage,
                key: product.id,
                energyContent: product.energyContent,
                templateType: product.type,
                isLinked: product.isLinked,
                templateShortName: product.templateShortName,
                isHidden: product.isHidden,
            };
        });
    }
);

const mapProductChildren = (product: ProductSummary, isChildLocation: boolean): CatalogueItem[] => {
    return product.variants
        ? product.variants.map((v) => {
              return {
                  id: v.id,
                  type: "variant",
                  displayName: v.displayName,
                  price: v.price || undefined,
                  sku: v.sku,
                  parentId: product.id,
                  validationStatus: v.validationStatus,
                  validationStatusReason: v.validationStatusReason,
                  key: `${product.id}-${v.id}`,
                  energyContent: v.energyContent,
                  isLinked: v.isLinked && isChildLocation,
                  isHidden: v.isHidden,
              };
          })
        : [];
};
