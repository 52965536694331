import { withSvg } from "core/components/svg/Svg";

export const Calendar = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M12 2C11.8232 2 11.6536 2.07024 11.5286 2.19526C11.4036 2.32029 11.3333 2.48986 11.3333 2.66667V7.33333C11.3333 7.51014 11.4036 7.67971 11.5286 7.80474C11.6536 7.92976 11.8232 8 12 8C12.1768 8 12.3464 7.92976 12.4714 7.80474C12.5964 7.67971 12.6667 7.51014 12.6667 7.33333V2.66667C12.6667 2.48986 12.5964 2.32029 12.4714 2.19526C12.3464 2.07024 12.1768 2 12 2ZM6.66667 2C6.48986 2 6.32029 2.07024 6.19526 2.19526C6.07024 2.32029 6 2.48986 6 2.66667V7.33333C6 7.51014 6.07024 7.67971 6.19526 7.80474C6.32029 7.92976 6.48986 8 6.66667 8C6.84348 8 7.01305 7.92976 7.13807 7.80474C7.2631 7.67971 7.33333 7.51014 7.33333 7.33333V2.66667C7.33333 2.48986 7.2631 2.32029 7.13807 2.19526C7.01305 2.07024 6.84348 2 6.66667 2ZM17.3333 2C17.1565 2 16.987 2.07024 16.8619 2.19526C16.7369 2.32029 16.6667 2.48986 16.6667 2.66667V7.33333C16.6667 7.51014 16.7369 7.67971 16.8619 7.80474C16.987 7.92976 17.1565 8 17.3333 8C17.5101 8 17.6797 7.92976 17.8047 7.80474C17.9298 7.67971 18 7.51014 18 7.33333V2.66667C18 2.48986 17.9298 2.32029 17.8047 2.19526C17.6797 2.07024 17.5101 2 17.3333 2Z" />
        <path d="M15.3334 7.33333C15.3316 7.72021 15.442 8.0993 15.6513 8.42467C15.8606 8.75004 16.1598 9.00772 16.5126 9.16648C16.8654 9.32525 17.2567 9.37827 17.639 9.31914C18.0214 9.26001 18.3783 9.09126 18.6667 8.83333V18.6667H5.33337V8.83333C5.62173 9.09126 5.97871 9.26001 6.36104 9.31914C6.74338 9.37827 7.13465 9.32525 7.48746 9.16648C7.84026 9.00772 8.13945 8.75004 8.34875 8.42467C8.55806 8.0993 8.66851 7.72021 8.66671 7.33333H10C10 7.86377 10.2108 8.37247 10.5858 8.74755C10.9609 9.12262 11.4696 9.33333 12 9.33333C12.5305 9.33333 13.0392 9.12262 13.4143 8.74755C13.7893 8.37247 14 7.86377 14 7.33333H15.3334ZM8.66671 4H10V6H8.66671V4Z" />
        <path d="M19.3333 4V6H20V20H4V6H4.66667V4H2V22H22V4H19.3333Z" />
        <path d="M14 4H15.3333V6H14V4Z" />
    </>
));
