import { Loaded } from "common/loader";
import { DailySalesDetails } from "../types";

export type State = Loaded<DailySalesDetails>;

enum TypeKeys {
    LOADING = "DAILY_SALES_DETAILS/LOAD_BEGIN",
    SUCCESS = "DAILY_SALES_DETAILS/LOAD_SUCCESS",
    FAILURE = "DAILY_SALES_DETAILS/LOAD_FAILURE",
}

export const createAction = {
    loading: () => ({ type: TypeKeys.LOADING }),
    loaded: (data: DailySalesDetails) => ({ type: TypeKeys.SUCCESS, data }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
};

type DailySalesDetailsLoadingAction = { type: TypeKeys.LOADING };
type DailySalesDetailsLoadedAction = { type: TypeKeys.SUCCESS; data: DailySalesDetails };
type DailySalesDetailsFailedAction = { type: TypeKeys.FAILURE; error: any };

type DailySalesDetailsAction =
    | DailySalesDetailsLoadingAction
    | DailySalesDetailsLoadedAction
    | DailySalesDetailsFailedAction;

const initialState: State = {
    status: "unloaded",
};

export function reducer(state: State = initialState, action: DailySalesDetailsAction): State {
    if (action.type === TypeKeys.LOADING) {
        return {
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { error } = action;

        return {
            status: "failed",
            error,
        };
    }

    return state;
}
