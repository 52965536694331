import styles from "./Switch.module.scss";

import { CheckMark, CloseSmall } from "common/icons";
import { FieldInputProps } from "formik";
import { Icon } from "core/components/icon/Icon";
import classNames from "classnames";
import warnOnce from "warn-once";
import { useFormElementTestAttributes } from "core/components/testAttributes";
import { ChangeEvent, useCallback } from "react";

type InputProps = React.HTMLProps<HTMLInputElement>;
export interface Props {
    disabled?: boolean;
    className?: string;
    field: Partial<FieldInputProps<any>>;
    onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => boolean | void;
}

export const Switch = ({ disabled, field, form, children, className, onChange, ...rest }: InputProps & Props) => {
    // form and children are not used, but destructured so they are not passed in ...rest
    const { checked, value, name } = field || {};

    const getTestAttributes = useFormElementTestAttributes();

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const checked = event.target?.value === "true";
            const proceed = onChange?.(checked, event);
            if (proceed !== false) {
                field?.onChange?.(event);
            }
        },
        [field, onChange]
    );

    warnOnce(
        field.checked === undefined || field.checked === null,
        `Switch did not receive a field.checked property for \`${field?.name}\`. Did you forget to add type="checkbox" to Field props?`
    );

    return (
        <label className={classNames(styles.switchContainer, className, disabled && styles.disabled)}>
            <input
                type="checkbox"
                disabled={disabled}
                {...field}
                value={value ?? ""}
                {...getTestAttributes("switch", name)}
                onChange={handleChange}
                {...rest}
            />
            <div className={styles.pip}>
                <Icon size="tiny" className={styles.switchIcon}>
                    {checked ? <CheckMark /> : <CloseSmall />}
                </Icon>
            </div>
        </label>
    );
};
