import styles from "./PosCatalogueImportPage.module.scss";

import { Button } from "core/components/button";
import { Form, Formik } from "formik";
import { LocationComponentProps, LocationLocaleContext, withLocation } from "features/location";
import { PageHeader } from "core/components/pageHeader";
import {
    CatalogueItemSelections,
    PosCatalogueImportItem,
    PosCatalogueImportSelections,
    PosCatalogueUpload,
} from "../types";
import { Link, RouteComponentProps, useParams } from "react-router-dom";
import { RouteTabPanel, RouteTabs } from "core/components/tabs";
import { useCallback, useContext, useMemo, useState } from "react";
import { useCurrencyFormatter, useLoadStatus } from "common/hooks";
import { useLocation } from "react-router";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { useSelector } from "react-redux";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { getState } from "common/selectors/getState";
import { fetchPosCatalogue, upload } from "../actions";
import { PageLoading } from "core/components/pageLoading";
import { PageError } from "core/components/pageError";
import { withPermissions } from "common/permissions/components/withPermission";
import { getModifierPosCatalogueItems, getProductPosCatalogueImportItems } from "../selectors/getPosCatalogueItems";
import { AngleDown } from "common/icons";
import { exportProductsCSV } from "../actions/exportProductsCSV";
import { exportModifiersCSV } from "../actions/exportModifiersCSV";
import { getSelectionInfo } from "../selectors/getSelectionInfo";
import { ModalImportConfirm } from "./ModalImportConfirm";
import { ModalRenderer } from "core/components/modal";
import { ListItemsTab } from "./ListItemsTab";
import { modifier, product } from ".";
import { trackEventItemAdd } from "../actions/trackEventImportPos";
import { ActionsPopup } from "core/components/actionsPopup/ActionsPopup";
import { Card } from "core/components/card";
import { FiltersProvider } from "../context/FiltersContext";
import { Prompt } from "core/components/modal/Prompt";
import { Location } from "history";
import { splitPathOnLastPart } from "common/utility/routeUtils";
import { createAction } from "../reducers/upload";
import { useAppDispatch } from "common/hooks/useAppDispatch";
import { getPosCatalogueHasNestedModifiers } from "../selectors/getPosCatalogueHasNestedModifiers";

interface Props {}

export enum TabKeys {
    Products = "products",
    Modifiers = "modifiers",
}

export const PosCatalogueImportPageComponent = ({
    restaurantLocation,
}: Props & LocationComponentProps & RouteComponentProps<any>) => {
    const location = useLocation();
    const { search } = location;

    const params = useParams<{ region: string; location: string }>();
    const locationLocale = useContext(LocationLocaleContext);
    const basePath = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
    const formatCurrency = useCurrencyFormatter(locationLocale).format;

    const products = useSelector(getProductPosCatalogueImportItems);
    const modifiers = useSelector(getModifierPosCatalogueItems);

    const permissions = useSelector(getCrudPermissions).catalogue;

    const dispatch = useAppDispatch();

    const state = useSelector(getState);

    const disableFields = !permissions.canImportPos;

    const [productsPageIndex, setProductsPageIndex] = useState(1);
    const [modifiersPageIndex, setModifiersPageIndex] = useState(1);
    const [showImportModal, setShowImportModal] = useState(false);

    const uploadStatus = useLoadStatus([state.posCatalogueImport.upload.status]);

    const hasNestedModifiers = useSelector(getPosCatalogueHasNestedModifiers);

    const handleSubmit = useCallback(
        (values: PosCatalogueImportSelections) => {
            setShowImportModal(true);
            trackEventItemAdd(state, values);
        },
        [state]
    );

    const handleConfirm = useCallback(
        (values: PosCatalogueImportSelections) => {
            const catalogue: PosCatalogueUpload = {
                products: getItemSelections(values.products, products),
                modifiers: getItemSelections(values.modifiers, modifiers),
                includeProductModifierRelationships: values.includeProductModifierRelationships,
                includeNestedModifiers: values.includeNestedModifiers,
            };

            dispatch(upload(restaurantLocation.id, catalogue));

            return false;
        },
        [dispatch, products, modifiers, restaurantLocation?.id]
    );

    const handleCancel = useCallback(() => {
        if (uploadStatus === "loaded") {
            dispatch(createAction.reset());
        }
        setShowImportModal(false);
    }, [dispatch, uploadStatus]);

    const handleBlockTransition = useCallback(
        (newLocation: Location | null) => {
            const currentPathParts = splitPathOnLastPart(location);
            const newPathParts = newLocation ? splitPathOnLastPart(newLocation) : [];

            // block any transition except between tabs
            return currentPathParts[0] !== newPathParts[0];
        },
        [location]
    );

    const initialValues: PosCatalogueImportSelections = {
        products: {},
        modifiers: {},
        includeProductModifierRelationships: true,
        includeNestedModifiers: hasNestedModifiers,
    };

    const cataloguePath = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return `/${params.region}/${params.location}/menu/catalogue/${searchParams.get("from") || "products"}`;
    }, [params.region, params.location, search]);

    const fetch = useCallback(() => dispatch(fetchPosCatalogue(params.location)), [dispatch, params.location]);

    const exportProducts = useCallback(() => {
        dispatch(exportProductsCSV(null));
    }, [dispatch]);

    const exportModifiers = useCallback(() => {
        dispatch(exportModifiersCSV(null));
    }, [dispatch]);

    const exportSelectedProducts = useCallback(
        (selections: CatalogueItemSelections) => {
            dispatch(exportProductsCSV(selections));
        },
        [dispatch]
    );

    const exportSelectedModifiers = useCallback(
        (selections: CatalogueItemSelections) => {
            dispatch(exportModifiersCSV(selections));
        },
        [dispatch]
    );

    const fetchStatus = useLoadStatus([state.posCatalogueImport.list.status], fetch, {
        refetchOnMount: true,
    });

    if (fetchStatus === "loading" || fetchStatus === "unloaded") {
        return <PageLoading message="Loading POS..." />;
    }

    if (fetchStatus === "failed") {
        return (
            <div className={styles.errorContainer}>
                <PageError
                    heading="Oh no! POS failed to connect"
                    message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
                    actions={
                        <>
                            <Button as={Link} to={cataloguePath} role="secondary">
                                Go back to catalogue
                            </Button>
                            <Button onClick={fetch}>Retry</Button>
                        </>
                    }
                />
            </div>
        );
    }

    return (
        <FiltersProvider>
            <Formik
                initialValues={initialValues}
                initialTouched={{ products: {}, modifiers: {}, includeProductModifierRelationships: false }}
                onSubmit={handleSubmit}
            >
                {(form) => (
                    <div className={styles.container}>
                        <PageHeader
                            title="Import from POS"
                            actions={
                                <ActionsPopup
                                    buttonProps={{
                                        children: (
                                            <>
                                                <span>Export CSV</span>
                                                <AngleDown />
                                            </>
                                        ),
                                    }}
                                >
                                    <Card cardStyle="popup">
                                        <Button
                                            className={styles.popupAction}
                                            shape="listItem"
                                            type="button"
                                            onClick={exportProducts}
                                        >
                                            Export All Products
                                        </Button>
                                        <Button
                                            className={styles.popupAction}
                                            shape="listItem"
                                            type="button"
                                            onClick={exportModifiers}
                                        >
                                            Export All Modifiers
                                        </Button>
                                        <Button
                                            className={styles.popupAction}
                                            shape="listItem"
                                            type="button"
                                            onClick={() => exportSelectedProducts(form.values.products)}
                                            disabled={!Object.keys(form.values?.products || {}).length}
                                        >
                                            Export selected Products
                                        </Button>
                                        <Button
                                            className={styles.popupAction}
                                            shape="listItem"
                                            type="button"
                                            onClick={() => exportSelectedModifiers(form.values.modifiers)}
                                            disabled={!Object.keys(form.values?.modifiers || {}).length}
                                        >
                                            Export selected Modifiers
                                        </Button>
                                    </Card>
                                </ActionsPopup>
                            }
                            backLink={cataloguePath}
                            subtitle="Import updated products and modifiers from your POS system into your me&u catalogue. Select the
                    products or modifiers you want to add to the catalogue."
                        />

                        <Form className={styles.form} id="pos-catalog-import">
                            <RouteTabs className={styles.tabsContainer}>
                                <RouteTabPanel
                                    className={styles.tabPanel}
                                    id="products"
                                    label="Products"
                                    route={`${basePath}/products`}
                                >
                                    <ListItemsTab
                                        collectionName="products"
                                        items={products}
                                        headers={product.Headers}
                                        listItem={product.ListItem}
                                        permissions={permissions}
                                        formatCurrency={formatCurrency}
                                        pageIndex={productsPageIndex}
                                        pageSizeStorageKey="posImportProductsPageSize"
                                        setPageIndex={setProductsPageIndex}
                                        relatedCollectionName="modifiers"
                                    />
                                </RouteTabPanel>
                                <RouteTabPanel id="modifiers" label="Modifiers" route={`${basePath}/modifiers`}>
                                    <ListItemsTab
                                        collectionName="modifiers"
                                        items={modifiers}
                                        headers={modifier.Headers}
                                        listItem={modifier.ListItem}
                                        permissions={permissions}
                                        formatCurrency={formatCurrency}
                                        pageIndex={modifiersPageIndex}
                                        pageSizeStorageKey="posImportModifiersPageSize"
                                        setPageIndex={setModifiersPageIndex}
                                    />
                                </RouteTabPanel>
                            </RouteTabs>
                            <ActionFooter>
                                <StatusMessage
                                    dirtyMessage={
                                        getSelectionInfo({ selections: form.values, products, modifiers }).parentSummary
                                    }
                                />
                                {!disableFields && (
                                    <Button type="submit" disabled={!form.dirty}>
                                        Add to Catalogue
                                    </Button>
                                )}
                            </ActionFooter>
                        </Form>
                        <ModalRenderer target="#modal">
                            <ModalImportConfirm
                                visible={showImportModal}
                                onClose={handleCancel}
                                onConfirm={() => handleConfirm(form.values)}
                                info={getSelectionInfo({ selections: form.values, products, modifiers })}
                                restaurantLocation={restaurantLocation}
                                cataloguePath={cataloguePath}
                            />
                        </ModalRenderer>
                        <ModalRenderer target="#modal">
                            <Prompt
                                title="Unsaved selections"
                                when={form.dirty && uploadStatus !== "loaded"}
                                blockTransition={handleBlockTransition}
                                cancelLabel="Keep editing"
                                confirmLabel="Discard changes"
                            >
                                Add the selected items to the catalog before exiting the page, or continue without
                                making any changes.
                            </Prompt>
                        </ModalRenderer>
                    </div>
                )}
            </Formik>
        </FiltersProvider>
    );
};

export const PosCatalogueImportPage = withPermissions(
    withLocation(PosCatalogueImportPageComponent),
    "menudata:pos:import"
);

function getItemSelections(
    itemSelections: CatalogueItemSelections,
    items: PosCatalogueImportItem[]
): PosCatalogueImportItem[] {
    const selectedParentIds = Object.keys(itemSelections);

    if (selectedParentIds.length) {
        return items
            .filter(({ id }) => id && selectedParentIds.includes(id))
            .map((item) => {
                const childSelections = itemSelections[item.id!];
                const selectedChildIds = Array.isArray(childSelections) ? childSelections : [];
                const { children, ...rest } = item;
                return {
                    ...rest,
                    children:
                        children?.map(({ id, ...rest }) => ({
                            id,
                            selected: (id && selectedChildIds?.includes(id)) || false,
                            ...rest,
                        })) || [],
                };
            });
    }

    return [];
}
