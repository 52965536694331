import { AppDispatch, AppState } from "features/state";
import { createAction as activeLocationActions } from "../../location/reducers/active";
import { createAction as activeGroupActions } from "../../group/reducers/active";
import { RoleOption } from "../types/RolesType";
import { createRoleActions } from "../reducers/fakeRoleReducer";
import { Scope } from "features/scope";

export const fakeRole =
    (scope: Scope, role?: RoleOption) => async (dispatch: AppDispatch, getState: () => AppState) => {
        if (scope === Scope.GROUP) {
            dispatch(activeGroupActions.overridePermission(role?.value));
        } else {
            dispatch(activeLocationActions.overridePermission(role?.value));
        }

        dispatch(createRoleActions.setFakeRole(role));
    };
