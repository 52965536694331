import { PublishSuccessAction, TypeKeys as PublishTypeKeys } from "./publish";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { TypeKeys as EditTypeKeys } from "./edit";
import { LocationSummary } from "..";

enum TypeKeys {
    LOADING = "LOCATION/LIST_BEGIN",
    LOADED = "LOCATION/LIST_SUCCESS",
    FAILED = "LOCATION/LIST_FAILURE",
    RELOAD = "LOCATION/LIST_RELOAD",
}

const list = scaffoldList<LocationSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction: listCreateAction, reducer: listReducer, initialState } = list;

export type State = ReturnType<typeof list.reducer>;

export const createAction = {
    ...listCreateAction,
    reload: () => ({ type: TypeKeys.RELOAD }),
};

type ReloadAction = { type: TypeKeys.RELOAD };

export function reducer(state: State = initialState, action: PublishSuccessAction | ReloadAction | any): State {
    if (action.type === TypeKeys.RELOAD) {
        return {
            status: "unloaded",
        };
    }

    if (action.type === PublishTypeKeys.SUCCESS && state.status === "loaded") {
        const { id, lastPublished, lastPublishedUpdate, companyDetails } = action.data;

        return {
            ...state,
            data: state.data.map((l) => {
                if (l.id === id) {
                    return {
                        ...l,
                        lastPublished,
                        lastPublishedUpdate,
                        companyDetails,
                    };
                }
                return l;
            }),
        };
    }

    return listReducer(state, action);
}
