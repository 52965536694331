import { DailySalesReport } from "./components/DailySalesReport";
import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.dailySalesReport = reducer;

    config.addRoutes(config.routes.locationRoutes, [<DailySalesReport key="reports-dailysales" />]);
}
