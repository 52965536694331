import { useSelector } from "react-redux";
import styles from "./VenueTime.module.scss";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { useState } from "react";
import moment, { Moment } from "moment-timezone";
import { useInterval } from "common/hooks";

const UPDATE_INTERVAL = 1000;

export const VenueTime = () => {
    const activeLocation = useSelector(getActiveLocation);

    const [currentTime, setCurrentTime] = useState<Moment | null>(null);

    useInterval(() => {
        if (!activeLocation) {
            return;
        }

        const newTime = moment()
            .tz(activeLocation.timeZone || "Australia/Sydney")
            .startOf("minute");

        if (!currentTime || newTime.diff(currentTime) !== 0) {
            setCurrentTime(newTime);
        }
    }, UPDATE_INTERVAL);

    return <div className={styles.time}>{currentTime?.format("ddd h:mm A [GMT]Z")}</div>;
};
