import { ReactElement } from "react";
import styles from "./Spinner.module.scss";
import { Lottie, useLottie } from "react-lottie-hook";
import twoDotSpinner from "./twodotspinner.json";
import twoDotSpinnerWhite from "./twodotspinner-white.json";

interface Props {
    size?: "default" | "medium" | "large";
    colorScheme?: "dark" | "light";
}

export const Spinner = ({ size = "default", colorScheme = "dark" }: Props): ReactElement => {
    const [lottieRef] = useLottie({
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            progressiveLoad: false,
        },
        animationData: colorScheme === "light" ? twoDotSpinnerWhite : twoDotSpinner,
    });

    return (
        <div className={styles.spinner}>
            <div className={styles[`icon--${size}`]}>
                <Lottie lottieRef={lottieRef} width={-1} height={-1} className="animated-svg" />
            </div>
        </div>
    );
};
