import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";
import { TypeKeys as EditTypeKeys } from "./edit";
import { TaxClassSummary } from "../types";

enum TypeKeys {
    LOADING = "TAXES/LIST_BEGIN",
    LOADED = "TAXES/LIST_SUCCESS",
    FAILED = "TAXES/LIST_FAILURE",
    RELOAD = "TAXES/LIST_RELOAD",
}
const list = scaffoldList<TaxClassSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
