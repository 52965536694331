import { opsApi } from "API";
import { GraphQLItem } from "common/scaffolding/types";
import { ItemValidationStatus } from "features/catalogue/types";

export const listModifiers = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.modifiers;
};

// FIXME these types should be in the types folder

type GraphQLPayload = { modifiers: GraphQLModifierSummary[] };

export interface GraphQLModifierSummary extends GraphQLItem {
    maxSelection: number;
    required: boolean;
    sku: string;
    options: Array<GraphQLModifierOptionSummary>;
    isLinked: boolean;
    isHidden?: boolean;
}

export interface GraphQLModifierOptionSummary {
    displayName: string;
    price: number;
    sku: string;
    validationStatus: ItemValidationStatus;
    validationStatusReason?: string | null;
    energyContent?: string | null;
    isLinked: boolean;
    isHidden?: boolean;
}

export const modifierListFields = `
    id,
    internalName,
    displayName,
    minSelection,
    maxSelection,
    maxSelectionPerOption,
    required,
    sku,
    status,
    options {
        id
        displayName,
        price,
        sku,
        validationStatus,
        validationStatusReason,
        recommended,
        modifiers,
        energyContent,
        isLinked
        isHidden
    },
    upsell,
    validationStatus,
    smartSorting,
    isLinked,
    isHidden,
    productVariantReferences {
        productId,
        variantId
    },
`;

const query = `
    query($locationId: ID!) {
	    modifiers(locationId: $locationId) {
            ${modifierListFields}
        }
    }
`;
