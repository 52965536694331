import { State as ListState, reducer as listReducer } from "features/catalogue/reducers/modifier/list";
import { State as EditState, reducer as editReducer } from "features/catalogue/reducers/modifier/edit";
import { combineReducers } from "redux";

export interface State {
    modifiers: {
        list: ListState;
        edit: EditState;
    };
}

export const reducer = combineReducers({
    list: listReducer as any, // I don't know why TS doesn't like this (the error described an overload having a return type that includes undefined)
    edit: editReducer,
});
