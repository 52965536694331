import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const CustomFeedbackPage = lazy(() =>
    import("features/customFeedback/components/edit/CustomFeedbackPage").then((module) => ({
        default: module.CustomFeedbackPage,
    }))
);

const EditCustomFeedbackPage = withPermissions(CustomFeedbackPage, "location:update");

export function CustomFeedback() {
    return (
        <Route
            key="custom-feedback"
            path={`/:region/venue/:location/post-payment-url`}
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <EditCustomFeedbackPage />
                </EditWrapper>
            )}
        />
    );
}
