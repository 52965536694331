import { isLoaded } from "common/loader/isLoaded";
import { AppState } from "features";
import { createSelector } from "reselect";

export const getPosCatalogueUpload = (state: AppState) =>
    isLoaded(state.posCatalogueImport.upload) ? state.posCatalogueImport.upload.data : null;

export const getPosCatalogueUploadSummary = createSelector(getPosCatalogueUpload, (upload) => {
    return upload?.summary;
});
