import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

type LocationListState = {
    filterText: string;
    setFilterText: Dispatch<SetStateAction<string>>;
    selectedNodeId: string;
    setSelectedNodeId: Dispatch<SetStateAction<string>>;
};

const LocationListContext = createContext<LocationListState>({
    filterText: "",
    setFilterText: () => {},
    selectedNodeId: "",
    setSelectedNodeId: () => {},
});

export const useLocationListContext = () => useContext(LocationListContext);

export const LocationListProvider = ({ children }: PropsWithChildren<{}>) => {
    const [filterText, setFilterText] = useState("");
    const [selectedNodeId, setSelectedNodeId] = useState("");

    return (
        <LocationListContext.Provider
            value={{
                filterText,
                setFilterText,
                selectedNodeId,
                setSelectedNodeId,
            }}
        >
            {children}
        </LocationListContext.Provider>
    );
};
