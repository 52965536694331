import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { TypeKeys as EditTypeKeys } from "./edit";
import { PackageSummary } from "..";

enum TypeKeys {
    LOADING = "PACKAGES/LIST_BEGIN",
    LOADED = "PACKAGES/LIST_SUCCESS",
    FAILED = "PACKAGES/LIST_FAILURE",
    RELOAD = "PACKAGES/LIST_RELOAD",
}
const list = scaffoldList<PackageSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
