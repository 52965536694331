import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

export const CalendarDateFormat = "YYYY-MM-DD";

type CalendarState = {
    headerPinned: boolean;
    setHeaderPinned: Dispatch<SetStateAction<boolean>>;
    weekStartDateString: string | undefined; // YYYY-MM-DD
    setWeekStartDateString: Dispatch<SetStateAction<string | undefined>>;
    singleDayDateString: string | undefined; // YYYY-MM-DD
    setSingleDayDateString: Dispatch<SetStateAction<string | undefined>>;
};

const CalendarContext = createContext<CalendarState>({
    headerPinned: false,
    setHeaderPinned: () => {},
    weekStartDateString: undefined,
    setWeekStartDateString: () => {},
    singleDayDateString: undefined,
    setSingleDayDateString: () => {},
});

export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }: PropsWithChildren<{}>) => {
    const [headerPinned, setHeaderPinned] = useState(false);

    const [weekStartDateString, setWeekStartDateString] = useState<string | undefined>(undefined);
    const [singleDayDateString, setSingleDayDateString] = useState<string | undefined>(undefined);

    return (
        <CalendarContext.Provider
            value={{
                headerPinned,
                setHeaderPinned,
                weekStartDateString,
                setWeekStartDateString,
                singleDayDateString,
                setSingleDayDateString,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
