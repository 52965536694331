import { State as ListState, reducer as listReducer } from "./list";
import { State as EditState, reducer as editReducer } from "./edit";

import { combineReducers } from "redux";

export interface State {
    promotions: {
        list: ListState;
        edit: EditState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
});
