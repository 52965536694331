import { createContext, PropsWithChildren, useContext, useState } from "react";

type PopupState = {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
};

export const PopupContext = createContext<PopupState>({
    expanded: false,
    setExpanded: () => {},
});

export const usePopupContext = () => useContext(PopupContext);

export const PopupProvider = ({ children }: PropsWithChildren<{}>) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <PopupContext.Provider
            value={{
                expanded,
                setExpanded,
            }}
        >
            {children}
        </PopupContext.Provider>
    );
};
