import { AppState } from "features";
import { Props, RegionSelector } from "../components/RegionSelector";
import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { getAvailableRegionsOptions, getSelectedRegion } from "../selectors";

function mapStateToProps(state: AppState): DataProps<Props> {
    const availableRegions = getAvailableRegionsOptions(state);
    const selectedRegion = getSelectedRegion(state);

    return {
        availableRegions,
        selectedRegion,
    };
}

export const RegionSelectorContainer = connect(mapStateToProps)(RegionSelector);
