import { Option } from "core/components/form/select";

export enum SectionOrderFlowType {
    PAYANDORDER = "PAYANDORDER",
    OPENTABLE = "OPENTABLE",
    PAYONLY = "PAYONLY",
}

export const OrderFlowLabel = {
    [SectionOrderFlowType.PAYANDORDER]: "Order & Pay",
    [SectionOrderFlowType.OPENTABLE]: "Flex",
    [SectionOrderFlowType.PAYONLY]: "Pay Only",
};

export const orderFlowOptions: Option[] = Object.keys(SectionOrderFlowType).map((key) => {
    return { label: OrderFlowLabel[key], value: key };
});
