import { withSvg } from "core/components/svg/Svg";

export const Diamonds = withSvg(
    24,
    24
)(() => (
    <>
        <path
            d="M22.875 12L18.525 16.35L14.175 12L18.525 7.65L22.875 12Z"
            stroke="#878D96"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.35 18.525L12 22.875L7.64999 18.525L12 14.175L16.35 18.525ZM16.35 5.475L12 9.825L7.65 5.475L12 1.125L16.35 5.475Z"
            stroke="#878D96"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.8252 12L5.47519 16.35L1.12519 12L5.47519 7.65L9.8252 12Z"
            stroke="#878D96"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
