import { AppState } from "features";
import { createSelector } from "reselect";
import { isLoaded } from "common/loader/isLoaded";

export const getEditTeam = ({ team: { edit } }: AppState) => edit;

export const getEditTeamStatus = createSelector(getEditTeam, (edit) => {
    return edit.status;
});

export const getEditTeamData = createSelector(getEditTeam, (edit) => {
    return isLoaded(edit) ? edit.data : undefined;
});
