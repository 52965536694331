import { State as ListState, reducer as listReducer } from "./list";

import { combineReducers } from "redux";

export interface State {
    auditlog: {
        list: ListState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
});
