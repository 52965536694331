import { region } from "../../region";
import { config } from "common/config";

if (!config.VITE_ORDER_APP_URL) {
    throw Error("Order App URL is not configured");
}

const previewUrl: string = `${config.VITE_ORDER_APP_URL}/preview`;

const previewOrigin: string = new URL(previewUrl).origin;

export class PreviewWindow {
    private initPromise: Promise<void>;

    constructor(private previewWindow: Window | null, private serviceId: string, private membershipLevelId?: string) {
        this.initPromise = this.waitForInit().catch(() => this.close());
    }

    get closed() {
        return !this.previewWindow || this.previewWindow.closed;
    }

    close() {
        if (this.previewWindow) {
            this.previewWindow.close();
        }
    }

    private waitForInit() {
        return new Promise<void>((resolve, reject) => {
            if (this.closed) {
                reject(new Error("preview window is closed or failed to open"));
            }

            let initTimeout = setTimeout(() => {
                window.removeEventListener("message", handleInit);
                reject(new Error("Timed out waiting for preview window to initialize"));
            }, 20000);

            const handleInit = (e: MessageEvent) => {
                if (
                    e.source === this.previewWindow &&
                    e.origin === previewOrigin &&
                    e.data &&
                    e.data.type === "previewReady"
                ) {
                    window.clearTimeout(initTimeout);
                    window.removeEventListener("message", handleInit);
                    resolve();
                }
            };

            window.addEventListener("message", handleInit);
        });
    }

    async setMenuData(menuData: {}) {
        await this.initPromise;

        this.previewWindow!.postMessage(
            {
                type: "setPreviewMenuData",
                menuData,
                serviceId: this.serviceId,
                membershipLevelId: this.membershipLevelId,
                region: region.getActiveRegion(),
            },
            previewOrigin
        );

        this.previewWindow!.focus();
    }

    static open(serviceId: string, membershipLevelId?: string) {
        const handle = window.open(previewUrl, undefined, "width=411,height=731,resizable=no");

        return new PreviewWindow(handle, serviceId, membershipLevelId);
    }
}
