export const toServiceTime = (time: moment.Moment) => time.hour() * 100 + time.minute();

export const isWithinTimes = (startTime: number, endTime: number, now: moment.Moment) => {
    const time = toServiceTime(now);
    return endTime < startTime ? time < endTime || time >= startTime : time >= startTime && time < endTime;
};

export const hoursFromTime = (time: number) => {
    const hours = Math.floor(time / 100);
    const mins = (time % 100) / 60;
    return hours + mins;
};
