import { parse } from "graphql/language/parser";
import { print } from "graphql/language/printer";
import { DocumentNode, OperationDefinitionNode, FieldNode, ExecutionResult } from "graphql";

export class PendingQuery {
    public sourceSelectionNames: string[][] = [];

    private document: DocumentNode;
    public promise: Promise<ExecutionResult<any>>;

    constructor(
        public query: string,
        public variables: {},
        invoker: (query: string, variables: {}) => Promise<ExecutionResult<any>>
    ) {
        this.document = parse(query);

        this.sourceSelectionNames.push(getDocumentSourceSelectionNames(this.document));

        this.validateVariables(this.document, variables);

        this.promise = new Promise<ExecutionResult<any>>((res, rej) => {
            setTimeout(() => {
                invoker(print(this.document), this.variables).then(res, rej);
            });
        });
    }

    tryMerge(query: string, variables?: {}) {
        const parsedQuery = parse(query);

        this.validateVariables(parsedQuery, variables);

        const definition = parsedQuery.definitions[0];

        if (definition.kind !== "OperationDefinition") {
            return false;
        }

        const selections = definition.selectionSet.selections;

        const sourceDefinition = this.document.definitions[0] as OperationDefinitionNode;
        const sourceSelectionSet = sourceDefinition.selectionSet;

        if (definition.operation !== sourceDefinition.operation) {
            return false;
        }

        const sourceSelectionNames = sourceSelectionSet.selections
            .filter((s) => s.kind === "Field")
            .map((s) => fieldAlias(s as FieldNode));

        if (!selections.every((s) => s.kind !== "Field" || sourceSelectionNames.indexOf(fieldAlias(s)) === -1)) {
            return false;
        }

        const sourceVariables = sourceDefinition.variableDefinitions!.map((vd) => vd.variable.name.value);

        if (variables) {
            for (let variableName of Object.getOwnPropertyNames(variables)) {
                if (
                    this.variables.hasOwnProperty(variableName) &&
                    this.variables[variableName] !== variables[variableName]
                ) {
                    return false;
                }
            }
        }

        this.sourceSelectionNames.push(getDocumentSourceSelectionNames(parsedQuery));

        const uniqueVariableDefinitions = definition.variableDefinitions!.filter(
            (vd) => !sourceVariables.some((vr) => vd.variable.name.value === vr)
        );

        this.document = {
            ...this.document,
            definitions: [
                {
                    ...sourceDefinition,
                    selectionSet: {
                        ...sourceSelectionSet,
                        selections: [...sourceSelectionSet.selections, ...selections],
                    },
                    variableDefinitions: [...sourceDefinition.variableDefinitions!, ...uniqueVariableDefinitions],
                },
            ],
        };

        if (variables) {
            this.variables = {
                ...this.variables,
                ...variables,
            };
        }

        return true;
    }

    private validateVariables(document: DocumentNode, variables?: {}) {
        const { variableDefinitions } = document.definitions[0] as OperationDefinitionNode;

        if (!variableDefinitions || variableDefinitions.length === 0) {
            return;
        }

        for (let i = 0; i < variableDefinitions.length; i++) {
            const variableDefinition = variableDefinitions[i];

            if (variableDefinition.type.kind === "NonNullType") {
                const variableName = variableDefinition.variable.name.value;

                if (!variables || variables[variableName] === undefined || variables[variableName] === null) {
                    throw new Error(`No value provided for non-nullable variable $${variableName}`);
                }
            }
        }

        const definedVariableNames = variableDefinitions.map((vd) => vd.variable.name.value);

        const variableNames = Object.getOwnPropertyNames(variables);

        for (let i = 0; i < variableNames.length; i++) {
            if (definedVariableNames.indexOf(variableNames[i]) === -1) {
                throw new Error(`Unused variable $${variableNames[i]} provided`);
            }
        }
    }
}

const fieldAlias = (n: FieldNode) => (n.alias ? n.alias.value : n.name.value);

function getDocumentSourceSelectionNames(doc: DocumentNode): string[] {
    const definition = doc.definitions[0];

    if (definition.kind !== "OperationDefinition") {
        return [];
    }

    const sourceDefinition = definition as OperationDefinitionNode;
    const sourceSelectionSet = sourceDefinition.selectionSet;

    if (definition.operation !== sourceDefinition.operation) {
        return [];
    }

    return sourceSelectionSet.selections.filter((s) => s.kind === "Field").map((s) => fieldAlias(s as FieldNode));
}
