import { withSvg } from "core/components/svg/Svg";

export const ArrowLeft = withSvg(
    24,
    24
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.25 11.9994C4.25 11.4471 4.69772 10.9994 5.25 10.9994H18.75C19.3023 10.9994 19.75 11.4471 19.75 11.9994C19.75 12.5517 19.3023 12.9994 18.75 12.9994H5.25C4.69772 12.9994 4.25 12.5517 4.25 11.9994Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2071 6.04302C11.5976 6.43354 11.5976 7.0667 11.2071 7.45723L6.66421 12.0001L11.2071 16.543C11.5976 16.9335 11.5976 17.5667 11.2071 17.9572C10.8166 18.3478 10.1834 18.3478 9.79289 17.9572L4.54289 12.7072C4.15237 12.3167 4.15237 11.6835 4.54289 11.293L9.79289 6.04302C10.1834 5.65249 10.8166 5.65249 11.2071 6.04302Z"
        />
    </>
));
