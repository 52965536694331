import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { teamsRoute } from "../util/routes";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { useRouteMatch } from "react-router-dom";

const ListTeamsPage = lazy(() =>
    import("./ListTeamsPage").then((module) => ({
        default: module.ListTeamsPage,
    }))
);

const TeamsWrapper = () => {
    const { isExact } = useRouteMatch({ path: teamsRoute }) || {};

    // Hide teams list when route doesn't match exactly; keep mounted so we don't lose pagination/scroll state

    return (
        <ListWrapper visible={isExact} name="teams" contentWidth={ContentWidth.STANDARD}>
            <ListTeamsPage />
        </ListWrapper>
    );
};

export function Teams() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_TEAMS}>
            <TeamsWrapper />
        </TestContext.Provider>
    );
}
