import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { ComponentType } from "react";
import { PosCatalogueImportItem } from "../types";
import { product, modifier } from ".";

interface CommonProps {
    item: PosCatalogueImportItem;
    formatCurrency: (x: number) => string;
    permissions: ExtendedPermissions;
    component: ComponentType<product.ListItemProps> | ComponentType<modifier.ListItemProps>;
}

type CollectionProps =
    | { collectionName: "modifiers" | "products"; relatedCollectionName?: never }
    | { collectionName: "products"; relatedCollectionName: "modifiers" };

type Props = CommonProps & CollectionProps;

// Renders catalogue list item e.g. product | modifier group and children as a flattened list fragment
export const ListItemGroup = ({ component: Component, item, formatCurrency, permissions }: Props) => {
    const disableSelection = !permissions.canImportPos;

    const collapse = item.collapse;

    return (
        <>
            <Component
                disableSelection={disableSelection}
                parentItem={null}
                item={item}
                formatCurrency={formatCurrency}
            />
            {!collapse &&
                item.children?.map((childItem) => (
                    <Component
                        key={childItem.key}
                        disableSelection={disableSelection}
                        item={childItem}
                        parentItem={item}
                        formatCurrency={formatCurrency}
                    />
                ))}
        </>
    );
};
