import { CrudRoute } from "common/scaffolding/components/CrudRoute";
import { lazy } from "react";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ListSectionsPageContainer = lazy(() =>
    import("../containers/ListSectionsPageContainer").then((module) => ({
        default: module.ListSectionsPageContainer,
    }))
);

const EditSectionPageContainer = lazy(() =>
    import("../containers/EditSectionPageContainer").then((module) => ({
        default: module.EditSectionPageContainer,
    }))
);

export function Section() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_SECTION}>
            <CrudRoute
                name="sections"
                section={null}
                listComponent={withPermissions(ListSectionsPageContainer, "section:read")}
                editComponent={withPermissions(EditSectionPageContainer, "section:read")}
            />
        </TestContext.Provider>
    );
}
