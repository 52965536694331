import styles from "./PublishErrors.module.scss";

import { Popover, List } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PublishError } from "features/location/selectors/publishResultSelectors";
import { NavLink } from "react-router-dom";

export interface Props {
    region: string;
    location: string;
    visible: boolean;
    errors: PublishError[];
}

const PublishErrors = ({ region, location, visible, errors }: Props) => {
    if (!visible) {
        return null;
    }

    function hideErrors() {
        // If you can find a better way to
        // close the Popover, then please delete this code.
        const clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent("mousedown", true, true);
        document.body.dispatchEvent(clickEvent);
    }

    const popup = (
        <div className="">
            <List
                size="small"
                className={styles.list}
                dataSource={errors}
                renderItem={({ message, link }) => (
                    <List.Item>
                        {link ? (
                            <NavLink onClick={hideErrors} to={`/${region}/venue/${location}${link}`}>
                                {message}
                            </NavLink>
                        ) : (
                            <>{message}</>
                        )}
                    </List.Item>
                )}
            />
        </div>
    );

    return (
        <div className={styles.error}>
            <Popover placement="bottomRight" title="Publish Validation Failed" content={popup} trigger="click">
                <ExclamationCircleOutlined />
            </Popover>
        </div>
    );
};
export { PublishErrors };
