import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import * as actions from "./actions";

export * from "./types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.membershipLevels = reducer;
}
