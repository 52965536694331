import React from "react";
import { RouteComponentProps } from "react-router";
import { LoginPageContainer } from "features/login/containers/LoginPageContainer";

interface GetAuthObj {
    (): any;
}

export const withLogin =
    <P extends Object>(
        Component: React.ComponentType<P>,
        getAuthObj: GetAuthObj
    ): React.FC<P & RouteComponentProps<any>> =>
    (props) => {
        const auth = getAuthObj();
        if (auth.isAuthenticated()) {
            return <Component {...props} />;
        } else {
            return <LoginPageContainer auth={auth} {...props} />;
        }
    };
