import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const SurchargesPageContainer = lazy(() =>
    import("../containers/SurchargesPageContainer").then((module) => ({
        default: module.SurchargesPageContainer,
    }))
);

export function Surcharges() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_SURCHARGE}>
            <Route
                path={`/:region/venue/:location/menu/surcharges`}
                exact
                render={() => (
                    <Col span={24} md={24} className="content__col content__col--white content__col--surcharges">
                        <div className="content__col-edit">
                            <Route component={withPermissions(SurchargesPageContainer, "surcharges:read")} />
                        </div>
                    </Col>
                )}
            />
        </TestContext.Provider>
    );
}
