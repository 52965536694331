import { Loaded } from "./types";

export function isLoaded<T>(loaded: Loaded<T>): loaded is { status: "loaded"; data: T } {
    return loaded.status === "loaded";
}

export function ignoreFailed<T>(loaded: Loaded<T>, defaultValue: () => T): Loaded<T> {
    if (loaded.status === "failed") {
        return {
            status: "loaded",
            data: defaultValue(),
        };
    }

    return loaded;
}
