import { createSelector } from "reselect";
import { AppState } from "features/state";
import { isLoaded } from "common/loader/isLoaded";
import { CatalogueItem, CatalogueModifierItem } from "features/catalogue";

export const getPosCatalogue = (state: AppState) =>
    isLoaded(state.posCatalogue.list) ? state.posCatalogue.list.data : null;

export const getProductPosCatalogueItems = createSelector(getPosCatalogue, (posCatalogue): CatalogueItem[] => {
    return !posCatalogue
        ? []
        : posCatalogue.products.map((posProduct) => {
              const categories = posCatalogue.categories.filter((c) => c.products.indexOf(posProduct.id) !== -1);

              const modifierIds = Array.from(
                  new Set(
                      Array.prototype.concat.apply(
                          [],
                          posProduct.variants.map((v) => v.modifiers)
                      )
                  )
              );

              const modifierChildren: CatalogueModifierItem[] = modifierIds
                  .map((id): CatalogueModifierItem => {
                      const modifier = posCatalogue.modifiers.find((m) => m.id === id);

                      if (!modifier) {
                          return undefined!;
                      }

                      return {
                          id: modifier.id,
                          key: posProduct.id + "-" + modifier.id,
                          sku: modifier.id,
                          parentId: posProduct.id,
                          maxSelection: modifier.maxSelection,
                          minSelection: modifier.minSelection,
                          maxSelectionPerOption: modifier.maxSelectionPerOption,
                          type: "modifier",
                          displayName: modifier.displayName,
                          validationStatus: "VALID",
                          isLinked: false,
                          isHidden: false,
                      };
                  })
                  .filter((c) => c !== undefined);

              if (posProduct.variants.length === 1) {
                  const posVariant = posProduct.variants[0];

                  return {
                      id: posProduct.id,
                      key: posProduct.id,
                      sku: posProduct.variants[0].sku,
                      displayName: posProduct.displayName,
                      validationStatus: "VALID",
                      price: posVariant.price,
                      image: posProduct.imageUrl || undefined,
                      categories,
                      children: modifierChildren,
                      isLinked: false,
                      isHidden: false,
                  };
              } else {
                  const variantChildren = posProduct.variants.map((posVariant, index) => ({
                      id: posVariant.id,
                      parentId: posProduct.id,
                      key: `${posProduct.id}-${posVariant.id}-${index}`,
                      displayName: posVariant.displayName,
                      sku: posVariant.sku,
                      price: posVariant.price,
                      validationStatus: "VALID",
                      isLinked: false,
                      isHidden: false,
                  }));

                  const allChildren = Array.prototype.concat.apply(variantChildren, modifierChildren);

                  return {
                      id: posProduct.id,
                      key: posProduct.id,
                      displayName: posProduct.displayName,
                      validationStatus: "VALID",
                      children: allChildren,
                      categories,
                      isLinked: false,
                      isHidden: false,
                  };
              }
          });
});

export const getModifierPosCatalogueItems = createSelector(getPosCatalogue, (posCatalogue): CatalogueModifierItem[] => {
    return !posCatalogue
        ? []
        : posCatalogue.modifiers.map((posModifier) => {
              return {
                  id: posModifier.id,
                  key: posModifier.id,
                  displayName: posModifier.displayName,
                  validationStatus: "VALID",
                  sku: posModifier.id,
                  maxSelection: posModifier.maxSelection,
                  minSelection: posModifier.minSelection,
                  maxSelectionPerOption: posModifier.maxSelectionPerOption,
                  children: posModifier.options.map((posOption, index) => {
                      const modifier = posOption.modifier
                          ? posCatalogue.modifiers.find((m) => m.id === posOption.modifier)
                          : null;

                      return {
                          id: posOption.id,
                          parentId: posModifier.id,
                          key: `${posModifier.id}-${posOption.sku}-${index}`,
                          type: modifier ? "modifier" : "option",
                          displayName: posOption.displayName,
                          internalName: modifier ? modifier.displayName : undefined,
                          sku: modifier ? `${posOption.sku} (${modifier.id})` : posOption.sku,
                          price: posOption.price,
                          validationStatus: "VALID",
                          isLinked: false,
                          isHidden: false,
                      };
                  }),
                  isLinked: false,
                  isHidden: false,
              };
          });
});

export const getSortedCategories = createSelector(getPosCatalogue, (posCatalogue) =>
    !posCatalogue ? [] : posCatalogue.categories.concat([]).sort((a, b) => a.displayName.localeCompare(b.displayName))
);
