import { createSelector } from "reselect";
import { getActiveGroupData } from "./getActiveGroup";
import { AppState } from "features/state";
import { EditableGroup } from "../types/EditableGroup";
import { ActiveGroup } from "../types/ActiveGroup";

export const getSaveGroupTrackingEventData = createSelector(
    getActiveGroupData,
    (_: AppState, update: EditableGroup) => update,
    (_: AppState, __: EditableGroup, saveResult?: ActiveGroup) => saveResult,
    (existing, update, saveResult) => {
        const changes: any = {
            group_id: update.id,
        };

        if (existing?.displayName !== update.displayName) {
            changes.display_name = update.displayName;
        }

        if (existing?.privacyPolicyUrl !== update.privacyPolicyUrl) {
            changes.privacy_policy_url = update.privacyPolicyUrl;
        }

        if (
            update.engage &&
            ((update.engage.active && !existing?.integrations?.length) ||
                existing?.integrations?.some(
                    (i) => (!update.engage?.id || update.engage.id === i.id) && update.engage?.active !== i.active
                ))
        ) {
            changes.engage_id = update.engage.id;
            changes.engage_is_new = !update.engage.id;
            changes.engage_active = update.engage.active;
        }

        return {
            event: {
                name: "GROUP/MODIFICATION_CHANGED",
            },
            customProperties: changes,
        };
    }
);
