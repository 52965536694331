import { Col } from "antd";
import { Route } from "react-router";
import { UsersPage } from ".";
import { withPermissions } from "common/permissions/components/withPermission";

export default function UserManagement() {
    return (
        <Route
            path={`/:region/group/:id/users`}
            exact
            render={() => (
                <>
                    <Col span={24} md={24} className={`content__col`}>
                        <div className="content__col-list-white">
                            <Route component={withPermissions(UsersPage, "usermanagement:export")} />
                        </div>
                    </Col>
                </>
            )}
        />
    );
}
