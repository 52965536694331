import { useEffect } from "react";

/* `handler` should be memoised for best performance */
export function useKeyboard(
    key: string,
    handler: (event: KeyboardEvent) => void,
    enable: boolean,
    eventType: "keydown" | "keypress" | "keyup" = "keydown"
) {
    useEffect(() => {
        const onKey = (event: KeyboardEvent) => {
            event.key === key && handler?.(event);
        };

        if (enable) {
            document.addEventListener(eventType, onKey);
        }

        return () => {
            document.removeEventListener(eventType, onKey);
        };
    }, [handler, enable, key, eventType]);
}
