import { opsApi } from "API";
import { Role } from "../types/RolesType";
import { UserType } from "features/user";

export const getRolesApi = async (): Promise<Role[]> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, {});
    return res.data.userFakeRoles.sort((a: Role, b: Role) =>
        a.userType === UserType.INTERNAL ? 1 : b.userType === UserType.INTERNAL ? -1 : 0
    );
};

type GraphQLPayload = { roles: Role[] };

const query = `
    query {
        userFakeRoles {
            shortName,
            description,
            displayName,
            isSystem,
            permissions,
            userType,
            featureScopes
        }
    }
`;
