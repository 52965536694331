import { useAppDispatch } from "common/hooks/useAppDispatch";
import { Button } from "core/components/button";
import { Select } from "core/components/form/select";
import { Team } from "core/components/icon/Team";
import { getOrigAuthInfo } from "features/location/selectors/getAuthInfo";
import { fakeRole } from "features/fakeRoles/actions/fakeRole";
import { getRolesAction } from "features/fakeRoles/actions/getRolesAction";
import { getDisplayableRoles, getSelectedFakeRole } from "features/fakeRoles/selectors/getFakeRoles";
import { RoleOption } from "features/fakeRoles/types/RolesType";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SingleValue } from "react-select";
import { useScopeContext } from "features/scope";

export const FakeRoleInput = () => {
    const roles = useSelector(getDisplayableRoles);
    const selectedFakeRole = useSelector(getSelectedFakeRole);
    const origAuth = useSelector(getOrigAuthInfo);
    const dispatch = useAppDispatch();
    const [showInput, setShowInput] = useState(!!origAuth);
    const [autoFocus, setAutoFocus] = useState(!selectedFakeRole);
    const scope = useScopeContext();

    const handleRoleSelect = useCallback(
        (selected: SingleValue<RoleOption>) => {
            setAutoFocus(!selected);
            dispatch(fakeRole(scope, selected ?? undefined));
        },
        [dispatch, scope]
    );

    useEffect(() => {
        dispatch(getRolesAction());
    }, [dispatch]);

    useEffect(() => {
        setShowInput(!!origAuth);
    }, [origAuth]);

    if (!roles?.length) {
        return null;
    }

    if (!showInput) {
        return (
            <Button type="button" role="secondary" padding="icon" onClick={() => setShowInput(true)}>
                <Team />
            </Button>
        );
    }

    return (
        <Select
            value={selectedFakeRole}
            blurInputOnSelect={false}
            placeholder="View as role"
            options={roles}
            search
            isClearable
            autoFocus={autoFocus}
            openMenuOnFocus
            closeMenuOnSelect
            onMenuClose={() => {
                setAutoFocus(!selectedFakeRole);
                setShowInput(!!selectedFakeRole);
            }}
            menuPlacement="auto"
            styles={{
                menu: ({ width, ...css }) => ({
                    ...css,
                    width: "max-content",
                }),
            }}
            onChange={handleRoleSelect}
        />
    );
};
