import { EditableMenu, MenuSummary } from "..";
import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";

export enum TypeKeys {
    FETCH_BEGIN = "MENU/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "MENU/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "MENU/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "MENU/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "MENU/SAVE_EDITABLE_SUCCESS",
    SAVE_FAILURE = "MENU/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditableMenu, MenuSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
