import { toTitleCase } from "common/utility/StringUtils";
import { EditableService, ServiceSummary } from "features/service/types";
import { AppState } from "features/state";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { isNew } from "common/utility/modelUtils";
import { DaysOfWeek } from "common/utility/dateUtils";

const getExistingService = ({ services: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : ({} as EditableService);
};

export const getArchiveServiceTrackingEventData = createSelector(
    [(state: AppState) => getExistingService(state), (_: AppState, service: EditableService) => service],
    (service: EditableService) => {
        const eventName = "SERVICES/DELETION_SAVED";
        const trackingData = {
            service_id: service.id,
        };
        return getTrackingData(eventName, trackingData);
    }
);

export const getSaveServiceTrackingEventData = createSelector(
    [
        (state: AppState) => getExistingService(state),
        (_: AppState, formData: EditableService) => formData,
        (_: AppState, __: EditableService, saveResult?: ServiceSummary) => saveResult,
    ],
    (existing, formData, saveResult) => {
        const serviceTrackingData = getServiceChanges(existing, formData, saveResult);
        const eventName = isNew(existing.id) ? "SERVICES/ADDITION_SAVED" : "SERVICES/MODIFICATION_SAVED";
        return getTrackingData(eventName, serviceTrackingData);
    }
);

export const getServiceChanges = (
    existing: EditableService,
    formData: EditableService,
    saveResult?: ServiceSummary
) => {
    const isNewRecord = isNew(formData.id);
    const serviceId = !isNewRecord ? formData.id : saveResult?.id;
    const changes: any = {};

    changes.timestamp = new Date().toISOString();

    if (serviceId) {
        changes.service_id = serviceId;
    }

    if (isNewRecord || existing.displayName !== formData.displayName) {
        changes.service_name = formData.displayName;
    }

    if (isNewRecord || existing.menuPriority !== formData.menuPriority) {
        changes.menu_order = formData.menuPriority;
    }

    if (isNewRecord || (existing && !isEqual(existing.categories, formData.categories))) {
        changes.categories = formData.categories;
    }

    if (isNewRecord || existing.featured !== formData.featured) {
        changes.featured_product = formData.featured;
    }

    if (isNewRecord || (existing && !isEqual(existing.sections, formData.sections))) {
        changes.sections = formData.sections;
    }

    if (isNewRecord || existing.priceList !== formData.priceList) {
        changes.price_list_id = formData.priceList;
    }

    if (isNewRecord || existing.surcharge !== formData.surcharge) {
        changes.surcharge = formData.surcharge;
    }

    if (isNewRecord || existing.available !== formData.available) {
        changes.availability = formData.available ? "enabled" : "disabled";
    }

    if (isNewRecord || existing.applyAlcoholicDrinksRestriction !== formData.applyAlcoholicDrinksRestriction) {
        changes.alcohol_restriction = !!formData.applyAlcoholicDrinksRestriction;
    }

    let schedulesChanged = isNewRecord || !isEqual(formData.schedules, existing.schedules.length);

    if (schedulesChanged) {
        changes.schedule = formData.schedules.map((schedule) => ({
            day: mapDaysOfWeekToNames(schedule.daysOfWeek),
            time_from: schedule.timeRanges[0].startTime,
            time_to: schedule.timeRanges[0].endTime,
        }));
    }

    if (isNewRecord || !isEqual(existing.dates, formData.dates)) {
        changes.date_ranges = formData.dates.map((d) => ({ from: d.startDate, to: d.endDate }));
    }

    return changes;
};

function mapDaysOfWeekToNames(daysOfWeek: DaysOfWeek[]) {
    return daysOfWeek.map((dow) => toTitleCase(DaysOfWeek[dow].substring(0, 3)));
}
