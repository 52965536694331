import { FieldProps } from "formik";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
import copy from "copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import "./QuickCopyInput.scss";

interface Props extends FieldProps<string> {
    readonly: boolean;
    disabled: boolean;
}

export const QuickCopyInput = ({ field: { value }, readonly, disabled }: Props): ReactElement => {
    const [copyHelp, setCopyHelp] = useState<string | null>(null);

    const timerRef = useRef<number | null>();

    const clearTimer = useCallback(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, [timerRef]);

    const reset = () => {
        setCopyHelp(null);
        timerRef.current = null;
    };

    const doCopy = () => {
        copy(value);
        setCopyHelp("Copied to clipboard");
        clearTimer();
        timerRef.current = window.setTimeout(() => {
            reset();
        }, 2000);
    };

    useEffect(
        () => () => {
            clearTimer();
        },
        [clearTimer]
    );

    return (
        <Form.Item className="quick-copy-input__container" validateStatus="success" help={copyHelp}>
            <Input
                className="quick-copy-input__field"
                value={value}
                onClick={doCopy}
                readOnly={readonly}
                disabled={disabled}
                onMouseOver={() => setCopyHelp("Click to copy")}
                onMouseOut={() => {
                    if (!timerRef.current) {
                        setCopyHelp(null);
                    }
                }}
            />
            <div className="quick-copy-input__icon-container">
                <CopyOutlined onClick={() => doCopy()} />
            </div>
        </Form.Item>
    );
};
