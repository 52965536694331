import { createSelector } from "reselect";
import { ActiveLocation } from "../../location";
import { getActiveLocation } from "../../location/selectors/getLocationPermissions";
import { EditableCustomFeedback } from "../types";

export const getCustomFeedback = createSelector(
    getActiveLocation,
    (location?: ActiveLocation): EditableCustomFeedback | undefined => {
        if (!location) return undefined;
        const { url, buttonText } = location.customFeedback ?? {};
        return {
            url,
            buttonText,
        };
    }
);
