import { FeatureConfiguration } from "..";

import { reducer } from "features/menuitemtemplate/reducers";

import * as actions from "features/menuitemtemplate/actions";

export * from "features/menuitemtemplate/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.menuItemTemplates = reducer;
}
