import styles from "./NavLayout.module.scss";

import { MenuContext } from "./MenuContainer";
import { useCallback, useContext, useRef } from "react";
import { useKeyboard } from "common/hooks";

export const NavLayout = ({ children }: React.PropsWithChildren<{}>) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { menuActive, setMenuActive } = useContext(MenuContext);

    const handleEscape = useCallback(() => {
        menuActive && setMenuActive(false);
    }, [menuActive, setMenuActive]);

    useKeyboard("Escape", handleEscape, menuActive);

    const isMenuItem = (target: HTMLElement | null) => {
        while (target && target !== ref.current) {
            if (target.getAttribute("role") === "menuitem" && target.getAttribute("aria-haspopup") !== "true") {
                return true;
            }
            target = target.parentElement;
        }

        return false;
    };

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        // overlay click
        if (e.target === ref.current) {
            e.stopPropagation();
            setMenuActive(false);
        }
        // child menu item
        if (isMenuItem(e.target as HTMLElement)) {
            setMenuActive(false);
        }
    };

    return (
        <div className={styles.container} onClick={handleClick} ref={ref}>
            {children}
        </div>
    );
};
