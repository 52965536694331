import { AppState } from "features/state";
import { DayOfWeekDictionary } from "features/surcharges/types";
import { createSelector } from "reselect";
import { ValidationError } from "../types/PublishResult";
import { getLocationPermissions } from "./getLocationPermissions";

const getPublishResult = (state: AppState) => state.locations.publish.result;

export const getHasPublishErrors = createSelector(getPublishResult, (result) => result && result.errors.length);

export type PublishError = {
    message: string;
    link?: string;
};

export const getPublishDisplayErrors = createSelector(
    getLocationPermissions,
    getPublishResult,
    (permissions, result) => {
        if (!result || !result.errors.length) {
            return [];
        }

        const useCataloguePage = permissions.has("catalogue:read");

        return result.errors.map((err) => formatPublishError(err, result.menuData, useCataloguePage));
    }
);

function formatPublishError(err: ValidationError, menuData: any, useCataloguePage: boolean): PublishError {
    const pathParts = err.path.split(".");

    const targetType = getTargetType(pathParts);
    const targetDisplayName = getTargetDisplayName(pathParts, menuData);
    const link = getTargetLink(pathParts, err.errorCode, menuData, useCataloguePage);
    const errorDisplay = formatErrorCode(err.errorCode);

    return {
        message:
            targetDisplayName.length > 0
                ? `${targetType} '${targetDisplayName}'${errorDisplay}`
                : `${targetType} ${errorDisplay}`,
        link,
    };
}

function getTargetType(parts: string[]) {
    switch (parts[0]) {
        case "items":
            if (parts.indexOf("priceLevels") !== -1) {
                return "Price level";
            }

            if (parts.indexOf("options") !== -1) {
                return "Modifier";
            }

            if (parts.indexOf("variants") !== -1) {
                return "Variant";
            }

            return "Item";
        case "modifiers":
            if (parts.indexOf("priceLevels") !== -1) {
                return "Price level";
            }
            return "Modifier";
        case "services":
            return "Service";
        case "sections":
            return "Section";
        case "surcharges":
            return "Surcharges";
        case "taxes":
            return "Taxes";
        case "categories":
            return "Category";
    }

    return parts[0];
}

function getTargetDisplayName(path: string[], menuData: any) {
    const displayParts: string[] = [];

    let target = menuData;

    for (let i = 0; i < path.length; i++) {
        if (path[i] === "surcharges" && menuData.surcharges && i < path.length - 2) {
            const surchargeType = menuData.surcharges[path[i + 1]];
            const surcharge = surchargeType[path[i + 2]];
            if (surcharge) {
                const label = surcharge.dayOfWeek ? DayOfWeekDictionary[surcharge.dayOfWeek] : surcharge.date;
                displayParts.push(`${label}`);
                continue;
            }
        }

        if (
            path.includes("priceLevels") &&
            (path[i] === "variants" || path[i] === "options" || (path[i] === "items" && !path.includes("variants")))
        ) {
            switch (path[i]) {
                case "variants":
                    displayParts.push(` - ${path[i + 2]}`);
                    break;
                case "items":
                    displayParts.push(` ${path[i + 2]}`);
                    break;
                case "options":
                    displayParts.push(` - ${path[i + 3]}`);
                    break;
                default:
                    break;
            }

            if (path[path.length - 2] === "priceLevels" && menuData.priceLists) {
                const priceList = menuData.priceLists[path[path.length - 1]];

                if (priceList) {
                    displayParts.push(` (${priceList.displayName})`);
                }
            }

            break;
        }

        target = target[path[i]];

        if (!target) {
            break;
        }

        if (typeof target === "object" && "displayName" in target) {
            const { displayName, internalName } = target;

            switch (path[i - 1]) {
                case "options":
                    displayParts.push(` (${displayName})`);
                    break;
                case "variants":
                    displayParts.push(` - ${displayName}`);
                    break;
                case "modifiers":
                    if (i === 1) {
                        displayParts.push(internalName || displayName);
                    }
                    break;
                default:
                    displayParts.push(internalName || displayName);
                    break;
            }
        }
    }

    return displayParts.join("");
}

function formatErrorCode(code: string) {
    if (code.indexOf("_sku") !== -1) {
        return " has an invalid SKU";
    }

    if (code.indexOf("_posid") !== -1) {
        return " has an invalid POS ID";
    }

    if (code === "price_level_missing_price") {
        return " has invalid price";
    }

    if (code === "invalid_featured_product") {
        return " has an invalid featured product";
    }

    if (code === "incomplete_product" || code === "incomplete_modifier") {
        return " has missing details";
    }

    if (code === "validation_status_none" || code === "validation_status_pending") {
        return " has not been validated with POS";
    }

    if (code === "validation_status_missing") {
        return " was not found in POS";
    }

    if (code === "validation_status_invalid") {
        return " is invalid for POS";
    }

    if (code === "surcharges_invalid_scope") {
        return " is missing food or drink";
    }

    if (code === "missing_taxes") {
        return " has missing tax classes";
    }

    if (code === "invalid_modifier_limit") {
        return " has an invalid modifier limit";
    }

    if (code === "invalid_modifier_nesting") {
        return " has invalid nested modifiers";
    }

    if (code === "circular_modifier_nesting") {
        return " references itself via nested modifiers";
    }

    if (code === "promotion_price_list_without_price_list") {
        return " has missing Price List";
    }

    if (code === "category_without_image") {
        return " has missing image";
    }

    if (code === "invalid_upsell_reference") {
        return " contains invalid product or variant references";
    }

    if (code === "invalid_upsell_product_circular_reference") {
        return " cannot be an upsell as it has modifiers that create a circular reference";
    }

    if (code === "invalid_upsell_product_nested_modifiers") {
        return " cannot be an upsell as it has nested modifiers.";
    }

    return `: ${code}`;
}

function getTargetLink(
    path: string[],
    errorCode: string,
    menuData: any,
    useCataloguePage: boolean
): string | undefined {
    switch (path[0]) {
        case "services":
            return `/menu/services/list/${menuData.services[path[1]].id}`;
        case "menus":
            return `/menu/menus/${path[1]}`;
        case "categories":
            return `/menu/categories/${path[1]}`;
        case "courses":
            return `/menu/courses/${menuData.courses[path[1]].id}`;
        case "items":
            return useCataloguePage
                ? `/menu/catalogue/products/${path[1]}${getCatalogFilters(errorCode)}`
                : `/menu/menuitems/${path[1]}`;
        case "modifiers":
            return useCataloguePage
                ? `/menu/catalogue/modifiers/${path[1]}${getCatalogFilters(errorCode)}`
                : `/menu/modifiers/${path[1]}`;
        case "surcharges":
            return `/menu/surcharges`;
        case "taxes":
            return `/menu/taxes`;
        case "membership":
            return `/membership/${path[1]}`;
        case "promotions":
            return `/promotions/${path[1]}`;
        default:
            return undefined;
    }
}

function getCatalogFilters(errorCode: string) {
    if (errorCode.startsWith("validation_status_")) {
        return "?s=" + encodeURIComponent("Validation issues");
    }

    if (errorCode === "incomplete_modifier" || errorCode === "incomplete_product") {
        return "?s=" + encodeURIComponent("Missing content");
    }

    return "";
}
