import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";
import { SurchargesSummary } from "../types";

export enum TypeKeys {
    FETCH_BEGIN = "SURCHARGES/FETCH_DATA_BEGIN",
    FETCH_SUCCESS = "SURCHARGES/FETCH_DATA_SUCCESS",
    FETCH_FAILURE = "SURCHARGES/FETCH_DATA_FAILURE",

    SAVE_BEGIN = "SURCHARGES/SAVE_DATA_BEGIN",
    SAVE_SUCCESS = "SURCHARGES/SAVE__DATA_SUCCESS",
    SAVE_FAILURE = "SURCHARGES/SAVE_DATA_FAILURE",
}

const edit = scaffoldEdit<SurchargesSummary, SurchargesSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
