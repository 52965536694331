import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";

const localesSupportEnergyContent = ["en-gb"];

export const getIsEnergyContentSupported = createSelector(getActiveLocation, (activeLocation) => {
    if (!activeLocation) {
        return undefined;
    }

    return localesSupportEnergyContent.includes(activeLocation.locale.toLowerCase());
});
