import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditComponent, ListComponent, ModalCrudRouteContainer } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ListCategoriesPage = lazy(() =>
    import("./ListCategoriesPage").then((module) => ({
        default: module.ListCategoriesPage,
    }))
);

const EditCategoryPage = lazy(() =>
    import("./EditCategoryPage").then((module) => ({
        default: module.EditCategoryPage,
    }))
);

const categoriesList: ListComponent = {
    route: "menu/categories",
    component: withPermissions(ListCategoriesPage, "menudata:read"),
    contentWidth: ContentWidth.WIDE,
    wrapper: ListWrapper,
};

const categoryEdit: EditComponent = {
    route: "menu/categories/:id",
    editTitle: "Edit category",
    createTitle: "Create category",
    component: withPermissions(EditCategoryPage, "menudata:read"),
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

export function Category() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_CATEGORY}>
            <ModalCrudRouteContainer
                name="categories"
                listComponent={categoriesList}
                editComponents={[categoryEdit]}
                route=""
            />
        </TestContext.Provider>
    );
}
