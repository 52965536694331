import { createSelector } from "reselect";
import { getModifiersListData } from "./getModifiersList";
import { ModifierSummary } from "features/catalogue/types";

// hash of all modfiers for quick lookup by id
// `undefined` will not appear in modifer list data but it's included in the value type to force consumers
// to handle cases where references might point to missing or unloaded modifiers
export const getModifiersById = createSelector(
    getModifiersListData,
    (modifiers): Record<string, ModifierSummary | undefined> => {
        return Object.fromEntries(modifiers.map((modifier) => [modifier.id, modifier]));
    }
);
