import { opsApi } from "API";
import { TakeawayOptions } from "features/location";

export const saveTakeawayOptions = async (locationId: string | undefined, takeawayOptions: TakeawayOptions) => {
    const res = await opsApi.graphQLQuery<{ takeawayOptions: TakeawayOptions }>(updateQuery, {
        locationId,
        takeawayOptions,
    });

    return res.data.updateTakeawayOptions;
};

const updateQuery = `
    mutation ($locationId: ID!, $takeawayOptions: TakeawayOptionsUpdateInput!) {
        updateTakeawayOptions(locationId: $locationId, takeawayOptions: $takeawayOptions) {
            takeawayOptions {
                enabled,
                minReadyTimeMins,
                sectionId,
                contactPhoneNumber, 
                collectionInstructions,
                scheduleOptions {
                    enabled
                    scheduleWindowMinutes
                }
            }
        }
    }
`;
