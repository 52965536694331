import { ActiveLocation } from "features/location";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { AppState } from "../../state";
import { createSelector } from "reselect";
import {
    MembershipLevel,
    MembershipLevelPublishedState,
    MembershipProgram,
    MembershipSummary as Summary,
    VenueMembershipProgram,
} from "../types";

const getMemberships = (state: AppState) =>
    state.memberships.list.status === "loaded" ? state.memberships.list.data : undefined;

export const getMembershipSummaryList = createSelector(
    getActiveLocation,
    getMemberships,
    (location?: ActiveLocation, memberships?: MembershipProgram[]): Summary[] => {
        if (!memberships) {
            return [];
        }
        return memberships.map((m) => {
            if (m.authentication?.method === "optin") {
                const level = (m as VenueMembershipProgram).membershipLevels?.[0];
                return {
                    id: m.id,
                    title: m.displayName,
                    subtitle: (m as VenueMembershipProgram).membershipLevels
                        ?.map((level) => level?.priceList?.displayName)
                        .join("\n"),
                    publishedStatus: getPublishStatus(location?.lastPublished, level),
                    type: m.type,
                    authType: m.authentication?.method,
                };
            }
            return {
                id: m.id,
                title: m.displayName,
                subtitle: m.type,
                publishedStatus: undefined,
                type: m.type,
                authType: m.authentication?.method,
            };
        });
    }
);

function getPublishStatus(lastPublished?: string, level?: MembershipLevel) {
    const lastPublishedMs = lastPublished ? new Date(lastPublished).getTime() : Number.MIN_VALUE;
    const membershipLevelLastUpdatedMs = level?.dateUpdated ? new Date(level.dateUpdated).getTime() : Number.MIN_VALUE;
    return membershipLevelLastUpdatedMs < lastPublishedMs
        ? MembershipLevelPublishedState.PUBLISHED
        : MembershipLevelPublishedState.STAGED;
}
