import { FeatureConfiguration } from "features";
import { PosCatalogueImport } from "./components/PosCatalogueImport";
import { reducer } from "features/posCatalogueImport/reducers";
import * as actions from "features/posCatalogueImport/actions";

export { actions };
export * from "features/posCatalogueImport/types";

export default function (config: FeatureConfiguration) {
    config.reducers.posCatalogueImport = reducer;

    config.addRoutes(config.routes.locationRoutes, [<PosCatalogueImport key="pos-catalogue-import" />]);
}
