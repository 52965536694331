import { withSvg } from "core/components/svg/Svg";

export const EmptySvg = withSvg(
    45,
    44
)(() => (
    <>
        <path d="M14.625 33.1751L6 28.3001V36.0251L20.925 43.9001V28.3001L16.35 32.9501C16.1215 33.1622 15.8326 33.2979 15.5234 33.3382C15.2143 33.3785 14.9003 33.3215 14.625 33.1751ZM28.725 32.9501L24 28.2251V43.9001L39 36.0251V28.3001L30.525 33.2501C30.2337 33.405 29.8995 33.4595 29.5741 33.4053C29.2487 33.3511 28.9503 33.1911 28.725 32.9501Z" />
        <path d="M39.975 14.6501L45 8.6501L28.725 0.100098L23.475 6.0251L39.675 14.5001L39.975 14.6501ZM0 8.7251L5.025 14.8751L5.25 14.7251L21.45 6.0251L16.275 0.100098L0 8.7251ZM5.025 15.6251L0 21.6251L15.3 30.4001L21.3 24.2501L5.325 15.8501L5.025 15.6251ZM39.675 15.6251L23.55 24.2501L29.775 30.4751L45 21.6251L39.975 15.4751L39.675 15.6251Z" />
    </>
));
