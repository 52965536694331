import { createSelector } from "reselect";
import { getProductsListData } from "./getProductsList";
import { ProductSummary } from "features/catalogue/types";

// hash of all products for quick lookup by id
// `undefined` will not appear in product list data but it's included in the value type to force consumers
// to handle cases where references might point to missing or unloaded products
export const getProductsById = createSelector(
    getProductsListData,
    (products): Record<string, ProductSummary | undefined> => {
        return Object.fromEntries(products.map((product) => [product.id, product]));
    }
);
