import { createSelector } from "reselect";
import { AppState } from "features/state";
import { isLoaded } from "common/loader/isLoaded";
import {
    CategoryFilter,
    PosCatalogueImportItem,
    PosCatalogueImportVariant,
    PosCategory,
    PosCatalogueImportModifier,
} from "../types";
import { EditableCmsItem } from "common/scaffolding/types";

const sortByDisplayName = (a: EditableCmsItem, b: EditableCmsItem) => {
    return a.displayName.localeCompare(b.displayName, "en", { ignorePunctuation: true });
};

export const getPosCatalogue = (state: AppState) =>
    isLoaded(state.posCatalogueImport.list) ? state.posCatalogueImport.list.data : null;

export const getProductPosCatalogueImportItems = createSelector(
    getPosCatalogue,
    (posCatalogueImport): PosCatalogueImportItem[] => {
        return !posCatalogueImport
            ? []
            : posCatalogueImport.products
                  .map((posProduct) => {
                      let categories = posCatalogueImport.categories
                          .filter((c) => c.products.indexOf(posProduct.id) !== -1)
                          .map((posCategory: PosCategory) => {
                              const { id, displayName, type } = posCategory;
                              return { id, displayName, type };
                          });

                      const modifierIds = Array.from(
                          new Set(
                              Array.prototype.concat.apply(
                                  [],
                                  posProduct.variants.map((v) => v.modifiers)
                              )
                          )
                      );

                      const modifierGroups: PosCatalogueImportModifier[] = modifierIds
                          .map((id): PosCatalogueImportModifier => {
                              const modifier = posCatalogueImport.modifiers.find((m) => m.id === id);

                              if (!modifier) {
                                  return undefined!;
                              }

                              return {
                                  id: modifier.id,
                                  key: posProduct.id + "-" + modifier.id,
                                  sku: modifier.id,
                                  parentId: posProduct.id,
                                  maxSelection: modifier.maxSelection,
                                  minSelection: modifier.minSelection,
                                  maxSelectionPerOption: modifier.maxSelectionPerOption,
                                  type: "modifier",
                                  displayName: modifier.displayName,
                                  children: modifier.options,
                              };
                          })
                          .filter((c) => c !== undefined);

                      const variantChildren: PosCatalogueImportVariant[] = posProduct.variants.map(
                          (posVariant, index) => ({
                              id: posVariant.id,
                              modifiers: posVariant.modifiers,
                              parentId: posProduct.id,
                              key: `${posProduct.id}-${posVariant.id}-${index}`,
                              displayName: posVariant.displayName,
                              sku: posVariant.sku,
                              price: posVariant.price,
                              type: "variant",
                          })
                      );

                      const item: PosCatalogueImportItem = {
                          id: posProduct.id,
                          key: posProduct.id,
                          displayName: posProduct.displayName,
                          children: variantChildren,
                          relatedItems: modifierGroups,
                          categories,
                          type: "product",
                          collapse: variantChildren.length === 1,
                          description: posProduct.description || undefined,
                      };

                      return item;
                  })
                  .sort(sortByDisplayName);
    }
);

export const getModifierPosCatalogueItems = createSelector(
    getPosCatalogue,
    (posCatalogue): PosCatalogueImportModifier[] => {
        return !posCatalogue
            ? []
            : posCatalogue.modifiers
                  .map((posModifier) => {
                      const item: PosCatalogueImportModifier = {
                          id: posModifier.id,
                          key: posModifier.id,
                          displayName: posModifier.displayName,
                          sku: posModifier.id,
                          maxSelection: posModifier.maxSelection,
                          minSelection: posModifier.minSelection,
                          maxSelectionPerOption: posModifier.maxSelectionPerOption,
                          type: "modifier",
                          children: posModifier.options.map((posOption, index) => {
                              const modifier = posOption.modifier
                                  ? posCatalogue.modifiers.find((m) => m.id === posOption.modifier)
                                  : null;

                              return {
                                  id: posOption.id,
                                  parentId: posModifier.id,
                                  key: `${posModifier.id}-${posOption.sku}-${index}`,
                                  type: modifier ? "modifier" : "option",
                                  displayName: posOption.displayName,
                                  internalName: modifier ? modifier.displayName : undefined,
                                  sku: modifier ? `${posOption.sku} (${modifier.id})` : posOption.sku,
                                  price: posOption.price,
                                  modifiers: posOption.modifiers ?? [],
                              };
                          }),
                      };
                      return item;
                  })
                  .sort(sortByDisplayName);
    }
);

export const getSortedCategories = createSelector(getPosCatalogue, (posCatalogue) =>
    !posCatalogue ? [] : posCatalogue.categories.concat([]).sort((a, b) => a.displayName.localeCompare(b.displayName))
);

export const getFilterCategories = createSelector(getSortedCategories, (categories) =>
    categories
        .map<CategoryFilter>(({ id: value, displayName: label }) => ({ value, label }))
        .concat({ value: "", label: "(No category)", italicize: true })
);

const getTotalChildren = (collection: PosCatalogueImportItem[]) => {
    return collection?.reduce<number>((prev, curr) => prev + (curr.children?.length || 0), 0) || 0;
};

const getChildren = (collection: PosCatalogueImportItem[]) => {
    return collection?.reduce<PosCatalogueImportItem[]>((prev, curr) => prev.concat(curr.children || []), []);
};

export const getProductVariantsPosCatalogueImportItems = createSelector(
    getProductPosCatalogueImportItems,
    (products) => {
        return getChildren(products);
    }
);
export const getTotalNumberOfProductVariants = createSelector(getProductPosCatalogueImportItems, (products): number => {
    return getTotalChildren(products);
});

export const getTotalNumberOfProducts = createSelector(getProductPosCatalogueImportItems, (products): number => {
    return products.length;
});

export const getTotalNumberOfModifierOptions = createSelector(
    getModifierPosCatalogueItems,
    (modifierGroups): number => {
        return getTotalChildren(modifierGroups);
    }
);

export const getTotalNumberOfModifierGroups = createSelector(getModifierPosCatalogueItems, (modifierGroups): number => {
    return modifierGroups.length;
});

export const getAllItemsSelector = (collectionName: "products" | "modifiers") => {
    if (collectionName === "products") {
        return getProductPosCatalogueImportItems;
    }

    if (collectionName === "modifiers") {
        return getModifierPosCatalogueItems;
    }

    return () => [];
};

export const getTotalChildItemsSelector = (collectionName: "products" | "modifiers") => {
    if (collectionName === "products") {
        return getTotalNumberOfProductVariants;
    }

    if (collectionName === "modifiers") {
        return getTotalNumberOfModifierOptions;
    }

    return () => 0;
};
