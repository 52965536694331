import styles from "./Nav.module.scss";

import { RegionRouteParams } from "common/types";
import { TEAMS_PATH } from "features/scope/types/ScopePathParameter";
import { Link, useLocation, useParams } from "react-router-dom";
import { ItemLabel } from "./Item";
import { User } from "common/icons/User";
import { useContext } from "react";
import { MenuContext } from "./MenuContainer";

export interface Props {}

export const TeamsLink = () => {
    const location = useLocation();
    const { pathname } = location;
    const { region } = useParams<RegionRouteParams>() || {};
    const { setMenuActive } = useContext(MenuContext);

    return (
        <Link
            to={{
                pathname: `/${region}/${TEAMS_PATH}`,
                state: { from: pathname },
            }}
            className={styles.itemLevel1}
            onClick={() => setMenuActive(false)}
        >
            <ItemLabel
                item={{
                    text: "My Teams",
                    icon: User,
                    testId: "myteams",
                    route: "",
                }}
                level={1}
            />
        </Link>
    );
};
