import { FeatureConfiguration } from "..";
import { LocationSettings } from "./components/LocationSettings";
import { reducer } from "./reducers";
import * as actions from "./actions";

export * from "./types/EditableLocationSettings";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.LocationSettings = reducer;

    config.addRoutes(config.routes.locationRoutes, [<LocationSettings key="location-settings" />]);
}
