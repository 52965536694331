import { AppState } from "features";
import { RouteComponentProps, withRouter } from "react-router";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { connect } from "react-redux";
import React from "react";
import { PermissionSet } from "../types/PermissionSet";
import { getActiveGroupData, getGroupPermissions } from "features/group/selectors";

interface Props {
    permissions: PermissionSet;
    children: (permissions: PermissionSet) => React.ReactElement<any>;
}

const PermissionsInner: React.FC<Props> = ({ permissions, children }: Props) => children(permissions);

interface OuterProps {
    children: (permissions: PermissionSet) => React.ReactElement<any>;
}

interface RouteParams {
    location: string;
}

function mapStateToProps(state: AppState, { children }: RouteComponentProps<RouteParams> & OuterProps): Props {
    const activeLocation = getActiveLocation(state);
    const activeGroup = getActiveGroupData(state);

    const permissions =
        (activeGroup && getGroupPermissions(state)) ||
        (activeLocation && getLocationPermissions(state)) ||
        new PermissionSet([]);

    return {
        permissions,
        children,
    };
}

function mergeProps(stateProps: Props, dispatchProps: any, ownProps: OuterProps): Props {
    return stateProps;
}

export const Permissions = withRouter(
    connect(mapStateToProps, null, mergeProps)(PermissionsInner) as any as React.ComponentType<
        OuterProps & RouteComponentProps<RouteParams>
    >
);
