import { withSvg } from "core/components/svg/Svg";

export const TimeCircle = withSvg(
    16,
    16
)(() => (
    <>
        <path d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" />
        <path d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V7.95135L11.4871 9.55976C11.7302 9.69068 11.8212 9.99391 11.6902 10.2371C11.5593 10.4802 11.2561 10.5712 11.013 10.4402L7.76295 8.69024C7.601 8.60303 7.5 8.43394 7.5 8.25V2.5C7.5 2.22386 7.72386 2 8 2Z" />
    </>
));
