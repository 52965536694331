import styles from "./IconWithText.module.scss";

import { FC, PropsWithChildren } from "react";
import { Icon, Size } from "../icon";
import classNames from "classnames";

interface Props {
    className?: string;
    icon?: FC;
    iconSize?: Size;
}

export const IconWithText = ({ className, children, icon: Svg, iconSize = "tiny" }: PropsWithChildren<Props>) => (
    <span className={classNames(styles.container, className)}>
        {Svg && (
            <span className={styles.iconContainer}>
                <Icon size={iconSize}>
                    <Svg />
                </Icon>
            </span>
        )}
        <span>{children}</span>
    </span>
);
