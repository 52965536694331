import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { withPermissions } from "common/permissions/components/withPermission";

const ListAuditLogEntriesPageContainer = lazy(() =>
    import("../containers/ListAuditLogEntriesPageContainer").then((module) => ({
        default: module.ListAuditLogEntriesPageContainer,
    }))
);

const List: ListComponent = {
    route: "",
    component: withPermissions(ListAuditLogEntriesPageContainer, "auditlog:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

export function AuditLog() {
    return <ModalCrudRouteContainer name="auditlog" listComponent={List} route="auditlog" />;
}
