import styles from "./FiltersModal.module.scss";

import { Row } from "core/components/card";
import { Select, formatCatalogueItemOption } from "core/components/form/select";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { useSelector } from "react-redux";
import { getFilterCategories } from "../selectors/getPosCatalogueItems";
import { Button } from "core/components/button";
import { useCallback, useContext, useMemo, useState } from "react";
import { CategoryFilter } from "../types";
import { FiltersContext } from "../context/FiltersContext";
import { trackEventFilterCategory } from "../actions/trackEventImportPos";
import { equalsIgnoreOrder } from "common/utility/arrayUtils";
import { MultiValue } from "react-select";
interface Props {
    onClose: () => void;
    onConfirm: () => boolean | void;
    visible: boolean;
}

export const FiltersModal = ({ onClose, onConfirm, visible }: Props) => {
    const [selectedCategories, setSelectedCategories] = useState<CategoryFilter[]>([]);

    const { categories: activeCategoryIds, setCategories } = useContext(FiltersContext);

    const categories = useSelector(getFilterCategories);

    const handleOpen = useCallback(() => {
        setSelectedCategories(categories.filter((category) => activeCategoryIds.includes(category?.value)));
    }, [categories, activeCategoryIds]);

    const handleChange = useCallback((selected: MultiValue<CategoryFilter>) => {
        setSelectedCategories([...selected]);
    }, []);

    const handleClearAll = useCallback(() => {
        setSelectedCategories([]);
    }, []);

    const handleConfirm = useCallback(() => {
        setCategories(selectedCategories.map(({ value }) => value));
        trackEventFilterCategory(selectedCategories);
    }, [selectedCategories, setCategories]);

    const isDirty = useMemo(() => {
        return !equalsIgnoreOrder(
            selectedCategories.map((category) => category.value),
            activeCategoryIds
        );
    }, [selectedCategories, activeCategoryIds]);

    return (
        <Modal
            cardClassName={styles.card}
            contentContainerClassName={styles.contentContainer}
            footer={
                <>
                    <Button nowrap role="secondary" type="button" onClick={handleClearAll}>
                        Clear all
                    </Button>
                    <Confirm onConfirm={handleConfirm} confirmProps={{ disabled: !isDirty }} />
                </>
            }
            onOpen={handleOpen}
            onClose={onClose}
            title={"Filter products"}
            visible={visible}
            width={CardWidth.NARROW}
        >
            <Row>
                <Select
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    isMulti
                    label="Category"
                    menuPlacement="auto"
                    onChange={handleChange}
                    options={categories}
                    value={selectedCategories}
                    maxMenuHeight={220}
                    formatOptionLabel={formatCatalogueItemOption}
                />
            </Row>
        </Modal>
    );
};
