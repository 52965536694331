import { PendingQuery } from "./PendingQuery";
import { ExecutionResult } from "graphql";

export type FetchApi = (url: string, init?: RequestInit) => Promise<Response>;

const pendingQueries = new Map<FetchApi, PendingQuery>();

export async function batchGraphQLQuery(query: string, variables: {} | undefined, api: FetchApi) {
    if (pendingQueries.has(api)) {
        const pendingQuery = pendingQueries.get(api)!;

        if (pendingQuery.tryMerge(query, variables)) {
            const thisQuerySelectionNames =
                pendingQuery.sourceSelectionNames[pendingQuery.sourceSelectionNames.length - 1];

            return awaitResult(pendingQuery.promise, thisQuerySelectionNames);
        }

        return awaitResult(invokeQuery(query, variables, api));
    }

    pendingQueries.set(
        api,
        new PendingQuery(query, variables || {}, (q, v) => {
            pendingQueries.delete(api);
            return invokeQuery(q, v, api);
        })
    );

    const pendingQuery = pendingQueries.get(api)!;

    const thisQuerySelectionNames = pendingQuery.sourceSelectionNames[pendingQuery.sourceSelectionNames.length - 1];

    return awaitResult(pendingQuery.promise, thisQuerySelectionNames);
}

async function invokeQuery(query: string, variables: {} | undefined, api: FetchApi) {
    pendingQueries.delete(api);

    const response = await api(`/graphql`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) {
        throw response;
    }

    const result = await response.json();

    return result;
}

async function awaitResult(
    query: Promise<ExecutionResult<any>>,
    failForErrorPaths?: string[]
): Promise<ExecutionResult<any>> {
    const result = await query;

    if (result.errors && result.errors.length) {
        if (
            !failForErrorPaths ||
            result.errors.some(
                (e) => !e.path || (typeof e.path[0] === "string" && failForErrorPaths.indexOf(e.path[0]) !== -1)
            )
        ) {
            throw result;
        }
    }

    return result;
}
