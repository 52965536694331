import { Role, RoleOption } from "../types/RolesType";

export type State = {
    roles?: Role[];
    fakeRole?: RoleOption;
};

export enum TypeKeys {
    SET_FAKE_ROLES = "FAKE_ROLES/SET",
    SET_FAKE_ROLE = "FAKE_ROLES/SET_FAKE_ROLE",
}

export const createRoleActions = {
    setFakeRoles: (roles?: Role[]) => ({ type: TypeKeys.SET_FAKE_ROLES, roles }),
    setFakeRole: (fakeRole?: RoleOption) => ({ type: TypeKeys.SET_FAKE_ROLE, fakeRole }),
};

type SetFakeRolesAction = { type: TypeKeys.SET_FAKE_ROLES; roles?: Role[] };
type SetFakeRoleAction = { type: TypeKeys.SET_FAKE_ROLE; fakeRole?: RoleOption };
type FakeRolesAction = SetFakeRolesAction | SetFakeRoleAction;

const initialState: State = {};

export function reducer(state: State = initialState, action: FakeRolesAction): State {
    if (action.type === TypeKeys.SET_FAKE_ROLES) {
        const { roles } = action;

        return {
            ...state,
            roles,
        };
    }

    if (action.type === TypeKeys.SET_FAKE_ROLE) {
        const { fakeRole } = action;

        return {
            ...state,
            fakeRole,
        };
    }

    return state;
}
