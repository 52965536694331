export const StatusDictionary = {
    0: "Not published",
    1: "Staged changes",
    2: "Published",
    3: "Missing content",
    4: "Missing SKU",
};

export enum Status {
    NotPublished = 0,
    Staged,
    Published,
    MissingContent,
    MissingSKU,
}

export const getStatusLabel = (itemStatus: Status | undefined | null) => {
    if (itemStatus === undefined || itemStatus === null) {
        return null;
    }

    return StatusDictionary[itemStatus];
};
