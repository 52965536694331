import styles from "./Nav.module.scss";

import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getTestId, getText } from "./Nav";
import { GroupRouteParams, LocationRouteParams, RegionRouteParams } from "common/types";
import { Icon } from "core/components/icon";
import { NavItem } from "features/structure/types/NavMenu";
import { NavLink, useParams } from "react-router-dom";
import { OpenInWindow } from "common/icons";
import { Scope, useScopeContext } from "features/scope";
import { SCOPE_PATH_MAP } from "features/scope/types/ScopePathParameter";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

export interface Props {
    item: Partial<NavItem> & Pick<NavItem, "text" | "icon" | "testId" | "route" | "externalUrl" | "iconProperties">;
    level?: number;
    expanded?: boolean;
}

type MixedRouteParams = Partial<LocationRouteParams> & Partial<GroupRouteParams> & RegionRouteParams;

export const Item = ({ item, level, expanded }: Props) => {
    const { route, externalUrl } = item;

    const { region, location: locationSlug, id: groupId } = useParams<MixedRouteParams>() || {};

    const scope = useScopeContext();

    const accessProps = expanded
        ? {}
        : {
              tabIndex: -1,
          };

    const internalLink = useMemo(() => {
        if (externalUrl) {
            return "";
        }

        const parts = [];

        parts.push(region);
        parts.push(SCOPE_PATH_MAP[scope]);

        if (scope === Scope.GROUP) {
            parts.push(groupId);
        }

        if (scope === Scope.LOCATION) {
            parts.push(locationSlug);
        }

        if (route) {
            parts.push(route);
        }

        return `/${parts.join("/")}`;
    }, [externalUrl, groupId, locationSlug, region, route, scope]);

    return (
        <li
            role="menuitem"
            className={styles.listItem}
            data-testid={getTestId({ element: "list-item", level }, item.testId)}
            data-testclass={getTestId({ element: "list-item", level })}
        >
            {!externalUrl ? (
                <NavLink
                    className={styles[`itemLevel${level}`]}
                    activeClassName={styles.linkActive}
                    to={internalLink}
                    data-testid={getTestId({ element: "link" }, item.testId)}
                    data-testclass={getTestId({ element: "link" })}
                    {...accessProps}
                >
                    <ItemLabel item={item} level={level} />
                </NavLink>
            ) : (
                <a
                    key={externalUrl}
                    className={styles[`itemLevel${level}`]}
                    href={externalUrl}
                    target="_blank"
                    rel="noreferrer"
                    data-testid={getTestId({ element: "link" }, item.testId)}
                    data-testclass={getTestId({ element: "link" })}
                    {...accessProps}
                >
                    <ItemLabel item={item} level={level} />
                </a>
            )}
        </li>
    );
};

export const ItemLabel = ({ item, level }: Props) => {
    const { externalUrl, icon: Svg, iconProperties } = item;
    const isParentLocation = useSelector(getIsParentLocation) || false;

    return (
        <span className={classNames(styles[`labelLevel${level}`])}>
            {Svg && (
                <Icon {...iconProperties} size="small" className={styles[`iconLevel${level}`]}>
                    <Svg />
                </Icon>
            )}
            <span className={styles.title}>{getText(item, { isParentLocation })}</span>
            {externalUrl && (
                <Icon className={styles.externalLinkIcon}>
                    <OpenInWindow />
                </Icon>
            )}
        </span>
    );
};
