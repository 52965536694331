export const downloadFile = async (url: string, filename: string) => {
    const resp = await fetch(url);
    const blob = await resp.blob();
    return downloadBlob(blob, filename);
};

export const downloadBlob = (blob: Blob, filename: string) => {
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(blob);
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(() => {
        URL.revokeObjectURL(anchor.href);
        document.body.removeChild(anchor);
    }, 0);
};

export const downloadCanvasDataURLAsPNG = (canvas: HTMLCanvasElement, filename: string) => {
    const anchor = document.createElement("a");
    anchor.href = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(() => {
        document.body.removeChild(anchor);
    }, 0);
};
