import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";
import { QuickSellSummary } from "../types";

export enum TypeKeys {
    FETCH_BEGIN = "QUICKSELL/FETCH_DATA_BEGIN",
    FETCH_SUCCESS = "QUICKSELL/FETCH_DATA_SUCCESS",
    FETCH_FAILURE = "QUICKSELL/FETCH_DATA_FAILURE",

    SAVE_BEGIN = "QUICKSELL/SAVE_DATA_BEGIN",
    SAVE_SUCCESS = "QUICKSELL/SAVE__DATA_SUCCESS",
    SAVE_FAILURE = "QUICKSELL/SAVE_DATA_FAILURE",
}

const edit = scaffoldEdit<QuickSellSummary, {}, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
