import { LoadStatus } from "common/loader/types";

export function mergeStatus(...statuses: LoadStatus[]) {
    if (statuses.length === 0) {
        return "loaded";
    }

    let outputStatus: LoadStatus = statuses[0];

    for (let i = 1; i < statuses.length; i++) {
        if (statuses[i] === "loading" && outputStatus !== "failed") {
            outputStatus = statuses[i];
        }

        if (statuses[i] === "unloaded" && outputStatus === "loaded") {
            outputStatus = statuses[i];
        }

        if (statuses[i] === "failed") {
            outputStatus = statuses[i];
        }
    }

    return outputStatus;
}
