import { AppState } from "features";
import { createSelector } from "reselect";

const getSectionsStatus = ({ sections: { list } }: AppState) => list.status;

const getSections = ({ sections: { list } }: AppState) => (list.status === "loaded" ? list.data : []);

export const getCalendarSections = createSelector(getSectionsStatus, getSections, (sectionsStatus, sections) => {
    // special case: cater for venues that have no sections by faking an 'all' section
    if (sectionsStatus === "loaded" && !sections.length) {
        return [{ id: "", displayName: "All" }];
    }

    return sections;
});

export const getCalendarSectionOptions = createSelector(getCalendarSections, (sections) => {
    return sections.map((section) => ({ value: section.id, label: section.displayName }));
});
