import styles from "./Nav.module.scss";

import { useAccordion, useMedia, useScopeBasePath } from "common/hooks";
import { Fragment, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { MenuContext } from "./MenuContainer";
import { matchPath, useRouteMatch } from "react-router-dom";
import { UserProfileLink } from "./UserProfileLink";
import {
    AbstractNavItem,
    isNavGroup,
    isNavItem,
    isNavItemGroup,
    NavGroup,
    NavItem,
    NavItemGroup,
    NavNode,
    NavNodeTextOptions,
} from "features/structure/types/NavMenu";
import { Icon } from "core/components/icon/Icon";
import { useLocation, useParams } from "react-router";
import { GroupRouteParams, LocationRouteParams } from "common/types";
import { Chevron } from "core/components/icon/Chevron";
import { useSelector } from "react-redux";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getLocationMainMenu } from "features/structure/selectors/getLocationMainMenu";
import { Scope, useScopeContext } from "features/scope";
import { getGroupMainMenu } from "features/structure/selectors/getGroupMainMenu";
import { getTeamsMainMenu } from "features/structure/selectors/getTeamsMainMenu";
import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";
import { VenueTime } from "../venueTime/VenueTime";
import { AppState } from "features/state";
import { useAppSelector } from "common/hooks/useAppSelector";
import { Item, Props as ItemProps } from "./Item";

export const { getTestId, getTestClass } = getTestAttributeGenerators(TestAttributePrefix.MAIN_NAV);

export interface Props {
    navigation: NavNode[];
}

export const Nav = () => {
    const { setMenuActive } = useContext(MenuContext);

    const state = useAppSelector((state) => state);

    const large = useMedia("(min-width: 896px)");

    const scope = useScopeContext();

    const navigation = useMemo(() => getMainMenu(state, scope), [scope, state]);

    useEffect(() => {
        if (large) {
            setMenuActive(false);
        }
    }, [large, setMenuActive]);

    return (
        <div className={styles.container}>
            <nav className={styles.nav} data-test-id={getTestId({ name: "root" })}>
                <div className={styles.innerContainer}>
                    <NavMenu navigation={navigation} />
                    <UserProfileLink />
                </div>
            </nav>
        </div>
    );
};

function getMainMenu(state: AppState, scope: Scope) {
    switch (scope) {
        case Scope.GROUP:
            return getGroupMainMenu(state);

        case Scope.TEAMS:
            return getTeamsMainMenu(state);

        case Scope.LOCATION:
        default:
            return getLocationMainMenu(state);
    }
}

interface NavMenuProps {
    navigation: NavNode[];
}

export const NavMenu = ({ navigation }: NavMenuProps) => {
    const menuRef = useRef<HTMLUListElement | null>(null);

    const { pathname } = useLocation();

    const locationParams = useParams<LocationRouteParams>();

    const groupParams = useParams<GroupRouteParams>();

    const scope = useScopeContext();

    const { region } = locationParams || groupParams || {};

    const { location } = locationParams || {};

    const { id: groupId } = groupParams || {};

    const isParentLocation = useSelector(getIsParentLocation) || false;

    const isLarge = useMedia("(min-width: 896px)");

    const isActiveItem = useCallback(
        (item: NavItem) => {
            if (scope === Scope.GROUP) {
                return (item?.route && pathname.startsWith(`/${region}/group/${groupId}/${item.route}`)) || false;
            } else {
                return (item?.route && pathname.startsWith(`/${region}/venue/${location}/${item.route}`)) || false;
            }
        },
        [scope, pathname, region, groupId, location]
    );

    const prominentItems: any[] = navigation.filter((node) => (node?.category === "prominent" ? node : null));

    const getSelectedNavItemGroupIndex = useCallback(
        (navGroup?: NavGroup) => {
            const groupIndex = navGroup
                ? navGroup.navItemGroups.findIndex((navItemGroup) => {
                      return !!navItemGroup.children.find(isActiveItem);
                  })
                : -1;

            return groupIndex;
        },
        [isActiveItem]
    );

    const displayTime = scope === Scope.LOCATION && !isParentLocation && !isLarge;

    return (
        <>
            {displayTime && (
                <div className={styles.timeContainer}>
                    <VenueTime />
                </div>
            )}

            {!!prominentItems.length && (
                <div className={styles.prominentContainer}>
                    {prominentItems.map((item, index) => (
                        <ProminentItem key={item.route || item.externalUrl} item={item} />
                    ))}
                </div>
            )}

            <ul className={styles.menu} ref={menuRef} data-testclass={getTestId({ element: "list", level: 0 })}>
                {navigation.map((node, index) => {
                    if (node?.category !== "prominent") {
                        if (isNavGroup(node)) {
                            return (
                                <Fragment key={index}>
                                    <li
                                        className={styles.itemLevel0}
                                        data-testid={getTestId({ element: "list-item", level: 0 }, node.testId)}
                                        data-testclass={getTestId({
                                            name: "group-label",
                                            element: "list-item",
                                            level: 0,
                                        })}
                                    >
                                        <span>{getText(node, { isParentLocation })}</span>
                                    </li>
                                    {node.navItemGroups.map((navItemGroup, navItemGroupIndex) => {
                                        return (
                                            <NavElement
                                                node={navItemGroup}
                                                key={navItemGroupIndex}
                                                index={navItemGroupIndex}
                                                selectedGroupIndex={getSelectedNavItemGroupIndex(node)}
                                            />
                                        );
                                    })}
                                </Fragment>
                            );
                        } else {
                            return <NavElement node={node} key={index} index={index} selectedGroupIndex={-1} />;
                        }
                    }
                    return null;
                })}
            </ul>
        </>
    );
};

interface NavElementProps {
    node: NavItem | NavItemGroup | null;
    index: number;
    selectedGroupIndex: number;
}

export const NavElement = ({ node, index, selectedGroupIndex }: NavElementProps) => {
    if (isNavItemGroup(node)) {
        return <SubMenu navItemGroup={node} key={index} startExpanded={index === selectedGroupIndex} />;
    } else if (isNavItem(node)) {
        return <Item key={node.route || node.externalUrl} item={node} level={1} expanded={true} />;
    }

    return null;
};

interface SubMenuProps {
    navItemGroup: NavItemGroup;
    startExpanded: boolean;
}

const SubMenu = ({ navItemGroup, startExpanded }: PropsWithChildren<SubMenuProps>) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLUListElement | null>(null);
    const { icon: Svg } = navItemGroup;
    const { expanded, setExpanded, contentStyle } = useAccordion(contentRef, { startExpanded });

    const isParentLocation = useSelector(getIsParentLocation) || false;

    const onClick = () => {
        setExpanded(!expanded);
    };

    const scopeBasePath = useScopeBasePath();

    const { pathname } = useLocation();

    const { path } = useRouteMatch(scopeBasePath) || {};

    useEffect(() => {
        const match = navItemGroup.children.some(
            (child) => child.route && path && matchPath(pathname, { path: `${path}/${child.route}`, exact: false })
        );

        if (match) {
            setExpanded(true);
        }
    }, [navItemGroup.children, path, pathname, setExpanded]);

    return (
        <li
            className={styles.submenu}
            data-testid={getTestId({ element: "list-item", level: 1 }, navItemGroup.testId)}
            data-testclass={getTestId({ element: "list-item", level: 1 })}
        >
            <button
                className={styles.itemLevel1}
                ref={buttonRef}
                onClick={onClick}
                aria-expanded={expanded}
                aria-haspopup={true}
                data-testid={getTestId({ element: "button", level: 1 }, navItemGroup.testId)}
                data-testclass={getTestId({ element: "button", level: 1 })}
            >
                <span className={styles.labelLevel1}>
                    {Svg && (
                        <Icon className={styles.iconLevel1} size="small">
                            <Svg />
                        </Icon>
                    )}
                    <span className={styles.title}>{getText(navItemGroup, { isParentLocation })}</span>
                    <Icon className={expanded ? styles.submenuArrowExpanded : styles.submenuArrowCollapsed}>
                        <Chevron />
                    </Icon>
                </span>
            </button>

            <ul
                className={styles.submenuContent}
                ref={contentRef}
                style={contentStyle}
                aria-hidden={!expanded}
                data-testid={getTestId({ element: "list", level: 1 }, navItemGroup.testId)}
                data-testclass={getTestId({ element: "list", level: 1 })}
            >
                {navItemGroup.children.map((item) => (
                    <Item key={item.route || item.externalUrl} item={item} level={2} expanded={expanded} />
                ))}
            </ul>
        </li>
    );
};

const ProminentItem = ({ item }: ItemProps) => {
    const { externalUrl, icon: Svg, iconProperties } = item;
    const isParentLocation = useSelector(getIsParentLocation) || false;
    return (
        <a
            key={externalUrl}
            className={styles.prominentLink}
            href={externalUrl}
            target="_blank"
            rel="noreferrer"
            data-testid={getTestId({ element: "link" }, item.testId)}
            data-testclass={getTestId({ element: "link" })}
        >
            <span className={styles.label}>
                <span>{getText(item, { isParentLocation })}</span>
                {Svg && (
                    <Icon {...iconProperties} className={styles.externalLinkIcon}>
                        <Svg />
                    </Icon>
                )}
            </span>
        </a>
    );
};

export const getText = (node: AbstractNavItem, { isParentLocation }: NavNodeTextOptions) => {
    if (!node?.text) {
        return "";
    }

    return typeof node.text === "function" ? node.text({ isParentLocation }) : node.text;
};
