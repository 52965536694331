import "./App.scss";
import { RouteComponentProps, Redirect } from "react-router";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Layout } from "features/structure/screen";
import { locationRoute, LocationContext } from "features/location";
import { CallbackPageContainer } from "features/callback/containers/CallbackPageContainer";
import { withLogin, auth } from "common/auth";
import featureConfig from "features";
import { region } from "features/region";
import { getLegacyRegion } from "features/region/getLegacyRegion";
import { withRegion } from "features/region/components/withRegion";
import { IsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";
import { PageView } from "common/appInsights/components/PageView";
import { GroupContext } from "features/group/context";
import { GroupContainer } from "features/group/containers/GroupContainer";
import { ErrorBoundary } from "core/components/errorBoundary/ErrorBoundary";
import { TeamsContainer } from "features/teams/containers/TeamsContainer";
import { GROUP_PATH, LOCATION_PATH, TEAMS_PATH } from "features/scope/types/ScopePathParameter";

const locationRoutes = ({ location, match }: RouteComponentProps<any>) => (
    <>
        <PageView />
        <Layout>{featureConfig.routes.locationRoutes}</Layout>
    </>
);

const groupRoutes = () => (
    <ErrorBoundary>
        <GroupContainer>
            <PageView />
            <Layout>{featureConfig.routes.groupRoutes}</Layout>
        </GroupContainer>
    </ErrorBoundary>
);

const teamsRoutes = () => (
    <TeamsContainer>
        <PageView />
        <Layout>{featureConfig.routes.teamsRoutes}</Layout>
    </TeamsContainer>
);

const getUserConfirmation = (dialogKey: string, callback: (ok: boolean) => void) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        // pass the callback to delegate to the invoked dialog
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};

const protectedLocationRoutes = withLogin(withRegion(locationRoute(locationRoutes)), () => auth);

const protectedGroupRoutes = withLogin(withRegion(groupRoutes), () => auth);

const protectedTeamsRoutes = withLogin(withRegion(teamsRoutes), () => auth);

const RedirectToDefaultLocation = withLogin(
    withRegion(
        locationRoute(() => (
            <LocationContext.Consumer>
                {(activeLocation) =>
                    activeLocation.slug && (
                        <Redirect
                            to={
                                IsPosMenuSupported(activeLocation.posMetadata.operations)
                                    ? `/${region.getActiveRegion().id}/venue/${activeLocation.slug}/sections`
                                    : `/${region.getActiveRegion().id}/venue/${activeLocation.slug}/menu/catalogue`
                            }
                        />
                    )
                }
            </LocationContext.Consumer>
        ))
    ),
    () => auth
);

const RedirectToDefaultGroup = withLogin(
    withRegion(() => (
        <GroupContainer>
            <GroupContext.Consumer>
                {(activeGroup) =>
                    activeGroup.id && (
                        <Redirect to={`/${region.getActiveRegion().id}/group/${activeGroup.id}/analytics`} />
                    )
                }
            </GroupContext.Consumer>
        </GroupContainer>
    )),
    () => auth
);

const RedirectToDefaultRegion = withLogin(
    withRegion((props: RouteComponentProps) =>
        region.isLegacyRegion() ? (
            <Redirect to={`/${getLegacyRegion()}${props.location.pathname}`} />
        ) : (
            <Redirect to={`/${getLegacyRegion()}`} />
        )
    ),
    () => auth
);

// TODO use of BrowserRouter here prevents ConnectedRouter working correctly
// (store.router not updated) but is necessary for getUserConfirmation
const App = () => (
    <Router getUserConfirmation={getUserConfirmation}>
        <Switch>
            <Route path="/:region([a-z]{2,3})">
                <Switch>
                    <Route path="/:region" exact component={RedirectToDefaultLocation} />
                    <Route path={`/:region/${GROUP_PATH}/:id?`} exact component={RedirectToDefaultGroup} />
                    <Route path={`/:region/${GROUP_PATH}/:id/*`} component={protectedGroupRoutes} />
                    <Route path={`/:region/${TEAMS_PATH}/:teamSlug?/`} component={protectedTeamsRoutes} />
                    <Route path={`/:region/${LOCATION_PATH}/:location?`} exact component={RedirectToDefaultLocation} />
                    <Route path={`/:region/${LOCATION_PATH}/:location/*`} component={protectedLocationRoutes} />
                    {/* Legacy routes */}
                    <Redirect from="/:region/:location/*" to={`/:region/${LOCATION_PATH}/:location/*`} />
                    <Redirect from="/:region/:location" to={`/:region/${LOCATION_PATH}/:location`} />
                </Switch>
            </Route>

            <Route path="/auth/callback" render={(props) => <CallbackPageContainer auth={auth} {...props} />} />

            <Route path="/" exact component={RedirectToDefaultLocation as any} />

            <Route component={RedirectToDefaultRegion} />
        </Switch>
    </Router>
);

export default App;
