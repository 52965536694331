import { createAction } from "features/catalogue/reducers/modifier/list";
import { ModifierSummary } from "features/catalogue/types";
import { scaffoldListAction } from "common/scaffolding/actions/scaffoldListAction";
import { listModifiers } from "features/catalogue/api/modifier";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

export const list = (location: string, refresh?: boolean, silent: boolean = false) =>
    scaffoldListAction<ModifierSummary>(
        (state) => state.modifiers.list,
        createAction,
        async () => {
            const modifiers = await listModifiers(location);

            return modifiers.sort(sortByDisplayName);
        },
        refresh,
        silent
    );
