import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const TakeawayPage = lazy(() =>
    import("./edit/TakeawayPage").then((module) => ({
        default: module.TakeawayPage,
    }))
);

const EditTakeawayPage = withPermissions(TakeawayPage, "settings:read");

export function Takeaway() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_TAKEAWAY}>
            <Route
                path={`/:region/venue/:location/takeaway`}
                exact
                render={() => (
                    <EditWrapper contentWidth={ContentWidth.NARROW}>
                        <EditTakeawayPage />
                    </EditWrapper>
                )}
            />
        </TestContext.Provider>
    );
}
