import {
    isNavGroup,
    isNavItem,
    isNavItemGroup,
    NavItem,
    NavItemGroup,
    NavNode,
} from "features/structure/types/NavMenu";

export type NavItemTest = (item: NavItem) => boolean;

export const isParentEnabledNavItem: NavItemTest = (item: NavItem) =>
    !!(item.includeForParentLocation || item.includeForParentLocationOnly);

export const isChildEnabledNavItem: NavItemTest = (item: NavItem) => !item.includeForParentLocationOnly;

export function findNavItemsRecursive(
    navItems: NavNode[] | NavItemGroup[],
    test: NavItemTest,
    results: NavItem[] = []
) {
    navItems.forEach((item) => {
        if (isNavItem(item) && test(item)) {
            results.push(item);
        }

        if (isNavGroup(item)) {
            findNavItemsRecursive(item.navItemGroups, test, results);
        }

        if (isNavItemGroup(item)) {
            findNavItemsRecursive(item.children, test, results);
        }
    });

    return results;
}
