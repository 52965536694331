import { ServiceFilters } from "../types/ServiceFilters";

export enum TypeKeys {
    CLEAR_FILTERS = "SERVICE/CLEAR_FILTERS",
    SET_ACTIVE_FILTERS = "SERVICE/SET_ACTIVE_FILTERS",
    SET_TEXT_FILTER = "SERVICE/SET_TEXT_FILTER",
}
export const createAction = {
    clearFilters: () => ({ type: TypeKeys.CLEAR_FILTERS }),
    setFilters: (filters: ServiceFilters) => ({ type: TypeKeys.SET_ACTIVE_FILTERS, filters }),
    setTextFilter: (text: string) => ({ type: TypeKeys.SET_TEXT_FILTER, text }),
};

type ClearFiltersAction = { type: TypeKeys.CLEAR_FILTERS };
type SetActiveFiltersAction = { type: TypeKeys.SET_ACTIVE_FILTERS; filters: ServiceFilters };
type SetFilterTextAction = { type: TypeKeys.SET_TEXT_FILTER; text: string };

type FilterAction = ClearFiltersAction | SetActiveFiltersAction | SetFilterTextAction;

export interface State {
    active: ServiceFilters;
    text: string;
}

export function getInitialState(): State {
    return {
        active: {
            categories: [],
            days: [],
            sections: [],
            status: { label: "All", value: undefined },
            hasFeatured: { label: "All", value: undefined },
        },
        text: "",
    };
}

export function reducer(state: State = getInitialState(), action: FilterAction): State {
    if (action.type === TypeKeys.CLEAR_FILTERS) {
        return getInitialState();
    }

    if (action.type === TypeKeys.SET_ACTIVE_FILTERS) {
        const { filters } = action;

        return {
            ...state,
            active: filters,
        };
    }

    if (action.type === TypeKeys.SET_TEXT_FILTER) {
        const { text } = action;

        return {
            ...state,
            text,
        };
    }

    return state;
}
