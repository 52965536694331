import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { Section } from "./components/Section";
import * as actions from "./actions";

export * from "./types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.sections = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Section key="sections" />]);
}
