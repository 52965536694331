import { State as ListState, reducer as listReducer } from "features/tags/reducers/list";
import { combineReducers } from "redux";

export interface State {
    tags: {
        list: ListState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
});
