import { AppState } from "features";
import { isLoaded } from "common/loader/isLoaded";
import { createSelector } from "reselect";

const setConfigurationOperation = (state: AppState) => {
    if (!isLoaded(state.locations.active)) {
        return null;
    }

    const operation = state.locations.active.data.posMetadata.operations.find((x) => x.name === "SetConfiguration");

    if (!operation) {
        return null;
    }

    return operation;
};

export const getPosConfigurationFields = createSelector(setConfigurationOperation, (op) => (op ? op.fields : []));
