import { ReactNode } from "react";

export enum Status {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    LOADING = "loading",
}

export interface Message {
    id?: string;
    content: ReactNode;
    icon?: ReactNode;
    duration?: number;
    status: Status;
}
