import { TeamSummary } from "features/teams/types";
import { TypeKeys as EditTypeKeys } from "./edit";
import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

enum TypeKeys {
    LOADING = "TEAM_LIST/LOADING",
    LOADED = "TEAM_LIST/LOADED",
    FAILED = "TEAM_LIST/FAILED",
}

const list = scaffoldList<TeamSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys, sortByDisplayName);

const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
