import styles from "./PosCatalogueImportPage.module.scss";

import { getIn, useFormikContext } from "formik";
import { PosCatalogueImportSelections } from "../types";
import { useMemo } from "react";
import { Alert } from "core/components/alert";

export interface Props {
    collectionName: "products" | "modifiers";
}

export const PreselectedItemsAlert = ({ collectionName }: Props) => {
    const { values, touched } = useFormikContext<PosCatalogueImportSelections>();

    const selections = getIn(values, collectionName);

    const hasPreselectedItems = useMemo(() => {
        if (collectionName === "products") {
            return false;
        }

        return (
            Object.entries(selections || {}).find(
                ([key, value]) => !!value && !getIn(touched, `${collectionName}.${key}`)
            ) !== undefined
        );
    }, [selections, touched, collectionName]);

    return hasPreselectedItems ? (
        <div className={styles.alertContainer}>
            <Alert>
                Some modifier groups are pre-selected as some of the products you selected are already linked to
                modifier groups in your POS
            </Alert>
        </div>
    ) : null;
};
