import { opsApi } from "API";
import { ActiveLocation } from "..";
import { activeLocationFields } from "./fetchLocation";

export const fetchDefaultLocation = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);

    return res.data.defaultLocation;
};

type GraphQLPayload = { defaultLocation: ActiveLocation };

const query = `
    query {
        defaultLocation {
            ${activeLocationFields}
        }
    }
`;
