import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditComponent, ListComponent, ModalCrudRouteContainer } from "common/scaffolding/components/ModalCrudRoute";
import { lazy } from "react";
import { listRoute } from "../util/routes";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const ServicesPageWrapper = lazy(() =>
    import("./ListServicesPage/ServicesPageWrapper").then((module) => ({
        default: module.ServicesPageWrapper,
    }))
);

const EditServicePage = lazy(() =>
    import("./EditServicePage").then((module) => ({
        default: module.EditServicePage,
    }))
);

const serviceList: ListComponent = {
    route: listRoute,
    component: withPermissions(ServicesPageWrapper, "menudata:read"),
    contentWidth: ContentWidth.WIDE,
    wrapper: ListWrapper,
};

const serviceEdit: EditComponent = {
    route: `${listRoute.replace("?", "")}/:id`,
    component: withPermissions(EditServicePage, "menudata:read"),
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

export function Service() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_SERVICE}>
            <ModalCrudRouteContainer
                name="services"
                listComponent={serviceList}
                editComponents={[serviceEdit]}
                route="menu/services"
            />
        </TestContext.Provider>
    );
}
