export function duplicates<T>(values: T[]): T[] {
    return values.reduce((acc, value, i, arr) => {
        if (arr.indexOf(value) !== i && acc.indexOf(value) < 0) {
            acc.push(value);
        }
        return acc;
    }, [] as T[]);
}

// simple array equality, identical order with the same items (using strict equality comparator)
export function equals(a: any[], b: any[]): boolean {
    return (
        Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index])
    );
}

// arrays equal but with items in any order
export function equalsIgnoreOrder(a: any[], b: any[]): boolean {
    if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
        a = [...a];
        b = [...b];

        while (a.length) {
            const foundAt = b.indexOf(a.pop());

            if (foundAt === -1) {
                return false;
            }

            b.splice(foundAt, 1);
        }

        return !a.length && !b.length;
    }

    return false;
}
