import styles from "./RegionList.module.scss";

import { Region } from "../types/region";
import { Popup } from "core/components/popup";
import { RegionPopupButton } from "./RegionPopupButton";
import { getTestAttributeGenerators, TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { Scope, useScopeContext } from "features/scope";

export interface Props {
    selectedRegion?: Region;
    availableRegions: Region[];
}

export const RegionSelector = ({ selectedRegion, availableRegions }: Props) => {
    if (availableRegions.length < 2) {
        return null;
    }

    return (
        <TestContext.Provider value={TestAttributePrefix.REGION_SELECTOR}>
            <Popup
                buttonLabel={selectedRegion ? selectedRegion.id : "Select region..."}
                button={RegionPopupButton}
                offset={{
                    x: 0,
                    y: 4,
                }}
                align="bottom-right"
            >
                <RegionList activeRegion={selectedRegion} availableRegions={availableRegions} />
            </Popup>
        </TestContext.Provider>
    );
};

interface RegionListProps {
    activeRegion?: Region;
    availableRegions: Region[];
}

const RegionList = ({ activeRegion, availableRegions }: RegionListProps) => {
    const { getTestId, getTestClass } = getTestAttributeGenerators(TestAttributePrefix.REGION_SELECTOR);

    const scope = useScopeContext();

    const defaultRegionPath = scope === Scope.TEAMS ? "/teams" : "";

    return (
        <ul className={styles.list} data-testid={getTestId({ element: "list" })}>
            {availableRegions.map(({ id, displayName }: Region) => (
                <li
                    key={id}
                    className={id === activeRegion?.id ? styles.listItemSelected : styles.listItem}
                    data-testclass={getTestClass({ element: "list-item" })}
                    data-testid={getTestId({ element: "list-item" }, id)}
                >
                    {displayName.includes("[access denied]") ? (
                        <span className={styles.labelDisabled}>{displayName}</span>
                    ) : (
                        <a
                            className={styles.link}
                            href={`/${id}${defaultRegionPath}`}
                            data-testclass={getTestClass({ element: "link" })}
                            data-testid={getTestId({ element: "link" }, id)}
                        >
                            <span className={styles.label}>{displayName}</span>
                        </a>
                    )}
                </li>
            ))}
        </ul>
    );
};
