import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";

export const getAuthInfo = createSelector(getActiveLocation, (location) => location?.authInfo);
export const getOrigAuthInfo = createSelector(getActiveLocation, (location) => location?.origAuthInfo);

export const getUserRoles = createSelector(getAuthInfo, (authInfo) => authInfo?.userRoles);

export const getRealAuthInfo = createSelector(
    getAuthInfo,
    getOrigAuthInfo,
    (authInfo, origAuthInfo) => origAuthInfo ?? authInfo
);
