import { RouteComponentProps, Route, StaticContext } from "react-router";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useTestAttributes } from "core/components/testAttributes";
import { MigratedHidden } from "features/migration/components/MigratedHidden";

export interface Props {
    name: string;
    section?: string | null;
    listComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    editComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    listWidth?: number;
    editWidth?: number;
    showBackLink?: boolean;
}

export const CrudRoute = ({
    name,
    section,
    listComponent,
    editComponent: EditComponent,
    listWidth = 12,
    editWidth = 12,
    showBackLink = true,
}: Props) => {
    const route = section !== null ? `${section || "menu"}/${name}` : name;

    const calculateColWidth = (props: RouteComponentProps<any, StaticContext, any>) =>
        props.location.pathname === `/${props.match.params.region}/${props.match.params.location}/${route}`
            ? listWidth
            : 24 - editWidth;

    const { getTestId } = useTestAttributes();

    return (
        <>
            <Route
                path={`/:region/venue/:location/${route}`}
                render={(props) => (
                    <MigratedHidden>
                        <>
                            <Col span={24} xl={calculateColWidth(props)} className={`content__col content__col--${name}`}>
                                <div className="content__col-list" data-testid={getTestId({ name: "list-page" })}>
                                    <Route component={listComponent} />
                                </div>
                            </Col>

                            <Route
                                exact
                                path={`/:region/venue/:location/${route}/:id`}
                                render={() => (
                                    <Col span={24} xl={editWidth} className={`content__col content__col--${name}`}>
                                        <div className="content__col-edit" data-testid={getTestId({ name: "edit-page" })}>
                                            <Route
                                                exact
                                                path={`/:region/venue/:location/${route}/:id`}
                                                render={(props) => (
                                                    <>
                                                        {showBackLink && (
                                                            <div className="link-back__container">
                                                                <Link
                                                                    className="link-back__link"
                                                                    to={`/${props.match.params.region}/${props.match.params.location}/${route}`}
                                                                >
                                                                    <CloseOutlined />
                                                                </Link>
                                                            </div>
                                                        )}
                                                        <EditComponent key={props.match.params.id} {...props} />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                )}
                            />
                        </>
                    </MigratedHidden>
                )}
            />
        </>
    );
};
