import { withSvg } from "core/components/svg/Svg";

export const FileSvg = withSvg(
    24,
    24
)(() => (
    <>
        <path
            d="M3.75 0.75V23.25H20.25V6.75L14.25 0.75H3.75Z"
            stroke="#878D96"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.25 0.75V6.75H20.25"
            stroke="#878D96"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.75 16.5H17.25M6.75 9H17.25V20.25H6.75V9ZM10.5 9V20.25V9ZM6.75 12.75H17.25H6.75Z"
            stroke="#878D96"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
));
