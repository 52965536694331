import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { TypeKeys as EditTypeKeys } from "./edit";
import { PriceListSummary } from "..";

enum TypeKeys {
    LOADING = "PRICELIST/LIST_BEGIN",
    LOADED = "PRICELIST/LIST_SUCCESS",
    FAILED = "PRICELIST/LIST_FAILURE",
    RELOAD = "PRICELIST/LIST_RELOAD",
}
const list = scaffoldList<PriceListSummary, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
