import { createSelector } from "reselect";
import { ActiveLocation } from "../../location";
import { getActiveLocation } from "../../location/selectors/getLocationPermissions";
import { EditableYumpingoOptions } from "../types";

export const getYumpingoOptions = createSelector(
    getActiveLocation,
    (location?: ActiveLocation): EditableYumpingoOptions | undefined => {
        if (!location) return undefined;
        const { externalBrandId, feedbackUrl } = location.yumpingoOptions ?? {};
        return {
            externalBrandId,
            feedbackUrl,
        };
    }
);
