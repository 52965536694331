import styles from "./ListItem.module.scss";

import { Button } from "core/components/button";
import { Checkbox } from "core/components/form/checkbox";
import { useFormikContext } from "formik";
import { CollectionName, PosCatalogueImportItem, PosCatalogueImportSelections } from "../types";
import { ComponentType, useCallback, useContext, useMemo, useState } from "react";
import {
    getAllPageItemsSelected,
    getPageSelectionInfo,
    getSelectionsWithoutPageItems,
} from "../selectors/getPageSelectionInfo";
import { StickyElement } from "common/scaffolding/components/StickyElement/StickyElement";
import { ModalRenderer } from "core/components/modal";
import { FiltersModal } from "./FiltersModal";
import { Badge } from "core/components/badge";
import { Input } from "core/components/form/input";
import { Icon } from "core/components/icon";
import { Search } from "components/icons";
import { ActionsPopup } from "core/components/actionsPopup/ActionsPopup";
import { Card } from "core/components/card";
import { FiltersContext } from "../context/FiltersContext";

import debounce from "lodash/debounce";
import { trackEventFilterText } from "../actions/trackEventImportPos";
import { CatalogueItemType } from "features/catalogue/types/CatalogueItemType";

interface Props {
    collectionName: CollectionName;
    pageIndex: number;
    pageSize: number;
    pageItems: PosCatalogueImportItem[];
    headers: ComponentType;
    relatedCollectionName?: "modifiers";
}

export const ListHeaderRow = ({
    collectionName,
    headers: Headers,
    pageIndex,
    pageItems,
    pageSize,
    relatedCollectionName,
}: Props) => {
    const [showFiltersModal, setShowFiltersModal] = useState(false);

    const form = useFormikContext<PosCatalogueImportSelections>();

    const selections = form.values[collectionName];

    const { numSelected, numItems } = getPageSelectionInfo({ pageItems, selections });

    const indeterminate = numSelected > 0 && numSelected < numItems;

    const isChecked = numSelected > 0 && numSelected === numItems;

    const { filterCount, filterText, setFilterText } = useContext(FiltersContext);

    const handleClear = useCallback(() => {
        if (relatedCollectionName) {
            const relatedCollection = relatedCollectionName ? form.values[relatedCollectionName] : {};
            const relatedCollectionTouched = relatedCollectionName ? form.touched[relatedCollectionName] : {};
            const relatedSelections = { ...relatedCollection };

            Object.keys(relatedCollection).forEach((key) => {
                if (!relatedCollectionTouched?.[key]) {
                    delete relatedSelections[key];
                }
            });

            form.setFieldValue(relatedCollectionName, relatedSelections);
        }

        form.setFieldValue(collectionName, {});
    }, [form, collectionName, relatedCollectionName]);

    const handleCheckboxChange = useCallback(
        (checked: boolean) => {
            pageItems.forEach((item) => {
                form.setFieldTouched(`${collectionName}.${item.id}`, true, false);
            });

            const relatedCollectionTouched = relatedCollectionName ? form.touched[relatedCollectionName] : {};

            if (isChecked) {
                form.setFieldValue(collectionName, getSelectionsWithoutPageItems({ pageItems, selections }));

                pageItems.forEach((item) => {
                    item.relatedItems?.forEach((relatedItem) => {
                        if (!relatedCollectionTouched?.[relatedItem.id]) {
                            form.setFieldValue(`${relatedCollectionName}.${relatedItem.id}`, undefined);
                        }
                    });
                });
            } else {
                form.setFieldValue(collectionName, {
                    ...selections,
                    ...getAllPageItemsSelected({ pageItems, selections }),
                });

                pageItems.forEach((item) => {
                    item.relatedItems?.forEach((relatedItem) => {
                        if (!relatedCollectionTouched?.[relatedItem.id]) {
                            form.setFieldValue(
                                `${relatedCollectionName}.${relatedItem.id}`,
                                relatedItem?.children?.length ? relatedItem.children.map((child) => child.id) : true
                            );
                        }
                    });
                });
            }
        },
        [collectionName, isChecked, form, pageItems, relatedCollectionName, selections]
    );
    const isProductSelected = collectionName === "products";

    const trackEventTextFilter = useMemo(() => {
        return debounce(
            (value) =>
                trackEventFilterText(value, isProductSelected ? CatalogueItemType.Product : CatalogueItemType.Modifier),
            2500
        );
    }, [isProductSelected]);

    return (
        <>
            <StickyElement>
                <div className={styles.containerHeaderTop}>
                    <div className={styles.innerContainerHeaderTop}>
                        <div className={styles.cellGrow}>
                            <Input
                                blockEnter
                                onChange={(value: string) => {
                                    setFilterText(value);
                                    trackEventTextFilter(value);
                                }}
                                value={filterText}
                                placeholder={`Search ${collectionName}`}
                                before={
                                    <Icon verticalAlign="middle">
                                        <Search />
                                    </Icon>
                                }
                                onClear={() => setFilterText("")}
                            />
                        </div>
                        <div className={styles.cellStatic}>
                            {isProductSelected && (
                                <Button role="secondary" type="button" onClick={() => setShowFiltersModal(true)}>
                                    <>
                                        {!!filterCount && (
                                            <Badge
                                                textColorScheme="light"
                                                backgroundColorScheme="dark"
                                                shape="circle"
                                                className={styles.badge}
                                            >
                                                {filterCount}
                                            </Badge>
                                        )}
                                        <span>Filter</span>
                                    </>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        numSelected !== 0 && pageItems.length > 0
                            ? styles.containerHeaderBottom
                            : styles.containerHeaderHidden
                    }
                >
                    <div className={styles.innerContainer}>
                        <div className={styles.controlsContainer}>
                            <Checkbox
                                key={`${pageSize}-${pageIndex}`}
                                indeterminate={indeterminate}
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <div className={styles.controlsInnerContainer}>
                                <div className={styles.controlsCompact}>
                                    <ActionsPopup closeOnMenuClick={true}>
                                        <Card cardStyle="popup">
                                            <Button
                                                shape="listItem"
                                                type="button"
                                                onClick={handleClear}
                                            >{`Clear all ${collectionName}`}</Button>
                                        </Card>
                                    </ActionsPopup>
                                </div>
                                <div className={styles.controlsLarge}>
                                    <Button role="secondary" type="button" onClick={handleClear}>
                                        {`Clear all ${collectionName}`}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="rowGroup">
                    <div
                        role="row"
                        className={
                            numSelected === 0 && pageItems.length > 0
                                ? styles.containerHeaderBottom
                                : styles.containerHeaderHidden
                        }
                    >
                        <div className={styles.innerContainer}>
                            <div role="columnheader" className={styles.check}>
                                <Checkbox
                                    key={pageIndex}
                                    indeterminate={indeterminate}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <Headers />
                        </div>
                    </div>
                </div>
            </StickyElement>
            <ModalRenderer target="#modal">
                <FiltersModal
                    visible={showFiltersModal}
                    onClose={() => setShowFiltersModal(false)}
                    onConfirm={() => {}}
                />
            </ModalRenderer>
        </>
    );
};
