import { opsApi } from "API";
import { ActiveGroup } from "../types/ActiveGroup";
import { activeGroupFields } from "./fetchGroup";

export const fetchDefaultGroup = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);

    return res.data.defaultGroup;
};

type GraphQLPayload = { defaultGroup: ActiveGroup };

const query = `
    query {
        defaultGroup {
            ${activeGroupFields}
        }
    }
`;
