import { Col } from "antd";
import { lazy } from "react";
import { Route } from "react-router";
import { TestAttributePrefix, TestContext } from "core/components/testAttributes";
import { withPermissions } from "common/permissions/components/withPermission";

const DailySalesReportPageContainer = lazy(() =>
    import("../containers/DailySalesReportContainer").then((module) => ({
        default: module.DailySalesReportPageContainer,
    }))
);

export function DailySalesReport() {
    return (
        <TestContext.Provider value={TestAttributePrefix.FEATURE_DAILYSALES}>
            <Route
                path={`/:region/venue/:location/reports/dailysales`}
                render={() => (
                    <Col span={24} md={24} className="content__col content__col__dailysalesreportpage">
                        <div className="content__col-list">
                            <Route component={withPermissions(DailySalesReportPageContainer, "reports:sales")} />
                        </div>
                    </Col>
                )}
            />
        </TestContext.Provider>
    );
}
