// Note localisation doesn't work in tests because of Intl.DateTimeFormat bugs in node v12

import { Option } from "core/components/form/select";

export function getShortDateFormatter(locale: string) {
    let formatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return {
        format: (date: Date) => formatter.format(date),
    };
}

export function getShortDatePlaceholder(locale: string) {
    const formatted = getShortDateFormatter(locale).format(new Date("2022/03/31"));

    return formatted.replace("2022", "YYYY").replace("03", "MM").replace("31", "DD");
}

export enum DaysOfWeek {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 4,
    THURSDAY = 8,
    FRIDAY = 16,
    SATURDAY = 32,
    SUNDAY = 64,
}

export const DAYS_OF_WEEK = [
    DaysOfWeek.MONDAY,
    DaysOfWeek.TUESDAY,
    DaysOfWeek.WEDNESDAY,
    DaysOfWeek.THURSDAY,
    DaysOfWeek.FRIDAY,
    DaysOfWeek.SATURDAY,
    DaysOfWeek.SUNDAY,
];

// number representing all days of week
export const ALL_DAYS = DAYS_OF_WEEK.reduce((total, n) => total | n, 0);

export const SHORT_WEEK_DAY_LABELS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export interface WeekdaySelectOption extends Option {
    label: string;
    value: DaysOfWeek;
}

export const WeekdaySelectOptions: WeekdaySelectOption[] = SHORT_WEEK_DAY_LABELS.map((label, index) => {
    return {
        label,
        value: DAYS_OF_WEEK[index],
    };
});

// convert bit field to array of days of week; if value is 0, return all days of week
export const getDaysOfWeek = (value: number): DaysOfWeek[] => {
    return value ? DAYS_OF_WEEK.filter((d) => (value & d) !== 0) : [...DAYS_OF_WEEK];
};

// convert array of days of week to bit field
export const getDaysOfWeekValue = (daysOfWeek: DaysOfWeek[]): number => {
    return daysOfWeek.reduce((total, n) => total | n, 0);
};

// convert bit field into array of short day week labels
export const getShortDaysOfWeekLabels = (value: number): string[] => {
    return getDaysOfWeek(value).map((d) => SHORT_WEEK_DAY_LABELS[DAYS_OF_WEEK.indexOf(d)]);
};

// convert either a bit field or `Days of week` array into string of short day week labels
export const getShortDaysOfWeekString = (
    daysOfWeek: DaysOfWeek[] | number,
    separator = ", ",
    allWeekDaysLabel: string | null = "All days"
): string => {
    const value = Array.isArray(daysOfWeek) ? getDaysOfWeekValue(daysOfWeek) : daysOfWeek;

    if (value === ALL_DAYS && allWeekDaysLabel) {
        return allWeekDaysLabel;
    }

    return getShortDaysOfWeekLabels(value).join(separator);
};
