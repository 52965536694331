import styles from "../ListItem.module.scss";
import classNames from "classnames";
import { FiltersContext } from "features/posCatalogueImport/context/FiltersContext";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { PosCatalogueImportItem } from "features/posCatalogueImport/types";
import { RowSelection } from "../RowSelection";
import { ReactElement, useContext } from "react";

export interface ListItemProps {
    disableSelection?: boolean;
    item: PosCatalogueImportItem;
    formatCurrency: (x: number) => string;
    parentItem: PosCatalogueImportItem | null;
}

export const ListItem = ({
    disableSelection,
    formatCurrency,
    item,
    item: {
        displayName,
        key,
        sku,
        parentId,
        price,
        // calories TODO https://meanduapp.atlassian.net/browse/INT-277
    },
    parentItem,
}: ListItemProps): ReactElement => {
    const displayNamePrefix = parentId ? "⸺ " : "";
    const containerStyle = parentId ? styles.containerSecondary : styles.containerPrimary;
    const { filterText } = useContext(FiltersContext);

    return (
        <div role="row" className={containerStyle}>
            <div className={styles.innerContainer}>
                <div role="cell" className={styles.check}>
                    <RowSelection
                        collectionName="modifiers"
                        item={item}
                        disabled={disableSelection || false}
                        parentItem={parentItem}
                        selectRelated={false}
                    />
                </div>
                <div className={styles.contentContainer}>
                    <label htmlFor={key} role="cell" className={styles.cellName}>
                        <span aria-hidden>{displayNamePrefix}</span>
                        <span className="sr-only">{parentId ? "child" : "parent"}</span>
                        <HighlightSearchTerm source={displayName} searchTerm={filterText} />
                    </label>
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber, styles.cellFirstWrap)}>
                        <span className={styles.cellLabel}>Price </span>
                        {price ? formatCurrency(price) : ""}
                    </span>
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber)}>
                        <span className={styles.cellLabel}>POS ID: </span>
                        <HighlightSearchTerm source={sku || ""} searchTerm={filterText} />
                    </span>
                    {/* TODO https://meanduapp.atlassian.net/browse/INT-277 */}
                    {/* <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber, styles.cellHideLast)}>
                    </span> */}
                </div>
            </div>
        </div>
    );
};
