import { State as UpdateState, reducer as updateReducer } from "./update";
import { combineReducers } from "redux";

export interface State {
    stripe: {
        update: UpdateState;
    };
}

export const reducer = combineReducers({
    update: updateReducer,
});
