import { CatalogueItemOption, Option, OptionSelectedTest, OptionWithSecondaryLabel } from "./types";
import { CatalogueItemSelectOption } from "./CatalogueItemSelectOption";
import { CatalogueItemSummary } from "common/scaffolding/types";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { GroupBase, Options, OptionsOrGroups, PropsValue } from "react-select";
import { normaliseText } from "common/utility/StringUtils";

export const SELECT_ALL_OPTION = {
    value: "__selectall__",
    label: "Select All",
};

export const defaultOptionSelectedTest: OptionSelectedTest = <T extends Option>(value: any, option: T) => {
    return value === option.value;
};

export function findOption<T extends Option>(
    value: any,
    options: OptionsOrGroups<T, GroupBase<T>>,
    optionSelectedTest: OptionSelectedTest
): PropsValue<T> {
    for (const optionOrGroup of options) {
        if ("value" in optionOrGroup) {
            if (optionSelectedTest(value, optionOrGroup)) return optionOrGroup;
            continue;
        }
        for (const option of optionOrGroup.options) {
            if (optionSelectedTest(value, option)) return option;
        }
    }
    return null;
}

export function findOptions<T extends Option>(
    value: any,
    options: OptionsOrGroups<T, GroupBase<T>>,
    optionSelectedTest: OptionSelectedTest = defaultOptionSelectedTest
): PropsValue<T> {
    return Array.isArray(value)
        ? (value.map((value) => findOption(value, options, optionSelectedTest)).filter(Boolean) as PropsValue<T>)
        : findOption(value, options, optionSelectedTest);
}

export function filterOptions<T extends Option>(
    options: OptionsOrGroups<T, GroupBase<T>>,
    searchText: string,
    filterOption: ((option: FilterOptionOption<T>, inputValue: string) => boolean) | null | undefined
) {
    const flatOptions: Options<T> = options.flatMap((option) => {
        return isGroup(option) ? option.options : option;
    });

    return flatOptions.filter(
        (option) =>
            option.value !== SELECT_ALL_OPTION.value &&
            (filterOption?.(
                {
                    value: option.value,
                    label: option.label,
                    data: option,
                },
                searchText
            ) ??
                true)
    );
}

export function hasGroups<T extends Option>(options: OptionsOrGroups<T, GroupBase<T>>): options is GroupBase<T>[] {
    if (!options) {
        return false;
    }

    return "options" in options || options.some((option) => "options" in option);
}

export function isGroup<T extends Option>(option: T | GroupBase<T>): option is GroupBase<T> {
    return "options" in option;
}

export function filterOptionByLabel<T extends OptionWithSecondaryLabel>(option: FilterOptionOption<T>, input: string) {
    const searchTerm = normaliseText(input.trim());

    if (!searchTerm || option.value === SELECT_ALL_OPTION.value) {
        return true;
    }

    const label = normaliseText(option.data.label || "");
    const secondaryLabel = normaliseText(option.data.secondaryLabel || "");

    return label.includes(searchTerm) || secondaryLabel.includes(searchTerm);
}

export const sortOptions = <T extends Option>(a: T, b: T): number => a.label.localeCompare(b.label);

export function formatCatalogueItemOption(option: CatalogueItemOption) {
    return <CatalogueItemSelectOption option={option} />;
}

export function optionFromCatalogueItemSummary(item: CatalogueItemSummary): CatalogueItemOption {
    return {
        label: item.displayName,
        secondaryLabel: item.internalName ?? undefined,
        value: item.id,
        isLinked: item.isLinked,
        isHidden: item.isHidden ?? false,
    };
}
