import { withSvg } from "core/components/svg/Svg";

export const AlertCircle = withSvg(
    60,
    60
)(() => (
    <>
        <path d="M30 3.75C15.5025 3.75 3.75 15.5025 3.75 30C3.75 44.4975 15.5025 56.25 30 56.25C44.4975 56.25 56.25 44.4975 56.25 30C56.25 15.5025 44.4975 3.75 30 3.75ZM0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z" />
        <path d="M30 12.1875C31.0355 12.1875 31.875 13.027 31.875 14.0625V36.5625C31.875 37.598 31.0355 38.4375 30 38.4375C28.9645 38.4375 28.125 37.598 28.125 36.5625V14.0625C28.125 13.027 28.9645 12.1875 30 12.1875Z" />
        <path d="M30 45.9375C31.0355 45.9375 31.875 45.098 31.875 44.0625C31.875 43.027 31.0355 42.1875 30 42.1875C28.9645 42.1875 28.125 43.027 28.125 44.0625C28.125 45.098 28.9645 45.9375 30 45.9375Z" />
    </>
));
