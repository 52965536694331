import { CmsItemSummary, GraphQLItem } from "common/scaffolding/types";
import { PriceListSummary } from "../../priceList";

export type MembershipProgramType = "Pos" | "Venue" | "Generic" | "MslGaming" | "Sprout";
export type MembershipAuthenticationMethod = "optin" | "form" | "oauth";

export interface MembershipSummary {
    id: string;
    title: string;
    subtitle: string;
    publishedStatus?: MembershipLevelPublishedState;
    type: MembershipProgramType;
    authType?: MembershipAuthenticationMethod;
}

export enum MembershipLevelPublishedState {
    STAGED = "Staged",
    PUBLISHED = "Published",
}

export interface MembershipProgram extends GraphQLItem, CmsItemSummary {
    type: MembershipProgramType;
    authentication?: MembershipAuthentication;
    links?: MembershipLinks[];
}

export interface VenueMembershipProgram extends MembershipProgram {
    membershipLevels: MembershipLevel[];
}

export interface MembershipAuthentication {
    method: MembershipAuthenticationMethod;
}

export interface SproutMembershipProgram extends VenueMembershipProgram {}

export interface MembershipLevel extends GraphQLItem {
    priceList: PriceListSummary;
    dateCreated?: string;
    dateUpdated?: string;
}

export interface MembershipLinks {
    key: MembershipLinksEnum;
    value: string;
}

export enum MembershipLinksEnum {
    PRIVACY = "privacy",
}
