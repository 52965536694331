import { createSelector } from "reselect";
import {
    CatalogueModifierItem,
    CatalogueModifierOption,
    ModifierSummary,
    ProductSummary,
    StatusDictionary,
} from "features/catalogue/types";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getProductsById } from "./getProductsById";
import { nonNullable } from "common/types/nonNullable";
import { getModifiersListData } from "./getModifiersList";

export const getModifierCatalogueItems = createSelector(
    getModifiersListData,
    getProductsById,
    getIsChildLocation,
    (modifiers, productsById, isChildLocation) => {
        return modifiers.map((modifier): CatalogueModifierItem => {
            return {
                children: mapModifierChildren(modifier, productsById, !!isChildLocation),
                id: modifier.id,
                type: "modifier",
                displayName: modifier.displayName,
                internalName: modifier.internalName,
                minSelection: modifier.minSelection,
                maxSelection: modifier.maxSelection,
                maxSelectionPerOption: modifier.maxSelectionPerOption,
                sku: modifier.sku,
                status: modifier.status,
                statusName: StatusDictionary[modifier.status] || StatusDictionary[0],
                validationStatus: modifier.validationStatus,
                key: modifier.id,
                upsell: modifier.upsell,
                isLinked: modifier.isLinked,
                isHidden: modifier.isHidden,
                productVariantReferences: modifier.productVariantReferences,
                csvExportDisabled: Array.isArray(modifier.productVariantReferences),
            };
        });
    }
);

export const getModifierCatalogueItemsWithoutUpsells = createSelector(getModifierCatalogueItems, (modifiers) => {
    return modifiers.filter((m) => {
        return !Array.isArray(m.productVariantReferences);
    });
});

function mapModifierChildren(
    modifier: ModifierSummary,
    productsById: Record<string, ProductSummary | undefined>,
    isChildLocation: boolean
): CatalogueModifierOption[] {
    // product variants as modifier options (upsells)
    if (Array.isArray(modifier.productVariantReferences)) {
        return modifier.productVariantReferences
            .map((ref) => {
                const product = productsById[ref.productId];

                const variant = product?.variants.find((v) => v.id === ref.variantId);

                if (!product) {
                    return null;
                }

                const option: CatalogueModifierOption = variant
                    ? {
                          id: ref.variantId,
                          type: "upsell",
                          displayName: `${product.displayName}/${variant.displayName}`,
                          price: variant.price,
                          sku: variant.sku,
                          parentId: modifier.id,
                          validationStatus: variant.validationStatus,
                          validationStatusReason: variant.validationStatusReason,
                          key: `${modifier.id}-${ref.productId}-${ref.variantId}`,
                          isLinked: product.isLinked,
                          isHidden: product.isHidden, // TODO use variant.isHidden when api ready
                          status: product.status,
                          disabled: true, // disable from bulk selection
                          csvExportDisabled: true,
                      }
                    : {
                          id: ref.productId,
                          type: "upsell",
                          displayName: `${product.displayName}`,
                          price: product.price,
                          sku: product.sku,
                          parentId: modifier.id,
                          validationStatus: product.validationStatus,
                          validationStatusReason: product.validationStatusReason,
                          key: `${modifier.id}-${ref.productId}`,
                          isLinked: product.isLinked,
                          isHidden: product.isHidden,
                          status: product.status,
                          disabled: true, // disable from bulk selection
                          csvExportDisabled: true,
                      };

                return option;
            })
            .filter(nonNullable);
    }

    return modifier.options
        ? modifier.options.map<CatalogueModifierOption>((o) => {
              return {
                  id: o.id,
                  type: "option",
                  displayName: o.displayName,
                  price: o.price,
                  sku: o.sku,
                  parentId: modifier.id,
                  validationStatus: o.validationStatus,
                  validationStatusReason: o.validationStatusReason,
                  key: `${modifier.id}-${o.id}`,
                  modifiers: o.modifiers,
                  energyContent: o.energyContent,
                  isLinked: o.isLinked,
                  isHidden: o.isHidden,
              };
          })
        : [];
}
