import styles from "./RegionPopButton.module.scss";

import { forwardRef, ReactElement } from "react";
import { ButtonProps } from "core/components/popup";
import { Button } from "core/components/button";
import { useMedia } from "common/hooks";
import { FlagCircularAU } from "core/components/icon/FlagCircularAU";
import { FlagCircularGB } from "core/components/icon/FlagCircularGB";
import { FlagCircularNZ } from "core/components/icon/FlagCircularNZ";
import { FlagCircularUS } from "core/components/icon/FlagCircularUS";
import { region } from "features/region";

const flags = {
    au: FlagCircularAU,
    gb: FlagCircularGB,
    nz: FlagCircularNZ,
    us: FlagCircularUS,
};

export const RegionPopupButton = forwardRef<ReactElement, ButtonProps>(({ label, onClick, expanded, ...rest }, ref) => {
    const large = useMedia("(min-width: 896px)");

    const activeRegion = region.getActiveRegion();

    const { id, displayName } = activeRegion || {};

    const hasAccess = region.hasAccess();

    const Flag = flags[id] || null;

    return (
        <Button
            ref={ref}
            onClick={onClick}
            role="secondary"
            padding={large ? "regular" : "icon"}
            aria-label={`Change region. Currently ${displayName}`}
            className={hasAccess ? undefined : styles.inactive}
            {...rest}
        >
            <span className={styles.icon}>{Flag && <Flag title={displayName} />}</span>
            <span className={styles.label}>{label}</span>
        </Button>
    );
});
