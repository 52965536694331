import {
    ApplicationInsights,
    IEventTelemetry,
    ICustomProperties,
    IPageViewTelemetry,
    SeverityLevel,
    Snippet,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin, useTrackEvent as useCustomEvent } from "@microsoft/applicationinsights-react-js";
import { useRef } from "react";
import { AppMiddleware, AppState, FeatureConfiguration } from "features";
import { ActiveLocation } from "features/location";
import {
    ReduxTrackEventPlugin,
    getScopeTelemetryInitializer,
    getUserTelemetryInitializer,
    DeviceTelemetryInitializer,
} from "./plugins";
import { AnyAction, Dispatch } from "redux";
import { findReduxActionPlugins } from "./util/findReduxActionPlugins";
import { history } from "foundation/history";
import { History } from "history";
import { auth } from "common/auth";
import { normalizeError } from "common/error";

const reactPlugin = new ReactPlugin();

const snippet: Snippet = {
    config: {
        // Has to be set to empty string to avoid initialization error:
        // "Uncaught Error: Please provide instrumentation key"
        instrumentationKey: "",
        // Has to be set to true to avoid initalization error: 
        // "Invalid Instrumentation key"
        disableInstrumentationKeyValidation: true,
        connectionString: "",
        extensions: [reactPlugin, new ReduxTrackEventPlugin()],
        loggingLevelConsole: 2,
        enableDebug: true,
        enableAutoRouteTracking: false,
    },
};

const appInsights = new ApplicationInsights(snippet);

let inited = false;
let enabled = false;

function initAppInsights(getState: () => AppState, connectionString: string | undefined) {
    const scopeTelemetryInitializer = getScopeTelemetryInitializer(getState);
    if (connectionString) {
        enabled = true;
        snippet.config.connectionString = connectionString;

        if (inited) {
            appInsights.flush();
            appInsights.updateSnippetDefinitions(snippet);
        } else {
            inited = true;
            appInsights.loadAppInsights();
            appInsights.addTelemetryInitializer(DeviceTelemetryInitializer);
            appInsights.addTelemetryInitializer(scopeTelemetryInitializer);
        }
    }
}

function setAppInsightsUserContext(activeLocation: ActiveLocation, getState: () => AppState) {
    if (!enabled) {
        return;
    }
    const authState = auth.getState();

    if (auth.isAuthenticated() && authState) {
        const idWithoutInvalidCharacters = authState.id?.replace(/[,;= |]/g, ":");
        appInsights.setAuthenticatedUserContext(idWithoutInvalidCharacters, activeLocation.id);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }

    appInsights.addTelemetryInitializer(getUserTelemetryInitializer(getState));
}

// set skipFirstRun to false if needed to be trigger on creation
function useTrackEvent<T>(eventName: string, eventData: T, skipFirstRun: boolean = true) {
    const trackEvent = useRef(useCustomEvent(reactPlugin, "CMS/" + eventName, eventData, skipFirstRun));
    if (!enabled) {
        return;
    }
    return trackEvent.current;
}

let refUri = document?.referrer;

function trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    if (!enabled) {
        return;
    }
    event.name = "CMS/" + event.name;
    reactPlugin.trackEvent(event, customProperties);
}

// set skipFirstRun to false if needed to be trigger on creation
function trackPageView(uri: string, additionalPageViewData: IPageViewTelemetry = {}) {
    if (!enabled) {
        return;
    }
    if (additionalPageViewData.name) {
        additionalPageViewData.name = "CMS/" + additionalPageViewData.name;
    }

    reactPlugin.trackPageView({
        uri,
        refUri,
        ...additionalPageViewData,
    });

    refUri = uri;
}

function trackException(input?: any, severity: SeverityLevel = SeverityLevel.Error) {
    const error = normalizeError(input);
    enabled && appInsights.trackException({ error: error, severityLevel: severity });
}

function flush() {
    enabled && appInsights.flush();
}

export const appInsightsMiddleware = (history: History) => (store: AppMiddleware) => (next: Dispatch) => {
    const reduxActionPlugins = findReduxActionPlugins(snippet.config.extensions || []);

    return (action: AnyAction) => {
        for (let reduxActionPlugin of reduxActionPlugins) {
            if (enabled) {
                reduxActionPlugin.handleReduxAction(action, store.getState);
            }
        }

        return next(action);
    };
};

export {
    reactPlugin,
    flush,
    initAppInsights,
    useTrackEvent,
    trackEvent,
    trackPageView,
    trackException,
    setAppInsightsUserContext,
};

export default function (config: FeatureConfiguration) {
    config.middleware.push(appInsightsMiddleware(history));
}
