import { AppState } from "features/state";
import { createSelector } from "reselect";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { getAnalyticsNavGroup } from "./getAnalyticsNavGroup";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getLocationCurrency } from "features/location/selectors/getLocationLocale";
import { getMainMenu } from "./getMainMenu";
import { getNavStructure } from "../api/locationNavStructure";
import { isConfigEnabled, isCurrencyEnabled, isDisplayable, isNotHiddenPage, isPermitted } from "./menuFilters";
import { isFeatureEnabled, isNonParentLocationEnabled, isParentLocationEnabled } from "./locationMenuFilters";
import { NavItemFilter } from "../types/NavItemFilter";
import { NavNode } from "../types/NavMenu";
import { region } from "features/region";
import {
    shouldHideCataloguePages,
    shouldHideCategoriesPage,
    shouldHidePriceListPage,
    shouldHideServicesPage,
    shouldHideTaxPage,
} from "features/location/selectors/getPosMenuSupported";
import { config } from "common/config";
import { isInternalUser } from "features/userManagement/utils";

const getState = (state: AppState) => state;

export const getIsMigratedLocation = createSelector(
    getActiveLocation,
    (activeLocation) => !!activeLocation?.manageMigrationUrl && !!activeLocation?.migrationSlug
)

export const getPagesToHide = createSelector(
    shouldHideCataloguePages,
    shouldHideCategoriesPage,
    shouldHideServicesPage,
    shouldHidePriceListPage,
    shouldHideTaxPage,
    getIsMigratedLocation,
    (hideCatalogue, hideCategories, hideServices, hidePricelist, hideTaxes, isMigratedLocation) => {
        const pagesToHide: string[] = [];

        if (hideCatalogue) {
            pagesToHide.push("menu/catalogue");
        }

        if (hideCategories) {
            pagesToHide.push("menu/categories");
        }

        if (hideServices) {
            pagesToHide.push("menu/services");
        }

        if (hidePricelist) {
            pagesToHide.push("menu/pricelists");
        }

        if (hideTaxes) {
            pagesToHide.push("menu/taxes");
        }

        if (isMigratedLocation) {
            pagesToHide.push(
                "branding",
                "sections",
                "staff",
                "settings",
                "takeaway",
                "menu/catalogue",
                "menu/services",
                "menu/categories",
                "menu/surcharges",
                "menu/pricelists",
                "quicksell",
                "menu/grouptabs",
                "promotions",
                "membership",
                "payments"
            );
        } else {
            pagesToHide.push("meandu2");
        }

        return pagesToHide;
    }
);

export const getLocationMainMenu = createSelector(
    getState,
    getActiveLocation,
    getLocationPermissions,
    getNavStructure,
    getLocationCurrency,
    getPagesToHide,
    getIsParentLocation,
    (state, location, permissions, navStructure: NavNode[], currency, hiddenPages, isParentLocation): NavNode[] => {
        if (!location) {
            return [];
        }

        const itemFilters: NavItemFilter[] = [
            isPermitted(permissions),
            isDisplayable(state),
            isFeatureEnabled(location),
            isConfigEnabled(),
            isCurrencyEnabled(currency),
            isNotHiddenPage(hiddenPages),
        ];

        isParentLocation && itemFilters.push(isParentLocationEnabled());
        !isParentLocation && itemFilters.push(isNonParentLocationEnabled());

        const routeParams = {
            location: location.slug,
            locationId: location.id,
            region: region.getActiveRegion().id,
        };

        const analyticsNavGroup = getAnalyticsNavGroup(location.analyticsLayoutConfig);

        return getMainMenu(
            analyticsNavGroup ? [...navStructure, analyticsNavGroup] : navStructure,
            itemFilters,
            routeParams
        );
    }
);

export const getSupportalLink = createSelector(
    getActiveLocation,
    (activeLocation) =>
        activeLocation &&
        isInternalUser(activeLocation) &&
        `${config.VITE_SUPPORTAL_LINK}/${region.getActiveRegion().id}/location/${activeLocation.id}/1`
);
