import { TagGroup } from "features/tags/types";
import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "TAGGROUPLIST/LOADING",
    LOADED = "TAGGROUPLIST/LOADED",
    FAILED = "TAGGROUPLIST/FAILED",
}

const list = scaffoldList<TagGroup, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
