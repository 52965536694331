import { useCallback, useEffect, useMemo, useRef } from "react";
import { PosCatalogueImportItem, PosCatalogueImportSelections } from "../types";
import { Checkbox } from "core/components/form/checkbox";
import { getIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { getModifierPosCatalogueItems, getProductPosCatalogueImportItems } from "../selectors/getPosCatalogueItems";
import { equalsIgnoreOrder } from "common/utility/arrayUtils";

export interface Props {
    collectionName: "products" | "modifiers";
    disabled: boolean;
    item: PosCatalogueImportItem;
    parentItem: PosCatalogueImportItem | null;
    selectRelated?: boolean;
}

export const RowSelection = ({ collectionName, disabled, item, parentItem, selectRelated = false }: Props) => {
    const form = useFormikContext<PosCatalogueImportSelections>();

    const { setFieldValue, setFieldTouched, values, touched } = form;

    const products = useSelector(getProductPosCatalogueImportItems);

    const modifiers = useSelector(getModifierPosCatalogueItems);

    const isParent = !parentItem;

    const parent = isParent ? item : parentItem;

    const parentId = isParent ? item.id : item.parentId;

    const selectableRelatedItemIds = useRef<string[]>([]);

    const deselectableRelatedItemIds = useRef<string[]>([]);

    // selections for the parent item and its children
    const itemSelections = useMemo(
        () => (parentId ? getIn(values, [collectionName, parentId]) : null),
        [values, collectionName, parentId]
    );

    const isParentTouched = useMemo(
        () => (parentId ? getIn(touched, [collectionName, parentId]) : false),
        [touched, collectionName, parentId]
    );

    const numChildren = item.children?.length || 0;

    const numChildrenSelected = item.children?.filter((child) => itemSelections?.includes(child.id)).length || 0;

    const indeterminate = numChildrenSelected > 0 && numChildren > numChildrenSelected;

    const checked = isParent
        ? itemSelections === true || (numChildren > 0 && numChildren === numChildrenSelected)
        : itemSelections?.includes(item.id) || false;

    const relatedItems = useMemo(() => {
        return (selectRelated && parent?.relatedItems?.length && parent?.relatedItems) || null;
    }, [parent, selectRelated]);

    const relatedTouched = useMemo(
        () => (collectionName === "products" ? touched.modifiers : null),
        [collectionName, touched.modifiers]
    );

    const relatedSelections = useMemo(
        () => (collectionName === "products" ? values.modifiers : null),
        [collectionName, values.modifiers]
    );

    // these effects update the selectable and deselectable related items.
    // By moving the checks outside the change handler, and storing results in refs we dont need
    // form.values as a dependency of change handler which would trigger the handler's
    // useCallback excessively.

    // this effect precalculates related items that should be selected with parent item
    useEffect(() => {
        const items =
            relatedItems
                ?.filter((relatedItem) => {
                    if (getIn(relatedTouched, relatedItem.id)) {
                        return false;
                    }

                    return !getIn(relatedSelections, relatedItem.id);
                })
                .map(({ id }) => id) || [];

        if (!equalsIgnoreOrder(items, selectableRelatedItemIds.current)) {
            selectableRelatedItemIds.current = items;
        }
    }, [relatedTouched, relatedSelections, relatedItems, selectableRelatedItemIds]);

    // this effect precalculates related items that should be deselected with parent item
    useEffect(() => {
        const collectionSelections = getIn(values, collectionName) || [];

        const relatedItemIds = relatedItems?.map((itm) => itm.id) || [];

        const items =
            relatedItems
                ?.filter((relatedItem) => {
                    if (getIn(relatedTouched, relatedItem.id)) {
                        return false;
                    }

                    if (!getIn(relatedSelections, relatedItem.id)) {
                        return false;
                    }

                    const selectedParentsThatShareRelatedItems = products.filter(
                        (product) =>
                            product.id !== parentId &&
                            product.relatedItems?.some(
                                (relatedItem) =>
                                    relatedItemIds.includes(relatedItem.id) && !!collectionSelections[product.id]
                            )
                    );

                    const isSelectedByOther = selectedParentsThatShareRelatedItems?.find((parent) => {
                        const relatedItemIds = parent.relatedItems?.map((itm) => itm.id) || [];
                        return relatedItemIds.includes(relatedItem.id);
                    });

                    return !isSelectedByOther;
                })
                .map(({ id }) => id) || [];

        if (!equalsIgnoreOrder(items, deselectableRelatedItemIds.current)) {
            deselectableRelatedItemIds.current = items;
        }
    }, [
        collectionName,
        selectableRelatedItemIds,
        deselectableRelatedItemIds,
        parentId,
        products,
        relatedTouched,
        relatedSelections,
        relatedItems,
        values,
    ]);

    const handleChange = useCallback(() => {
        const isParent = !item.parentId;

        if (!parent) {
            return;
        }

        const numChildren = parent.children?.length || 0;

        const numChildrenSelected = parent.children?.filter((child) => itemSelections?.includes(child.id)).length || 0;

        const parentIsChecked = itemSelections === true || (numChildren > 0 && numChildren === numChildrenSelected);

        const relatedCollectionName = collectionName === "products" ? "modifiers" : undefined;

        if (isParent) {
            if (parentIsChecked) {
                const childIds = item.children?.map(({ id }) => id);
                // only deselect current item.children in case some selections are hidden by filters
                const newSelections = Array.isArray(itemSelections)
                    ? itemSelections.filter((value) => !childIds?.includes(value))
                    : [];

                setFieldValue(`${collectionName}.${item.id}`, newSelections.length ? newSelections : undefined);

                deselectableRelatedItemIds.current?.forEach((relatedId) =>
                    setFieldValue(`${relatedCollectionName}.${relatedId}`, undefined)
                );
            } else {
                setFieldValue(
                    `${collectionName}.${item.id}`,
                    item.children?.length ? item.children.map((child) => child.id) : true
                );

                if (!isParentTouched) {
                    setFieldTouched(`${collectionName}.${item.id}`, true, false);
                }

                selectableRelatedItemIds.current?.forEach((relatedId) => {
                    const modifier = modifiers.find((modifier) => modifier.id === relatedId);
                    if (modifier) {
                        const value = modifier.children?.map((child) => child.id) || true;
                        setFieldValue(`${relatedCollectionName}.${relatedId}`, value);
                    }
                });
            }
        } else {
            let val: string[] | undefined;

            if (!itemSelections || !Array.isArray(itemSelections)) {
                val = [item.id!];
            } else {
                if (itemSelections.includes(item.id)) {
                    val =
                        itemSelections.length === 1
                            ? undefined
                            : itemSelections?.filter((id: string) => item.id !== id);
                } else {
                    val = [...itemSelections, item.id];
                }
            }

            setFieldValue(`${collectionName}.${item.parentId}`, val);

            if (!isParentTouched) {
                setFieldTouched(`${collectionName}.${item.parentId}`, true, false);
            }

            if (!val?.length) {
                deselectableRelatedItemIds.current?.forEach((relatedId) =>
                    setFieldValue(`${relatedCollectionName}.${relatedId}`, undefined)
                );
            }
        }
    }, [collectionName, item, itemSelections, isParentTouched, modifiers, parent, setFieldTouched, setFieldValue]);

    return (
        <Checkbox
            key={item.key}
            checked={checked}
            disabled={disabled}
            id={item.key}
            indeterminate={indeterminate}
            name={`${collectionName}.${item.id}`}
            onChange={handleChange}
            value={item.id}
        />
    );
};
