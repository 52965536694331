import { PropsWithChildren } from "react";
import { useTestAttributes } from "../testAttributes";
import styles from "./EmptyMessage.module.scss";
import { EmptySvg } from "./EmptySvg";

export type Size = "standard" | "large";
export interface EmptyMessageProps {
    heading?: string;
    message?: string;
    size?: Size;
}

export const EmptyMessage = ({
    children,
    message = "Oops, there’s nothing here!",
    heading = "",
    size = "large",
}: PropsWithChildren<EmptyMessageProps>) => {
    const { getTestClass } = useTestAttributes();

    return (
        <div className={styles.container}>
            <div className={styles[`icon--${size}`]}>
                <EmptySvg />
            </div>
            {heading && (
                <h2 className={styles.heading} data-testclass={getTestClass({ fragment: "empty", element: "title" })}>
                    {heading}
                </h2>
            )}
            {message && (
                <p className={styles.message} data-testclass={getTestClass({ fragment: "empty", element: "subtitle" })}>
                    {message}
                </p>
            )}
            {children}
        </div>
    );
};
