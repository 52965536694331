import { FeatureConfiguration } from "features";
import { Memberships } from "./components/Memberships";
import { reducer } from "./reducers";
import * as actions from "./actions";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.memberships = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Memberships key="membership" />]);
}
