import styles from "./Card.module.scss";

import { forwardRef, PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type Collapse = "up" | "down" | "both";
export type Border = "top" | "bottom" | "auto";
export type Background = "default" | "neutral" | "subtle" | undefined;
export type PaddingY = "large" | "medium";

export interface Props {
    as?: React.ElementType;
    collapse?: Collapse;
    border?: Border;
    className?: string;
    direction?: "column";
    background?: Background;
    hide?: boolean;
    paddingY?: PaddingY;
}

export const Row = forwardRef<ReactElement | HTMLElement, PropsWithChildren<Props & Record<string, any>>>(
    (
        {
            as: Component = "div",
            background,
            children,
            collapse,
            border,
            className,
            direction,
            hide,
            paddingY = "large",
            ...rest
        },
        ref
    ) => {
        // prevent error if as is null or empty string
        if (!Component) {
            Component = "div";
        }

        const classes = classNames({
            [styles.row]: !collapse,
            [styles.asColumn]: direction === "column",
            [styles[`row--${background}`]]: background,
            [styles[`row--padding-y-${paddingY}`]]: true,
            [styles[`collapse--${collapse}`]]: collapse,
            [styles[`border--${border}`]]: border,
            [styles.hidden]: hide,
            [`${className}`]: className,
        });

        return (
            <Component className={classes} ref={ref} {...rest}>
                {children}
            </Component>
        );
    }
);
