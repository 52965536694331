function calculatePathComplexity(path: string) {
    return path
        .trim()
        .split(",")
        .reduce((tot, part) => tot + part.split(" ").length, 0);
}

export function calculateSvgComplexity(svgXml: string) {
    var dom = new DOMParser().parseFromString(svgXml, "application/xml");

    return calculateComplexity(dom, dom.documentElement);
}

function calculateComplexity(dom: Document, el: Element): number {
    switch (el.localName) {
        case "metadata":
            // use?
            return 0;
        case "svg":
        case "g":
            break;

        case "image":
            return 30000; // Instant penalty for embedding an image

        case "circle":
        case "ellipse":
            return 10;

        case "line":
            return 2;

        case "rect":
            return 4;

        case "polyline":
        case "polygon":
            const points = el.getAttribute("points");

            if (!points) {
                return 1;
            }

            return calculatePathComplexity(points);

        case "path":
            const d = el.getAttribute("d");
            if (!d) {
                return 1;
            }
            return calculatePathComplexity(d);

        case "use":
            var href = el.getAttributeNS("http://www.w3.org/1999/xlink", "href");

            if (href) {
                const ref = dom.querySelector(href);

                if (ref) {
                    return calculateComplexity(dom, ref as HTMLElement);
                }

                return 0;
            }
    }

    return Array.from(el.children).reduce((tot, child) => tot + calculateComplexity(dom, child), 0);
}
