import { Category } from "./components/Category";
import { FeatureConfiguration } from "..";
import { reducer } from "features/category/reducers";

import * as actions from "features/category/actions";
import { getTestAttributeGenerators, TestAttributePrefix } from "core/components/testAttributes";

export * from "features/category/types";

export { actions };

export const { getTestId, getTestClass } = getTestAttributeGenerators(TestAttributePrefix.FEATURE_CATEGORY);

export default function (config: FeatureConfiguration) {
    config.reducers.categories = reducer;

    config.addRoutes(config.routes.locationRoutes, [<Category key="categories" />]);
}
