import styles from "./EditGroupSettings.module.scss";

import { FastField, Field, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { Form } from "antd";
import { EditableGroup } from "../types/EditableGroup";
import { editableGroupSchema } from "../schema/editableGroupSchema";
import { useCallback, useContext } from "react";
import { save } from "../actions";
import { useAppDispatch } from "common/hooks/useAppDispatch";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { FieldErrors } from "components/forms";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { getTestId } from "features/category";
import { useGroupPermissions } from "features/location/hooks";
import { QuickCopyInput } from "components/forms/QuickCopyInput";
import { GroupContext } from "../context";
import { useFetchGroup } from "common/hooks/useFetchGroup";
import { PageError } from "core/components/pageError";
import { Button } from "core/components/button";
import { PageLoading } from "core/components/pageLoading";
import { getGroupSettingsOptions } from "../selectors/getGroupSettingsOptions";
import { useSelector } from "react-redux";
import { Switch } from "core/components/form/switch";
import { CrudActionFooter } from "core/components/actionFooter";
import { Card, Row, Title } from "core/components/card";
import { Input } from "core/components/form/input";
import { Label } from "core/components/form/input/Label";
import { EditLocationsWithEngage } from "./EditLocationsWithEngage";
import { Alert, AlertStatus } from "core/components/alert";

export const EditGroupSettings = () => {
    const permissions = useGroupPermissions();
    const disableFields = !permissions.group.canUpdate;
    const disableEnageFields = !permissions.engage.canUpdate;
    const group = useContext(GroupContext);
    const { isLoading, hasErrored, retry } = useFetchGroup(group.id);
    const formValues = useSelector(getGroupSettingsOptions);

    const dispatch = useAppDispatch();

    const handleOnSubmit = useCallback(
        (data: EditableGroup) => {
            dispatch(save(data.id, data));
        },
        [dispatch]
    );
    const validate = useCallback((value: EditableGroup) => {
        const context = {};

        return validateYupSchema(value, editableGroupSchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    }, []);

    if (hasErrored) {
        return (
            <PageError
                heading="Oh no! Something went wrong."
                message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
                actions={<Button onClick={retry}>Retry</Button>}
            />
        );
    }

    if (isLoading || !formValues) {
        return <PageLoading message="Loading group settings..." />;
    }

    return (
        <div className={styles.page} data-testid={getTestId({ name: "edit-group-details-page" })}>
            <Formik validate={validate} initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize>
                {(form) => (
                    <Form
                        onFinish={form.handleSubmit}
                        layout="vertical"
                        autoComplete="off"
                        className={styles.container}
                        data-testid={getTestId({ name: "edit-group-details", element: "form" })}
                    >
                        <ScrollToFormikError />
                        <PageTitle
                            title="Group settings"
                            description="These are the settings for your entire group. "
                        />

                        <Card>
                            <Row collapse="down">
                                <Title title="General information" />
                            </Row>
                            <Row collapse="down">
                                <FieldErrors name="displayName" form={form} className={styles.inputFull}>
                                    <Label label="Display name" name="displayName" markRequired />
                                    <div className={styles.labelCaption}>
                                        Enter the name of your group as you want it to display in me&u.
                                    </div>
                                    <FastField
                                        name="displayName"
                                        component={Input}
                                        required
                                        placeholder="Display name"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </Row>
                            <Row collapse="both">
                                <div className={styles.inputFull}>
                                    <Label label="Group slug" name="slug" />
                                    <FastField name="slug" component={QuickCopyInput} readonly />
                                </div>
                            </Row>
                            <Row collapse="both">
                                <div className={styles.inputFull}>
                                    <Label label="Group ID" name="id" />
                                    <FastField name="id" component={QuickCopyInput} readonly />
                                </div>
                            </Row>
                            <Row collapse="both">
                                <FieldErrors name="privacyPolicyUrl" form={form} className={styles.inputFull}>
                                    <Label label="Group privacy policy URL" name="privacyPolicyUrl" />
                                    <div className={styles.labelCaption}>
                                        Insert a URL to your group’s privacy policy if you want customers to be able to
                                        view it when opting-in to marketing.
                                    </div>
                                    <FastField
                                        name="privacyPolicyUrl"
                                        component={Input}
                                        placeholder="https://www.privacypolicy.com"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </Row>
                        </Card>
                        <Card>
                            <FieldErrors name="hideCatalogueMigrationWarning" form={form} className={styles.inputFull}>
                                <Row className={styles.engageContainer} collapse="down">
                                    <div>
                                        <Title title="Hide catalogue migration warning" />
                                        <div>
                                            When enabled, the catalogue page for venues in this group will not
                                            display the migration warning.
                                        </div>
                                    </div>
                                    <Field
                                        name="hideCatalogueMigrationWarning"
                                        type="checkbox"
                                        component={Switch}
                                        disabled={disableFields}
                                    />
                                </Row>
                            </FieldErrors>
                        </Card>
                        <Card>
                            <FieldErrors name="engage.active" form={form} className={styles.inputFull}>
                                <Row className={styles.engageContainer} collapse="down">
                                    <div>
                                        <Title title="Engage" />
                                        <div>
                                            Enable Engage to sync your group and menu data daily with Eagle Eye.
                                            Promotional campaigns and offers may then be requested through the Engage
                                            Concierge service.
                                        </div>
                                    </div>
                                    <Field
                                        name="engage.active"
                                        type="checkbox"
                                        component={Switch}
                                        disabled={disableFields || disableEnageFields}
                                    />
                                </Row>
                            </FieldErrors>
                            <Row
                                className={styles.engageContainer}
                                collapse="up"
                                hide={!form.values.engage?.active || formValues.engage?.active}
                            >
                                <Alert
                                    title="Please note it may take 24 hours for Group data to be populated in Eagle Eye."
                                    status={AlertStatus.INFO}
                                    closeable
                                />
                            </Row>
                            <EditLocationsWithEngage form={form} disabled={disableFields || disableEnageFields} />
                        </Card>
                        <CrudActionFooter
                            saving={false}
                            submitMode="save"
                            showDuplicate={false}
                            showDelete={false}
                            showSave={!disableFields}
                            saveProps={{
                                disabled: !form.dirty,
                            }}
                            position="fixed"
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};
