import { ExportIssue } from "../types/ExportIssue";
import { scaffoldList } from "common/scaffolding/reducers/scaffoldList";

enum TypeKeys {
    LOADING = "EXPORT_ISSUES/LOADING",
    LOADED = "EXPORT_ISSUES/LOADED",
    FAILED = "EXPORT_ISSUES/FAILED",
}

export enum EditTypeKeys {
    FETCH_BEGIN = "EXPORT_ISSUES/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "EXPORT_ISSUES/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "EXPORT_ISSUES/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "EXPORT_ISSUES/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "EXPORT_ISSUES/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "EXPORT_ISSUES/SAVE_EDITABLE_FAILURE",
}

const list = scaffoldList<ExportIssue, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys);

const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
