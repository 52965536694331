import { AppState } from "features";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { EditableModifierSubmission, ModifierSummary } from "features/catalogue/types";
import { isNew } from "common/utility/modelUtils";

const getMenuItems = ({ menuItems: { list } }: AppState) => {
    return list.status === "loaded" ? list.data : null;
};

const getExistingModifier = ({ modifiers: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : null;
};

export const getSaveModifierTrackingEventData = createSelector(
    [
        (state: AppState) => getMenuItems(state),
        (state: AppState) => getExistingModifier(state),
        (_: AppState, formData: EditableModifierSubmission) => formData,
        (_: AppState, __: EditableModifierSubmission, saveResult?: ModifierSummary) => saveResult,
    ],
    (menuItems, existing, formData, saveResult) => {
        const isNewModel = isNew(formData.id);
        const modifierId = !isNewModel ? formData.id : saveResult?.id;
        const changes: any = {};

        if (modifierId) {
            changes.modifier_id = modifierId;
        }

        if (isNewModel || (existing && existing.upsell !== formData.upsell)) {
            changes.print_as_products = formData.upsell;
        }

        if (isNewModel || (existing && existing.required !== formData.required)) {
            changes.required = formData.required;
        }

        if (isNewModel || (existing && existing.smartSorting !== formData.smartSorting)) {
            changes.smart_sorting = formData.smartSorting;
        }

        if (isNewModel || (existing && !isEqual(existing.options, formData.options))) {
            changes.options =
                formData.options?.map(({ id, sku, price, recommended }) => ({
                    id,
                    pos_id: sku,
                    price,
                    recommended,
                })) ?? null;
        }

        if (isNewModel || (existing && !isEqual(existing.options, formData.options))) {
            changes.productOptions = formData.productOptions ?? null;
        }

        if (isNewModel || (existing && !isEqual(existing.products, formData.products))) {
            changes.products = formData.products?.map((product) => {
                const menuItem = menuItems?.find(({ id }) => id === product.productId);
                const { id, displayName, variants } = menuItem || {};
                const hasVariants = !!variants?.length;
                const selectedVariants = variants?.filter(({ id }) => product.variantIds?.includes(id));
                return {
                    id,
                    name: displayName,
                    variants: hasVariants
                        ? selectedVariants?.map(({ id, displayName }) => ({
                              id,
                              name: displayName,
                          }))
                        : undefined,
                };
            });
        }

        return {
            event: {
                name: `MODIFIER/${isNewModel ? "ADDITION" : "MODIFICATION"}_SAVED`,
            },
            customProperties: changes,
        };
    }
);
