import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { lazy } from "react";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";

const TeamAuditLogEntries = lazy(() =>
    import("./TeamAuditLogWrapper").then((module) => ({
        default: module.TeamAuditLogWrapper,
    }))
);

const List: ListComponent = {
    route: ":teamSlug/auditLog",
    component: TeamAuditLogEntries,
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

export function TeamAuditLog() {
    return <ModalCrudRouteContainer name="auditlog" listComponent={List} route="" />;
}
