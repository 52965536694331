import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { GraphQLAccessLevel } from "../api/listStaffAccessLevels";

enum TypeKeys {
    LOADING = "STAFF_ACCESS_LEVEL/LIST_BEGIN",
    LOADED = "STAFF_ACCESS_LEVEL/LIST_SUCCESS",
    FAILED = "STAFF_ACCESS_LEVEL/LIST_FAILURE",
}

const list = scaffoldList<GraphQLAccessLevel, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
