import { withSvg } from "core/components/svg/Svg";

export const Bin = withSvg(
    24,
    24
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.7125 1.75C9.58984 1.75 9.4722 1.79873 9.38546 1.88546C9.29873 1.9722 9.25 2.08984 9.25 2.2125V2.75H14.75V2.2125C14.75 2.15177 14.738 2.09162 14.7148 2.03551C14.6916 1.9794 14.6575 1.92841 14.6145 1.88546C14.5716 1.84252 14.5206 1.80845 14.4645 1.78521C14.4084 1.76196 14.3482 1.75 14.2875 1.75H9.7125ZM16.75 2.75V2.2125C16.75 1.88912 16.6863 1.5689 16.5626 1.27014C16.4388 0.971378 16.2574 0.699914 16.0288 0.47125C15.8001 0.242585 15.5286 0.0611988 15.2299 -0.0625533C14.9311 -0.186306 14.6109 -0.25 14.2875 -0.25H9.7125C9.0594 -0.25 8.43306 0.00944138 7.97125 0.47125C7.50944 0.933058 7.25 1.5594 7.25 2.2125V2.75H4.32161C4.31618 2.74996 4.31073 2.74996 4.30528 2.75H2.25C1.69772 2.75 1.25 3.19772 1.25 3.75C1.25 4.30228 1.69772 4.75 2.25 4.75H3.37218L4.40075 21.4554C4.42117 22.1896 4.72191 22.8885 5.2417 23.4083C5.76693 23.9335 6.47506 24.2351 7.2177 24.2498L7.2375 24.25H16.7625L16.7823 24.2498C17.5249 24.2351 18.2331 23.9335 18.7583 23.4083C19.2781 22.8885 19.5788 22.1896 19.5993 21.4554L20.6278 4.75H21.75C22.3023 4.75 22.75 4.30228 22.75 3.75C22.75 3.19772 22.3023 2.75 21.75 2.75H19.6947C19.6893 2.74996 19.6838 2.74996 19.6784 2.75H16.75ZM18.624 4.75H5.37597L6.39811 21.351C6.39896 21.3649 6.39953 21.3788 6.3998 21.3927C6.40428 21.6187 6.49606 21.8342 6.65591 21.9941C6.81387 22.152 7.02617 22.2435 7.24925 22.25H16.7507C16.9738 22.2435 17.1861 22.152 17.3441 21.9941C17.5039 21.8342 17.5957 21.6187 17.6002 21.3927C17.6005 21.3788 17.601 21.3649 17.6019 21.351L18.624 4.75Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.19456 5.75157C8.74599 5.72093 9.21785 6.14312 9.24849 6.69456L9.99849 20.1946C10.0291 20.746 9.60693 21.2179 9.0555 21.2485C8.50406 21.2791 8.0322 20.8569 8.00157 20.3055L7.25157 6.8055C7.22093 6.25406 7.64312 5.7822 8.19456 5.75157ZM15.8055 5.75157C16.3569 5.7822 16.7791 6.25406 16.7485 6.8055L15.9985 20.3055C15.9679 20.8569 15.496 21.2791 14.9446 21.2485C14.3931 21.2179 13.9709 20.746 14.0016 20.1946L14.7516 6.69456C14.7822 6.14312 15.2541 5.72093 15.8055 5.75157ZM12 5.75003C12.5523 5.75003 13 6.19774 13 6.75003V20.25C13 20.8023 12.5523 21.25 12 21.25C11.4477 21.25 11 20.8023 11 20.25V6.75003C11 6.19774 11.4477 5.75003 12 5.75003Z"
        />
    </>
));
