import styles from "../ListItem.module.scss";
import classNames from "classnames";
import { FiltersContext } from "features/posCatalogueImport/context/FiltersContext";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { PosCatalogueImportItem } from "features/posCatalogueImport/types";
import { ReactElement } from "react";
import { RowSelection } from "../RowSelection";
import { useContext } from "react";

export interface ListItemProps {
    disableSelection?: boolean;
    item: PosCatalogueImportItem;
    formatCurrency: (x: number) => string;
    parentItem: PosCatalogueImportItem | null;
}

export const ListItem = (props: ListItemProps): ReactElement => {
    const {
        disableSelection,
        formatCurrency,
        item,
        item: {
            displayName,
            key,
            categories,
            parentId,
            // calories TODO https://meanduapp.atlassian.net/browse/INT-277
        },
        parentItem,
    } = props;

    let { sku, price } = item;

    const displayNamePrefix = parentId ? "⸺ " : "";
    const containerStyle = parentId ? styles.containerSecondary : styles.containerPrimary;
    const { filterText } = useContext(FiltersContext);

    if (item.collapse && item.children?.length === 1) {
        price = item.children[0].price;
        sku = item.children[0].sku;
    }

    return (
        <div role="row" className={containerStyle}>
            <div className={styles.innerContainer}>
                <div role="cell" className={styles.check}>
                    <RowSelection
                        key={item.key}
                        collectionName="products"
                        item={item}
                        disabled={disableSelection || false}
                        selectRelated={true}
                        parentItem={parentItem}
                    />
                </div>
                <div className={styles.contentContainer}>
                    <label htmlFor={key} role="cell" className={styles.cellName}>
                        <span className={styles.displayNamePrefix} aria-hidden>
                            {displayNamePrefix}
                        </span>
                        <span className="sr-only">{parentId ? "child" : "parent"}</span>
                        <HighlightSearchTerm source={displayName} searchTerm={filterText} />
                    </label>
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellHideFirst)}>
                        {categories?.map((c) => c.displayName).join(", ")}
                    </span>
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber, styles.cellFirstWrap)}>
                        <span className={styles.cellLabel}>Price </span>
                        {price ? formatCurrency(price) : ""}
                    </span>
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber)}>
                        <span className={styles.cellLabel}>POS ID: </span>
                        <HighlightSearchTerm source={sku || ""} searchTerm={filterText} />
                    </span>
                    {/* TODO https://meanduapp.atlassian.net/browse/INT-277 */}
                    {/* <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber, styles.cellHideLast)}>
                    </span> */}
                </div>
            </div>
        </div>
    );
};
