import { withSvg } from "core/components/svg/Svg";

export const CancelBig = withSvg(
    24,
    24
)(() => (
    <>
        <path d="M19.9207 18.33L13.591 12L19.9203 5.67079C20.1252 5.45861 20.2386 5.17443 20.236 4.87946C20.2335 4.58449 20.1152 4.30233 19.9066 4.09374C19.698 3.88516 19.4158 3.76684 19.1209 3.76428C18.8259 3.76172 18.5417 3.87511 18.3295 4.08004L11.9999 10.4093L5.67066 4.07929C5.56618 3.97481 5.44215 3.89194 5.30565 3.8354C5.16915 3.77886 5.02284 3.74976 4.8751 3.74976C4.72735 3.74976 4.58104 3.77886 4.44454 3.8354C4.30804 3.89194 4.18401 3.97481 4.07953 4.07929C3.86854 4.29029 3.75 4.57646 3.75 4.87485C3.75 5.0226 3.7791 5.1689 3.83564 5.30541C3.89218 5.44191 3.97506 5.56594 4.07953 5.67041L10.4088 12.0004L4.07878 18.3304C3.8739 18.5426 3.76058 18.8268 3.76321 19.1218C3.76584 19.4168 3.88422 19.6989 4.09286 19.9075C4.30149 20.116 4.58368 20.2342 4.87866 20.2367C5.17363 20.2392 5.45778 20.1258 5.66991 19.9208L11.9999 13.5912L18.3292 19.9212C18.5402 20.1322 18.8263 20.2508 19.1248 20.2508C19.4232 20.2509 19.7094 20.1323 19.9205 19.9214C20.1315 19.7104 20.2501 19.4242 20.2501 19.1257C20.2502 18.8273 20.1317 18.5411 19.9207 18.33Z" />
    </>
));
