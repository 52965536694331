import "./index.scss";
import App from "./App";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider, useSelector } from "react-redux";
import featureConfig, { AppState } from "features";
import thunkMiddleware from "redux-thunk";
import { ConnectedRouter, connectRouter, routerMiddleware } from "connected-react-router";
import { history } from "./foundation/history";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { initAppInsights, reactPlugin } from "./common/appInsights";
import { useEffect, useState } from "react";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { MenuProvider } from "features/structure/screen/nav/MenuContainer";
import { createFocusStylesObserver } from "core/utility/focus";
import { preventAutoZoom } from "core/utility/input/preventAutoZoom";
import { getSelectedRegion } from "features/region/selectors";
import { auth } from "common/auth";
import { createRoot } from "react-dom/client";
import { Spinner } from "core/components/spinner";

const createRootReducer = () =>
    combineReducers<AppState>({
        router: connectRouter(history),
        ...featureConfig.reducers,
    });

// FIXME fix the types here
const store = createStore(
    createRootReducer(),
    // @ts-ignore
    composeWithDevTools(applyMiddleware(...featureConfig.middleware, routerMiddleware(history), thunkMiddleware) as any)
);

export const AppWithAppInsights = () => {
    const [isLoading, setLoading] = useState(true);
    const activeRegion = useSelector(getSelectedRegion);
    const authorised = auth.isAuthenticated();

    useEffect(() => {
        if (activeRegion && isLoading) {
            initAppInsights(store.getState, activeRegion.appInsightsConnectionString);
            setLoading(false);
        }
    }, [activeRegion, isLoading]);

    if (!authorised) {
        return <App />;
    } else if (isLoading) {
        return (
            <div className="loader__container loader__container--overlay">
                <Spinner size="large" />
            </div>
        );
    } else {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <AppInsightsErrorBoundary
                    onError={() => <h1>Oops, something went wrong. Please try again</h1>}
                    appInsights={reactPlugin}
                >
                    <App />
                </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
        );
    }
};

const appElement = document.getElementById("root") as HTMLElement;

const root = createRoot(appElement);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <MenuProvider>
                <AppWithAppInsights />
            </MenuProvider>
        </ConnectedRouter>
    </Provider>
);

createFocusStylesObserver(document.body).start();

preventAutoZoom();
