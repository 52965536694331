import { AppState } from "features";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { EditableTakeawayOptions } from "features/takeaway/types";
import { createSelector } from "reselect";

export const getSaveTakeawayOptionsTrackingEventData = createSelector(
    getActiveLocation,
    (_: AppState, update: EditableTakeawayOptions) => update,
    (existing, update) => {
        const changes: any = {
            section_id: update.sectionId,
        };

        const takeawayOptions = existing && existing.takeawayOptions;
        changes.initial_state = takeawayOptions?.enabled;
        changes.final_state = update.enabled;

        if (takeawayOptions?.minReadyTimeMins !== update.minReadyTimeMins) {
            changes.min_order_lead_time = update.minReadyTimeMins;
        }

        if (takeawayOptions?.contactPhoneNumber !== update.contactPhoneNumber) {
            changes.phone_number = update.contactPhoneNumber;
        }

        if (takeawayOptions?.collectionInstructions !== update.collectionInstructions) {
            changes.instructions = update.collectionInstructions;
        }

        const scheduleOptions = existing && existing.takeawayOptions.scheduleOptions;
        if (scheduleOptions?.enabled !== update.scheduleOptions.enabled) {
            changes.scheduling_orders = update.scheduleOptions.enabled;
        }

        if (scheduleOptions?.scheduleWindowMinutes !== update.scheduleOptions.scheduleWindowMinutes) {
            changes.time_increments = update.scheduleOptions.scheduleWindowMinutes;
        }

        const venueAddress = existing && existing.venueAddress;
        if (venueAddress?.addressLine1 !== update.venueAddress.addressLine1) {
            changes.address_line1 = update.venueAddress.addressLine1;
        }

        if (venueAddress?.addressLine2 !== update.venueAddress.addressLine2) {
            changes.address_line2 = update.venueAddress.addressLine2;
        }

        if (venueAddress?.city !== update.venueAddress.city) {
            changes.address_city = update.venueAddress.city;
        }

        if (venueAddress?.region !== update.venueAddress.region) {
            changes.address_region = update.venueAddress.region;
        }

        if (venueAddress?.postcode !== update.venueAddress.postcode) {
            changes.address_postcode = update.venueAddress.postcode;
        }

        if (venueAddress?.country !== update.venueAddress.country) {
            changes.address_country = update.venueAddress.country;
        }

        if (venueAddress?.googleBusinessName !== update.venueAddress.googleBusinessName) {
            changes.address_google_business_name = update.venueAddress.googleBusinessName;
        }

        return {
            event: {
                name: "TAKEAWAY/CONFIGURATION_SET",
            },
            customProperties: changes,
        };
    }
);
