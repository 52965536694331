import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { lazy } from "react";
import { Route } from "react-router";
import { withPermissions } from "common/permissions/components/withPermission";

const YumpingoOptionsPage = lazy(() =>
    import("./edit/YumpingoOptionsPage").then((module) => ({
        default: module.YumpingoOptionsPage,
    }))
);

const EditYumpingoOptionsPage = withPermissions(YumpingoOptionsPage, "settings:read");

export function Yumpingo() {
    return (
        <Route
            key="yumpingo"
            path={`/:region/venue/:location/yumpingo`}
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <EditYumpingoOptionsPage />
                </EditWrapper>
            )}
        />
    );
}
