import { withSvg } from "core/components/svg/Svg";

export const More = withSvg(
    64,
    64
)(() => (
    <>
        <path d="M8.00098 40C12.4193 40 16.001 36.4183 16.001 32C16.001 27.5817 12.4193 24 8.00098 24C3.5827 24 0.000976562 27.5817 0.000976562 32C0.000976562 36.4183 3.5827 40 8.00098 40Z" />
        <path d="M55.999 40C60.4173 40 63.999 36.4183 63.999 32C63.999 27.5817 60.4173 24 55.999 24C51.5807 24 47.999 27.5817 47.999 32C47.999 36.4183 51.5807 40 55.999 40Z" />
        <path d="M31.999 40C36.4173 40 39.999 36.4183 39.999 32C39.999 27.5817 36.4173 24 31.999 24C27.5807 24 23.999 27.5817 23.999 32C23.999 36.4183 27.5807 40 31.999 40Z" />
    </>
));
