export type TreeNodeChild<T, S = {}> = S & {
    id: string;
    data: T;
};

export type TreeNodeParent<T, S = {}> = TreeNodeChild<T, S> & {
    children: TreeNode<T, S>[];
};

export type TreeNode<T, S = {}> = TreeNodeChild<T, S> | TreeNodeParent<T, S>;

export function isParent<T, S>(node: TreeNode<T, S> | null): node is TreeNodeParent<T, S> {
    if (!node) {
        return false;
    }
    return "children" in node;
}

export function isChild<T, S>(node: TreeNode<T, S> | null): node is TreeNodeChild<T, S> {
    if (!node) {
        return false;
    }
    return !isParent(node);
}

// DFS tree vistor which execs passed in callback with each node and it's parents
export function visit<T, S>(
    nodes: TreeNode<T, S>[],
    callback: (node: TreeNode<T, S>, parents: TreeNodeParent<T, S>[]) => void,
    parents: TreeNodeParent<T, S>[] = []
): void {
    nodes.forEach((node) => {
        callback(node, parents);

        if (isParent(node)) {
            parents.push(node);
            visit(node.children, callback, parents);
            parents.pop();
        }
    });
}

// return new tree with the results of calling provided function on every node in the tree
export function mapTree<T, S, R>(
    nodes: TreeNode<T, S>[],
    callback: (node: TreeNode<T, S>, parents: TreeNodeParent<T, S>[]) => R,
    parents: TreeNodeParent<T, S>[] = []
): R[] {
    return nodes.map((node) => {
        if (isParent(node)) {
            parents.push(node);
            const children = mapTree(node.children, callback, parents);
            parents.pop();

            return {
                ...callback(node, parents),
                children,
            };
        }

        return callback(node, parents);
    });
}

export function clone<T, S>(nodes: TreeNode<T, S>[]): TreeNode<T, S>[] {
    return mapTree<T, S, TreeNode<T, S>>(nodes, (node) => node);
}

export function flatMap<T, S, R>(
    nodes: TreeNode<T, S>[],
    callback: (node: TreeNode<T, S>, parents: TreeNodeParent<T, S>[]) => R,
    parents: TreeNodeParent<T, S>[] = [],
    results: R[] = []
): R[] {
    nodes.forEach((node) => {
        results.push(callback(node, parents));

        if (isParent(node)) {
            parents.push(node);
            flatMap(node.children, callback, parents, results);
            parents.pop();
        }
    });

    return results;
}

// get unique string key for a node based on the ids of it's parents and itself
export function generateKey<T, S>(node: TreeNode<T, S>, parents: TreeNodeParent<T, S>[]): string {
    return [...parents, node].map(({ id }) => id).join("-");
}

// total number of nodes in the tree
export function size<T, S>(nodes: TreeNode<T, S>[]): number {
    return flatMap(nodes, () => true).length;
}
