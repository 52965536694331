import classNames from "classnames";
import { forwardRef, ReactElement } from "react";
import styles from "./Icon.module.scss";

export type Size = "largest" | "extraLarge" | "large" | "medium" | "small" | "tiny";

export type VerticalAlign = "baseline" | "top" | "middle" | "bottom" | "sub" | "text-top";
export interface Props {
    as?: React.ElementType;
    children?: React.ReactNode;
    className?: string;
    size?: Size;
    verticalAlign?: VerticalAlign;
    outline?: boolean;
}

export const Icon = forwardRef<ReactElement, Props & Record<string, any>>(
    (
        { children, className, as: Component = "span", size = "medium", outline = false, verticalAlign, ...rest },
        ref
    ) => {
        if (!Component) {
            return null;
        }

        return (
            <Component
                className={classNames([
                    styles[size],
                    verticalAlign && styles[verticalAlign],
                    outline && styles.outline,
                    className,
                ])}
                ref={ref}
                {...rest}
            >
                {children}
            </Component>
        );
    }
);
